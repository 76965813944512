import Medium from "../../../assets/medium.svg";
import Telegram from "../../../assets/telegram.svg";
import Twitter from "../../../assets/twitter.svg";
import Github from "../../../assets/github.svg";
import Email from "../../../assets/email.svg";
export interface IconInfo {
  path: string;
  text: string;
  imgSrc: string;
  type: "link" | "copy";
}

export const iconInfos: IconInfo[] = [
  {
    path: "https://medium.com/@omnibtc",
    imgSrc: Medium,
    text: "medium",
    type: "link",
  },
  {
    path: "https://t.me/comingchatapp",
    imgSrc: Telegram,
    text: "telegram",
    type: "link",
  },
  {
    path: "https://twitter.com/ComingChatApp",
    imgSrc: Twitter,
    text: "twitter",
    type: "link",
  },
  // {
  //   path: "https://discord.gg/uRzpk8n53G",
  //   imgSrc: Discord,
  //   text: "discord",
  //   type: "link",
  // },
  {
    path: "https://github.com/coming-chat",
    imgSrc: Github,
    text: "github",
    type: "link",
  },
  {
    path: "comingchat@gmail.com",
    imgSrc: Email,
    text: "email",
    type: "copy",
  },
];
