import React, { useEffect } from "react";
import styled from 'styled-components';
import icon_logo from '../../assets/dashboard/logo_coming_blue.svg'
import icon_dashboard from '../../assets/dashboard/dashboard.svg'
import icon_twitter from '../../assets/dashboard/Twitter.svg'
import icon_discord from '../../assets/dashboard/discord.svg'

const Wrapper = styled.div`
  width: 10.7vw;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #EEF1F5;
  .index-link{
    width: 100%;
    height: 7.56vw;
    border-bottom: 1px solid #EEF1F5;
    .logo{
      width: 3.56vw;
      height: 3.56vw;
    }
  }
`

const LinkItem = styled.div`
  width: 100%;
  height: 9.37vw;
  border-bottom: 1px solid #EEF1F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #131415;
  &.active{
    background: #FAF9FC;
    color: #2B33FF; 
  }
  :hover{
    background: #FAF9FC;
    cursor: pointer;
  }
  .icon{
    width: 2.25vw;
    height: 2.25vw;
    margin-bottom: 0.625vw;
  }
  .name{
    font-size: 0.875vw;
    font-weight: 600;
    line-height: 1vw;
  }
`

const LeftMenu = (): React.ReactElement => {
  return <Wrapper>
      <div className="flex flex-row items-center justify-center index-link cursor-pointer" onClick={() => window.location.href = '/'}>
        <img className="logo" src={icon_logo} alt=""/>
      </div>
    <LinkItem className="active">
      <img className="icon" src={icon_dashboard} alt=""/>
      <span className="name">Dashboard</span>
    </LinkItem>
    <LinkItem onClick={()=> window.open('https://twitter.com/ComingChatApp')}>
      <img className="icon" src={icon_twitter} alt=""/>
      <span className="name">Twitter</span>
    </LinkItem>
    <LinkItem onClick={()=> window.open('https://discord.gg/YRTpDTMQud')}>
      <img className="icon" src={icon_discord} alt=""/>
      <span className="name">Discord</span>
    </LinkItem>
  </Wrapper>
};

export default LeftMenu;
