import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import icon_logo from '../../assets/home/LOGO-point.svg'

import downLoadIcon from './assets/download_blue.svg'
import HomeMbolAnimation from "./homeMbolAnimation";
import title_logo from './assets/text_icon.svg'
const Wrapper = styled.div`
  background: #D6DCED;
  justify-content: center;
  align-items: center;
    display:flex;
    align-items:center;
    position: relative;
    width: 100vw;
    height: 177.867vw;
    img.bg2 {
      position: absolute;
      width: 100vw;
      height: 177.867vw;
      left: 0;
      top: 0;
    }
    img.mbolbg {
      width: 85.6vw;
      height: 85.7vw;
      position: absolute;
      z-index:0;
      left: 7.5vw;
      top: 20vw;
      transform: scale(1.004,1);
    }
`;
const MainWrap = styled.div`
  width: 54.271vw;
  //height: 25.313vw;
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  bottom: 12vw;
  left: 2vw;
  flex-direction: column;
  * {
    font-weight: normal !important;
  }
  .zh {
    position: relative;
    bottom: 25vw;
    img.mbolTitleZH {
      display: flex !important;
      width: 64.1vw;
      height: 26.3vw;
      transform: scale(1.6);
    }
  }
  .en {
    position: relative;
    right: 3vw;
    //bottom: 3vw;
    img.mbolTitleEN {
      display: flex !important;
      width: 68vw;
      height: 47vw;
      transform: scale(1.6);
    }
    img.mbolTitleEN {
      display: none;
    }
  }
  .content {
    position: absolute;
    font-weight: 100;
    width: 49.28vw !important;
    color: #999999;
    font-size: 3.733vw;
    line-height: 5.333vw !important;
    top: 28vw;
    right: 2vh;
    text-align: center;
    word-break: break-words;
  }
`;

const Info = styled.div`
  //max-width: 22.9vw;
  flex-wrap: wrap;
  font-size: 3.73vw;
  font-weight: 400;
  color: #000000;
  line-height: 5.4vw;
  text-align: center;
  position: relative;
  bottom: 15vw;
`;

const DownLoadBtn = styled.div`
  position: absolute;
  z-index: 2;
  background: #FFFFFF;
  border-radius: 7px;
  border: 1px solid #000000;
  padding: 2.9vw 15.6vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: -2.4vw;
  >img{
    width: 5.56vw;
    margin-right: 2.97vw;
  }
  .btnName{
    font-size: 4.3vw;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: #000000;
    line-height: 5.4vw
  }
`

const IndexMbolPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Wrapper>
        {/*<img src={BG2} alt="" className="bg2" />*/}
        <img src={icon_logo} alt="" className="mbolbg" />
        <MainWrap>
          <div className="zh">
             <img src={title_logo} alt="" className="mbolTitleZH" />
          </div>
          <Info>{t('All-in-One Portal and Solution for Web3 & AI')}</Info>
          <DownLoadBtn onClick={()=> window.location.href = '/download'}>
            <img src={downLoadIcon} alt=""/>
            <div className='btnName'>{t('Download')}</div>
          </DownLoadBtn>
        </MainWrap>
        <HomeMbolAnimation/>
      </Wrapper>
    </>
  );
};

export default IndexMbolPage;
