import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useSettings } from '../../hooks/useSettings';
import StatisticsItem from './StatisticsItem';
import { DASHBOARD_LIST } from './WebDashboard';
import MobileInfoCard from './MobileInfoCard';
import logoIcon from '../../assets/dashboard/logo_coming_blue.svg'
import bgWithDot from '../../assets/dashboard/amount_bg.svg'
import bgMobile from '../../assets/home/statisticsBgMobile.svg'
import useDashboardStore from '../../store';

const MobileDashBoard = (): React.ReactElement => {
  const { totalStatisticsHomePage, dmensTraceInfoData, redPacketTraceInfoData, stakingTraceInfoData} = useDashboardStore()
  const [currenTab, setCurrentTab] = useState<'All' | 'Staking' | 'RedPacket' | 'DMens'>('All')
  const { setRouteName, setIsMenuOpen } = useSettings();
  useEffect(() => {
    setRouteName("dashboard");
    setIsMenuOpen(false);
  }, [setIsMenuOpen, setRouteName]);

  const Default = <MobileInfoCard img={DASHBOARD_LIST['default'].img} title={DASHBOARD_LIST['default'].title} content={DASHBOARD_LIST['default'].content} statistics={<StatisticsItem bg={isMobile? bgMobile : bgWithDot} statisticsList={totalStatisticsHomePage} />}/>
  const Staking = <MobileInfoCard img={DASHBOARD_LIST['staking'].img} title={DASHBOARD_LIST['staking'].title} content={DASHBOARD_LIST['staking'].content} statistics={<StatisticsItem statisticsList={stakingTraceInfoData} />}/>
  const RedPacket = <MobileInfoCard img={DASHBOARD_LIST['redPacket'].img} title={DASHBOARD_LIST['redPacket'].title} content={DASHBOARD_LIST['redPacket'].content} statistics={<StatisticsItem statisticsList={redPacketTraceInfoData} />}/>
  const DMens = <MobileInfoCard img={DASHBOARD_LIST['DMens'].img} title={DASHBOARD_LIST['DMens'].title} content={DASHBOARD_LIST['DMens'].content} statistics={<StatisticsItem statisticsList={dmensTraceInfoData} />}/>
  const All = <>
    {Default}{Staking}{RedPacket}{DMens}
  </>
  const tabList = {
    All,
    Staking,
    RedPacket,
    DMens
  }

  return <div className={"flex flex-col"} style={{width:'100%', background: '#FAF9FC'}}>
      <TopMenuWrapper>
        <div className="flex flex-row justify-start items-center cursor-pointer" onClick={() => window.location.href = '/'}>
          <img src={logoIcon} alt="" className="logo"/>
          <span className="title">Dashboard</span>
        </div>
        <div className="tab">
          <div className={`tab-item ${currenTab === 'All' && 'active'}`} onClick={()=>setCurrentTab('All')}>All</div>
          <div className={`tab-item ${currenTab === 'Staking' && 'active'}`} onClick={()=>setCurrentTab('Staking')}>Staking</div>
          <div className={`tab-item ${currenTab === 'RedPacket' && 'active'}`} onClick={()=>setCurrentTab('RedPacket')}>RedPacket</div>
          <div className={`tab-item ${currenTab === 'DMens' && 'active'}`} onClick={()=>setCurrentTab('DMens')}>DMens</div>
        </div>
      </TopMenuWrapper>
      {tabList[currenTab]}
    </div>
};

export default MobileDashBoard;

const TopMenuWrapper = styled.div`
  width: 100%;
  min-height: 23.4vw;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 4.26vw 0 0 4.26vw;
  .logo{
    width: 6.4vw;
    height: 6.4vw;
    margin-right: 2.1vw;
  }
  .title{
    font-size: 4.8vw;
    font-weight: bold;
    color: #000000;
    line-height: 5.73vw;
  }
  .tab{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 5.867vw;
    font-size: 3.2vw;
    font-weight: 600;
    color: #000000;
    line-height: 3.87vw;
    .tab-item{
      border-bottom: 0.53vw solid transparent;
      background-color: transparent;
      text-decoration: none;
      position: relative;
      font-size: 1vw;
      color: #333;
      padding-bottom: 1.2vw;
      transition: border-bottom 0.3s ease-in-out;
      &.active{
        border-bottom: 0.53vw solid #2B33FF;
      }
      :hover{
        border-bottom: 0.53vw solid #2B33FF;
      }
    }
  }
`
