import React from "react";
import styled from "styled-components";
import arrowDown from "../../assets/social/ArrowDown.svg";

const Wrapper = styled.div`
  margin-top: 0.69vw;
  margin-left: 15.9vw;
  width: 100vw;
  display: flex;
  flex-direction: row;

  .title {
    font-size: 1.11vw;
    font-weight: 600;
    color: #131415;
    line-height: 1.38vw;
    padding: 0.5vw 2.08vw 0 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    max-height: 10vw;
    flex-wrap: wrap;
  }

  .item {
    min-width: 15.67vw;
    margin-right: 2.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.69vw;
    background: white;
    cursor: pointer;
    color: #131415;
    @keyframes bg-color {
      0% {
        background: white
      }
      100% {
        background: #f4f5f7
      }
    }

    :hover {
      animation: bg-color 0.1s ease-in;
      background: #f4f5f7;
      border-radius: max(0.41vw, 6px);
      color: #2b33ff;

      & > img {
        visibility: visible;
      }
    }

    > span {
      font-size: 1vw;
      font-weight: 500;
      line-height: 1.11vw;
    }

    & > img {
      visibility: hidden;
      width: 1.11vw;
      height: 1.11vw;
    }
  }
`;

const Features = (): React.ReactElement => {
  function onTopClick(value: string) {
    window.location.hash = value;
  }

  const targetList = [
    {
      key:"comingChat",
      value:"ComingChat",
    },
    {
      key:"CID",
      value:"CID",
    },
    {
      key:'chatGPT',
      value: "ChatGPT Plugins",
    },
    {
      key:"OmnichainWallet",
      value:"Omnichian Wallet",
    },
    {
      key:'dmens',
      value:'Dmens'
    },
    {
      key:'moreFeatures',
      value:'More Features'
    },
  ];

  return (
    <Wrapper>
      <div className="title">FEATURES</div>
      <div className="info">
        {targetList.map((item) => {
          return (
            <div className="item" onClick={() => onTopClick(item.key)}>
              <span>{item.value}</span>
              <img src={arrowDown} alt="" />
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Features;