import React, { useEffect } from "react";
import { GroupPageWrapper } from "./styles";
import { useSettings } from "../../hooks/useSettings";
import PcPart from "./PcPart";
import MobilePart from "./MobilePart";
import styled from "styled-components";
import WarnAndroid from "../../components/Warn/WarnAndroid";
import WarnIOS from "../../components/Warn/WarnIOS";

const MbolWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupPage = (): React.ReactElement => {
  const { setRouteName, setIsMenuOpen, isIOS, isAndroid, isSafari, isBrowser } =
    useSettings();

  useEffect(() => {
    setRouteName("group");
    setIsMenuOpen(false);
  }, []);

  return (
    <GroupPageWrapper className="flex flex-row">
      <PcPart />
      <MbolWrap>
        {isIOS && !isSafari && <WarnIOS />}
        {isAndroid && !isBrowser && <WarnAndroid />}
        <MobilePart />
      </MbolWrap>
    </GroupPageWrapper>
  );
};

export default GroupPage;
