import React, { useRef } from "react";
import AboutUs from "../home/AboutUs";
import { Parallax, ParallaxLayer, IParallax } from "@react-spring/parallax";
import Download from "./download";
import SwiperContent, { RouteList } from "../../components/SwiperContent"


const DownloadPage: React.FC = () => {
  const slides: React.ReactNode[] = [
    <Download />,
    <AboutUs />
  ]
  const routeList: RouteList = {
    0: "download",
    1: "contact",
  }
  return (
    <>
      <Download/>
      {/*<div style={{ width: "100%", height: "100%" }}>*/}
      {/*  <SwiperContent routeList={routeList} slides={slides}/>*/}
      {/*</div>*/}
    </>
  );
};

export default DownloadPage;
