import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { mobileImgList } from '../../pages/download/utils/data';
import DownloadImg from '../../pages/download/DownloadImg';
import { TranslationItem } from '../Header/MenuContent';
import { footerData } from './footerData';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Wrapper = styled.div`
  background: #000000;
  position: relative;
  width: 100vw;
  height: 20vw
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.52vw 3.05vw;
`;

const TextGray = styled.div`
  font-size: 0.8vw;
  font-weight: 600;
  color: #666666;
  line-height: 1.11vw;
`

const Line = styled.div`
  width: 100%;
  opacity: 0.4;
  border: 0.5px solid #FFFFFF;
`

const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4.735vw;
  .title{
    max-width: 34.79vw;
    flex-wrap: wrap;
    white-space: normal;
    font-size: 2.5vw;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 3.04vw;
  }
  .downloadIcon {
    display: flex;
    flex-direction: row;
    img {
      height: 3.75vw;
      margin-right: 2.22vw;
      cursor: pointer;
    }
    a:nth-child(3){
      >img{
        margin-right: 0vw;
      }
    }
    p {
      margin-top: 9px;
      width: 73%;
      text-align: center;
      height: 28px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
`

const SplitLine = styled.div`
  height: 1vw;
  margin: 0 10px;
  opacity: 0.4;
  border: 1px solid #FFFFFF;
`
const Links = styled.div`
  display: flex;
  flex-direction: column;
  .info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2.78vw 0;
    .title{
      font-size: 1vw;
      font-weight: 600;
      color: #666666;
      line-height: 1.11vw;
    }
    .content{
      display: flex;
      flex-direction: row;
      cursor: pointer;
    }
  }
  .info2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .littleContent {
        .imgIcon {
          margin-right: 10px;
          opacity: 0.6;
          &:hover {
            opacity: 1;
          }
        }
        .iconwechats {
          position: relative;
          .codes {
            position: absolute;
            bottom: 32px;
            left: 0;
            display: none;
            width: 150px;
            height: 150px;
          }
          &:hover .codes {
            display: block;
          }
        }
        .copytoggle {
          position: relative;
          .copyemails {
            width: 150px;
            height: 42px;
            line-height: 42px;
            color: #fff;
            background: #242ce0;
            text-align: center;
            border-radius: 4px;
            position: absolute;
            bottom: 40px;
            right: 0;
            .arrow {
              position: relative;
              &:after{
                content: "";
                border: 10px solid transparent;
                border-top-color: #242ce0;
                position: absolute;
                bottom: -32px;
                right: -10px;
              }
            }
          }
        }
      }
  }
`

const LinkItem = styled.div`
  font-size: 1vw;
  font-weight: 600;
  color: #666666;
  line-height: 1.11vw;
  border-bottom: 1px solid transparent;
  margin:0 0 0 4.6vw;
  padding-bottom:1vw;
  :hover{
    color: #FFFFFF;
    border-bottom: 1px solid white;
  }
`

const Footer = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const translationList: TranslationItem[] = [
    {
      text: "English",
      language: "en",
    },
    {
      text: "中文",
      language: "zh",
    }
  ];
  const [show, SetShow] = useState<boolean>(false);
  const [emails,] = useState('comingchat@gmail.com');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => setCopied(false), 3001);
    return () => {
      clearInterval(timer);
    };
  }, [copied]);

  return (
    <Wrapper>
      <FirstLine>
        <div className='title'>{t('Ready to get started with ComingChat ?')}</div>
        <div className="downloadIcon flex">
          {mobileImgList.map((item) => (
            <DownloadImg
              name={item.name}
              link={item.link}
              imgSrc={item.imgSrc}
              key={item.name}
              version={item.version}
            />
          ))}
        </div>
        {/*<LanguageSwitcher>*/}
        {/*  <div*/}
        {/*    className="translate"*/}
        {/*    onClick={(e) => {*/}
        {/*      e.stopPropagation();*/}
        {/*      SetShow(!show);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*      <span className="translate">*/}
        {/*        <img src={LangIcon} className="LanguateIcon" />*/}
        {/*        <span className="text">*/}
        {/*          {translationList.map(*/}
        {/*            (t) => i18n.language === t.language && t.text*/}
        {/*          )}*/}
        {/*        </span>*/}
        {/*        <img src={DropDown} alt="" className="DropDown" />*/}
        {/*      </span>*/}
        {/*    {show && (*/}
        {/*      <div className="translateList">*/}
        {/*        {translationList.map((t) => (*/}
        {/*          <TranslateButton*/}
        {/*            text={t.text}*/}
        {/*            language={t.language}*/}
        {/*            key={t.text}*/}
        {/*          />*/}
        {/*        ))}*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</LanguageSwitcher>*/}
      </FirstLine>
      <Line/>
      <Links>
        <div className='info'>
          <TextGray>{t('CONTACT US')}</TextGray>
          <div className='content'>
            {footerData.friendLinks.map(({name,linkTo})=>{
              return <a href={linkTo} target={`${linkTo.includes('https')?'_blank':'_self'}`}><LinkItem key={name}>{name}</LinkItem></a>})}
          </div>
        </div>
        <div className='info2'>
          <div className="littleContent flex">
            {footerData.IconList.map(({title, img, linkTo}) => {
              return <a href={linkTo} key={title} target="_blank" rel="noopener noreferrer">
                <img src={img} className={`imgIcon cursor-pointer`} alt="img"/>
              </a>;
            })}
            <div className="copytoggle">
              <CopyToClipboard text={emails} onCopy={() => setCopied(true)}>
                <img src={footerData.email.img} className={`imgIcon cursor-pointer`} alt="img"/>
              </CopyToClipboard>
              {copied && <span className="copyemails">
                  <span className="arrow">
                    Copied address
                  </span>
                </span>}
            </div>
          </div>
          <div className='flex'>
            <TextGray className='title'>{t('All rights reserved © 2021 Coming')}</TextGray>
            <SplitLine/>
            <a href="https://www.coming.chat/privacy/" target='_blank'>
              <TextGray className='title'>{t('Privacy Policy')}</TextGray>
            </a>
            <SplitLine/>
            <a href="https://www.coming.chat/privacy/" target='_blank'>
              <TextGray className='title'>{t('TERMS OF SERVICE')}</TextGray>
            </a>
          </div>
        </div>
      </Links>
    </Wrapper>
  );
};

export default Footer;
