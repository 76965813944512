import * as React from "react";
import styled from "styled-components";
import Row from "./assets/Row.png";

const StyledWarnIOS = styled.div`
  background: #242ce0;
  height: 16.8vw;
  padding: 3.5vw 6.7vw;
  font-size: 3.5vw;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: inline-flex;
    width: 76.8vw;
    color: #ffffff;
  }
  img {
    // margin-top: 0% !important;
    width: 7.9vw !important;
    height: 9.5vw !important;
  }
`;

type WarnIOSProps = {};
const WarnIOS: React.FC<WarnIOSProps> = ({}) => {
  return (
    <StyledWarnIOS>
      <div>
        Click the button in the upper right corner, and then in the pop-up menu,
        click open in Safari.
      </div>
      <img src={Row} />
    </StyledWarnIOS>
  );
};

export default WarnIOS;
