import React from "react";
import styled from "styled-components";
import IndexMbolPage from "./mbolIndex";
import OmnichainMbolPage from "./mbolOmnichain";
import PrivateMbolPage from "./mbolPrivate";
import CIDMbolPage from "./mboCIDcard";
import MobComingChatInfo from './comingchat';
import MbolChatGPT from './mbolChatGPT';
import MbolDmens from './mbolDmens';
import MbolMoreFeature from './moreFeatures';
const MbolWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
`;
const HomeMbolPage = (): React.ReactElement => {
  return (
    <MbolWrap>
      <IndexMbolPage />
      <MobComingChatInfo/>
      <PrivateMbolPage />
      <CIDMbolPage/>
      <MbolChatGPT/>
      <OmnichainMbolPage />
      <MbolDmens/>
      <MbolMoreFeature/>
    </MbolWrap>
  );
};

export default HomeMbolPage;
