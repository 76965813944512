import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter, useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect'
import Header from "./components/Header";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import HomeMbolPage from "./pages/mbolhome/HomeMbolPage";
import GroupPage from "./pages/group";
import DownloadPage from "./pages/download";
import PrivacyPage from "./pages/privacy";
import Cid from "./pages/cid";
import About from "./pages/about";
import { useSettings } from "./hooks/useSettings";
import HomeWebPage from "./pages/home/HomeCbolPage";
import Footer from "./components/Footer/index";
import MobileHeader from './components/MobileHeader';
import DashBoard from './pages/dashboard';

const getFont = (language: any) => {
  // if (language?.split("zh")[0] === "") {
  //   return `font-family: "Georgia", serif;`;
  // } else {
  return `font-family: "Linux Libertine", "Times", serif;`;
  // }
};

const getLineHeight = (language: any) => {
  if (language?.split("en")[0] === "") {
    return "auto";
  } else {
    return "2.2vw";
  }
};

const I18nWrap = styled.div`
  height: 100%;
  background: white;
  * {
    ${(props: any) => getFont(props.language)}
  }
  .content,
  p {
    line-height: ${(props: any) => getLineHeight(props.language)};
  }
`;

const App: React.FC = (props) => {
  const { routeName, browserType } = useSettings();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const hiddenHeaderMenuList = ['group', 'dashboard']
  const hiddenFooterMenuList = ['group', 'privacy', 'dashboard']

  return (
    <I18nWrap {...{ language }}>
      {!hiddenHeaderMenuList.includes(routeName) && (
        <>
          {isMobile ?<MobileHeader/>: <Header/>}
        </>
      )}
      <BrowserRouter>
        <Switch>
          <Route exact path="/group">
            <GroupPage />
          </Route>
          <Route exact path="/dashboard">
            <DashBoard />
          </Route>
          <Route exact path="/download">
            <DownloadPage />
          </Route>
          <Route exact path="/privacy">
            <PrivacyPage />
          </Route>
          <Route exact path="/cid">
            <Cid />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/">
            {browserType === "CBOL" && <HomeWebPage />}
            {browserType === "MBOL" && <HomeMbolPage />}
          </Route>
        </Switch>
      </BrowserRouter>
      {!hiddenFooterMenuList.includes(routeName) && <>{!isMobile && <Footer />}</>}
    </I18nWrap>
  );
};

export default App;
