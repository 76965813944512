import Email from '../../assets/link/email.svg';
import Github from '../../assets/link/github.svg';
import Medium from '../../assets/link/Medium.svg';
import Telegram from '../../assets/link/telegram.svg';
import Twitter from '../../assets/link/twitter.svg';

export const footerData = {
  background: '#0B0F12',
  textColor: 'rgba(255, 255, 255, .7)',
  hoverTextColor: 'rgba(255, 255, 255, 1)',
  reserved: 'All rights reserved © 2021 Coming',
  friendLinks:[
    {
      name: 'ABOUT',
      linkTo: '/about'
    },
    {
      name: 'HELP',
      linkTo: 'https://docs.coming.chat/'
    },
    {
      name: 'GET CID',
      linkTo: '/cid'
    },
    {
      name: 'OMNIBTC',
      linkTo: 'https://omnibtc.finance/'
    },
  ],
  IconList: [
    {
      title: 'Twitter',
      img: Twitter,
      linkTo: 'https://twitter.com/ComingChatApp'
    },
    {
      title: 'Medium',
      img: Medium,
      linkTo: 'https://medium.com/@omnibtc'
    },
    {
      title: 'Telegram',
      img: Telegram,
      linkTo: 'https://t.me/comingchatapp'
    },
    {
      title: 'Github',
      img: Github,
      linkTo: 'https://github.com/coming-chat'
    }
  ],
  email: {
    title: 'email',
    img: Email
  }
};
