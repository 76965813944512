import React from "react";
import GroupLogo from "../../assets/group-logo.png";
import DownLoad from "../../assets/download.svg";
import { iconInfos } from "./utils/data";
import LinkIcon from "../../components/LinkIcon";
import shield from "../home/AboutUs/assets/shield.png";
import cubeTop from "../home/AboutUs/assets/cube.png";
import rhombus from "../home/AboutUs/assets/rhombus.png";
import cubeBottom from "../../assets/cube.png";
import { useTranslation } from "react-i18next";
import ComingLogo from "../../assets/white-logo.svg";

const PcPart = (): React.ReactElement => {
  const _onClick = () => {
    window.location.href = "#/download";
  };
  const { t } = useTranslation();

  return (
    <div className="pc flex flex-row relative">
      <img src={ComingLogo} className="coming-logo" />
      <div className="left">
        <img className="group-logo" src={GroupLogo} alt="group" />
        <div className="content">
          <p className="title text-white">{t("Group conversation")}</p>
          <p className="description text-white">
            {t(
              "Make sure your chat and conversation well protected through the most cutting-edge end-to-end encryption technology (Signal Protocol)"
            )}
          </p>
          <button
            className="download flex items-center text-2xl font-sonTi bg-black rounded-lg leading-8.25 text-white px-4.5 py-2.5"
            onClick={_onClick}
          >
            <img className="pr-3 h-6.25" src={DownLoad} alt="icon" />
            {t("Download ComingChat")}
          </button>
        </div>
      </div>
      <div className="right relative">
        <div className="sidebar absolute flex flex-col">
          {iconInfos.map((i) => (
            <LinkIcon
              path={i.path}
              imgSrc={i.imgSrc}
              text={i.text}
              key={i.text}
              type={i.type}
            />
          ))}
        </div>
      </div>
      <img src={shield} alt="img" className="shield absolute" />
      <img src={cubeTop} alt="img" className="cube-top absolute" />
      <img src={rhombus} alt="img" className="rhombus absolute" />
      <img src={cubeBottom} alt="cube" className="cube-bottom absolute" />
    </div>
  );
};

export default PcPart;
