import React, { useEffect, useState } from "react";
import { DownloadWrapper } from "./styles";
import { useSettings } from "../../hooks/useSettings";
import PCComponent from "./PCComponent";
import MobileComponent from "./MobileComponent";

const DownloadPage: React.FC = () => {
  const { setRouteName, setIsMenuOpen } = useSettings();
  useEffect(() => {});
  useEffect(() => {
    setRouteName("download");
    setIsMenuOpen(false);
  }, [setIsMenuOpen, setRouteName]);

  return (
    <DownloadWrapper className="relative">
      <PCComponent />
      <MobileComponent />
    </DownloadWrapper>
  );
};

export default DownloadPage;
