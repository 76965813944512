import React, { useState } from "react";
import { elastic as Menu, State } from "react-burger-menu";
import BlueLogo from "../../assets/blue-logo.svg";
import { iconInfos } from "../../pages/group/utils/data";
import LinkIcon from "../LinkIcon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
export interface TranslationItem {
  text: string;
  language: string;
}

interface Props {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<boolean>;
  translationList: TranslationItem[];
}

export const LanguageSwitcher = styled.div<{color?:string, background?:string, border?:string, padding?:string}>`
  position: relative;
  .translate,.translate-covered {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    position: absolute;
    //left: -200px;
    //height: 10px;
    //width: 100px;
    box-sizing: content-box;
    padding: ${({padding}) => padding ? padding : '0.8vw 2vw'};
    border: 1px solid ${({border}) => border ? border : 'black'};
    border-radius: 0.55vw;
    color: ${({color}) => color ? color : 'white'};
    background: ${({background}) => background ? background : 'black'};

    & > svg {
      display: inline-flex;
      width: 1.38vw;
      height: 1.38vw;
      margin-right: 1vw;
    }

    & > span {
      min-width: 4.7vw;
      font-size: 1.11vw;
      font-weight: 400;
      line-height: 1.38vw;
      padding: 0 0.07vw;
    }
  }
  .translate-covered{
    width: 0;
    border: 0;
    overflow: hidden;
    transition: 1s;
    z-index: -1;
    padding: ${({padding}) => padding ? padding : '0.8vw 2vw'};
  }
  &:hover{
    .translate-covered{
      z-index: 9;
      width: 100px;
      padding: ${({padding}) => padding ? padding : '0.8vw 2vw'};
      border: 1px solid ${({border}) => border ? border : 'black'};
      background: white;
      color: black;
    }
  }
`;

export const SwiperBtn = styled.div<{color?:string, background?:string, border?:string, padding?:string}>`
  @media screen and (max-width: 769px) {
    margin-left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
    right: unset !important;
    .cid-wapper {
      flex-direction: column;
    }
  }
  z-index: 2;

  section {
    text-align: center;
    color: #fff;
    border-radius: 0.55vw;
    overflow: hidden;
  }
  section p {
    position: relative;
    z-index: 1;
    font-size: 1.11vw;
    font-weight: 400;
    line-height: 1.38vw;
    border: none;
  }
  .color-1 {
    background: black;
  }

  /* General button style (reset) */
  .btn {
    display: inline-flex;
    width: fit-content;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    padding: ${({padding}) => padding ? padding : '0.88vw 2vw'};
    border-radius: 0.55vw;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    .icon-hover {
      display: none;
    }
    .icon {
      display: flex;
      margin: 0;
    }
  }

  .btn:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .btn-1 {
    overflow: hidden;
    color: white;
    border: 1px solid transparent;
    & > svg {
      display: inline-flex;
      width: 1.38vw;
      height: 1.38vw;
      margin-right: 1vw;
    }
  }

  .btn-1c:after {
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
  }
  .btn-1c:hover,
  .btn-1c:active {
    .icon-hover {
      display: inline-flex;
    }
    .icon {
      display: none;
    }
  }
  .black-hover-text:hover,
  .black-hover-text:active {
    color: #000000;
    border: 1px solid black;
  }
  .white-hover-text:hover,
  .white-hover-text:active {
    color: #ffffff;
  }
  .btn-1c:hover:after,
  .btn-1c:active:after {
    width: 100%;
  }
`;

const MenuContent = ({
  isMenuOpen,
  setIsMenuOpen,
  translationList,
}: Props): React.ReactElement<Props> => {
  const { t, i18n } = useTranslation();
  const [show, SetShow] = useState<boolean>(false);
  const whitePaperLink = 'https://coming-application.oss-cn-hongkong.aliyuncs.com/coming-website/ComingChat%20Whitepaper%281%29.pdf'

  return (
    <Menu
      pageWrapId="page-wrap"
      // outerContainerId="outer-container"
      menuClassName="bg-white"
      overlayClassName="menu-border"
      itemClassName="menu-link"
      onStateChange={(state: State) => setIsMenuOpen(state.isOpen)}
      isOpen={isMenuOpen}
      right
      disableAutoFocus
    >
      <div
        className="main"
        id="page-wrap"
        onClick={() => {
          SetShow(false);
        }}
      >
        <div className="menu-title">
          <img className="menu-logo" src={BlueLogo} alt="ComingChat" />
          {/*<MenuButton onClick={() => setIsMenuOpen(false)} className='close-btn' type='close' color='black' />*/}
        </div>
        <div className="menu-content flex-col">
          <a id="home" className="text-black menu-item" href="/">
            {t("Home")}
          </a>
          <a id="about" className="text-black menu-item" href="/download">
            {t("Download")}
          </a>
          <a id="contact" className="text-black menu-item" href="/cid">
            {t("Get CID")}
          </a>
          <a
            id="contact"
            className="text-black menu-item"
            href="https://docs.coming.chat/"
          >
            {t("Help")}
          </a>
          {/*<a*/}
          {/*  id="contact"*/}
          {/*  className="text-black menu-item"*/}
          {/*  href="https://comingchat.medium.com/"*/}
          {/*>*/}
          {/*  {t("Blog")}*/}
          {/*</a>*/}
          {/*<a className="text-black menu-item" id="about" target='_blank' href={whitePaperLink}>*/}
          {/*  {t("About")}*/}
          {/*</a>*/}
          {/*<LanguageSwitcher>*/}
          {/*  <div*/}
          {/*    className="translate"*/}
          {/*    onClick={(e) => {*/}
          {/*      e.stopPropagation();*/}
          {/*      SetShow(!show);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <span className="translate">*/}
          {/*      <img src={LanguateIcon} className="LanguateIcon" />*/}
          {/*      <span className="text">*/}
          {/*        {translationList.map(*/}
          {/*          (t) => i18n.language === t.language && t.text*/}
          {/*        )}*/}
          {/*      </span>*/}
          {/*      <img src={DropDown} alt="" className="DropDown" />*/}
          {/*    </span>*/}
          {/*    {show && (*/}
          {/*      <div className="translateList">*/}
          {/*        {translationList.map((t) => (*/}
          {/*          <TranslateButton*/}
          {/*            text={t.text}*/}
          {/*            language={t.language}*/}
          {/*            key={t.text}*/}
          {/*          />*/}
          {/*        ))}*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</LanguageSwitcher>*/}
        </div>
        <div className="menu-footer flex flex-col justify-center items-center">
          <div className="sidebar flex">
            {iconInfos.map((i) => (
              <LinkIcon
                imgClassName={"menu-link-item-img"}
                path={i.path}
                imgSrc={i.imgSrc}
                text={i.text}
                key={i.text}
                type={i.type}
              />
            ))}
          </div>
          <p>All rights reserved © 2021 Coming</p>
        </div>
      </div>
    </Menu>
  );
};

export default MenuContent;
