import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { defaultOptions } from '../../utils/animateoption';
import animationData from '../home/assetsAnimationHome/dmens.js';
import { ChatItem } from '../../components/ChatItem';
import icon_atom from '../home/assetsDmens/Atom.svg';
import icon_confetti from '../home/assetsDmens/Confetti.svg';
import icon_user from '../home/assetsDmens/UserFocus.svg';
import icon_board from '../home/assetsDmens/Broadcast.svg';
import icon_circuitry from '../home/assetsDmens/Circuitry.svg'
import icon_medal from '../home/assetsDmens/Medal.svg'
import star_icon from '../home/assetswallet/cid.svg';

const ChatGPTList = [
  {
    key:0,
    icon:icon_atom,
    title:'Real-time Information Sharing',
    content:'Real-time Information Sharing',
  },{
    key:1,
    icon:icon_confetti,
    title:'Content NFTization and Trading',
    content:'Content NFTization and Trading',
  },{
    key:2,
    icon:icon_user,
    title:'Identity Authentication Based on CID',
    content:'Identity Authentication Based on CID',
  },{
    key:3,
    icon:icon_board,
    title:'Decentralized Advertising System',
    content:'Decentralized Advertising System',
  },{
    key:4,
    icon:icon_circuitry,
    title:'Performance Revolution',
    content:'New Technology Breaks Performance Bottlenecks',
  },{
    key:5,
    icon:icon_medal,
    title:'Social Mining and Incentive Mechanisms',
    content:'Social Mining and Incentive Mechanisms',
  }
]

const BtnWrapper = styled.div`
  background: #ffffff;
  border: 1px solid black;
  border-radius: 2.13vw;
  padding: 4.26vw 8.53vw;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: 12.13vw 0 0 0;
  >img{
    width: 5.06vw;
    margin-right: 4.26vw;
  }
  .btnName{
    font-size: 4.3vw;
    font-weight: 400;
    color: black;
    line-height: 5.4vw
  }
`


const MbolDmens: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Wrapper id='dmens'>
      <TopWrap>
        <h1>
          {t('Dmens')}
        </h1>
        <div className="content">{t('Decentralized Content Distribution Platform, Only for Valuable Information.')}</div>
        <div className="item-wrapper">
          {ChatGPTList?.map(item=> <ChatItem
            key={item.key}
            iconName={item.icon}
            title={item.title}
            description={item.content}
          />)}
        </div>
        <BtnWrapper onClick={()=> window.open('https://dmens-app.coming.chat/explore')}>
          <img src={star_icon} alt=""/>
          <div className='btnName'>{t('Learn more')}</div>
        </BtnWrapper>
      </TopWrap>
      <MainWrap>
        <Lottie
          options={defaultOptions(animationData)}
          height={'auto'}
          isStopped={false}
          isPaused={false}
        />
      </MainWrap>
    </Wrapper>
  );
};

export default MbolDmens;

const Wrapper = styled.div`
  background: #FFFFFF;
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainWrap = styled.div`
  position: relative;
  z-index: 1;
  margin: 8.4vw 0 16.5vw 0;
`;

const TopWrap = styled.div`
  position: relative;
  margin-top: 14.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 9.6vw;
    font-weight: 500;
    color: #000000;
    line-height: 11.6vw;
    margin-bottom: 8.67vw;
  }

  .content {
    font-size: 4vw;
    font-weight: 400;
    color: #333333;
    line-height: 4.8vw;
    max-width: 85vw;
    flex-wrap: wrap;
    margin-bottom: 7.2vw;
    text-align: center;
  }
  .item-wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
`;

