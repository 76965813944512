import axios from "axios";

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "/v1";
} else if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = "";
}

export default async function getVersion(version: string) {
  const res = await axios.get("/accounts/getComingVersion", {
    headers: { ua: version },
  });
  return res.data;
}
