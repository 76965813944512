import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { defaultOptions } from '../../utils/animateoption';
import animationData from '../home/assetsAnimationHome/chatGPT.js';
import { ChatItem } from '../../components/ChatItem';
import icon_work_study from '../home/assetsChatGPT/BookBookmark.svg';
import icon_life from '../home/assetsChatGPT/Storefront.svg';
import icon_FM from '../home/assetsChatGPT/Bank.svg';
import icon_EI from '../home/assetsChatGPT/FlyingSaucer.svg';
import icon_SC from '../home/assetsChatGPT/StarAndCrescent.svg';
import icon_coffee from '../home/assetsChatGPT/Coffee.svg';

const ChatGPTList = [
  {
    key:0,
    icon:icon_work_study,
    title:'Work and Study',
    content:'Work and Study: English, Copywriting, Spreadsheet, Code',
  },{
    key:1,
    icon:icon_life,
    title:'Life Information',
    content:'Life Information: Shopping, Travel, Flight, Food',
  },{
    key:2,
    icon:icon_FM,
    title:'Financial \n Management',
    content:'Financial Management: Stocks, Virtual Currency',
  },{
    key:3,
    icon:icon_EI,
    title:'Entertainment \n Information',
    content:'Entertainment Information: movie, game, competiton',
  },{
    key:4,
    icon:icon_SC,
    title:'Companion',
    content:'Companion: Psychological Counseling, Virtual Companionship',
  },{
    key:5,
    icon:icon_coffee,
    title:'Metaphysics:',
    content:'Metaphysics: Divination, Fortune-Telling',
  }
]

const MbolChatGPT: React.FC = () => {
  const {t} = useTranslation();
  return (
    <Wrapper id='chatGPT'>
      <TopWrap>
        <h1>
          {t('ChatGPT Plugins')}
        </h1>
        <div className="content">{t('Based on chatgpt plugins, we will adapt them for the mobile platform, and equip the app with multi-functional plugins to enhance your communication, productivity, and creativity.')}</div>
        <div className="item-wrapper">
          {ChatGPTList?.map(item=> <ChatItem
            key={item.key}
            iconName={item.icon}
            title={item.title}
            description={item.content}
          />)}
        </div>
      </TopWrap>
      <MainWrap>
        <Lottie
          options={defaultOptions(animationData)}
          height={'auto'}
          isStopped={false}
          isPaused={false}
        />
      </MainWrap>
    </Wrapper>
  );
};

export default MbolChatGPT;

const Wrapper = styled.div`
  background: #FFFFFF;
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainWrap = styled.div`
  position: relative;
  z-index: 1;
  margin: 8.4vw 0 16.5vw 0;
`;

const TopWrap = styled.div`
  position: relative;
  margin-top: 14.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 9.6vw;
    font-weight: 500;
    color: #000000;
    line-height: 11.6vw;
    margin-bottom: 8.67vw;
  }

  .content {
    font-size: 4vw;
    font-weight: 400;
    color: #333333;
    line-height: 4.8vw;
    max-width: 85vw;
    flex-wrap: wrap;
    margin-bottom: 7.2vw;
    text-align: center;
  }
  .item-wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
`;
