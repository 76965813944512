import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const PublicWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100vw;
  //height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PublicMainWrap = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100vw;
  //height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BtnWrapper = styled.div<{border?:string, color?:string, background?:string, margintop?:string}>`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-top: ${({ margintop }) => margintop?margintop:'3.05vw'};
  background: ${({ background }) => background?background:'black'};
  color: ${({ color }) => color?color:'white'};
  border-radius: 0.55vw;
  border: 1px solid ${({ border }) => border?border:'white'};
  padding: 1vw 2.22vw;
  cursor: pointer;
  &>img{
    width: 1.38vw;
    margin-right: 1.11vw;
  }
  &>span{
    color: ${({ color }) => color?color:'black'};
    font-size: 1.11vw;
    font-weight: 400;
    line-height: 1.38vw;
  }
`

export const AmountItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title{
    font-size: ${isMobile?'2.13vw':'1vw'};
    font-weight: 400;
    color: #000000;
    line-height: ${isMobile?'2.67vw':'1.25vw'};
    margin-bottom: ${isMobile?'3.2vw':'1.5vw'};
    margin-top: ${isMobile?'3.2vw':'1.1vw'};
    width: 100%;
    text-align: center;
  }
  .amount{
    font-size: ${isMobile?'6.4vw':'3vw'};
    font-weight: 600;
    color: #000000;
    line-height: ${isMobile?'7.8vw':'3.876vw'}
  }
  .coming{
    font-size: ${isMobile?'3.5vw':'1.2vw'};
    line-height: ${isMobile?'7.8vw':'3.876vw'}
  }
`;