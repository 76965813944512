import { hasProps } from "@react-spring/core/dist/declarations/src/helpers";
import styled from "styled-components";

interface IProps {
  showText: boolean;
  isMenuOpen?: boolean;
  color: string;
}

export const MenuButtonWrapper = styled.div<IProps>`
  cursor: pointer;
  position: relative;
  user-select: none;

  &::before {
    position: absolute;
    display: ${(props) =>
      props.showText && !props.isMenuOpen ? "block" : "none"};
    content: "Menu";
    top: 50%;
    left: -71px;
    line-height: 33px;
    font-size: 20px;
    z-index: -1;
    transform: translateY(-50%);
    font-weight: normal;
    font-family: STSongti-SC-Regular, STSongti-SC;
    color: ${(props) => props.color};
  }

  .inner {
    position: absolute;
    top: 17px;
    left: 18px;
  }
  @media screen and (min-width: 769px) {
    .round {
      transition: 0.4s in;
      animation: rotate 4s linear infinite;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .menu-button {
    &.black-bar {
      .bar {
        background-color: black;
      }
    }
    &.white-bar {
      .bar {
        background-color: white;
      }
    }
    position: absolute;
    top: 0;
    left: -1.5px;
    padding: 15px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .menu-button .bar:nth-of-type(1) {
    margin-top: 0;
  }

  .menu-button .bar:nth-of-type(3) {
    margin-bottom: 0;
  }

  .bar {
    position: relative;
    display: block;
    width: 30px;
    height: 4px;
    margin: 6px auto;
    border-radius: 10px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  @media screen and (min-width: 769px) {
    .menu-button:hover .bar:nth-of-type(1) {
      -webkit-transform: translateY(1.5px) rotate(-4.5deg);
      -ms-transform: translateY(1.5px) rotate(-4.5deg);
      transform: translateY(1.5px) rotate(-4.5deg);
    }

    .menu-button:hover .bar:nth-of-type(2) {
      opacity: 0.9;
    }

    .menu-button:hover .bar:nth-of-type(3) {
      -webkit-transform: translateY(-1.5px) rotate(4.5deg);
      -ms-transform: translateY(-1.5px) rotate(4.5deg);
      transform: translateY(-1.5px) rotate(4.5deg);
    }
  }
  .cross {
    .bar {
      background-color: black !important;
    }
  }
  .cross .bar:nth-of-type(1) {
    -webkit-transform: translateY(9px) rotate(-45deg);
    -ms-transform: translateY(9px) rotate(-45deg);
    transform: translateY(9px) rotate(-45deg);
  }

  .cross .bar:nth-of-type(2) {
    opacity: 0;
  }

  .cross .bar:nth-of-type(3) {
    -webkit-transform: translateY(-11px) rotate(45deg);
    -ms-transform: translateY(-11px) rotate(45deg);
    transform: translateY(-11px) rotate(45deg);
  }
  @media screen and (min-width: 769px) {
    .cross:hover .bar:nth-of-type(1) {
      -webkit-transform: translateY(8px) rotate(-40.5deg);
      -ms-transform: translateY(8px) rotate(-40.5deg);
      transform: translateY(8px) rotate(-40.5deg);
    }

    .cross:hover .bar:nth-of-type(1) {
      -webkit-transform: translateY(8px) rotate(-40.5deg);
      -ms-transform: translateY(8px) rotate(-40.5deg);
      transform: translateY(8px) rotate(-40.5deg);
    }

    .cross:hover .bar:nth-of-type(2) {
      opacity: 0;
    }

    .cross:hover .bar:nth-of-type(3) {
      -webkit-transform: translateY(-10px) rotate(40.5deg);
      -ms-transform: translateY(-10px) rotate(40.5deg);
      transform: translateY(-10px) rotate(40.5deg);
    }
  }
`;
