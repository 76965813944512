import styled from "styled-components";

export const MainWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    width: 90vw;
    align-self: center;
    text-align: center;
    margin-bottom: 8.64vw;
    font-size: 10vw;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: #000000;
    line-height: 14.67vw;
  }
  p {
    width: 100%;
    padding: 2vw 0;
    font-size: 4.05vw;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 5.13vw;
    text-align: start;
  }
`;
