import React, { useEffect } from "react";
import styled from "styled-components";
import marked from "marked";
import { useSettings } from "../../hooks/useSettings";

const StyledPrivacyPage = styled.div`
  padding: 100px 0;
  width: 100%;
`;

const Head = styled.div`
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1080px !important;
  @media screen and (max-width: 769px) {
    box-sizing: content-box;
    width: 89vw;
    padding: 7.2vw 0 0;
  }
  .title {
    line-height: 44px;
    max-width: 100%;
    font-family: "Optimistic Display", "NotoSans", "NotoSansJP", "NotoSansKR",
      sans-serif;
    font-size: 36px;
    color: #012549;
    font-weight: 900;
    @media screen and (max-width: 769px) {
      margin: 0;
      height: auto;
      font-size: 7.533vw;
      font-weight: bold;
      line-height: 14vw;
    }
  }
  .date {
    padding-bottom: 40px;
    font-family: "Optimistic Text", sans-serif;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #012549;
    @media screen and (max-width: 769px) {
      padding: 3.2vw 0 7.2vw;
      font-size: 3.8vw;
      font-weight: 400;
    }
  }
`;
const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  margin: 48px auto 80px;
  padding: 0 40px;
  width: 1080px !important;
  margin-bottom: 80px;
  @media screen and (max-width: 769px) {
    width: 89vw !important;
    margin: 0 auto 8.533vw;
    padding: 0;
  }
  p,h3 {
    margin-top: 32px;
    font-family: 'Optimistic Text',sans-serif;
    line-height: 29px !important;
    font-size: 20px;
    color: #022549;
    font-weight: 400;
    @media screen and (max-width: 769px) {
      margin: 3.2vw 0;
      font-size: 4.267vw;
      line-height: 7.5vw !important;
    }
  }
  h2 {
    margin: 80px 0 32px;
    font-family: 'Optimistic Display','NotoSans','NotoSansJP','NotoSansKR',sans-serif;
    font-size: 30px;
    color: #012549;
    font-weight: 900;
    @media screen and (max-width: 769px) {
      margin 12vw 0 3.2vw;
      font-size: 5.333vw;
      font-weight: bold;
      line-height: 7.733vw;
    }
  }
  h3,h4,h5,h6 {
    font-family: 'Optimistic Display','NotoSans','NotoSansJP','NotoSansKR',sans-serif;
    color: #012549;
    font-weight: 900;
    @media screen and (max-width: 769px) {
      font-weight: bold;
    }
  }
  hr{
    margin: 80px 0;
    border: 0;
    height: 1px;
    background: #ddd;
    @media screen and (max-width: 769px) {
        margin 12vw 0;
    }
  }
  ul {
    list-style: disc;
    margin-left: 15px;
    margin-top: 32px;
    li{
    font-family: 'Optimistic Text',sans-serif;
    line-height: 29px !important;
    font-size: 16px;
    color: #022549;
    font-weight: 400;
    @media screen and (max-width: 769px) {
      margin: 3.2vw 0;
      font-size: 3.5vw;
      line-height: 6vw !important;
    }
    }
  }
`;

const mark = `## Terms of Service
ComingChat utilizes the most advanced security and end-to-end encryption to provide private messaging,  cryptocurrency wallet, and other services to users worldwide. You agree to our Terms of Service (“Terms”) by installing or using our apps, services, or website (together, “Services”).
### About our services
**Minimum Age.** You must be at least 17 years old to use our Services. The minimum age to use our Services without parental approval may be higher in your home country.  
**Account Registration.** To create an account you must register for our Services using your mnemonic phrase.   
**Privacy of user data.** ComingChat does not sell, rent or monetize your personal data or content in any way – ever.  
**Software.** In order to enable new features and enhanced functionality, you consent to downloading and installing updates to our Services.  
**Fees and Taxes.** You are responsible for data and mobile carrier fees and taxes associated with the devices on which you use our Services.  
### Using ComingChat
**Legal and Acceptable Use.** You agree to use our Services only for legal, authorized, and acceptable purposes. You will not use (or assist others in using) our Services in ways that: (a) violate or infringe the rights of ComingChat, our users, or others, including privacy, publicity, intellectual property, or other proprietary rights; (b) involve sending illegal or impermissible communications such as bulk messaging, auto-messaging, and auto-dialing.  
**Harm to ComingChat.** You must not (or assist others to) access, use, modify, distribute, transfer, or exploit our Services in unauthorized manners, or in ways that harm ComingChat, our Services, or systems. For example you must not (a) gain or try to gain unauthorized access to our Services or systems; (b) disrupt the integrity or performance of our Services; (c) create accounts for our Services through unauthorized or automated means; (d) collect information about our users in any unauthorized manner; or (e) sell, rent, or charge for our Services.  
**Keeping Your Account Secure.** ComingChat embraces privacy by design and does not have the ability to access your messages. You are responsible for keeping your device and your ComingChat account safe and secure. When you register with a new device, your old device will stop receiving all messages and calls.  
**No Access to Emergency Services.** Our Services do not provide access to emergency service providers like the police, fire department, hospitals, or other public safety organizations. Make sure you can contact emergency service providers through a mobile, fixed-line telephone, or other service.  
**Third-party services.** Our Services may allow you to access, use, or interact with third-party websites, apps, content, and other products and services. When you use third-party services, their terms and privacy policies govern your use of those services.  
### Your Rights and License with ComingChat
**Your Rights.** You own the information you submit through our Services.   
**ComingChat’s Rights.** We own all copyrights, trademarks, domains, logos, trade dress, trade secrets, patents, and other intellectual property rights associated with our Services. You may not use our copyrights, trademarks, domains, logos, trade dress, patents, and other intellectual property rights unless you have our written permission. To report copyright, trademark, or other intellectual property infringement, please contact comingchat@gmail.com.  
**ComingChat’s License to You.** ComingChat grants you a limited, revocable, non-exclusive, and non-transferable license to use our Services in accordance with these Terms.
## Privacy Policy
ComingChat utilizes state-of-the-art security and end-to-end encryption to provide private messaging  services to users worldwide (“Services”). Your messages are always encrypted, so they can never be shared or viewed by anyone but yourself and the intended recipients.
### Information you provide
ComingChat queues up end-to-end encrypted messages on its servers so that they can be delivered to temporarily offline devices. Your message history is stored on your own devices. Additional technical information is stored on our servers, including randomly generated authentication tokens, keys, push tokens, and other material that is necessary to establish calls and transmit messages.During the process of your request to send images and audios, we will obtain some of your image and audio permissions, which you can subsequently remove by yourself. 
### Some instances where ComingChat may need to share your data
  
  -   To meet any applicable law, regulation, legal process or enforceable governmental request.
  -   To enforce applicable Terms, including investigation of potential violations.
  -   To detect, prevent, or otherwise address fraud, security, or technical issues.

## Contact Us
If you have questions about our Privacy Policy please contact us at comingchat@gmail.com
## Updates
Effective as of September 21, 2022  Updated September 21, 2022`;

const PrivacyPage = (): React.ReactElement => {
  const { routeName, setRouteName, setIsMenuOpen } = useSettings();
  useEffect(() => {
    setRouteName("privacy");
    setIsMenuOpen(false);
  }, [setIsMenuOpen, setRouteName]);

  return (
    <StyledPrivacyPage>
      <Head>
        <div className="title">ComingChat Terms & Privacy Policy</div>
        {/* <div className="date"></div> */}
      </Head>
      <Wrapper>
        <div dangerouslySetInnerHTML={{ __html: marked(mark) }} />
      </Wrapper>
    </StyledPrivacyPage>
  );
};

export default PrivacyPage;
