import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MainWrap } from "./mainwrap";
import { defaultOptions } from "../../utils/animateoption";
import Lottie from "react-lottie";
import animationData from "../../css/animate/0mni_wallet.json";
import { omnichainData } from '../../common/local-data';
const Wrapper = styled.div`
  background: #D6DCED;
  position: relative;
  padding: 14.5vw 6.48vw;
  h1 {
    color: black;
  }
  p {
    font-size: 4.05vw;
    font-weight: 400;
    color: black;
    line-height: 5.13vw;
  }
  img.identityiconwrap {
    width: 87.467vw;
    height: 16.133vw;
    margin: 3vw auto 18.133vw;
  }
  .identity {
    margin: 12.9vw 0 0 0;
  }
`;

const Item = styled.div`
  margin-top: 8.9vw;

  li {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4.3vw;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 6.48vw;
      margin-right: 6.2vw;
    }
  }
  .content{
    font-size: 3.78vw;
    font-weight: 400;
    color: black;
    line-height: 4.59vw;
  }
`

const IdentifierMbolPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <MainWrap>
        <h1>{t("Omnichain Wallet")}</h1>
        <p>{t("Easy-to-use")}</p>
        <Item>
          {omnichainData.map((item, index) => (
            <li key={item.text}>
              <img src={item.imgUrl} alt={`icon${index}`}/>
              <div className='content'>{t(`${item.text}`)}</div>
            </li>
          ))}
        </Item>
      </MainWrap>
      <div className='identity'>
        <Lottie
          options={{ ...defaultOptions(animationData, "identity") }}
          isStopped={false}
          isPaused={false}
        />
      </div>
    </Wrapper>
  );
};

export default IdentifierMbolPage;
