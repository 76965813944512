import Mac from "../../../assets/macOS.svg";
import Windows from "../../../assets/windows.svg";
import Linux from "../../../assets/Linux.svg";
import AppStore from "../../../assets/app-store.svg";
import GooglePlay from "../../../assets/google-play.svg";
import Android from "../../../assets/apk.svg";
export interface ListItem {
  name: string;
  link: string;
  imgSrc: string;
  version?: string;
}

export const desktopImgList: ListItem[] = [
  {
    name: "Mac",
    link: "https://coming-application.oss-cn-hongkong.aliyuncs.com/coming-desktop/v0.0.300/coming-chat-desktop-mac-0.0.300.dmg",
    imgSrc: Mac,
  },
  {
    name: "Windows",
    link: "https://coming-application.oss-cn-hongkong.aliyuncs.com/coming-desktop/v0.0.300/coming-chat-desktop-win-0.0.300.exe",
    imgSrc: Windows,
  },
  {
    name: "Linux",
    link: "https://coming-application.oss-cn-hongkong.aliyuncs.com/coming-desktop/v0.0.300/coming-chat-desktop_0.0.300_amd64%20.deb",
    imgSrc: Linux,
  },
];

export const mobileImgList: ListItem[] = [
  {
    name: "AppStore",
    link: "https://apps.apple.com/us/app/coming-chat/id1564704361",
    imgSrc: AppStore,
  },
  {
    name: "GooglePlay",
    link: "https://play.google.com/store/apps/details?id=org.coming.securesms",
    imgSrc: GooglePlay,
  },
  {
    name: "Android",
    link: "https://coming-application.oss-cn-hongkong.aliyuncs.com/release/latest/ComingChat.apk",
    imgSrc: Android,
  },
];
