import React, { createContext, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export interface SettingInfo {
  routeName: string;
  setRouteName: React.Dispatch<string>;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<boolean>;
  isIOS: boolean;
  isAndroid: boolean;
  isSafari: boolean;
  isBrowser: boolean;
  groupString: string;
  inviteString: string;
  isWindows: boolean;
  getSplit: any;
  browserType: string;
  showText: boolean;
  setShowText: React.Dispatch<boolean>;
}

export const SettingContext = createContext<SettingInfo>({} as SettingInfo);

export const SettingProvider: React.FC = ({children}) => {
  const [routeName, setRouteName] = useState<string>('first');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isIOS, setIsIOS] = useState<boolean>(false);
  const [isAndroid, setIsAndroid] = useState<boolean>(false);
  const [isSafari, setIsSafari] = useState<boolean>(false);
  const [isBrowser, setIsBrowser] = useState<boolean>(true);
  const [ua, setUa] = useState<string>('');
  const [groupString, setGroupString] = React.useState<string>('');
  const [inviteString, setInviteString] = useState<string>('');
  const [isWindows, setIsWindows] = useState(false);
  const [browserType, setBrowserType] = useState('');
  const [showText, setShowText] = useState(true);
  useEffect(() => {
    setShowText(true);
  }, [showText]);
  const getSplit = (e: any) => {
    const t = e.split(' ');
    const c: JSX.Element[] = [];
    t.forEach((str: 'string', i: number) => {
      c.push(<span key={`${str}-${i}`}>{str}</span>);
      if (i < t.length - 1) {
        c.push(<span key={` -${i}`}> </span>);
      }
    });
    return c;
  };
  useEffect(() => {
    if (isIOS || isAndroid) {
      setBrowserType('MBOL');
    } else if (ua && !isIOS && !isAndroid) {
      setBrowserType('CBOL');
    }
  }, [isIOS, isAndroid, ua]);
  useEffect(() => {
    ua?.match(/windows/) && setIsWindows(true);
  }, [ua]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUa(window.navigator.userAgent.toLowerCase());
    }
  });
  useEffect(() => {
    ua?.match(/(iphone|ipad|ipod)/) && setIsIOS(true);
    ua?.match(/android[\s\/]([\d\.]+)/) && setIsAndroid(true);
    ua?.match(/version\/([\d.]+).*safari/) && setIsSafari(true);
    ua?.match(/micromessenger/i) && setIsBrowser(false);
  }, [ua]);
  useEffect(() => {
    if (window.location.href.split('group/#')[1]) {
      setGroupString(window.location.href.split('group/#')[1]);
    } else {
      setGroupString('');
    }
  }, []);
  useEffect(() => {
    if (window.location.href.split('download?')[1]) {
      setInviteString(window.location.href.split('download?')[1]);
    } else if (window.location.href.split('download/?')[1]) {
      setInviteString(window.location.href.split('download/?')[1]);
    } else {
      setInviteString('');
    }
  }, []);

  useEffect(() => {
    console.log()
    if (routeName) {
      window.localStorage.setItem('local_routeName', routeName);
    }
  }, [routeName]);
  return (
    <SettingContext.Provider
      value={{
        routeName,
        setRouteName,
        isMenuOpen,
        setIsMenuOpen,
        isIOS,
        isAndroid,
        isSafari,
        isBrowser,
        groupString,
        inviteString,
        isWindows,
        getSplit,
        browserType,
        showText,
        setShowText,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};