import React, { useState, useEffect, useContext } from "react";
import BG from "../../assets/line_download.svg";
import DownloadMobile from "../../assets/download-mobile.png";
import DownLoad from "../../assets/download.svg";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../hooks/useSettings";
import styled from "styled-components";
import useAddresse from "../../utils/hooks/useAddress";
import { AddressContext } from "../../utils/providers/AddressProvider";
import getVersion from "../../server";

const MbolWrapper = styled.div`
  p {
    font-family: "Linux Libertine", "Times", serif;
  }
  p.title {
    line-height: 8.4vh;
  }
  p.description {
    line-height: 5vw;
  }
  .version {
    color: white;
    margin-bottom: 1.5vh;
    line-height: 5vw;
    letter-spacing: 2px;
    font-size: 0.875rem;
    font-family: "TimesNewRomanPSMT,self";
  }
  .download-button {
    z-index: 10;
  }
`;

const MobileComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const { setAndroidDownload } = useContext(AddressContext);
  const [varsion, setVersion] = useState<string>("");
  useEffect(() => {
    getVersion("Web-Android").then((res: any) => {
      setVersion(res.versionName);
      setAndroidDownload(res.url);
    });
  }, [setAndroidDownload]);
  const { isIOS, isAndroid, isSafari, isBrowser, inviteString } = useSettings();
  const { IOSDownload, androidDownload } = useAddresse();
  const handleOnClick = () => {
    let link = isAndroid ? androidDownload : IOSDownload;
    window.location = link;
  };
  return (
    <MbolWrapper>
      <div className="mobile-phone flex flex-col items-center">
        <img src={BG} alt="" className="bg-image absolute" />
        <div className="container flex flex-col items-center">
          <p className="title">Download ComingChat</p>
          <p className="description">
            Welcome to ComingChat, a comprehensive application tailored for the Web3.0 and AI era.
            On this innovative and dynamic platform, you will experience unprecedented convenience and personalized services.
          </p>
          <img src={DownloadMobile} alt="download" className="download-image" />
          {varsion && <p className="version">{`Version ${varsion}`}</p>}

          <button
            className="download-button flex items-center"
            id="downloadButton"
            onClick={handleOnClick}
          >
            <img src={DownLoad} alt="download" />
            Download
          </button>
          <p className="footer">All rights reserved © 2021 Coming</p>
        </div>
      </div>
    </MbolWrapper>
  );
};

export default MobileComponent;
