import React, { useState } from 'react';
import styled from 'styled-components';
import animationDataFirst from './animation/first/firstAnimation.js';
import animationDataSecond from './animation/second/secondAnimation.js';
import animationDataThird from './animation/third/thirdAnimation.js';
import Lottie from 'react-lottie';

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  left: 10px;
  bottom: 0;
`;


const HomeMbolAnimation: React.FC = () => {
  const [firstAnimationStart, setFirstAnimationStart] = useState(true)
  const [secondAnimationStart, setSecondAnimationStart] = useState(false)
  const [thirdAnimationStart, setThirdAnimationStart] = useState(false)

  return (
    <Wrapper>
      {firstAnimationStart &&  <Lottie
        width={'80vw'}
        options={{
          loop: false,
          autoplay: true,
          animationData: animationDataFirst,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        eventListeners={[{
          eventName: 'complete',
          callback: () => {
            setFirstAnimationStart(false)
            setSecondAnimationStart(true)
            // console.log('动画2开始')
          },
        },]}/>}

      {secondAnimationStart && <Lottie
        width={'80vw'}
        options={{
          loop: false,
          autoplay: true,
          animationData: animationDataSecond,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        eventListeners={[{
          eventName: 'complete',
          callback: () => {
            setTimeout(()=>{
              // setFirstAnimationStart(true)
              setSecondAnimationStart(false)
              setThirdAnimationStart(true)
            },4000)
            // console.log('动画2退出，动画3开始')
          },
        },]}/>}

      {thirdAnimationStart && <Lottie
        width={'80vw'}
        options={{
          loop: false,
          autoplay: true,
          animationData: animationDataThird,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        eventListeners={[{
          eventName: 'complete',
          callback: () => {
            setTimeout(()=>{
              setFirstAnimationStart(true)
              setThirdAnimationStart(false)
            },4000)
            // console.log('动画3退出，动画一恢复')
          },
        },]}/>}
    </Wrapper>
  );
};

export default HomeMbolAnimation;
