import React from 'react';
import styled from 'styled-components';
import grayDown from '../../assets/nav/arrowmenu.svg'
import hoverDown from '../../assets/nav/arrow_down.svg'
import hoverUp from '../../assets/nav/arrow_up.svg'

interface Props {
  showMore:boolean
  setShowMore:()=>void
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const WrapperRoute = styled.div`
  display: flex;
  flex-direction: row;
  .name{
    display: flex;
    flex-direction: row;
    margin: 0 1vw;
    font-size: 1vw;
    font-weight: 600;
    color: #131415;
    line-height: 1.11vw;
    cursor: pointer;
    align-items:center;
    &>img{
      width: 1vw;
      height: 1.7vw;
      margin-left: 4px;
    }
    .icon{
      width: 1vw;
      height: 1.7vw;
      margin-left: 4px;
      background-image: url(${grayDown});
      background-repeat: no-repeat;
      background-position: center;
    }
    
    :hover{
      color: #2B33FF;
      .icon{
        background-image: url(${hoverDown});
      }
    }
  }
`;



const WebHeaderRoute = ({setShowMore, showMore}: Props): React.ReactElement<Props> => {

  function linkToOtherPage(val:string){
    window.location.href=val
  }

  const helpLink = 'https://docs.coming.chat/'
  return (
    <Wrapper>
      <WrapperRoute>
        <div className='name' onClick={setShowMore}>
          FEATURES
          <div className={`icon`}/>
          {/*<img src={showMore?arrowUp:arrowDown} alt=""/>*/}
        </div>
        {/*<div className='name' onClick={()=>linkToOtherPage('/about')}>ABOUT</div>*/}
        <a href={helpLink} target={'_blank'} className='name'>
          HELP
        </a>
      </WrapperRoute>
      <div></div>
    </Wrapper>

  );
};

export default WebHeaderRoute;
