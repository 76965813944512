import React from 'react';
import IndexPage from './home';
import IdentifierPage from './identity';
import CidPage from './cid';
import styled from 'styled-components';
import Private from './private';
import ComingChatInfo from './comingchatInfo';
import ChatGPT from './chatGPT';
import Dmens from './dmens';
import MoreFeature from './moreFeatures';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const NavDiv = styled.div`
  background: #d6dced;
  border: none;
`;
const HomeWebPage = (): React.ReactElement => {
  return (
    <Wrapper>
      <NavDiv />
      <IndexPage/>
      <ComingChatInfo/>
      <Private/>
      <CidPage/>
      <ChatGPT/>
      <IdentifierPage/>
      <Dmens/>
      <MoreFeature/>
    </Wrapper>
  );
};

export default HomeWebPage;

