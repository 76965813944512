import styled from "styled-components";
import { judgeFontWeightBySystem } from "../../utils/help";

export const Wrapper = styled.div`
  padding-top: 18vh;

  .title {
    font-size: 3.5vw;
    line-height: 4.16vw;
    font-weight: 500;
    margin-bottom: 3.33vw;
  }

  .description {
    //font-size: 2vh;
    max-width: 45vw;
    flex-wrap: wrap;
    padding: 0 6vw;
    text-align: center;
    font-size: 1.25vw;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    color: #333333;
    line-height: 1.875vw;
  }

  .downloadIcon {
    padding-top: 4.4vh;
    padding-bottom: 9.16vw;
    //width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    img {
      //width: 10.2vw;
      height: 3.75vw;
      margin-right: 1.944vw;
      cursor: pointer;
    }
    a:nth-child(3){
      >img{
        margin-right: 0vw;
      }
    }
    p {
      margin-top: 9px;
      width: 73%;
      text-align: center;
      height: 28px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  .icon{
    display: flex;
    height: 41.67vw;
    align-items: center;
    justify-content: center;
    margin-bottom: 10.2vw;
  }
  .app {
    width: 22.7vw;
  }
  .desktop {
    width: 44.58vw;
  }
  .app;
  .desktop {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
`;

export const DownloadWrapper = styled.div`
  overflow: hidden;
  .cube {
    top: 47.4vh;
    left: 6.4vw;
    height: 12vh;
    max-height: 130px;
  }
  .cone {
    left: 44vw;
    bottom: 15vh;
    height: 19vh;
    max-height: 205px;
  }
  .pc {
    width: 100%;
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
  .mobile-phone {
    background: black;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    .bg-image {
      z-index: 0;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
    }
    .container {
      z-index: 1;
    }

    p {
      text-align: center;
      &.title {
        color: white;
        padding-top: 12.4vh;
        padding-bottom: 1.8vh;
        margin: 0 auto;
        font-size: 30px;
        line-height: 8.4vh;
        font-weight: bold;
      }
      &.description {
        color: #b3b3b3;
        font-size: 12px;
        padding: 0 15vw;
      }
    }
    .download-image {
      width: 46vh;
      left: 33px;
      padding-top: 1.7vh;
      padding-bottom: 1vh;
      transform: translate(3.4vh);
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
    .download-button {
      color: white;
      width: fit-content;
      padding: 2.9vw 15.6vw;
      margin-bottom: 8.1vw;
      border: 1px solid white;
      border-radius: 1.89vw;
      font-size: 4.02vw;
      font-weight: 400;
      img {
        padding-right: 2.16vw;
      }
    }
    .footer {
      color: white;
      bottom: 2.5vh;
    }
    @media screen and (min-width: 780px) {
      display: none;
    }
  }
`;
