export const defaultOptions = (animationData: any, className?: string) => {
  return {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    className: className,
  };
};
