import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TotalStatistics from '../home/totalStatistics';
import Arrow from '../../assets/home/more.svg';
import { Link } from "react-router-dom";

const MobComingChatInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <MainWrap>
        <TotalStatistics/>
        <Link className='scroll' to={'/dashboard'}>
          <span>MORE</span>
          <div className='icon'>
            <img src={Arrow} alt=""/>
          </div>
        </Link>
        <div className='info'>
          <span className='title'>What Is ComingChat?</span>
          <div className='content'>
            <span>An all-in-one life tool that utilizes Web3<br/>and AI technologies to make your mobile life<br/>more secure and convenient.</span>
          </div>
        </div>
      </MainWrap>
    </Wrapper>
  );
};

export default MobComingChatInfo;

const MainWrap = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100vw;
  height: fit-content;
  .scroll{
    color: #131415;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 6.5vw;
    padding-bottom: 21.5vw;
    &>span{
      font-size: 2vw;
      line-height: 2.2vw;
      font-weight: 600;
    }
    .icon{
      border-radius: 50%;
      background:#2B33FF;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 2vw;
      margin-top: 3vw;
      &>img{
        width: 8.53vw;
      }
    }
  }
  .info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3.2vw;
    .title{
      max-width: 87.83vw;
      flex-wrap: wrap;
      font-size: 10vw;
      font-weight: 500;
      color: #000000;
      line-height: 14.67vw;
    }
    .content{
      display: flex;
      flex-direction: column;
      max-width: 86.5vw;
      flex-wrap: wrap;
      margin: 8.6vw 0 11.89vw 0;
      gap: 5vw;
      &>span{
        font-size: 4.03vw;
        font-weight: 400;
        color: #333333;
        line-height: 5.13vw;
      }
    }
  }
`;

const Wrapper = styled.div`
  background: white;
  position: relative;
  //padding: 17.867vw 0;
  img.dappshap {
    position: absolute;
    width: 71.333vw;
    height: 46.533vw;
    top: -16.133vw;
    margin: 0 auto;
    z-index: 1;
  }
  h1 {
    color: #ffffff;
    margin-top: 15vw;
  }
  p {
    color: white;
  }
  img.dappiconwrap {
    width: 87.467vw;
    height: 16.133vw;
    margin: 3vw auto 9.867vw;
  }
  .dapp {
    width: 72.8vw;
    height: 62.933vw;
    margin: 0 auto;
  }
`;
