import React, { useState } from 'react';
import styled from 'styled-components';
import animationDataFirst from './assetsAnimation/first/animation1.js';
import animationDataSecond from './assetsAnimation/second/animation2.js';
import animationDataThird from './assetsAnimation/third/animation3.js';
import Lottie from 'react-lottie';

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: -10vh;
`;


const HomeAnimation: React.FC = () => {
  const [firstAnimationStart, setFirstAnimationStart] = useState(true)
  const [secondAnimationStart, setSecondAnimationStart] = useState(false)
  const [thirdAnimationStart, setThirdAnimationStart] = useState(false)

  return (
    <Wrapper>
      {firstAnimationStart &&  <Lottie
        width={'34vw'}
        options={{
          loop: false,
          autoplay: true,
          animationData: animationDataFirst,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        eventListeners={[{
          eventName: 'complete',
          callback: () => {
            setFirstAnimationStart(false)
            setSecondAnimationStart(true)
            // console.log('动画2开始')
          },
        },]}/>}

      {secondAnimationStart && <Lottie
        width={'34vw'}
        options={{
          loop: false,
          autoplay: true,
          animationData: animationDataSecond,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        eventListeners={[{
          eventName: 'complete',
          callback: () => {
            setTimeout(()=>{
              setSecondAnimationStart(false)
              setThirdAnimationStart(true)
            },4000)
            // console.log('动画2退出，动画3开始')
          },
        },]}/>}

      {thirdAnimationStart &&  <Lottie
        width={'34vw'}
        options={{
          loop: false,
          autoplay: true,
          animationData: animationDataThird,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        eventListeners={[{
          eventName: 'complete',
          callback: () => {
            setThirdAnimationStart(false)
            setFirstAnimationStart(true)
            // console.log('动画1开始')
          },
        },]}/>}
    </Wrapper>
  );
};

export default HomeAnimation;
