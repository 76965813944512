import React from 'react';
import styled from 'styled-components';
import Info1 from '../../assets/home/frames.svg'


const Wrapper = styled.div`
  position: relative;
  background: #F4F5F7;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    gap: 1.38vw;
    width: 100%;
    padding-top: 5vw;
    padding-bottom: 8.75vw;
    &>img{
      height: 54vw;
    }
  }
`;

const Private: React.FC = () => {

  return (
    <Wrapper id='EncryptedChart'>
      <div className="icon">
        <img src={Info1} alt=""/>
      </div>
    </Wrapper>
  );
};

export default Private;
