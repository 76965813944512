import getVersion from "../../../server";

import { ListItem } from "./data";

export default async function changeMobileData<T>(mobileImgList: ListItem[]) {
  const iosResult = await getVersion("Web-iOS");
  const androidResult = await getVersion("Web-Android");

  const iosV = iosResult.versionName;
  const andV = androidResult.versionName;

  const targetArr = mobileImgList.map((item) => {
    let { name } = item;
    if (name === "Android") {
      item.link = androidResult.url;
    }
    if (name === "GooglePlay" || name === "Android") {
      item.version = andV;
    } else {
      item.version = iosV;
    }
    return item;
  });

  return targetArr;
}
