import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

interface Props {
  iconName: string;
  title: string;
  description: string;
}

const Wrapper = styled.div`
  box-sizing: border-box;
  .card {
    width: ${isMobile? '37.5vw':'10.875vw'};
    height: ${isMobile? '33.5vw':'9.375vw'};
    box-sizing: border-box;
    border: 1px dashed black;
    .content {
      width: ${isMobile? '37.5vw':'10.875vw'};
      height: ${isMobile? '33.5vw':'9.375vw'};
      text-decoration: none;
      color: black;
      transition: 0.25s ease;
      border: 1px solid black;
      padding: ${isMobile?'0 0 0 4.26vw':'0 0 0 0.75vw'};
      left: -1px;
      position: relative;
      top: -1px;
      &:hover {
        transform: translate(-0.5vw, -0.5vw);
        background: #242CE0;
        padding: 0;
        .card--display {
          display: none;
        }

        .card--hover {
          display: block;
          color: white;
          padding: ${isMobile?'4.27vw 0 0 3.2vw':'0.75vw'};
        }
      }

      .card--display {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .icon {
          width: ${isMobile?'7.33vw':'2.06vw'};
          height: ${isMobile?'7.33vw':'2.06vw'};
        }

        .title {
          font-size: ${isMobile?'3.73vw':'1.125vw'};
          font-weight: ${isMobile?'600':'400'};
          color: ${isMobile?'#333333':'black'};
          line-height: ${isMobile?'4.53vw':'1.68vw'};
          max-width: ${isMobile?'28.9vw':'8.625vw'};
          flex-wrap: wrap;
          //margin-top: ${isMobile?'2.53vw':'0.6vw'};
          ${isMobile&& 'margin-top:2.67vw;'}
        }
      }

      .card--hover {
        display: none;

        p {
          font-size:  ${isMobile?'3.73vw':'1.125vw'};
          font-weight: 400;
          color: #FFFFFF;
          line-height: ${isMobile?'4.56vw':'1.68vw'};
        }
      }
    }
  }

  .card--border {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 2px dashed black;
    z-index: -1;
  }
`;

export const ChatItem = ({iconName, title, description}: Props) => {
  return (
    <Wrapper>
      <div className="card">
        <div className="content">
          <div className="card--display">
            <img className="icon" src={iconName} alt=""/>
            <div className="title" style={{textAlign:'start'}}>{title}</div>
          </div>
          <div className="card--hover">
            <p>{description}</p>
          </div>
        </div>
      </div>
      <div className="card--border"/>
    </Wrapper>
  );
};