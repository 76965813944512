import React from "react";
import styled from "styled-components";
import { isMobile } from 'react-device-detect'
import bgWeb from '../../assets/home/statisticsBg.svg'
import bgMobile from '../../assets/home/statisticsBgMobile.svg'
import bgDot from '../../assets/dashboard/texture.svg';
import AnimationAmount from './AnimationAmount';

type listItem = {
  key:string
  title:string
  amount:string
}
interface StatisticsItemProps {
  bg?: string
  statisticsList: listItem[]
  hasDot?: boolean
}


const StatisticsItem: React.FC<StatisticsItemProps> = ({bg, statisticsList, hasDot = false}: StatisticsItemProps) => {

  return (
    <Wrapper bg={bg}>
      {hasDot && <img className="center-dot" src={bgDot} alt=""/>}
      <div className="content">
        {statisticsList?.map(item=><AmountItemWrapper key={item.key}>
          <div className="title">{item.title}</div>
          <AnimationAmount amount={item.amount}/>
          {/*{item.title === 'Number of New Users Per Week' ? <span className="coming">Coming soon</span>:<AnimationAmount amount={item.amount}/>}*/}
        </AmountItemWrapper>)}
      </div>
    </Wrapper>
  );
};

export default StatisticsItem;

const Wrapper = styled.div<{bg?: string}>`
  width: ${({ bg }) => isMobile ? bg ? '87vw' :'fit-content' :''}; 
  height: ${({ bg }) => bg? isMobile?'87vw':'8.25vw' : isMobile?'fit-content':'8.25vw'};
  background: ${({ bg }) => bg? 'transparent':'#EDEEF9'};  
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 3vw;
  background-image: ${({ bg }) => `url(${bg})`};
  border-radius: ${isMobile? '2.13vw': ''};
  position: relative;
  .center-dot{
    position: absolute;
    z-index: 1;
    transform: translateX(27vw);
    width: 18.75vw;
    height: 8.06vw;
  }
  .content{
    position: relative;
    z-index: 2;
    padding: ${isMobile?'':'0 7vw'};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${isMobile?'column':'row'};
    justify-content: space-around;
    align-items: center;
  }
`

const AmountItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:  ${isMobile?'space-around':'space-between'};
  height: ${isMobile?'25.3vw':'6vw'};
  width: ${isMobile?'87.2vw': ''};
  .title{
    max-width: ${isMobile?'':'13.25vw'};
    flex-wrap: wrap;
    text-align: center;
    font-size: ${isMobile?'3.73vw':'1vw'};
    font-weight: 400;
    color: #000000;
    line-height: ${isMobile?'4.53vw':'1.25vw'};
  }
  .amount{
    font-size: ${isMobile?'6.4vw':'2.25vw'};
    font-weight: 600;
    color: #000000;
    line-height: ${isMobile?'7.8vw':'2.75vw'}
  }
  .coming{
    font-size: ${isMobile?'3.5vw':'1.2vw'};
    line-height: ${isMobile?'7.8vw':'3.876vw'}
  }
`;