import React, { useEffect } from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px 0px rgba(0,0,0,0.17);
  border-radius: 24px;
  width: 76.4vw;
  margin: 1.937vw;
  height: 100%;

  .info{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 2vw 3vw 3vw;
    gap: 11.06vw;
  }
  .infoCard-icon{
    width: 22.875vw;
    height: 21.375vw;
  }
`

const CardTitle = styled.div`
  max-width: 33.25vw;
  font-size: 3.375vw;
  font-weight: 600;
  color: #000000;
  line-height: 4.06vw;
  margin-bottom: 3vw;
`

const CardContent = styled.div`
  max-width: 33.25vw;
  font-size: 1.125vw;
  font-weight: 400;
  color: #333333;
  line-height: 1.687vw;
`

interface InfoCardProps {
  isTextOnLeft: boolean
  title: string
  content: string
  img: string
  statistics: React.ReactNode
}

const InfoCard = ({isTextOnLeft, title, content, img, statistics}: InfoCardProps): React.ReactElement => {
  return <Wrapper>
    {isTextOnLeft? <div className="info">
      <div className="flex flex-col">
        <CardTitle>{title}</CardTitle>
        <CardContent>{content}</CardContent>
      </div>
      <img src={img} alt="" className="infoCard-icon" style={{marginRight: '3.25vw'}}/>
    </div> : <div className="info">
      <img src={img} alt="" className="infoCard-icon"/>
      <div className="flex flex-col" style={{marginRight: '3.25vw'}}>
        <CardTitle>{title}</CardTitle>
        <CardContent>{content}</CardContent>
      </div>
    </div>}
    {statistics}
  </Wrapper>
};

export default InfoCard;
