import create from 'zustand'
import axios from 'axios';


const dmensTraceInfoInitList = [{
  key:'0',
  title:'Total Transactions',
  amount:'0'
},{
  key:'1',
  title:'Daily Active Users',
  amount:'0'
}]

const redPacketTraceInfoInitList = [{
  key:'0',
  title:'Total Transactions',
  amount:'0'
},{
  key:'1',
  title:'Total Sui RedPacket Transactions',
  amount:'0'
}]

const stakingTraceInfoInitList = [{
  key:'0',
  title:'Total Number of Staking Users',
  amount:'0'
},{
  key:'1',
  title:'Total Number of Staking Addresses',
  amount:'0'
}]

interface statistics {
  key:string
  title:string
  amount:string
}

interface INIT_SDK{
  getDashboardData: () => Promise<any>
  dashboard: {
    comingChatTraceInfo: {
      totalUsers: number
      dau: number
      newUsersPreWeek: number
    },
    redPacketTraceInfo: {
      totalTransactions: number
      totalSuiTransactions: number
    },
    dmensTraceInfo: {
      totalTransactions: number
      dau: number
    },
    stakingTraceInfo: {
      totalStakingUsers: number
      totalStakingAdrress: number
    }
  }
  totalStatisticsHomePage: statistics[]
  dmensTraceInfoData: statistics[]
  redPacketTraceInfoData:statistics[]
  stakingTraceInfoData: statistics[]
}

const useDashboardStore = create<INIT_SDK>((set, get) => ({
  dashboard: {
    comingChatTraceInfo: {
      totalUsers: 0,
      dau: 0,
      newUsersPreWeek: 0
    },
    redPacketTraceInfo: {
      totalTransactions: 0,
      totalSuiTransactions: 0
    },
    dmensTraceInfo: {
      totalTransactions: 0,
      dau: 0
    },
    stakingTraceInfo: {
      totalStakingUsers: 0,
      totalStakingAdrress: 0
    }
  },
  totalStatisticsHomePage:[{
    key:'0',
    title:'Total Number of Users',
    amount:'0'
  },{
    key:'1',
    title:'Daily Active Users',
    amount:'0'
  },
  //   {
  //   key:'2',
  //   title:'Number of New Users Per Week',
  //   amount:'0'
  // }
  ],
  dmensTraceInfoData: dmensTraceInfoInitList,
  redPacketTraceInfoData:redPacketTraceInfoInitList,
  stakingTraceInfoData: stakingTraceInfoInitList,
  async getDashboardData() {
    try {
      axios
        .get(`https://common.coming.chat/comingTraceData/getComingTraceData`)
        .then(res => {
          if(res?.data){
            const {comingChatTraceInfo, dmensTraceInfo,redPacketTraceInfo, stakingTraceInfo} =  res?.data
            set({totalStatisticsHomePage:[
                {
                  key:'0',
                  title:'Total Number of Users',
                  amount:comingChatTraceInfo?.totalUsers,
                },{
                  key:'1',
                  title:'Daily Active Users',
                  amount:comingChatTraceInfo?.dau
                },
                // {
                //   key:'2',
                //   title:'Number of New Users Per Week',
                //   amount:comingChatTraceInfo?.newUsersPreWeek
                // }
              ]})
            set({dmensTraceInfoData: [{
                key:'0',
                title:'Total Transactions',
                amount:dmensTraceInfo?.totalTransactions
              },{
                key:'1',
                title:'Daily Active Users',
                amount:dmensTraceInfo?.dau
              }]})
            set({redPacketTraceInfoData: [{
                key:'0',
                title:'Total Transactions',
                amount:redPacketTraceInfo?.totalTransactions
              },{
                key:'1',
                title:'Total Sui RedPacket Transactions',
                amount:redPacketTraceInfo?.totalSuiTransactions
              }]})
            set({stakingTraceInfoData: [{
                key:'0',
                title:'Total Number of Staking Users',
                amount:stakingTraceInfo?.totalStakingUsers
              },{
                key:'1',
                title:'Total Number of Staking Addresses',
                amount:stakingTraceInfo?.totalStakingAdrress
              }]})
          }
          console.log(res)
        })
        .catch(err => {})
    } catch (e) {
      console.log(e)
    }
  }

}))

export default useDashboardStore
