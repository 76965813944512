import React, { useState } from "react";
import RoundBlack from "../../assets/round_black.svg";
import RoundWhite from "../../assets/round-white.svg";
import { MenuButtonWrapper } from "./styles";

interface Props {
  type: "close" | "list";
  color: "white" | "black";
  className?: string;
  onClick: () => void;
  isMenuOpen?: boolean;
}

const MenuButton = ({
  type,
  color,
  className,
  onClick,
  isMenuOpen,
}: Props): React.ReactElement<Props> => {
  const [showText, setShowText] = useState(false);

  return (
    <MenuButtonWrapper
      onClick={onClick}
      className={className}
      showText={showText}
      color={color}
      isMenuOpen={isMenuOpen}
    >
      <img
        className="round"
        src={color === "black" || isMenuOpen ? RoundBlack : RoundWhite}
        alt=""
        onLoad={() => setShowText(true)}
      />
      <div
        className={`menu-button ${
          color === "black" ? "black-bar" : "white-bar"
        } ${isMenuOpen ? "cross" : ""}`}
      >
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </div>
    </MenuButtonWrapper>
  );
};

export default MenuButton;
