import React, { memo } from "react";
import CIDItemWrapper from "./styled";

import arrow from "../../assets/arrow.svg";

interface IProps {
  title: string;
  text: string;
  textWidth: string;
  link: string;
  imgUrl: string;
  imgSize?: string;
  right?: string;
  mImgSize?: string;
  mTextWidth?: string;
}

const CIDItem = memo(
  ({
    title,
    text,
    imgUrl,
    link,
    imgSize,
    right,
    textWidth,
    mImgSize,
    mTextWidth,
  }: IProps) => {
    return (
      <CIDItemWrapper
        imgSize={imgSize}
        mImgSize={mImgSize}
        right={right}
        textWidth={textWidth}
        mTextWidth={mTextWidth}
      >
        <a href={link} rel="noreferrer" target="_blank">
          <h2 className="font">{title}</h2>
          <div className="content">
            <p className="font-pf text">{text}</p>
          </div>
          <button className="btn-arrow font">
            MORE
            <img src={arrow} alt="" />
          </button>
          <img src={imgUrl} alt="item-img" className="item-img" />
        </a>
        <div className="mask"></div>
      </CIDItemWrapper>
    );
  }
);

export default CIDItem;
