import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../hooks/useSettings";

import CIDItem from "../../components/CIDItem";
import { cidDataList, ICidData } from "../../common/local-data";

const StyledPrivacyPage = styled.div`
  padding-top: 80px;
  background: white;
  @media screen and (max-width: 769px) {
    padding-top: 17px;
  }
`;

const Head = styled.div`
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  padding-top: 5.55vw;
  ///* padding: 0 2.75rem; */

  @media screen and (max-width: 769px) {
    margin: 0;
  }
  .title {
    @media screen and (max-width: 769px) {
      width: 100%;
      font-size: 30px;
      text-align: center;
    }
    width: 1216px;
    margin: 0 auto;
    margin-left: calc(auto - 11px);
    font-size: 60px;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    color: #000000;
    line-height: 72px;
  }
`;

const CIDWrapper = styled.div`
  background-color: white;
  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    margin: 0 auto;
    margin-top: 24px;
    @media screen and (min-width: 640px) {
      width: 1216px;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 68px;
      margin-bottom: 105px;
    }
    margin-bottom: 50px;
  }
`;

const Cid = (): React.ReactElement => {
  const { routeName, setRouteName, setIsMenuOpen } = useSettings();
  const { t } = useTranslation();
  useEffect(() => {
    setRouteName("privacy");
    setIsMenuOpen(false);
  }, [setIsMenuOpen, setRouteName]);

  return (
    <StyledPrivacyPage>
      <Head>
        <div className="title">{t("How to get CID")}</div>
      </Head>
      <CIDWrapper>
        <ul>
          {cidDataList.map((item: ICidData, index) => {
            return <CIDItem {...item} key={index}></CIDItem>;
          })}
        </ul>
      </CIDWrapper>
    </StyledPrivacyPage>
  );
};

export default Cid;
