import React from "react"
import Abouts from "./assets/Abouts.png"
import arrowright from "../../../assets/arrowright.png"
import arrowleft from "../../../assets/arrowleft.png"
import styled from "styled-components"
import shield from "./assets/shield.png"
import rhombus from "./assets/rhombus.png"
import cube from "./assets/cube.png"
import email from "./assets/email.svg"
import github from "./assets/github.svg"
import medium from "./assets/medium.svg"
import twitter from "./assets/twitter.svg"
import telegram from "./assets/telegram.svg"
import star from "./assets/star.svg"
import cubeTop from "./assets/iconTop.png"
import cubeLeft from "./assets/textLeft.png"
import { useTranslation } from "react-i18next"
import { iconInfos } from "../../group/utils/data"
import LinkIcon from "../../../components/LinkIcon"

const AboutUs: React.FC = () =>
{
  const { t } = useTranslation()

  return <Wrapper>
    {/* <a className="nextpage">
        <img src={arrowright} alt="" className="arrowright" />
      </a> */}
    {/* <a className="prevpage">
        <img src={arrowleft} alt="" className="arrowleft" />
      </a> */}
    <img src={cubeTop} alt='img' className='cubeTop' />
    <LeftMain>
      <div className='aboutImg'>
        <img src={Abouts} alt='img' className='abouts' />
        <img src={star} alt='img' className='star' />
      </div>
      <div className='content'>
        <img src={cubeLeft} alt='img' className='cubeLeft' />
        <div className='txt'>
          <div className='titles'>{t("Contact us")}</div>
          <p
            className='text'>{t("Use the most advanced end-to-end encryption technology (Signal Protocol) to protect the security of your chat information. \nWe cannot obtain the content of your message, and other people cannot steal your information. Coming Chat is born for privacy")}</p>
          <span className='tag'>All rights reserved © 2021 Coming</span>
        </div>
        <img src={shield} alt='img' className='shield' />
      </div>
    </LeftMain>
    <HomeFooter>
      <div className='cubeRhombus'>
        <img src={cube} alt='img' className='cube' />
        <img src={rhombus} alt='img' className='rhombus' />
      </div>
      <div className='footerIconList'>
        {iconInfos.map(i =>
          <LinkIcon
            path={i.path}
            imgSrc={i.imgSrc}
            text={i.text}
            key={i.text}
            type={i.type}
          />)}
      </div>
    </HomeFooter>
  </Wrapper>
}

export default AboutUs

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  // padding: 0 3.12vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .cubeTop {
    position: absolute;
    top: -3.73vw;
    width: 9.27vw;
    height: 9.27vw;
  }
  a.prevpage {
    width: 1.094vw;
    height: 0.833vw;
    position: absolute;
    left: 3.177vw;
  }
  a.nextpage {
    width: 1.094vw;
    height: 0.833vw;
    position: absolute;
    right: 3.177vw;
  }
`
const LeftMain = styled.div`
  width: 70vw;
  height: 100vh;
  background: #242CE0;
  padding: 8vw 2.7vw 4.27vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  .aboutImg {
    width: 100%;
    padding-left: 3.177vw;
    position: relative;
    .abouts {
      width: 32.55vw;
      height: 16.09vw;
    }
    .star {    
      position: absolute;
      right: 10vw;
      bottom: 2vw;
      width: 4.68vw;
      height: 4.68vw;
    }
  }
  .content {
    margin-top: 9.18vw;
    position: relative;
    .shield {
      position: absolute;
      right: -8vw;
      bottom: 12vw;
      width: 10.36vw;
      height: 12.18vw;
    }
    .cubeLeft {
      position: absolute;
      left: -13vw;
      bottom: 9vw;
      width: 4.63vw;
      height: 4.83vw;
    }
    .txt {
      text-align: left;
      .titles {
        height: 6.458vw;
        line-height: 6.458vw;
        font-size: 4.635vw;
        // font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 2.08vw;
      }
      .text {
        width: 46.777vw;
        height: 3.43vw;
        font-size: 1.25vw;
        font-weight: 100;
        color: #FFFFFF;
        line-height: 2vw;
        margin-bottom: 2.39vw;
      }
      .tag {
        font-size: 0.72vw;
        color: #FFFFFF;
        line-height: 0.83vw;;
        font-weight: normal;
      }
    }
  }
`


const HomeFooter = styled.div`
  width: 30vw;
  height: 100vh;
  
  padding: 0 5.46vw 0 6.56vw;
  display: flex;
  justify-content: space-between;
  
  .cubeRhombus {
    margin-top: 3.9vw;
    .cube {
      width: 4.63vw;
      height: 4.68vw;
      margin-bottom: 5.31vw;
    }
    .rhombus {
      width: 8.9vw;
      height: 13.85vw;
      margin-left: 0.72vw;
    }
  }
  .footerIconList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 2.5vw;
      height: 2.5vw;
      margin:  1.04vw 0;
    }
  }
`
