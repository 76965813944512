import React, { useEffect, useState, useRef } from 'react';
import BlackLogo from '../../assets/home/logoBlack.svg';
import { HeaderWrapper } from './styles';
import { LanguageSwitcher, SwiperBtn, TranslationItem } from './MenuContent';
import WebHeaderRoute from './WebHeaderRoute';
import styled from 'styled-components';
import Features from './Features';
import DownLoadIcon from '../../assets/nav/icon_download.svg';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import path from 'path';


const Wrapper = styled.div`
  position: relative;

  .showNav {
    background: white;
    max-height: 88px;
    transition: max-height 0.3s ease-in;
    transform-origin: 50% 0;
    animation: slide-down 0.3s ease-in;
    -webkit-animation: slide-down 0.3s ease-in;
  }

  .hiddenNav {
    visibility: hidden;
    transition: max-height 0.3s ease-out;
  }
`;

const HoverMenu = styled.div`
  position: relative;
  width: 100vw;
  z-index: 2;
  background: white;

  .m-mobile-nav {
    opacity: 1 !important;
    background: transparent !important;
  }

  .m-select {
    position: fixed;
    top: 5.46vw;
    width: 100%;
    background: white;
    transition: 0.4s;
    height: 0;
    overflow: hidden;
  }

  .m-show {
    border-top: 1px solid #EFEFEF;
    height: 12.33vw;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  }

  .m-hidden {
    height: 0;
    transition: 0s;
  }
`;

const LinkWrapper = styled.div<{ showposition?: string }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({showposition}) => showposition ? showposition : 'space-between'};
  margin-left: 3.4vw;
`;

const Header = ({bgColor}: { bgColor?: string }): React.ReactElement => {
  const {t, i18n} = useTranslation();
  const [show, SetShow] = useState<boolean>(false);
  const {location: {pathname},} = useHistory();

  const withOutLinkPage = ['/about', '/download'];
  const translationList: TranslationItem[] = [
    {
      text: 'English',
      language: 'en',
    },
    {
      text: '中文',
      language: 'zh',
    },
  ];
  const oldTop = useRef(0);
  const [showMore, setShowMore] = useState(false);

  function showMenu() {
    const navBarDom = navBarRef.current;
    if (navBarDom) {
      if (showMore && oldTop.current <= 699) {
        navBarDom.style.background = 'transparent';
      } else {
        navBarDom.style.background = 'white';
      }
    }

    setShowMore(!showMore);
  }

  const downloadIcon = <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g id="4.0" stroke="currentColor" stroke-width="1" fill="currentColor">
      <g id="首页" transform="translate(-1067.000000, -34.000000)">
        <g id="编组-4" transform="translate(1035.000000, 18.000000)">
          <g id="编组-2" transform="translate(32.000000, 16.000000)">
            <g id="icon_download" transform="translate(0.000000, 0.769231)">
              <polygon id="路径" fill="currentColor"
                       points="10.05838 9.96153846 10.0007399 10.0615385 9.94161995 9.96153846"></polygon>
              <path
                d="M15.4853906,7.68383331 L11.9533203,7.68383331 L11.9533203,0 L8.25144531,0 L8.25144531,7.68383331 L4.66755859,7.68383331 L10.1414063,13.2489495 L15.4853906,7.68383331 Z M0,16.6692167 L20,16.6692167 L20,18.4615385 L0,18.4615385 L0,16.6692167 Z"
                id="形状" fill="currentColor"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>;

  const navBarRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const navBarDom = navBarRef.current;
    const setOpacity = (opacity: number, bgColor?: string) => {
      if (navBarDom) {
        if (bgColor) {
          navBarDom.style.background = bgColor;
        }
        if (opacity === 0) {
          navBarDom.style.opacity = '0';
          navBarDom.style.transition = '0.3s ease-out';
          return;
        }
        if (opacity === 1) {
          navBarDom.style.transition = '0.3s ease-in';
          navBarDom.style.opacity = '1';
          return;
        }
        navBarDom.style.opacity = parseFloat(`${1 - opacity})`).toFixed(2);
      }
    };
    // 初始设置透明
    setOpacity(1);
    // let oldTop = 0; //旧数据，初始为0
    const onScroll = () => {
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      let opacity = 1;
      if (top !== 0) {
        opacity = top < 135 ? top / 135 : 0;
      }
      setOpacity(opacity, 'transparent');
      setShowMore(false);
      //旧数据大于当前位置，表示滚动条top向上滚动
      if (oldTop.current > top && top > 699) {
        setOpacity(1, 'white');
        // console.log("↑");
      } else if (top > 135 && top <= 699) {
        //相反...
        setOpacity(0);
        // console.log("↓");
      }
      oldTop.current = top; //更新旧的位置
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Wrapper>
      <HeaderWrapper
        ref={navBarRef}
        className={`z-10 fixed top-0  flex items-center`}
      >
        <a className="ComingChat-logo" href="/">
          <img className="logo-pc" src={BlackLogo} alt="ComingChat"/>
        </a>
        {!isMobile && (
          <LinkWrapper showposition={withOutLinkPage.includes(pathname) ? 'end' : 'space-between'}>
            {!withOutLinkPage.includes(pathname) && <WebHeaderRoute showMore={showMore} setShowMore={showMenu}/>}
            <div className="flex" style={{gap: '1.11vw'}}>
              <SwiperBtn>
                <div className={'flex flex-row cid-wapper'}>
                  <section className="color-1">
                    <p>
                      <button className={`btn btn-1 btn-1c black-hover-text`} onClick={() => (window.location.href = '/download')}>
                        {downloadIcon}
                        <span>{t('Download')}</span>
                      </button>
                    </p>
                  </section>
                </div>
              </SwiperBtn>
              {/*<LanguageSwitcher color={"black"} background={"white"}>*/}
              {/*  <div*/}
              {/*    className="translate"*/}
              {/*    onClick={(e) => {*/}
              {/*      e.stopPropagation();*/}
              {/*      SetShow(!show);*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <span className="translate">*/}
              {/*      <img src={LangIcon} className="LanguateIcon" />*/}
              {/*      <span className="text">*/}
              {/*        {translationList.map(*/}
              {/*          (t) => i18n.language === t.language && t.text*/}
              {/*        )}*/}
              {/*      </span>*/}
              {/*      <img src={FBlack} alt="" className="DropDown" />*/}
              {/*    </span>*/}
              {/*    {show && (*/}
              {/*      <div className="translateList">*/}
              {/*        {translationList.map((t) => (*/}
              {/*          <TranslateButton*/}
              {/*            text={t.text}*/}
              {/*            language={t.language}*/}
              {/*            key={t.text}*/}
              {/*          />*/}
              {/*        ))}*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</LanguageSwitcher>*/}
            </div>
          </LinkWrapper>
        )}
      </HeaderWrapper>
      {!isMobile && (
        <HoverMenu>
          <div className={[`${showMore ? 'm-show' : 'm-hidden'}`, 'm-select'].join(' ')}>
            <Features/>
          </div>
        </HoverMenu>
      )}
    </Wrapper>
  );
};

export default Header;