import styled from "styled-components";

interface IImgSize {
  textWidth: string;
  imgSize?: string;
  right?: string;
  mImgSize?: string;
  mTextWidth?: string;
}

const CIDItemWrapper = styled.li<IImgSize>`
  position: relative;
  top: 0;
  margin: 0 19px 25px 0;
  transition: all 0.5s;
  &:nth-child(3) {
    margin-right: 0;
  }

  > a {
    position: relative;
    display: block;
    z-index: 1;
    width: 295px;
    height: 253px;
    padding: 24px 20px;
    border-radius: 12px;
    box-sizing: border-box;
    background: #f4f5f7;
    margin: auto;
    @media screen and (min-width: 640px) {
      width: 392px;
      height: 328px;
    }
    :hover {
      background: #d6dced;
    }
    .font {
      font-family: TimesNewRomanPS-BoldMT, TimesNewRomanPS;
    }
    .font-pf {
      font-family: PingFangSC-Regular, PingFang SC;
    }

    > h2 {
      @media screen and (min-width: 640px) {
        line-height: 36px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 24px;
      }
      font-size: 23px;
    }
    .content {
      position: relative;
      .text {
        @media screen and (min-width: 640px) {
          width: ${(props) => props.textWidth};
          font-size: 14px;
          line-height: 20px;
        }
        width: ${(props) => props.mTextWidth};
        font-size: 12px;
        font-weight: 400;
        color: #000000;
        line-height: 15px;
      }
    }
    .btn-arrow {
      @media screen and (min-width: 640px) {
        bottom: 28px;
        width: 104px;
        height: 28px;
        font-size: 13px;
        img {
          width: 23px !important;
        }
      }
      position: absolute;
      bottom: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 22px;
      background: #0c0c0d;
      border-radius: 6px;
      font-size: 12px;
      font-weight: normal;
      color: #ffffff;
      line-height: 20px;
      img {
        width: 18px;
        margin-left: 4px;
      }
    }

    img {
      &.item-img {
        @media screen and (min-width: 640px) {
          width: ${(props) => props.imgSize};
          right: -5px;
          bottom: -6px;
        }
        position: absolute;
        right: 0;
        bottom: 0;
        width: ${(props) => props.mImgSize};
      }
    }
  }

  .mask {
    @media screen and (min-width: 640px) {
      width: 280px;
      height: 157px;
      opacity: 0.24;
    }
    position: absolute;
    display: none;
    left: 50%;
    bottom: -10px;
    width: 251px;
    height: 154px;
    transform: translate(-50%);
    background: #0c0c0d;
    border-radius: 12px;
    filter: blur(20px);
    opacity: 0.2;
  }
  &:hover {
    top: -20px;
    .mask {
      display: block;
    }
  }
`;

export default CIDItemWrapper;
