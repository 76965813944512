import React, { useEffect } from "react";
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 0.5vw 1.25vw 0 rgba(0,0,0,0.17);
  border-radius: 3.2vw;
  width: fit-content;
  margin: 3.06vw 4.26vw 0 4.26vw;
  height: fit-content;
  align-items: center;
  padding: 2.26vw;
  .infoCard-icon{
    width: 74.6vw;
    height: 69.7vw;
    margin-top: 7.2vw;
    margin-bottom:5.6vw;
  }
`

const CardTitle = styled.div`
  font-size: 8vw;
  font-weight: 600;
  color: #000000;
  line-height: 9.6vw;
  max-width: 82.67vw;
  text-align: center;
  margin-top: 2.13vw;
  margin-bottom: 4.267vw;
`

const CardContent = styled.div`
  max-width: 82.67vw;
  text-align: center;
  font-size: 3.73vw;
  font-weight: 400;
  color: #333333;
  line-height: 4.53vw;
`

interface InfoCardProps {
  title: string
  content: string
  img: string
  statistics: React.ReactNode
}

const MobileInfoCard = ({title, content, img, statistics}: InfoCardProps): React.ReactElement => {
  return <Wrapper>
    <CardTitle>{title}</CardTitle>
    <CardContent>{content}</CardContent>
    <img src={img} alt="" className="infoCard-icon"/>
    {statistics}
  </Wrapper>
};

export default MobileInfoCard;
