import React, { useEffect } from 'react';
import styled from "styled-components";
import bgWeb from '../../assets/home/statisticsBg.svg'
import bgMobile from '../../assets/home/statisticsBgMobile.svg'

import { AmountItemWrapper } from './style';
import { isMobile } from 'react-device-detect'
import AnimationAmount from '../dashboard/AnimationAmount';
import useDashboardStore from '../../store';

const TotalStatistics: React.FC = () => {
  const { totalStatisticsHomePage, getDashboardData } = useDashboardStore()
  useEffect(()=>{
    getDashboardData()
  },[])

  return (
    <Wrapper>
      <img className="bg" src={isMobile ? bgMobile : bgWeb} alt=""/>
      <div className="content">
        {totalStatisticsHomePage?.map(item=><AmountItemWrapper key={item.key}><div className="title">{item.title}</div>
          <AnimationAmount amount={item.amount}/>
          {/*{item.title === 'Number of New Users Per Week' ? <span className="coming">Coming soon</span>:<AnimationAmount amount={item.amount}/>}*/}
        </AmountItemWrapper>)}
      </div>
    </Wrapper>
  );
};

export default TotalStatistics;

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 82vw;
  height: ${isMobile?'100vw':'19vw'};
  position: relative;
  .bg{
    position: absolute;
    z-index: 1;
    //top: 3.5vw;
    width: 100%;
    height: 100%;
  }
  .content{
    padding: ${isMobile && '20vw 0vw'};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${isMobile?'column':'row'};
    justify-content: space-around;
    align-items: center;
    position: absolute;
    z-index: 2;
    &>div{
      width: ${!isMobile && '18.75vw'};
      max-width: ${!isMobile && '18.75vw'};
      flex-wrap: ${!isMobile && 'wrap'};
    }
  }
`