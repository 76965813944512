import React, { useEffect } from "react";
import BG from "../../assets/line_group.svg";
import ComingLogo from "../../assets/white-logo.svg";
import GroupLogo from "../../assets/group-logo.png";
import { useTranslation } from "react-i18next";
import { useSettings } from "../../hooks/useSettings";
import useAddresse from "../../utils/hooks/useAddress";
type GroupProps = {
  // ua: string;
  // ua2: string;
};
const MobilePart: React.FC<GroupProps> = ({}) => {
  const { t, i18n } = useTranslation();
  const { isIOS, isAndroid, isSafari, isBrowser, groupString } = useSettings();
  const { IOSDownload, androidDownload } = useAddresse();
  const installMethod = () => {
    let link = isAndroid ? androidDownload : IOSDownload;
    window.location = link;
  };
  const IOSClick = () => {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      window.location.href = `cmig://coming.group/#${groupString}`;
      window.setTimeout(function () {
        installMethod();
      }, 600);
    }
  };
  const AndroidClick = () => {
    var startTime = Date.now();
    try {
      window.location.href = `cmig://coming.group/#${groupString}`;
    } catch (e) {
      installMethod();
    }
    var t = setTimeout(function () {
      var endTime = Date.now();
      if (!startTime || endTime - startTime < 600 + 200) {
        installMethod();
      } else {
      }
    }, 600);
    window.onblur = function () {
      clearTimeout(t);
    };
  };
  const handleOnClick = () => {
    if (isAndroid) {
      if (!isBrowser) {
        alert("please use browser!");
      } else {
        AndroidClick();
      }
    } else if (isIOS) {
      if (!isSafari) {
        alert("please use Safari!");
      } else {
        IOSClick();
      }
    }
  };

  return (
    <>
      <div className="mobile-phone flex flex-col justify-center items-center">
        <img src={BG} alt="" className="bg-image absolute" />
        <img src={ComingLogo} className="coming-logo" />
        <div className="container  flex flex-col justify-center items-center">
          <img className="group-logo" src={GroupLogo} alt="group" />
          <p className="title">{t("Group conversation")}</p>
          <p className="description">
            {t(
              "Use the most cutting-edge end-to-end encryption technology (Signal Protocol) to make sure your conversation well protected. We are not able to interfere with or intercept your chat, nor are others. Coming Chat is born for privacy."
            )}
          </p>
          <button
            className="join-button flex items-center"
            onClick={handleOnClick}
            id="downloadButton"
          >
            {t("Join Group")}
          </button>
          <a className="download-link" href="/download">
            {t("Download ComingChat")}
          </a>
          <p className="footer absolute">All rights reserved © 2021 Coming</p>
        </div>
      </div>
    </>
  );
};

export default MobilePart;
