import React from "react";

import { ListItem } from "./utils/data";

interface Props extends ListItem {}

const DownloadImg = ({
  name,
  link,
  imgSrc,
  version,
}: Props): React.ReactElement<Props> => {
  return (
    <a href={link}>
      <img src={imgSrc} alt={name} />
      {/*{version && <p style={{}}>Version {version}</p>}*/}
    </a>
  );
};

export default DownloadImg;
