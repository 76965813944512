import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MainWrap } from "./mainwrap";
import { defaultOptions } from "../../utils/animateoption";
import Lottie from "react-lottie";
import animationData from "../../css/animate/CID.json";
import star_icon from './assets/icon_get_white_cid.svg'
const Wrapper = styled.div`
  background: #D6DCED;
  position: relative;
  padding: 14.5vw 6.48vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img.nftshap {
    position: absolute;
    width: 74.733vw;
    height: 46.133vw;
    top: -11.733vw;
    left: 16.4vw;
    margin: 0 auto;
    z-index: 1;
  }
  h1 {
    color: #000000;
  }
  p {
    color: #333333;
  }
  .nft {
    margin: 8.133vw 0 0 0;
  }
`;

const BtnWrapper = styled.div`
  background: black;
  border: 1px solid #ffffff;
  border-radius: 2.13vw;
  padding: 4.26vw 8.53vw;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: 5.6vw 0 9.2vw 0;
  >img{
    width: 5.06vw;
    margin-right: 4.26vw;
  }
  .btnName{
    font-size: 4.3vw;
    font-weight: 400;
    color: #ffffff;
    line-height: 5.4vw
  }
`

const NFTMbolPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <MainWrap>
        <h1>{t("CID")}</h1>
        <p style={{textAlign:'center'}}>{t("CID is a new type")}</p>
        <p style={{textAlign:'center'}}>{t("CID consists of 1 to 12 digits")}</p>
      </MainWrap>
      <BtnWrapper onClick={()=> window.location.href = '/cid'}>
        <img src={star_icon} alt=""/>
        <div className='btnName'>{t('Get CID')}</div>
      </BtnWrapper>
      <div className='nft'>
        <Lottie
          options={{ ...defaultOptions(animationData, "nft") }}
          isStopped={false}
          isPaused={false}
        />
      </div>
    </Wrapper>
  );
};

export default NFTMbolPage;
