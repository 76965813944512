import styled from "styled-components";

export const GroupPageWrapper = styled.div`
  .pc {
    width: 100%;
    @media screen and (max-width: 769px) {
      display: none;
    }
    .coming-logo {
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }
  .mobile-phone {
    position: relative;
    background: black;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    @media screen and (min-width: 780px) {
      display: none;
    }
    .bg-image {
      left: 0;
      top: 0;
      height: 100%;
      z-index: 0;
    }
    .coming-logo {
      position: absolute;
      left: 5vw;
      top: 5vw;
    }
    .container {
      z-index: 1;
      p {
        text-align: center;
        &.title {
          color: white;
          padding-bottom: 1vh;
          margin: 0 auto;
          font-size: 30px;
          line-height: 84px;
          font-weight: bold;
        }
        &.description {
          color: #b3b3b3;
          font-size: 12px;
          line-height: 20px;
          padding: 0 18vw;
        }
      }
      .group-logo {
        width: 53vw;
        padding-bottom: 4vh;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
      .join-button {
        color: white;
        width: fit-content;
        padding: 11px 40px;
        border: 1px solid white;
        border-radius: 7px;
        margin: 4.2vh 0;
        font-size: 16px;
        img {
          padding-right: 8px;
        }
      }
      .download-link {
        font-size: 14px;
        color: #585eff;
        text-decoration: revert;
      }
      .footer {
        color: white;
        bottom: 25px;
      }
    }
  }
  .left {
    width: 70vw;
    background: #242ce0;
    height: 100vh;
    padding-top: 18vh;
    padding-left: 17vw;

    .group-logo {
      height: 24vh;
    }

    .content {
      padding-left: 7vw;

      .title {
        font-size: 8vh;
        max-height: 124px;
        font-weight: bold;
        margin-bottom: 3.7vh;
      }

      .description {
        font-size: 2.5vh;
        margin-bottom: 5vh;
        max-width: 558px;
      }
      .download {
        &:focus {
          outline: none;
        }
      }
    }
  }

  .right {
    flex-grow: 1;
    background: #f0f2f5;

    .sidebar {
      top: 50%;
      transform: translateY(-50%);
      left: 65%;
      z-index: 1;
      img {
      }
    }
  }

  .shield {
    height: 21.6vh;
    bottom: 6.4vh;
    right: 24vw;
  }
  .cube-top {
    height: 5vh;
    right: 20vw;
    top: 7.2vh;
  }
  .rhombus {
    height: 24.5vh;
    top: 26vh;
    right: 14vw;
  }
  .cube-bottom {
    height: 5vh;
    left: 11vw;
    bottom: 13.8vh;
    transform: rotate(-180deg);
  }
`;
