import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LeftMenu from './LeftMenu';
import { useSettings } from '../../hooks/useSettings';
import InfoCard from './InfoCard';
import StatisticsItem from './StatisticsItem';

import bgWithDot from '../../assets/dashboard/amount_bg.svg';
import iconDefault from '../../assets/dashboard/icon_cc.png';
import iconStaking from '../../assets/dashboard/img_2.svg';
import iconRedPacket from '../../assets/dashboard/icon_redPacket.png';
import iconDMens from '../../assets/dashboard/icon_dmens.png';
import useDashboardStore from '../../store';

export const DASHBOARD_LIST = {
  default: {
    title: 'The Portal to the Web3.0 World',
    content: 'ComingChat is a SocialFi-based Web3 portal application with #DeFi and #GameFi functions.',
    img:iconDefault,
  },
  staking: {
    title: 'Staking',
    content: 'ComingChat is the first App to introduce the Sui economy. We have successively supported Sui\'s Devnet and Testnet, and were the first to implement the Sui Testnet Wave2 Staking activity. Sui Testnet Wave3 Staking is about to start, please stay tuned!',
    img:iconStaking,
  },
  redPacket: {
    title: 'RedPacket',
    content: 'ComingChat supports everyone to send and receive red envelopes. The sender can choose the number and amount of red envelopes to send, as well as words of blessing, and can send them to friends by paying with the selected token red envelopes; the receiver will get corresponding benefits after opening.',
    img:iconRedPacket,
  },
  DMens: {
    title: 'DMens',
    content: 'Sui\'s on-chain Twitter, and also a social metaverse with Time tokenmics. There, you can just focus on the Sui ecosystem, knowing anything that happens, and also be rewarded by your social interactions. The tokenmics will be released as Sui mainnet goes online.',
    img:iconDMens,
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
`

const WebDashBoard = (): React.ReactElement => {
  const { totalStatisticsHomePage, dmensTraceInfoData, redPacketTraceInfoData, stakingTraceInfoData} = useDashboardStore()
  const [currenTab, setCurrentTab] = useState<'All' | 'Staking' | 'RedPacket' | 'DMens'>('All')
  const { setRouteName, setIsMenuOpen } = useSettings();
  useEffect(() => {
    setRouteName("dashboard");
    setIsMenuOpen(false);
  }, [setIsMenuOpen, setRouteName]);

  const Default = <InfoCard img={DASHBOARD_LIST['default'].img} isTextOnLeft={true} title={DASHBOARD_LIST['default'].title} content={DASHBOARD_LIST['default'].content} statistics={<StatisticsItem hasDot={true} bg={bgWithDot} statisticsList={totalStatisticsHomePage} />}/>
  const Staking = <InfoCard img={DASHBOARD_LIST['staking'].img} isTextOnLeft={false} title={DASHBOARD_LIST['staking'].title} content={DASHBOARD_LIST['staking'].content} statistics={<StatisticsItem bg={bgWithDot} statisticsList={stakingTraceInfoData} />}/>
  const RedPacket = <InfoCard img={DASHBOARD_LIST['redPacket'].img} isTextOnLeft={true} title={DASHBOARD_LIST['redPacket'].title} content={DASHBOARD_LIST['redPacket'].content} statistics={<StatisticsItem bg={bgWithDot} statisticsList={redPacketTraceInfoData} />}/>
  const DMens = <InfoCard img={DASHBOARD_LIST['DMens'].img} isTextOnLeft={false} title={DASHBOARD_LIST['DMens'].title} content={DASHBOARD_LIST['DMens'].content} statistics={<StatisticsItem bg={bgWithDot} statisticsList={dmensTraceInfoData} />}/>
  const All = <>
    {Default}{Staking}{RedPacket}{DMens}
  </>
  const tabList = {
    All,
    Staking,
    RedPacket,
    DMens
  }

  return <Wrapper>
    <LeftMenu/>
    <div className={"flex flex-col"} style={{width:'100%', background: '#FAF9FC'}}>
      <TopMenuWrapper>
        <span className="title">Dashboard</span>
        <div className="tab">
          <div className={`tab-item ${currenTab === 'All' && 'active'}`} onClick={()=>setCurrentTab('All')}>All</div>
          <div className={`tab-item ${currenTab === 'Staking' && 'active'}`} onClick={()=>setCurrentTab('Staking')}>Staking</div>
          <div className={`tab-item ${currenTab === 'RedPacket' && 'active'}`} onClick={()=>setCurrentTab('RedPacket')}>RedPacket</div>
          <div className={`tab-item ${currenTab === 'DMens' && 'active'}`} onClick={()=>setCurrentTab('DMens')}>DMens</div>
        </div>
      </TopMenuWrapper>
      {tabList[currenTab]}
    </div>
  </Wrapper>
};

export default WebDashBoard;

const TopMenuWrapper = styled.div`
  width: 100%;
  min-height: 7.56vw;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-top: 1.5vw;
  padding-left: 2vw;
  .title{
    font-size: 2.5vw;
    font-weight: 500;
    color: #000000;
    line-height: 3vw;
  }
  .tab{
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 2.75vw;
    font-size: 0.875vw;
    font-weight: 600;
    color: #000000;
    line-height: 1vw;
    .tab-item{
      border-bottom: 0.25vw solid transparent;
      background-color: transparent;
      text-decoration: none;
      position: relative;
      font-size: 1vw;
      color: #333;
      padding-bottom: 0.5vw;
      transition: border-bottom 0.3s ease-in-out;
      &.active{
        border-bottom: 0.25vw solid #2B33FF;
      }
      :hover{
        border-bottom: 0.25vw solid #2B33FF;
      }
    }
  }
`
