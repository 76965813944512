import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import { BtnWrapper } from '../home/style';
import WhitePaperIcon from '../../assets/about/lightpaper.svg';
import MissionIcon from '../../assets/about/The Mission.svg'
import Logo from '../../assets/about/logo_white.svg';

const Wrapper = styled.div`
  background: white;
  color: white;
  justify-content: center;
  position: relative;
  width: 100vw;
  display: flex;
  img.bg1 {
    animation: 0.7s middleshow cubic-bezier(0.41, 0, 0.31, 1);
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  img.bg2 {
    position:absolute;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-40%) ;
    width: 67.188vw;
    z-index:1;
  }
  .backImg{
    position: absolute;
    z-index: 1;
    top: 6.25vw;
    margin: 0 auto;
  }
  .video-content {
    width:67.292vw;
    margin-top:6.25vw;
    padding-bottom:4.063vw;
    padding-top: 4.7vw;
    z-index: 2;
    text-align: center;
    background-image: url(${Logo});
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 44.58vw;
    //background-position-y: -4.7vw;
    //background-size: cover;
    //background: url("../../assets/bg.png");
    z-index: 2;
    .wapper {
      width: 67.292vw;
      height:calc(100% - 4.167vw);
      position: relative;
      .wapper_content {
        width: 44.688vw;
        margin:auto !important;
        display: flex;
        align-items: center;
        p {
          text-overflow:ellipsis;
          display:-webkit-box;
          -webkit-line-clamp:2;
          -webkit-box-orient:vertical;
          text-align: center;
          font-size: 1.38vw;
          font-weight: 400;
          color: #333333;
          line-height: 1.67vw;
          margin: 1.67vw 0 4.44vw 0;
        }
      }
    }
 
    h2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 3.47vw;
      font-family: Inter-Medium, Inter;
      font-weight: 500;
      color: #000000;
      line-height: 4.16vw;
    }
  }

  @media screen and (max-width: 1200px) {
    align-items: center !important;
    .video-content .wapper {
      //height: 70vh;
    }
    @media screen and (max-width: 1000px) {
      .video-content .wapper {
        //height: 40vh;
        .wapper_content {
          width:60vw;
        }
      }
      @media screen and (max-width: 640px) {
        .video-content .wapper {
          //height: 40vh;
          .wapper_content {
            width:60vw;
          }
          .mask,.icon_w,.video_logo {display:none!important;}
          #video {
            width: 100%!important;
            height: 168px!important;
            margin: 0!important;
          }
        }
        @media screen and (max-width: 400px) {
          .video-content .wapper {
            height: 30vh;
            .wapper_content {
              width:60vw;
            }
          }

        }

`;

const BtnFlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4.44vw 0 6vw 0;
  gap: 2.78vw;
  width: fit-content;
`

const About: React.FC = () => {
  const { t } = useTranslation();
  const opts = {
    width:'842px',
    height:'437px',
    playerVars: {
      loop: 1,
      playlist: 'kA0haHi1otI',
      modestbranding:1,
      iv_load_policy:3,
      rel:0
    },
  };
  const whitePaperLink = 'https://coming-application.oss-cn-hongkong.aliyuncs.com/coming-website/ComingChat%20Whitepaper%281%29.pdf'
  const missionLink = 'https://coming-application.oss-cn-hongkong.aliyuncs.com/coming-website/Web3_Ecosystem_of_Coming.ecfe0324.pdf'
  return (
    <Wrapper>
      <div className="video-content">
        <h2>{t("Meet ComingChat")}</h2>
        <div className="wapper">
          <div className="wapper_content flex flex-col">
            <p>
              {t(
                "Integration of Social Privacy, Digital Wallets, Digital Identity,NFT Authoring Platform, and DApp Middleware for the metaverse"
              )}
            </p>
            <div>
              <YouTube videoId="kA0haHi1otI"  opts={opts} />
            </div>
            <BtnFlexWrapper>
              <a href={whitePaperLink} target={'_blank'}>
                <BtnWrapper background={'black'} border={'#000000'} color={'white'}>
                  <img src={WhitePaperIcon} alt=""/>
                  <span>{t('Light WhitePaper')}</span>
                </BtnWrapper>
              </a>
              <a href={missionLink} target='_blank'>
                <BtnWrapper background={'black'} border={'#000000'} color={'white'}>
                  <img src={MissionIcon} alt=""/>
                  <span>{t('The Mission')}</span>
                </BtnWrapper>
              </a>
            </BtnFlexWrapper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default About;