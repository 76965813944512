export default {
  'v': '5.10.0',
  'fr': 25,
  'ip': 0,
  'op': 175,
  'w': 740,
  'h': 724,
  'nm': 'omniBTC 4',
  'ddd': 0,
  'assets': [{
    'id': 'comp_0', 'nm': '▽ coin19', 'fr': 25,
    'layers': [{
      'ddd': 0, 'ind': 1, 'ty': 4, 'nm': 'GPT', 'sr': 1, 'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11}, 'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [104.338, 97.126, 0], 'ix': 2, 'l': 2}, 'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [73, 73, 100], 'ix': 6, 'l': 2}
      }, 'ao': 0,
      'shapes': [{
        'ty': 'gr', 'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[7.59, -8.46], [5.77, -5.07], [-2, -6.61], [0.03, -5.01], [-9.6, 1.85], [-5.71, 0.02], [-7.6, 8.46], [-5.74, 5.07], [2.01, 6.61], [0, 0], [-0.04, 5.04], [9.58, -1.85], [5.71, -0.03]],
              'o': [[-6.27, 1.18], [-8.84, 7.72], [-5.01, 5.54], [-0.15, 7.74], [1.25, 4.37], [8.69, 0], [6.28, -1.19], [8.86, -7.71], [0, 0], [5.01, -5.54], [0.14, -7.71], [-1.27, -4.36], [-8.69, 0]],
              'v': [[-7.913, -26.489], [-26.853, -16.659], [-38.313, 7.371], [-46.253, 23.981], [-30.393, 33.841], [-19.443, 40.681], [7.887, 26.491], [26.797, 16.651], [38.277, -7.369], [38.297, -7.399], [46.247, -24.039], [30.397, -33.869], [19.417, -40.679]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 1,
          'ty': 'sh',
          'ix': 2,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [-1.37, -2.29], [0.21, -0.08], [0, 0], [0.56, -0.96], [0, 0], [0, 0], [0, 0], [-8.23, 0.01]],
              'o': [[3.5, 0], [-0.14, 0.05], [0, 0], [-1.08, 0.47], [0, 0], [0, 0], [0, 0], [4.88, -8.42], [0, 0]],
              'v': [[16.307, -35.359], [23.817, -31.829], [23.167, -31.559], [1.937, -22.219], [-0.703, -19.909], [-13.423, 2.021], [-17.893, -1.999], [-7.383, -20.129], [16.347, -35.389]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 2',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 2,
          'ty': 'sh',
          'ix': 3,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0.08, -4.74], [2.62, -3.5], [0.09, 0.08], [0, 0], [1.08, -0.47], [0, 0], [0, 0], [0, 0], [-2.6, 0]],
              'o': [[-0.07, 3.09], [-0.06, -0.08], [0, 0], [-0.54, -0.47], [0, 0], [0, 0], [0, 0], [3.03, -1.34], [5.35, -0.01]],
              'v': [[40.209, -21.364], [36.069, -11.174], [35.769, -11.444], [25.389, -20.814], [22.779, -20.814], [-2.121, -9.844], [2.549, -17.894], [23.109, -26.954], [31.699, -29.004]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 3',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 3,
          'ty': 'sh',
          'ix': 4,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [-0.52, -0.49], [0, 0], [0, 0], [0, 0], [-8.34, 7.29], [-3.98, 1.24]],
              'o': [[-0.56, 0.96], [0, 0], [0, 0], [0, 0], [-4.67, -4.22], [3.53, -3.08], [0, 0]],
              'v': [[-23.425, -1.417], [-23.475, 0.893], [-11.305, 11.833], [-20.485, 15.883], [-30.525, 6.843], [-23.885, -13.987], [-12.255, -20.677]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 4',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 4,
          'ty': 'sh',
          'ix': 5,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0], [8.36, -7.31], [0, 0], [3.96, -1.21], [0, 0], [0.53, 0.47], [0, 0]],
              'o': [[0, 0], [4.68, 4.21], [0, 0], [-3.54, 3.09], [0, 0], [0.56, -0.96], [0, 0], [0, 0]],
              'v': [[20.443, -15.91], [30.513, -6.87], [23.863, 13.96], [23.883, 13.98], [12.263, 20.65], [23.433, 1.39], [23.483, -0.92], [11.303, -11.89]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 5',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 5,
          'ty': 'sh',
          'ix': 6,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
              'o': [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
              'v': [[5.348, -9.27], [10.468, -4.64], [5.118, 4.59], [-5.372, 9.22], [-10.492, 4.59], [-5.142, -4.64]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 6',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 6,
          'ty': 'sh',
          'ix': 7,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0], [0, 0], [8.21, 0], [0, 0], [1.34, 2.29], [-0.18, 0.08], [0, 0], [-0.53, 0.97]],
              'o': [[0, 0], [0, 0], [-4.89, 8.43], [0, 0], [-3.47, 0], [0.14, -0.05], [0, 0], [1.08, -0.47], [0, 0]],
              'v': [[13.401, -1.996], [17.871, 2.024], [7.371, 20.124], [-16.359, 35.394], [-16.339, 35.364], [-23.819, 31.834], [-23.179, 31.564], [-1.939, 22.224], [0.691, 19.904]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 7',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 7,
          'ty': 'sh',
          'ix': 8,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0], [0, 0], [-0.1, 7.27], [0, 0], [-2.62, 3.5], [-0.09, -0.09], [0, 0], [-1.08, 0.46]],
              'o': [[0, 0], [0, 0], [-9.55, 4.19], [0, 0], [0.02, -3.06], [0.06, 0.08], [0, 0], [0.54, 0.46], [0, 0]],
              'v': [[2.089, 9.85], [-2.581, 17.89], [-23.131, 26.93], [-40.241, 21.37], [-40.211, 21.37], [-36.081, 11.17], [-35.781, 11.45], [-25.391, 20.82], [-22.781, 20.82]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 8',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {'ty': 'mm', 'mm': 1, 'nm': '合并路径 1', 'mn': 'ADBE Vector Filter - Merge', 'hd': false}, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [200, 200], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }], 'nm': 'GPT', 'np': 10, 'cix': 2, 'bm': 0, 'ix': 1, 'mn': 'ADBE Vector Group', 'hd': false
      }], 'ip': 0, 'op': 236, 'st': 0, 'ct': 1, 'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 0,
      'nm': '▽ 椭圆形 5',
      'refId': 'comp_1',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [101.444, 95.267, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [85.5, 79.5, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'w': 171,
      'h': 159,
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [101.701, 95.344, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-51.61, 0], [-22.15, 52.66], [51.61, 0], [22.15, -52.65]],
              'o': [[51.6, 0], [22.14, -52.65], [-51.6, 0], [-22.14, 52.66]],
              'v': [[-40.091, 95.346], [93.439, -0.004], [40.089, -95.344], [-93.441, -0.004]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 5,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [114.413, 113.354, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-50.14, 0], [-21.51, 51.16], [50.14, 0], [21.52, -51.17]],
              'o': [[50.15, 0], [21.52, -51.17], [-50.14, 0], [-21.52, 51.16]],
              'v': [[-38.963, 92.644], [90.787, 0.004], [38.957, -92.646], [-90.793, 0.004]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_1', 'nm': '▽ 椭圆形 5', 'fr': 25, 'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 4,
      'nm': '▨ 椭圆形 2',
      'parent': 5,
      'td': 1,
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [0, 0, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-44.08, 0], [-18.92, 45.05], [44.08, 0], [18.91, -45.06]],
              'o': [[44.07, 0], [18.91, -45.06], [-44.08, 0], [-18.91, 45.05]],
              'v': [[-36.018, 79.683], [77.452, -1.777], [36.202, -79.687], [-77.488, 1.283]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'fl',
        'c': {'a': 0, 'k': [1, 0, 0, 1], 'ix': 4},
        'o': {'a': 0, 'k': 100, 'ix': 5},
        'r': 1,
        'bm': 0,
        'nm': '填充 1',
        'mn': 'ADBE Vector Graphic - Fill',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 4,
      'nm': '椭圆形',
      'tt': 1,
      'tp': 1,
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [91.615, 88.162, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-35.97, 0], [-15.44, 36.94], [35.97, 0], [15.44, -36.94]],
              'o': [[35.98, 0], [15.44, -36.94], [-35.97, 0], [-15.44, 36.94]],
              'v': [[-29.403, 65.34], [63.207, -1.46], [29.547, -65.34], [-63.243, 1.05]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 2,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': '▨ 椭圆形 2',
      'parent': 5,
      'td': 1,
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [0, 0, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-44.08, 0], [-18.92, 45.05], [44.08, 0], [18.91, -45.06]],
              'o': [[44.07, 0], [18.91, -45.06], [-44.08, 0], [-18.91, 45.05]],
              'v': [[-36.018, 79.683], [77.452, -1.777], [36.202, -79.687], [-77.488, 1.283]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }, {
        'ty': 'fl',
        'c': {'a': 0, 'k': [1, 0, 0, 1], 'ix': 4},
        'o': {'a': 0, 'k': 100, 'ix': 5},
        'r': 1,
        'bm': 0,
        'nm': '填充 1',
        'mn': 'ADBE Vector Graphic - Fill',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 4,
      'nm': '椭圆形',
      'tt': 1,
      'tp': 3,
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [91.615, 88.162, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-44.03, 0], [-18.89, 45.21], [44.03, 0], [18.89, -45.21]],
              'o': [[44.03, 0], [18.89, -45.21], [-44.02, 0], [-18.89, 45.21]],
              'v': [[-35.981, 79.972], [77.359, -1.788], [36.159, -79.968], [-77.401, 1.282]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 2,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 5,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [85.258, 79.687, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-44.08, 0], [-18.92, 45.05], [44.08, 0], [18.91, -45.06]],
              'o': [[44.07, 0], [18.91, -45.06], [-44.08, 0], [-18.91, 45.05]],
              'v': [[-36.018, 79.683], [77.452, -1.777], [36.202, -79.687], [-77.488, 1.283]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_2',
    'nm': '▽ coin18',
    'fr': 25,
    'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 0,
      'nm': '▽ 编组 34',
      'refId': 'comp_3',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [48.069, 51.969, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [40, 39.5, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'w': 80,
      'h': 79,
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 0,
      'nm': '▽ 编组 40',
      'refId': 'comp_4',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [48.069, 51.969, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [40, 39.5, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'w': 80,
      'h': 79,
      'ip': 103,
      'op': 228,
      'st': 103,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [48.069, 51.969, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [96.138, 96.062], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [48.812, 46.514, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [93.103, 93.029], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0.141176477075, 0.172549024224, 0.878431379795, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_3', 'nm': '▽ 编组 34', 'fr': 25, 'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 4,
      'nm': '形状 2',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.267, 42, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [68, 68, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, -1.32], [1.31, 0], [0, 1.31], [-1.32, 0]],
              'o': [[0, 1.31], [-1.32, 0], [0, -1.32], [1.31, 0]],
              'v': [[-2.969, 13.068], [-5.339, 15.438], [-7.719, 13.068], [-5.339, 10.688]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 1,
          'ty': 'sh',
          'ix': 2,
          'ks': {
            'a': 0,
            'k': {
              'i': [[1.31, 0], [0, -1.32], [-1.32, 0], [0, 1.31]],
              'o': [[-1.32, 0], [0, 1.31], [1.31, 0], [0, -1.32]],
              'v': [[10.099, 10.688], [7.719, 13.068], [10.099, 15.438], [12.469, 13.068]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 2',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 2,
          'ty': 'sh',
          'ix': 3,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0.22, 0.3], [0.38, 0], [0, 0], [0, 0], [1.06, 0], [0, 0], [0, -0.66], [-0.66, 0], [0, 0], [0, 0], [-1.59, -0.01], [0, 0], [-0.45, 1.49], [0, 0]],
              'o': [[-0.23, -0.3], [0, 0], [0, 0], [-0.3, -1.02], [0, 0], [-0.66, 0], [0, 0.65], [0, 0], [0, 0], [0.45, 1.52], [0, 0], [1.56, 0.01], [0, 0], [0.11, -0.36]],
              'v': [[16.991, -9.018], [16.031, -9.497], [-10.049, -9.497], [-11.249, -13.717], [-13.539, -15.438], [-16.029, -15.438], [-17.219, -14.247], [-16.029, -13.068], [-13.539, -13.068], [-8.169, 5.732], [-4.739, 8.312], [9.531, 8.312], [12.931, 5.803], [17.171, -7.967]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 3',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {'ty': 'mm', 'mm': 1, 'nm': '合并路径 1', 'mn': 'ADBE Vector Filter - Merge', 'hd': false}, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [200, 200], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '形状',
        'np': 5,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 240,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [40.026, 40, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [66.053, 66], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 2,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.763, 39.731, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [79.525, 79.462], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0.776470601559, 0.776470601559, 0.776470601559, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_4', 'nm': '▽ 编组 40', 'fr': 25, 'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 4,
      'nm': '形状',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.61, 35.459, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr', 'it': [{
          'ind': 0, 'ty': 'sh', 'ix': 1, 'ks': {
            'a': 0, 'k': {
              'i': [[-0.6, -0.31], [-0.43, -0.7], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.09, -0.41], [0.24, -0.96], [0.24, -0.49], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.31, -0.3], [0.96, -0.29], [0.28, -0.04], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.29, 0.19], [0.04, 0.67], [-0.21, 0.46], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.37, 0.19]],
              'o': [[0.37, 0.19], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.24, 0.48], [0.24, 0.96], [-0.09, 0.42], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.3, 0.45], [-0.73, 0.7], [-0.24, 0.06], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.6, -0.04], [-0.57, -0.37], [-0.01, -0.32], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.43, -0.7], [0.6, -0.31]],
              'v': [[0.862, -21.769], [1.982, -20.469], [2.212, -20.089], [7.452, -10.949], [7.712, -10.479], [7.932, -10.059], [8.132, -9.679], [8.622, -8.379], [8.622, -5.449], [8.132, -4.129], [7.932, -3.749], [7.712, -3.329], [-4.018, 17.411], [-4.598, 18.391], [-4.838, 18.801], [-5.078, 19.171], [-5.958, 20.271], [-8.528, 21.761], [-9.298, 21.911], [-9.648, 21.941], [-9.838, 21.961], [-9.838, 21.961], [-10.238, 21.981], [-10.678, 21.991], [-11.438, 22.001], [-21.488, 22.001], [-22.158, 21.991], [-22.158, 21.991], [-22.538, 21.971], [-23.828, 21.661], [-24.788, 20.011], [-24.488, 18.891], [-24.328, 18.571], [-24.138, 18.211], [-23.788, 17.581], [-2.658, -19.659], [-2.408, -20.089], [-2.188, -20.469], [-1.058, -21.769]],
              'c': true
            }, 'ix': 2
          }, 'nm': '路径 1', 'mn': 'ADBE Vector Shape - Group', 'hd': false
        }, {
          'ind': 1,
          'ty': 'sh',
          'ix': 2,
          'ks': {
            'a': 0,
            'k': {
              'i': [[-0.28, 0.14], [-0.6, -0.31], [-0.3, -0.43], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.02, -0.35], [0.57, -0.37], [0.71, -0.03], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.29, 0.18], [0.04, 0.68], [-0.27, 0.53], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
              'o': [[0.6, -0.31], [0.29, 0.15], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.29, 0.56], [-0.03, 0.67], [-0.32, 0.21], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.6, -0.03], [-0.57, -0.37], [-0.02, -0.34], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0.29, -0.41]],
              'v': [[13.708, 4.306], [15.618, 4.306], [16.458, 5.156], [16.668, 5.466], [17.018, 6.036], [17.498, 6.836], [23.568, 17.226], [23.968, 17.916], [24.188, 18.316], [24.378, 18.676], [24.788, 19.986], [23.828, 21.656], [22.338, 21.976], [21.928, 21.996], [21.458, 21.996], [7.898, 21.996], [7.218, 21.986], [7.218, 21.986], [6.828, 21.966], [5.538, 21.666], [4.578, 19.996], [4.958, 18.736], [5.128, 18.396], [5.338, 18.016], [5.878, 17.086], [12.248, 6.156], [12.478, 5.756], [12.698, 5.416], [12.898, 5.116]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 2',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {'ty': 'mm', 'mm': 1, 'nm': '合并路径 1', 'mn': 'ADBE Vector Filter - Merge', 'hd': false}, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }], 'nm': '形状', 'np': 4, 'cix': 2, 'bm': 0, 'ix': 1, 'mn': 'ADBE Vector Group', 'hd': false
      }],
      'ip': 0,
      'op': 125,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.763, 39.731, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [66.053, 66], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 2,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 125,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.763, 39.731, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [79.525, 79.462], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0.776470601559, 0.776470601559, 0.776470601559, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 125,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_5', 'nm': '▽ coin17', 'fr': 25, 'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 4,
      'nm': '形状 2',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [62.5, 64.5, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [70, 70, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, -0.83], [0.83, 0], [0, 0], [0, 0.83], [-0.83, 0], [0, 0]],
              'o': [[0, 0.83], [0, 0], [-0.83, 0], [0, -0.83], [0, 0], [0.83, 0]],
              'v': [[22.5, 16.5], [21, 18], [-21, 18], [-22.5, 16.5], [-21, 15], [21, 15]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 1,
          'ty': 'sh',
          'ix': 2,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0.18, 0.65], [-0.58, 0.35], [0, 0], [-0.49, -0.29], [0, 0], [0.18, -0.65], [0.67, 0], [0, 0], [0, 0], [0, 0], [0, -0.83], [0.83, 0], [0, 0], [0, 0.83], [-0.83, 0], [0, 0], [0, 0], [0, 0]],
              'o': [[-0.19, -0.65], [0, 0], [0.48, -0.29], [0, 0], [0.57, 0.35], [-0.19, 0.65], [0, 0], [0, 0], [0, 0], [0.83, 0], [0, 0.83], [0, 0], [-0.83, 0], [0, -0.83], [0, 0], [0, 0], [0, 0], [-0.67, 0]],
              'v': [[-20.941, -4.09], [-20.281, -5.78], [-0.781, -17.78], [0.789, -17.78], [20.289, -5.78], [20.949, -4.09], [19.499, -3], [14.999, -3], [14.999, 9], [17.999, 9], [19.499, 10.5], [17.999, 12], [-18.001, 12], [-19.501, 10.5], [-18.001, 9], [-15.001, 9], [-15.001, -3], [-19.501, -3]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 2',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 2,
          'ty': 'sh',
          'ix': 3,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [-0.83, 0], [0, 0.83], [0, 0], [0.83, 0], [0, -0.83]],
              'o': [[0, 0.83], [0.83, 0], [0, 0], [0, -0.83], [-0.83, 0], [0, 0]],
              'v': [[3, 7.5], [4.5, 9], [6, 7.5], [6, -1.5], [4.5, -3], [3, -1.5]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 3',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 3,
          'ty': 'sh',
          'ix': 4,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [-0.83, 0], [0, 0.83], [0, 0], [0.83, 0], [0, -0.83]],
              'o': [[0, 0.83], [0.83, 0], [0, 0], [0, -0.83], [-0.83, 0], [0, 0]],
              'v': [[-6, 7.5], [-4.5, 9], [-3, 7.5], [-3, -1.5], [-4.5, -3], [-6, -1.5]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 4',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {'ty': 'mm', 'mm': 1, 'nm': '合并路径 1', 'mn': 'ADBE Vector Filter - Merge', 'hd': false}, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [200, 200], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '形状',
        'np': 6,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 236,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [62.059, 67.991, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [86.069, 86], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 2,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [62.059, 67.991, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [102.67, 102.588], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0.776470601559, 0.776470601559, 0.776470601559, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 5,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [62.059, 67.991, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [124.118, 124.019], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 6,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [64.837, 60.052, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [120.199, 120.103], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0.141176477075, 0.172549024224, 0.878431379795, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 226,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_6',
    'nm': '▽ coin16',
    'fr': 25,
    'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 0,
      'nm': '▽ 编组 33',
      'refId': 'comp_7',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [47.588, 51.45, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [39.5, 39.5, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'w': 79,
      'h': 79,
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 2,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [47.588, 51.45, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [95.177, 95.101], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 228,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [48.324, 46.049, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [92.172, 92.099], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0.141176477075, 0.172549024224, 0.878431379795, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 228,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_7', 'nm': '▽ 编组 33', 'fr': 25, 'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 4,
      'nm': '形状 2',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.59, 41.97, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [80, 80, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[1.1, 0], [0, 0], [0, -2.21], [0, 0], [0.55, 0], [0, 0.55], [0, 0], [2.21, 0], [0, 0], [0, -1.1], [0, 0], [-1.1, 0], [0, 0], [0, -1.66], [-0.55, 0], [0, 0.55], [-1.66, 0], [0, 0], [0, 1.1], [0, 0]],
              'o': [[0, 0], [-2.21, 0], [0, 0], [0, 0.55], [-0.55, 0], [0, 0], [0, -2.21], [0, 0], [-1.1, 0], [0, 0], [0, 1.1], [0, 0], [1.66, 0], [0, 0.55], [0.55, 0], [0, -1.66], [0, 0], [1.1, 0], [0, 0], [0, -1.1]],
              'v': [[12, -12], [5, -12], [1, -8], [1, 3], [0, 4], [-1, 3], [-1, -8], [-5, -12], [-12, -12], [-14, -10], [-14, 6], [-12, 8], [-4, 8], [-1, 11], [0, 12], [1, 11], [4, 8], [12, 8], [14, 6], [14, -10]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 1,
          'ty': 'sh',
          'ix': 2,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0.55, 0], [0, 0], [0, 0.55], [-0.55, 0], [0, 0], [0, -0.55]],
              'o': [[0, 0], [-0.55, 0], [0, -0.55], [0, 0], [0.55, 0], [0, 0.55]],
              'v': [[10, 3], [5, 3], [4, 2], [5, 1], [10, 1], [11, 2]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 2',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 2,
          'ty': 'sh',
          'ix': 3,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0.55, 0], [0, 0], [0, 0.55], [-0.55, 0], [0, 0], [0, -0.55]],
              'o': [[0, 0], [-0.55, 0], [0, -0.55], [0, 0], [0.55, 0], [0, 0.55]],
              'v': [[10, -1], [5, -1], [4, -2], [5, -3], [10, -3], [11, -2]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 3',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 3,
          'ty': 'sh',
          'ix': 4,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0.55, 0], [0, 0], [0, 0.55], [-0.55, 0], [0, 0], [0, -0.55]],
              'o': [[0, 0], [-0.55, 0], [0, -0.55], [0, 0], [0.55, 0], [0, 0.55]],
              'v': [[10, -5], [5, -5], [4, -6], [5, -7], [10, -7], [11, -6]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 4',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {'ty': 'mm', 'mm': 1, 'nm': '合并路径 1', 'mn': 'ADBE Vector Filter - Merge', 'hd': false}, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [1, 1, 1, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [200, 200], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '形状',
        'np': 6,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 241,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 3,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.487, 39.462, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [62.975, 62.925], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 2,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }, {
      'ddd': 0,
      'ind': 4,
      'ty': 4,
      'nm': '椭圆形',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [39.365, 39.334, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [100, 100, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'd': 1,
          'ty': 'el',
          's': {'a': 0, 'k': [78.73, 78.668], 'ix': 2},
          'p': {'a': 0, 'k': [0, 0], 'ix': 3},
          'nm': '椭圆路径 1',
          'mn': 'ADBE Vector Shape - Ellipse',
          'hd': false
        }, {
          'ty': 'st',
          'c': {'a': 0, 'k': [0, 0, 0, 1], 'ix': 3},
          'o': {'a': 0, 'k': 100, 'ix': 4},
          'w': {'a': 0, 'k': 1.5, 'ix': 5},
          'lc': 1,
          'lj': 1,
          'ml': 4,
          'bm': 0,
          'nm': '描边 1',
          'mn': 'ADBE Vector Graphic - Stroke',
          'hd': false
        }, {
          'ty': 'fl',
          'c': {'a': 0, 'k': [0.776470601559, 0.776470601559, 0.776470601559, 1], 'ix': 4},
          'o': {'a': 0, 'k': 100, 'ix': 5},
          'r': 1,
          'bm': 0,
          'nm': '填充 1',
          'mn': 'ADBE Vector Graphic - Fill',
          'hd': false
        }, {
          'ty': 'tr',
          'p': {'a': 0, 'k': [0, 0], 'ix': 2},
          'a': {'a': 0, 'k': [0, 0], 'ix': 1},
          's': {'a': 0, 'k': [100, 100], 'ix': 3},
          'r': {'a': 0, 'k': 0, 'ix': 6},
          'o': {'a': 0, 'k': 100, 'ix': 7},
          'sk': {'a': 0, 'k': 0, 'ix': 4},
          'sa': {'a': 0, 'k': 0, 'ix': 5},
          'nm': '变换'
        }],
        'nm': '椭圆形',
        'np': 3,
        'cix': 2,
        'bm': 0,
        'ix': 1,
        'mn': 'ADBE Vector Group',
        'hd': false
      }],
      'ip': 0,
      'op': 225,
      'st': 0,
      'ct': 1,
      'bm': 0
    }]
  }, {
    'id': 'comp_8', 'nm': '▽ coin15', 'fr': 25, 'layers': [{
      'ddd': 0,
      'ind': 1,
      'ty': 4,
      'nm': '形状',
      'sr': 1,
      'ks': {
        'o': {'a': 0, 'k': 100, 'ix': 11},
        'r': {'a': 0, 'k': 0, 'ix': 10},
        'p': {'a': 0, 'k': [61.5, 68, 0], 'ix': 2, 'l': 2},
        'a': {'a': 0, 'k': [0, 0, 0], 'ix': 1, 'l': 2},
        's': {'a': 0, 'k': [60, 60, 100], 'ix': 6, 'l': 2}
      },
      'ao': 0,
      'shapes': [{
        'ty': 'gr',
        'it': [{
          'ind': 0,
          'ty': 'sh',
          'ix': 1,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0], [0, 0], [0, 0], [0, 0.01], [5.67, 0], [0, 0], [0.99, -5.59], [0, 0], [0, -0.01], [-2.72, -1.42], [-2.17, 2.16], [0, 0], [0, 0], [0, 0], [0, 0], [-0.02, -0.03], [-1.86, -0.01], [-1.33, 1.59], [0.36, 2.05]],
              'o': [[0, -0.01], [0, 0], [0, -0.02], [-1.02, -5.57], [0, 0], [-5.68, 0], [0, 0], [0, 0], [-0.54, 3.02], [2.73, 1.42], [0, 0], [0, 0], [0, 0], [0, 0], [0.03, 0.03], [1.32, 1.31], [2.07, -0.01], [1.34, -1.59], [0, 0]],
              'v': [[23.328, 8.932], [23.328, 8.913], [20.128, -7.527], [20.128, -7.568], [8.588, -17.188], [-8.612, -17.188], [-20.142, -7.518], [-23.322, 8.913], [-23.322, 8.932], [-19.652, 16.393], [-11.432, 15.133], [-11.362, 15.053], [-3.612, 6.253], [3.618, 6.253], [11.368, 15.053], [11.438, 15.133], [16.408, 17.193], [21.788, 14.673], [23.328, 8.932]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 1',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 1,
          'ty': 'sh',
          'ix': 2,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0.87, 0], [0, 0], [0, 0], [0.86, -0.01], [0, 0.87], [0, 0], [0, 0], [0, 0.86], [-0.86, 0], [0, 0], [0, 0], [-0.87, 0], [0, -0.86], [0, 0], [0, 0], [0, -0.87]],
              'o': [[0, 0], [0, 0], [0, 0.87], [-0.87, -0.01], [0, 0], [0, 0], [-0.86, 0], [0, -0.87], [0, 0], [0, 0], [0, -0.86], [0.86, 0], [0, 0], [0, 0], [0.87, 0], [0, 0.86]],
              'v': [[-4.691, -3.125], [-6.251, -3.125], [-6.251, -1.565], [-7.811, 0.005], [-9.381, -1.565], [-9.381, -3.125], [-10.941, -3.125], [-12.501, -4.685], [-10.941, -6.245], [-9.381, -6.245], [-9.381, -7.815], [-7.811, -9.375], [-6.251, -7.815], [-6.251, -6.245], [-4.691, -6.245], [-3.131, -4.685]],
              'c': true
            },
            'ix': 2
          },
          'nm': '路径 2',
          'mn': 'ADBE Vector Shape - Group',
          'hd': false
        }, {
          'ind': 2,
          'ty': 'sh',
          'ix': 3,
          'ks': {
            'a': 0,
            'k': {
              'i': [[0, 0.87], [-0.86, 0], [0, 0], [0, -0.86], [0.86, 0], [0, 0]],
              'o': [[0, -0.86], [0, 0], [0.86, 0], [0, 0.87], [0, 0], [-0.86, 0]],
              'v': [[3.124, -4.69], [4.684, -6.25], [9.374, -6.25], [10.934, -4.69], [9.374, -3.12], [4.684, -3.12]],
              "c": true
            },
            "ix": 2
          },
          "nm": "路径 3",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ind": 3,
          "ty": "sh",
          "ix": 4,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0.6, -0.84], [1.02, -0.18], [0.9, 0.88], [0, 0], [0, 0], [-2.14, 3.45], [0, 0]],
              "o": [[-0.59, 0.85], [-1.24, 0.21], [0, 0], [0, 0], [4.06, 0], [0, 0], [0.17, 1.01]],
              "v": [[19.603, 12.397], [17.083, 14.007], [13.673, 12.947], [7.773, 6.247], [8.593, 6.247], [18.553, 0.707], [20.263, 9.497]],
              "c": true
            },
            "ix": 2
          },
          "nm": "路径 4",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {"ty": "mm", "mm": 1, "nm": "合并路径 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [200, 200], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "形状",
        "np": 6,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 236,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "椭圆形 2",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [62.034, 68, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [86.069, 86], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 2,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 225,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "椭圆形 3",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [62.059, 67.991, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [102.67, 102.588], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0.776470601559, 0.776470601559, 0.776470601559, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 225,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [62.059, 67.991, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [124.118, 124.019], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 226,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [64.837, 60.052, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [120.199, 120.103], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0.141176477075, 0.172549024224, 0.878431379795, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 226,
      "st": 0,
      "ct": 1,
      "bm": 0
    }]
  }, {
    "id": "comp_9", "nm": "▽ tai8", "fr": 25, "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [267, 164.268, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [228.392, 116], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0.776470601559, 0.776470601559, 0.776470601559, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [267, 164.268, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [338.461, 186], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 0,
      "nm": "▽ 编组 5",
      "refId": "comp_10",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [267, 150, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [234.5, 127, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "w": 469,
      "h": 254,
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [267, 150, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [522.675, 300], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 2, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [267, 170, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [534, 306], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0.776470601559, 0.776470601559, 0.776470601559, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }]
  }, {
    "id": "comp_10", "nm": "▽ 编组 5", "fr": 25, "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "路径",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [234.436, 139.634, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[-122.06, 0], [0, -66.27], [8.02, -12.42], [90.93, 0], [38.89, 41], [0, 13.91]],
              "o": [[122.07, 0], [0, 13.91], [-38.88, 41], [-90.93, 0], [-8.01, -12.42], [0, -66.27]],
              "v": [[-0.005, -114.366], [221.025, 5.634], [208.645, 45.324], [-0.005, 114.364], [-208.655, 45.324], [-221.025, 5.634]],
              "c": true
            },
            "ix": 2
          },
          "nm": "路径 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "路径",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [234.436, 127, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [468.872, 254], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 1.5, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0.776470601559, 0.776470601559, 0.776470601559, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }]
  }, {
    "id": "comp_11",
    "nm": "▽ tai7",
    "fr": 25,
    "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "椭圆形",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [234.377, 129.039, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "d": 1,
          "ty": "el",
          "s": {"a": 0, "k": [424.808, 220.442], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "nm": "椭圆路径 1",
          "mn": "ADBE Vector Shape - Ellipse",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "椭圆形",
        "np": 2,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "路径",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [234.377, 129.039, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[0, 0], [0, 0], [0, -70.55], [129.44, 0], [0, 71.27], [-127.65, 1.14]],
              "o": [[0, 0], [127.66, 1.14], [0, 71.27], [-129.45, 0], [0, -70.55], [0, 0]],
              "v": [[0.003, -129.039], [3.873, -129.019], [234.373, 0.001], [0.003, 129.041], [-234.377, 0.001], [-3.877, -129.019]],
              "c": true
            },
            "ix": 2
          },
          "nm": "路径 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 2, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "路径",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "路径",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [234.335, 147.409, 0], "ix": 2, "l": 2},
        "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ind": 0,
          "ty": "sh",
          "ix": 1,
          "ks": {
            "a": 0,
            "k": {
              "i": [[-121.8, 0], [-11.03, -65.38], [0, 0], [0, 0], [0, -1.11], [129.17, 0], [2.06, 70.04], [0, 0], [0, 0], [0, 0], [0, 0]],
              "o": [[121.79, 0], [0, 0], [0, 0], [0.05, 1.1], [0, 71.02], [-127.88, 0], [0, 0], [0, 0], [0, 0], [0, 0], [11.03, -65.38]],
              "v": [[0.045, -128.591], [232.915, -12.021], [233.845, -12.021], [233.845, -3.311], [233.925, -0.001], [0.045, 128.589], [-233.805, 2.129], [-233.845, 0.069], [-233.925, 0.079], [-233.925, -12.021], [-232.835, -12.021]],
              "c": true
            },
            "ix": 2
          },
          "nm": "路径 1",
          "mn": "ADBE Vector Shape - Group",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 2, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "bm": 0,
          "nm": "描边 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [0.776470601559, 0.776470601559, 0.776470601559, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "填充 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [0, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "变换"
        }],
        "nm": "路径",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 125,
      "st": 0,
      "ct": 1,
      "bm": 0
    }]
  }], "layers": [{
    "ddd": 0, "ind": 1, "ty": 0, "nm": "▽ coin19", "refId": "comp_0", "sr": 1, "ks": {
      "o": {
        "a": 1,
        "k": [{"i": {"x": [0], "y": [1]}, "o": {"x": [0.333], "y": [0]}, "t": 0, "s": [0]}, {
          "i": {
            "x": [0.833],
            "y": [1]
          }, "o": {"x": [0.333], "y": [0]}, "t": 10, "s": [100]
        }, {"i": {"x": [0.833], "y": [1]}, "o": {"x": [1], "y": [0]}, "t": 150, "s": [100]}, {"t": 160, "s": [0]}],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 0,
          "s": [375.245, 89.053, 0],
          "to": [0, 33.333, 0],
          "ti": [0, -33.333, 0]
        }, {
          "i": {"x": 0, "y": 0},
          "o": {"x": 0.333, "y": 0.333},
          "t": 10,
          "s": [375.245, 289.053, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.938, "y": 1},
          "o": {"x": 0.088, "y": 0},
          "t": 55,
          "s": [375.245, 289.053, 0],
          "to": [0, 8.667, 0],
          "ti": [0, 8, 0]
        }, {
          "i": {"x": 0.901, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 75,
          "s": [375.245, 341.053, 0],
          "to": [0, -8, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.938, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 112,
          "s": [375.245, 241.053, 0],
          "to": [0, 0, 0],
          "ti": [0, 25.333, 0]
        }, {
          "i": {"x": 0.807, "y": 1},
          "o": {"x": 1, "y": 0},
          "t": 150,
          "s": [375.245, 341.053, 0],
          "to": [0, -25.333, 0],
          "ti": [0, 42, 0]
        }, {"t": 160, "s": [375.245, 89.053, 0]}],
        "ix": 2,
        "l": 2
      },
      "a": {"a": 0, "k": [106.5, 103, 0], "ix": 1, "l": 2},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
    }, "ao": 0, "w": 213, "h": 206, "ip": 0, "op": 225, "st": 0, "ct": 1, "bm": 0
  }, {
    "ddd": 0,
    "ind": 2,
    "ty": 4,
    "nm": "cicle",
    "sr": 1,
    "ks": {
      "o": {"a": 0, "k": 100, "ix": 11},
      "r": {
        "a": 1,
        "k": [{"i": {"x": [0.658], "y": [1]}, "o": {"x": [0.97], "y": [0]}, "t": 10, "s": [0]}, {"t": 35, "s": [1440]}],
        "ix": 10
      },
      "p": {"a": 0, "k": [368.281, 289.053, 0], "ix": 2, "l": 2},
      "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "ind": 0,
        "ty": "sh",
        "ix": 1,
        "ks": {
          "a": 0,
          "k": {
            "i": [[-5, -3.37], [4.06, -6], [0.32, -0.38], [0, 0], [9.94, -9.94], [80.51, 80.51], [7.17, 10.53], [0, 0], [-5.16, 5.15], [-5.15, -5.16], [-0.64, -1.68], [-8.11, -8.11], [-70.4, 70.39], [-6.31, 9.32], [-0.68, 1.02], [-0.77, 0.69], [0, 0], [0, 0]],
            "o": [[6.01, 4.06], [-0.28, 0.42], [0, 0], [-7.48, 11.27], [-80.45, 80.45], [-9.35, -9.34], [0, 0], [-5.16, -5.15], [5.15, -5.16], [1.35, 1.35], [6.25, 9.14], [70.45, 70.45], [8.25, -8.26], [0.37, -1.11], [0.61, -0.89], [0, 0], [0, 0], [4.37, -3.74]],
            "v": [[168.966, 94.372], [172.506, 112.582], [171.606, 113.782], [171.676, 113.862], [145.546, 145.782], [-145.894, 145.662], [-170.674, 115.772], [-170.884, 115.562], [-170.884, 96.902], [-152.224, 96.902], [-149.234, 101.502], [-127.694, 127.452], [127.326, 127.562], [149.176, 101.132], [150.756, 97.912], [152.826, 95.532], [153.026, 95.212], [153.106, 95.282]],
            "c": true
          },
          "ix": 2
        },
        "nm": "路径 1",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {
        "ind": 1,
        "ty": "sh",
        "ix": 2,
        "ks": {
          "a": 0,
          "k": {
            "i": [[-79.71, -79.71], [0, 0], [4.96, -4.96], [5.03, 5.03], [0.14, 0.16], [0, 0], [70.48, -69.22], [0, 0], [0.39, -0.39], [5.04, 5.04], [-5.03, 5.03], [-0.43, 0.33], [0, 0], [0, 0]],
            "o": [[0, 0], [4.82, 5.05], [-5.03, 5.03], [-0.15, -0.15], [0, 0], [-70.06, -70.06], [0, 0], [-0.33, 0.43], [-5.04, 5.04], [-5.03, -5.03], [0.4, -0.39], [0, 0], [0, 0], [80.63, -78.05]],
            "v": [[145.431, -145.662], [145.641, -145.442], [145.431, -127.442], [127.211, -127.442], [126.771, -127.902], [127.221, -127.452], [-126.619, -128.722], [-126.699, -128.802], [-127.789, -127.562], [-146.019, -127.562], [-146.019, -145.782], [-144.769, -146.872], [-144.839, -146.942], [-143.589, -148.172]],
            "c": true
          },
          "ix": 2
        },
        "nm": "路径 2",
        "mn": "ADBE Vector Shape - Group",
        "hd": false
      }, {"ty": "mm", "mm": 1, "nm": "合并路径 1", "mn": "ADBE Vector Filter - Merge", "hd": false}, {
        "ty": "st",
        "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
        "o": {"a": 0, "k": 100, "ix": 4},
        "w": {"a": 0, "k": 1.5, "ix": 5},
        "lc": 1,
        "lj": 1,
        "ml": 4,
        "bm": 0,
        "nm": "描边 1",
        "mn": "ADBE Vector Graphic - Stroke",
        "hd": false
      }, {
        "ty": "fl",
        "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
        "o": {"a": 0, "k": 100, "ix": 5},
        "r": 1,
        "bm": 0,
        "nm": "填充 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": {"a": 0, "k": [0, 0], "ix": 2},
        "a": {"a": 0, "k": [0, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100], "ix": 3},
        "r": {"a": 0, "k": 0, "ix": 6},
        "o": {"a": 0, "k": 100, "ix": 7},
        "sk": {"a": 0, "k": 0, "ix": 4},
        "sa": {"a": 0, "k": 0, "ix": 5},
        "nm": "变换"
      }],
      "nm": "cicle",
      "np": 5,
      "cix": 2,
      "bm": 0,
      "ix": 1,
      "mn": "ADBE Vector Group",
      "hd": false
    }],
    "ip": 0,
    "op": 175,
    "st": 0,
    "ct": 1,
    "bm": 0
  }, {
    "ddd": 0, "ind": 3, "ty": 0, "nm": "▽ coin18", "refId": "comp_2", "sr": 1, "ks": {
      "o": {
        "a": 1,
        "k": [{"i": {"x": [0.802], "y": [1]}, "o": {"x": [1], "y": [0]}, "t": 150, "s": [100]}, {"t": 160, "s": [0]}],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 40,
          "s": [370.554, 272.858, 0],
          "to": [-44.167, 14.333, 0],
          "ti": [44.167, -14.333, 0]
        }, {
          "i": {"x": 0, "y": 0},
          "o": {"x": 0.167, "y": 0.167},
          "t": 50,
          "s": [105.554, 358.858, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.84, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 55,
          "s": [105.554, 358.858, 0],
          "to": [0, 9.167, 0],
          "ti": [0, 4.5, 0]
        }, {
          "i": {"x": 0.864, "y": 1},
          "o": {"x": 0.094, "y": 0},
          "t": 75,
          "s": [105.554, 413.858, 0],
          "to": [0, -4.5, 0],
          "ti": [0, -1, 0]
        }, {
          "i": {"x": 0.826, "y": 1},
          "o": {"x": 0.094, "y": 0},
          "t": 112,
          "s": [105.554, 331.858, 0],
          "to": [0, 1, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.789, "y": 1},
          "o": {"x": 1, "y": 0},
          "t": 150,
          "s": [105.554, 419.858, 0],
          "to": [0, 0, 0],
          "ti": [0, 14.667, 0]
        }, {"t": 160, "s": [105.554, 331.858, 0]}],
        "ix": 2,
        "l": 2
      },
      "a": {"a": 0, "k": [48, 50, 0], "ix": 1, "l": 2},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0, 0, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
          "t": 40,
          "s": [0, 0, 100]
        }, {"t": 50, "s": [100, 100, 100]}],
        "ix": 6,
        "l": 2
      }
    }, "ao": 0, "w": 96, "h": 100, "ip": 0, "op": 225, "st": 0, "ct": 1, "bm": 0
  }, {
    "ddd": 0, "ind": 4, "ty": 0, "nm": "▽ coin17", "refId": "comp_5", "sr": 1, "ks": {
      "o": {
        "a": 1,
        "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [1], "y": [0]}, "t": 160, "s": [100]}, {"t": 170, "s": [0]}],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 35,
          "s": [373.491, 283.355, 0],
          "to": [-36.667, -10.333, 0],
          "ti": [36.667, 10.333, 0]
        }, {
          "i": {"x": 0, "y": 0},
          "o": {"x": 0.167, "y": 0.167},
          "t": 45,
          "s": [153.491, 221.355, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.847, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 65,
          "s": [153.491, 221.355, 0],
          "to": [0, 10.333, 0],
          "ti": [0, 5, 0]
        }, {
          "i": {"x": 0.877, "y": 1},
          "o": {"x": 0.098, "y": 0},
          "t": 85,
          "s": [153.491, 283.355, 0],
          "to": [0, -5, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.863, "y": 1},
          "o": {"x": 0.089, "y": 0},
          "t": 122,
          "s": [153.491, 191.355, 0],
          "to": [0, 0, 0],
          "ti": [0, 6.167, 0]
        }, {
          "i": {"x": 0.898, "y": 1},
          "o": {"x": 1, "y": 0},
          "t": 160,
          "s": [153.491, 283.355, 0],
          "to": [0, -6.167, 0],
          "ti": [0, 21.5, 0]
        }, {"t": 170, "s": [153.491, 154.355, 0]}],
        "ix": 2,
        "l": 2
      },
      "a": {"a": 0, "k": [62.5, 65, 0], "ix": 1, "l": 2},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0, 0, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
          "t": 35,
          "s": [0, 0, 100]
        }, {"t": 45, "s": [100, 100, 100]}],
        "ix": 6,
        "l": 2
      }
    }, "ao": 0, "w": 125, "h": 130, "ip": 0, "op": 225, "st": 0, "ct": 1, "bm": 0
  }, {
    "ddd": 0, "ind": 5, "ty": 0, "nm": "▽ coin16", "refId": "comp_6", "sr": 1, "ks": {
      "o": {
        "a": 1,
        "k": [{"i": {"x": [0.948], "y": [1]}, "o": {"x": [1], "y": [0]}, "t": 150, "s": [100]}, {"t": 160, "s": [0]}],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 45,
          "s": [374.927, 278.578, 0],
          "to": [43.333, 14.333, 0],
          "ti": [-43.333, -14.333, 0]
        }, {
          "i": {"x": 0, "y": 0},
          "o": {"x": 0.167, "y": 0.167},
          "t": 55,
          "s": [634.927, 364.578, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.677, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 57,
          "s": [634.927, 364.578, 0],
          "to": [0, 10.167, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.575, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 75,
          "s": [634.927, 425.578, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.789, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 112,
          "s": [634.927, 364.578, 0],
          "to": [0, 0, 0],
          "ti": [0, 8, 0]
        }, {
          "i": {"x": 0.885, "y": 1},
          "o": {"x": 1, "y": 0},
          "t": 150,
          "s": [634.927, 425.578, 0],
          "to": [0, -8, 0],
          "ti": [0, 18.167, 0]
        }, {"t": 160, "s": [634.927, 316.578, 0]}],
        "ix": 2,
        "l": 2
      },
      "a": {"a": 0, "k": [47.5, 49.5, 0], "ix": 1, "l": 2},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0, 0, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
          "t": 45,
          "s": [0, 0, 100]
        }, {"t": 55, "s": [100, 100, 100]}],
        "ix": 6,
        "l": 2
      }
    }, "ao": 0, "w": 95, "h": 99, "ip": 0, "op": 225, "st": 0, "ct": 1, "bm": 0
  }, {
    "ddd": 0, "ind": 6, "ty": 0, "nm": "▽ coin15", "refId": "comp_8", "sr": 1, "ks": {
      "o": {
        "a": 1,
        "k": [{"i": {"x": [1], "y": [1]}, "o": {"x": [1], "y": [0]}, "t": 160, "s": [100]}, {"t": 170, "s": [0]}],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 40,
          "s": [380.363, 286.663, 0],
          "to": [33.667, -8.833, 0],
          "ti": [-33.667, 8.833, 0]
        }, {
          "i": {"x": 0, "y": 0},
          "o": {"x": 0.167, "y": 0.167},
          "t": 50,
          "s": [582.363, 233.663, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.806, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 65,
          "s": [582.363, 233.663, 0],
          "to": [0, 12.167, 0],
          "ti": [0, 2.667, 0]
        }, {
          "i": {"x": 0.673, "y": 1},
          "o": {"x": 0.167, "y": 0},
          "t": 85,
          "s": [582.363, 306.663, 0],
          "to": [0, -2.667, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.683, "y": 1},
          "o": {"x": 0.374, "y": 0},
          "t": 122,
          "s": [582.363, 217.663, 0],
          "to": [0, 0, 0],
          "ti": [0, 13.333, 0]
        }, {
          "i": {"x": 0.94, "y": 1},
          "o": {"x": 1, "y": 0},
          "t": 160,
          "s": [582.363, 306.663, 0],
          "to": [0, -13.333, 0],
          "ti": [0, 28.167, 0]
        }, {"t": 170, "s": [582.363, 137.663, 0]}],
        "ix": 2,
        "l": 2
      },
      "a": {"a": 0, "k": [62.5, 65, 0], "ix": 1, "l": 2},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0, 0, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
          "t": 40,
          "s": [0, 0, 100]
        }, {"t": 50, "s": [100, 100, 100]}],
        "ix": 6,
        "l": 2
      }
    }, "ao": 0, "w": 125, "h": 130, "ip": 0, "op": 225, "st": 0, "ct": 1, "bm": 0
  }, {
    "ddd": 0,
    "ind": 7,
    "ty": 4,
    "nm": "shadow",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [{"i": {"x": [0.667], "y": [1]}, "o": {"x": [1], "y": [-0.24]}, "t": 0, "s": [0]}, {
          "i": {
            "x": [0.777],
            "y": [1]
          }, "o": {"x": [0.318], "y": [0]}, "t": 10, "s": [24]
        }, {"i": {"x": [0.724], "y": [1]}, "o": {"x": [0.387], "y": [0]}, "t": 150, "s": [24]}, {"t": 160, "s": [0]}],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 55,
          "s": [367.927, 388.882, 0],
          "to": [0, 6.667, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 75,
          "s": [367.927, 428.882, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 112,
          "s": [367.927, 388.882, 0],
          "to": [0, 0, 0],
          "ti": [0, 0, 0]
        }, {
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 150,
          "s": [367.927, 428.882, 0],
          "to": [0, 0, 0],
          "ti": [0, 6.667, 0]
        }, {"t": 160, "s": [367.927, 388.882, 0]}],
        "ix": 2,
        "l": 2
      },
      "a": {"a": 0, "k": [0, 0, 0], "ix": 1, "l": 2},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
          "o": {"x": [1, 1, 0.333], "y": [-0.082, -0.082, 0]},
          "t": 0,
          "s": [0, 0, 100]
        }, {
          "i": {"x": [0.648, 0.648, 0.648], "y": [1, 1, 1]},
          "o": {"x": [0.303, 0.303, 0.303], "y": [0, 0, 0]},
          "t": 10,
          "s": [70, 70, 100]
        }, {
          "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.332, 0.332, 0.332], "y": [0, 0, 0]},
          "t": 55,
          "s": [70, 70, 100]
        }, {
          "i": {"x": [0.648, 0.648, 0.648], "y": [1, 1, 1]},
          "o": {"x": [0.389, 0.389, 0.389], "y": [0, 0, 0]},
          "t": 75,
          "s": [80, 80, 100]
        }, {
          "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.332, 0.332, 0.332], "y": [0, 0, 0]},
          "t": 112,
          "s": [70, 70, 100]
        }, {
          "i": {"x": [0.724, 0.724, 0.724], "y": [1, 1, 1]},
          "o": {"x": [0.389, 0.389, 0.389], "y": [0, 0, 0]},
          "t": 150,
          "s": [80, 80, 100]
        }, {"t": 160, "s": [0, 0, 100]}],
        "ix": 6,
        "l": 2
      }
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "d": 1,
        "ty": "el",
        "s": {"a": 0, "k": [318.532, 124], "ix": 2},
        "p": {"a": 0, "k": [0, 0], "ix": 3},
        "nm": "椭圆路径 1",
        "mn": "ADBE Vector Shape - Ellipse",
        "hd": false
      }, {
        "ty": "fl",
        "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
        "o": {"a": 0, "k": 100, "ix": 5},
        "r": 1,
        "bm": 0,
        "nm": "填充 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": {"a": 0, "k": [0, 0], "ix": 2},
        "a": {"a": 0, "k": [0, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100], "ix": 3},
        "r": {"a": 0, "k": 0, "ix": 6},
        "o": {"a": 0, "k": 100, "ix": 7},
        "sk": {"a": 0, "k": 0, "ix": 4},
        "sa": {"a": 0, "k": 0, "ix": 5},
        "nm": "变换"
      }],
      "nm": "shadow",
      "np": 2,
      "cix": 2,
      "bm": 0,
      "ix": 1,
      "mn": "ADBE Vector Group",
      "hd": false
    }],
    "ip": 0,
    "op": 175,
    "st": 0,
    "ct": 1,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 8,
    "ty": 0,
    "nm": "▽ tai8",
    "refId": "comp_9",
    "sr": 1,
    "ks": {
      "o": {"a": 0, "k": 100, "ix": 11},
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [367.927, 450.673, 0], "ix": 2, "l": 2},
      "a": {"a": 0, "k": [267, 161.5, 0], "ix": 1, "l": 2},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
    },
    "ao": 0,
    "w": 534,
    "h": 323,
    "ip": 100,
    "op": 225,
    "st": 100,
    "ct": 1,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 9,
    "ty": 0,
    "nm": "▽ tai7",
    "refId": "comp_11",
    "sr": 1,
    "ks": {
      "o": {"a": 0, "k": 100, "ix": 11},
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [367.927, 553.173, 0], "ix": 2, "l": 2},
      "a": {"a": 0, "k": [234.5, 138, 0], "ix": 1, "l": 2},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
    },
    "ao": 0,
    "w": 469,
    "h": 276,
    "ip": 100,
    "op": 225,
    "st": 100,
    "ct": 1,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 10,
    "ty": 0,
    "nm": "▽ tai8",
    "refId": "comp_9",
    "sr": 1,
    "ks": {
      "o": {"a": 0, "k": 100, "ix": 11},
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [367.927, 450.673, 0], "ix": 2, "l": 2},
      "a": {"a": 0, "k": [267, 161.5, 0], "ix": 1, "l": 2},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
    },
    "ao": 0,
    "w": 534,
    "h": 323,
    "ip": 0,
    "op": 125,
    "st": 0,
    "ct": 1,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 11,
    "ty": 0,
    "nm": "▽ tai7",
    "refId": "comp_11",
    "sr": 1,
    "ks": {
      "o": {"a": 0, "k": 100, "ix": 11},
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [367.927, 553.173, 0], "ix": 2, "l": 2},
      "a": {"a": 0, "k": [234.5, 138, 0], "ix": 1, "l": 2},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6, "l": 2}
    },
    "ao": 0,
    "w": 469,
    "h": 276,
    "ip": 0,
    "op": 125,
    "st": 0,
    "ct": 1,
    "bm": 0
  }], "markers": []
}