import React from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { defaultOptions } from '../../utils/animateoption';
import animationData from '../home/assetsAnimationHome/dmens.js';
import { ChatItem } from '../../components/ChatItem';
import icon_atom from './assetsDmens/Atom.svg';
import icon_confetti from './assetsDmens/Confetti.svg';
import icon_user from './assetsDmens/UserFocus.svg';
import icon_board from './assetsDmens/Broadcast.svg';
import icon_circuitry from './assetsDmens/Circuitry.svg'
import icon_medal from './assetsDmens/Medal.svg'
import { SwiperBtn } from '../../components/Header/MenuContent';

const ChatGPTList = [
  {
    key:0,
    icon:icon_atom,
    title:'Real-time Information Sharing',
    content:'Real-time Information Sharing',
  },{
    key:1,
    icon:icon_confetti,
    title:'Content NFTization and Trading',
    content:'Content NFTization and Trading',
  },{
    key:2,
    icon:icon_user,
    title:'Identity Authentication Based on CID',
    content:'Identity Authentication Based on CID',
  },{
    key:3,
    icon:icon_board,
    title:'Decentralized Advertising System',
    content:'Decentralized Advertising System',
  },{
    key:4,
    icon:icon_circuitry,
    title:'Performance Revolution',
    content:'New Technology Breaks Performance Bottlenecks',
  },{
    key:5,
    icon:icon_medal,
    title:'Social Mining and Incentive Mechanisms',
    content:'Social Mining and Incentive Mechanisms',
  }
]

const Dmens: React.FC = () => {
  const {t} = useTranslation();
  const startIcon = <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="4.0" stroke="currentColor" stroke-width="1" fill="currentColor" fill-rule="evenodd">
      <g id="首页" transform="translate(-189.000000, -3613.000000)" fill="currentColor">
        <g id="编组-14" transform="translate(157.000000, 3597.000000)">
          <g id="icon_get-cid" transform="translate(32.000000, 16.000000)">
            <polygon id="路径" points="0 18.1958587 19 18.1958587 19 20 0 20"></polygon>
            <polygon id="路径" transform="translate(9.500000, 16.827228) rotate(-90.000000) translate(-9.500000, -16.827228) " points="7.10153159 15.3072281 11.8984684 15.3072281 11.8984684 18.3472281 7.10153159 18.3472281"></polygon>
            <path d="M10.1739556,0.431576506 L11.7865202,3.70659072 L11.7865202,3.70659067 C11.8986484,3.93374511 12.1115494,4.09174525 12.3581521,4.13081572 L15.8867249,4.64488729 L15.886725,4.64488731 C16.3201989,4.72430752 16.6084039,5.1467028 16.5304521,5.58833487 C16.5008104,5.75626551 16.4200289,5.91042584 16.2995628,6.02895642 L13.7872033,8.48074441 L13.7872033,8.48074437 C13.5995944,8.66765779 13.5127532,8.93574726 13.5543091,9.19971323 L14.1541724,12.7048289 L14.1541724,12.704829 C14.2413159,13.1264255 13.9765013,13.5401718 13.5626917,13.6289547 C13.3939756,13.665153 13.2182054,13.6422782 13.0638398,13.5640334 L9.8528361,11.863614 L9.8528361,11.863614 C9.63270289,11.7421634 9.36726249,11.7421634 9.14712927,11.863614 L5.90789417,13.571229 L5.90789418,13.571229 C5.52927747,13.7631394 5.06964853,13.606008 4.8812838,13.2202652 C4.80448446,13.0629925 4.78203213,12.8839148 4.81756197,12.7120248 L5.41742532,9.20692877 L5.41742535,9.20692857 C5.45899434,8.94294885 5.37215194,8.67484247 5.1845311,8.4879204 L2.68633546,6.02895648 L2.6863355,6.02895653 C2.36952868,5.71724214 2.36073509,5.20288943 2.66669126,4.88012215 C2.78303148,4.75738923 2.93434501,4.67508725 3.09917332,4.64488742 L6.6277461,4.13081584 L6.62774616,4.13081583 C6.87434893,4.09174576 7.08724967,3.93374523 7.19937815,3.70659078 L8.82604872,0.431576575 L8.82604868,0.431576644 C9.00533751,0.0523574364 9.45241826,-0.10698352 9.82463418,0.0756801805 C9.97727322,0.15058722 10.1004332,0.27606547 10.1739563,0.431576644 L10.1739556,0.431576506 Z" id="路径" fill-rule="nonzero"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>

  return (
    <Wrapper id='dmens'>
      <MainWrap>
        <Lottie
          options={defaultOptions(animationData)}
          height={'auto'}
          isStopped={false}
          isPaused={false}
        />
      </MainWrap>
      <RightWrap>
        <h1>
          {t('Dmens')}
        </h1>
        <div className="content">{t('Decentralized Content Distribution Platform, Only for Valuable Information.')}</div>
        <div className="item-wrapper">
          {ChatGPTList?.map(item=> <ChatItem
            key={item.key}
            iconName={item.icon}
            title={item.title}
            description={item.content}
          />)}
        </div>
        <div style={{marginTop: '3.05vw', width:'fit-content'}}>
          <SwiperBtn  padding={'1vw 2.22vw'}>
            <div className={'flex flex-row cid-wapper'}>
              <section className="color-1">
                <p>
                  <button className={`btn btn-1 btn-1c black-hover-text`} onClick={() => (window.open('https://dmens-app.coming.chat/explore'))}>
                    {startIcon}
                    <span>{t('Learn more')}</span>
                  </button>
                </p>
              </section>
            </div>
          </SwiperBtn>
        </div>
      </RightWrap>
    </Wrapper>
  );
};

export default Dmens;

const Wrapper = styled.div`
  background: #FFFFFF;
  position: relative;
  width: 100vw;
  //height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainWrap = styled.div`
  position: relative;
  width: 31.615vw;
  right: 0.7vw;
  //bottom: 6vw;
  z-index: 1;
  margin-right: 6.74vw;
`;

const RightWrap = styled.div`
  position: relative;
  width: 42.01vw;
  height: 40.55vw;
  margin-top: 10vw;
  margin-bottom: 8.75vw;
  h1 {
    font-size: 4.17vw;
    font-weight: 500;
    color: black;
    line-height: 5vw;
    margin: 2.5vw 0;
  }

  .content {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    max-width: 35vw;
    font-size: 1.25vw;
    font-weight: 400;
    color: black;
    line-height: 1.875vw;
  }
  .item-wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.75vw;
    margin-top: 3.06vw;
  }
`;
