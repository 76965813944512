import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FeatureItem } from '../../components/ChatItem/featureItem';
import icon_EC from '../../assets/feature/encrypted.png';
import icon_OB from '../../assets/feature/omnibtc.svg';
import icon_RB from '../../assets/feature/redBag.png';
import icon_borrow from '../../assets/feature/brower.svg';
import icon_arrow from '../../assets/arrow.svg';
import { FeatureBtn } from '../../components/ChatItem/featureBtn';


const Wrapper = styled.div`
  background: white;
  width: 100vw;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 14.37vw;
`;

const MainWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 4.17vw;
    font-weight: 500;
    color: black;
    line-height: 5vw;
    flex-wrap: wrap;
    margin: 7.152vw;
  }

  .info {
    display: grid;
    width: 84.42vw;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5vw;
    align-items: center;
    justify-content: center;
  }
`;

const MoreFeature: React.FC = () => {
  const {t} = useTranslation();

  const GameItem = [{
    key:0,
    title: 'Encrypted Communication',
    className:'icon_ec',
    content:'',
    icon: icon_EC,
  }, {
    key:1,
    title: 'OmniBTC',
    className:'icon_ob',
    icon: icon_OB,
    content:<FeatureBtn className="btn" content={'MORE'} icon={icon_arrow} link={'https://omnibtc.finance/'} />,
    link: 'https://dmens-app.coming.chat/explore'
  }, {
    key:2,
    title: 'Red Packet',
    className:'',
    content:'',
    icon: icon_RB,
  },{
    key:3,
    title: 'Web3 Browser',
    className:'icon_wb',
    content:'',
    icon: icon_borrow,
  }];
  return (
    <Wrapper id="moreFeatures">
      <MainWrap>
        <h1>{t('More Features')}</h1>
        <div className="info">
          {GameItem?.map(item =>
            <FeatureItem iconClassName={item?.className} key={item.key} title={item?.title} iconName={item?.icon} description={item?.content}/>)}
        </div>
      </MainWrap>
    </Wrapper>
  );
};

export default MoreFeature;
