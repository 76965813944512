import React, { useEffect, useRef, useState } from 'react';

interface AnimationAmountProps{
  amount: string
}

const AnimationAmount = ({amount}: AnimationAmountProps) => {
  const [count, setCount] = useState(Number(amount));
  const countRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const initialCount = parseInt(amount, 10);
    const step = Math.ceil(initialCount / 60);

    let counter = 0;
    const timer = setInterval(() => {
      counter = Math.min(counter + step, initialCount);
      setCount(counter);
      if (counter >= initialCount) {
        clearInterval(timer);
      }
    }, 10);

    return () => clearInterval(timer);
  }, [amount]);


  return <span className="amount" ref={countRef}>{count.toLocaleString()}</span>;
};

export default AnimationAmount;
