import React, { useCallback, useState } from "react"
import { IconInfo } from "../pages/group/utils/data"
import { CopyToClipboard } from "react-copy-to-clipboard"
import styled from "styled-components"

interface Props extends IconInfo {
  imgClassName?: string;
  linkClassName?: string;
}

const LinkWrapper = styled.div`
  img{
    cursor: pointer;
    height: 2.5vw;
    margin-bottom: 3.6vh;
  }
`

const ToolTipWrapper = styled.div`
  position: relative;
  .copyemails {
    width: 152px;
    height: 44px;
    line-height: 44px;
    color: #ffffff;
    font-size: 16px;
    font-family: NotoSansSC-Regular, NotoSansSC;
    background: #007aff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 5px;
    position: absolute;
    bottom: 8vh;
    //left: -0.5vw;
    left: -10px;
    .arrow {
      position: relative;
      &:after {
        content: "";
        border: 10px solid transparent;
        border-top-color: #007aff;
        position: absolute;
        bottom: -30px;
        left: 0px;
      }
    }
  }
`

const LinkIcon = ({ linkClassName, imgClassName, path, text, imgSrc, type }: Props): React.ReactElement<Props> => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const handleCopy = useCallback(() => {
    if(isCopied) return ;
    setIsCopied(true)
    const timeId = setTimeout(() => {
      setIsCopied(false)
    }, 3000)
    return () => clearTimeout(timeId)
  }, [isCopied])

  return (
    <LinkWrapper>
      {type === "link" ?
        <a className={linkClassName} href={path} target="_blank">
          <img className={imgClassName} src={imgSrc} alt={text} />
        </a> :
        <CopyToClipboard
          text={path}
          onCopy={handleCopy}
        >
          <img className={imgClassName} src={imgSrc} alt={text} />
        </CopyToClipboard>
      }
      {isCopied? <ToolTipWrapper className="toolTip">
        <span className="copyemails">
          <span className="triangle" />
          <span className="arrow">Copied Success</span>
        </span>
      </ToolTipWrapper>: null}
    </LinkWrapper>
  )
}

export default LinkIcon
