import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Info1 from '../../assets/home/frames.svg';
const Wrapper = styled.div`
  background: #F4F5F7;
  position: relative;
  padding: 6.5vw 0 11.6vw 0vw;
  h1 {
    color: #000000;
  }
  p {
    color: #333333;
  }
  .private {
    margin: 16.8vw auto 17.8vw;
  }
`;

const PrivateMbolPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className='infoImg'>
        <img src={Info1} alt=""/>
      </div>
    </Wrapper>
  );
};

export default PrivateMbolPage;
