import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

interface Props {
  content:string;
  icon: string;
  link: string;
  className: string;
}

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 2vw;
  width: 100%;
  .btn-wrapper{
    background: #000000;
    border-radius: ${isMobile?'1vw':'0.5vw'};
    border: 1px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    padding: ${isMobile?'2.4vw 4.9vw':'1.125vw 2.93vw'};
    align-items: center;
    .icon{

    }
    .name{
      font-size: ${isMobile? '1.37vw':'0.82vw'};
      font-weight: bold;
      color: #FFFFFF;
      line-height: ${isMobile? '2.13vw':'1vw'};
      margin-right: ${isMobile? '1.06vw':'0.5vw'};
    }
  }
`;

export const FeatureBtn = ({className, content, icon, link}: Props) => {
  return (
    <Wrapper className={className}>
      <div className="btn-wrapper" onClick={()=> window.open(link)}>
        <span className="name">{content}</span>
        <img src={icon} alt=""/>
      </div>
    </Wrapper>
  );
};