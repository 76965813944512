import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DownloadImg from "./DownloadImg";
import { Wrapper } from "./styles";
import Phone from "../../assets/phone.png";
import Desktop from "../../assets/desktop.png";

import { ListItem } from "./utils/data";
import changeMobileData from "./utils/changeMobileData";
import { desktopImgList, mobileImgList } from "./utils/data";

const PCComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const [targetList, setTargetList] = useState<ListItem[]>([]);

  useEffect(() => {
    changeMobileData(mobileImgList).then((res) => {
      setTargetList(res);
    });
  }, []);

  return (
    <div className="pc">
      <div className="flex">
        <Wrapper className="flex flex-col items-center w-halfScreen h-screen" style={{background:'#F2F4F7', height:'fit-content'}}>
          <p className="title">{t("Mobile Phone Download")}</p>
          <p className="description">
            {t(
              "Integrate encrypted private social networking, crypto payment, digital identity, and NFTS into one, providing you with both convenience and assurance"
            )}
          </p>
          <div className="downloadIcon flex">
            {mobileImgList.map((item) => (
              <DownloadImg
                name={item.name}
                link={item.link}
                imgSrc={item.imgSrc}
                key={item.name}
                version={item.version}
              />
            ))}
          </div>
          <div className='icon'>
            <img className="app" src={Phone} alt="phone" />
          </div>
        </Wrapper>
        <Wrapper className="flex flex-col items-center w-halfScreen bg-white h-screen">
          <p className="title text-black">{t("PC Download")}</p>
          <p className="description text-white">
            {t(
              "Use the most advanced end-to-end encryption technology (Signal Protocol) to protect your privacy at work"
            )}
          </p>
          <div className="downloadIcon flex">
            {desktopImgList.map((item) => (
              <DownloadImg
                name={item.name}
                link={item.link}
                imgSrc={item.imgSrc}
                key={item.name}
              />
            ))}
          </div>
          <div className='icon'>
            <img className="desktop" src={Desktop} alt="Desktop" />
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default PCComponent;
