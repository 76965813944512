import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

interface Props {
  iconName: string;
  title: string;
  description: React.ReactNode | string;
  iconClassName: string;
}

const Wrapper = styled.div`
  box-sizing: border-box;
  .card {
    width: ${isMobile?'40vw':'18.875vw'};
    height: ${isMobile?'53.33vw':'25.125vw'};
    box-sizing: border-box;
    background: white;
    border-radius: 1.5vw;
    :hover{
      background: black;
    }
    .content {
      width: ${isMobile?'40vw':'18.875vw'};
      height: ${isMobile?'53.33vw':'25.125vw'};
      text-decoration: none;
      color: black;
      transition: 0.25s ease;
      border: 1px solid black;
      border-radius: 1.5vw;
      .btn{
        display: none;
      }
      &:hover {
        transform: translate(-0.56vw, -0.56vw);
        background: #242CE0;
        padding: 0;
        color: white;
        .card--hover {
          display: block;
          color: white;
          padding: 0.75vw;
        }
        .btn{
          display: flex;
        }
        .card--display{
          .title{
            color: #ffffff;
          }
        }
      }

      .card--display {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        .icon_ec{
          margin: ${isMobile?'2.67vw 1.27vw':'2.56vw 1.18vw'};
        }
        .icon_ob{
          margin: ${isMobile?'2.67vw':'2.56vw 2.125vw'};
        }
        .icon_wb{
          margin: ${isMobile?'2.67vw':'2.56vw 1.44vw'};
        }
        .title {
          text-align: center;
          width: 100%;
          font-size: ${isMobile?'3.73vw':'1.75vw'};
          font-weight: 600;
          color: #000000;
          line-height: ${isMobile?'3.73vw':'1.75vw'};
          margin-bottom: ${isMobile?'4.27vw':'2vw'};
        }
      }
    }
  }

  .card--border {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 2px dashed black;
    z-index: -1;
  }
  .card-shadow {
    position: relative;
    z-index: 1;
  }

  .card-shadow::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 0;
    height: 1px;
    width: ${isMobile?'34.1vw':'16.1vw'};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
    z-index: -1;
  }
`;

export const FeatureItem = ({iconName, title, description, iconClassName}: Props) => {
  return (
    <Wrapper>
      <div className="card">
        <div className="content">
          <div className="card--display">
            <img className={iconClassName} src={iconName} alt=""/>
            <div className="title">{title}</div>
            {description}
          </div>
        </div>
      </div>
      <div className="card--border"/>
      <div className="card-shadow"/>
    </Wrapper>
  );
};