import React  from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { defaultOptions } from '../../utils/animateoption';
import Lottie from 'react-lottie';
import animationData from '../../css/animate/0mni_wallet.json';
import { omnichainData } from '../../common/local-data';

const Wrapper = styled.div`
  background: #D6DCED;
  position: relative;
  width: 100vw;
  //height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const OmnichainListWrap = styled.ul`
  margin-top: 3.3854vw;
  bottom: 2vw;
  left: 2vw;

  li {
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 2.08vw;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: 3.26vw;
      margin-right: 3.3vw;
    }
  }
`;

const MainWrap = styled.div`
  position: relative;
  width: 36.615vw;
  right: 0.7vw;
  //bottom: 6vw;
  z-index: 1;
  margin-right: 6.74vw;
  h1 {
    font-size: 4.17vw;
    font-weight: 500;
    color: black;
    line-height: 5vw;
    //max-width: 36.3vw;
    margin-bottom: 2.7vw;
  }

  .content {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    max-width: 35vw;
    font-size: 1.25vw;
    font-weight: 400;
    color: black;
    line-height: 1.875vw;
  }
`;

const RightWrap = styled.div`
  position: relative;
  width: 38.88vw;
  height: 37.78vw;
  margin: 12.15vw 0;
`;

const Identifier: React.FC = () => {
  const {t, i18n} = useTranslation();

  return (
    <Wrapper id='OmnichainWallet'>
      <MainWrap>
        <h1>{t('Omnichain Wallet')}</h1>
        <div className="content">{t('Easy-to-use')}</div>
        <OmnichainListWrap>
          {omnichainData.map((item, index) => (
            <li key={item.text}>
              <img src={item.imgUrl} alt={`icon${index}`}/>
              <div className='content'>{t(`${item.text}`)}</div>
            </li>
          ))}
        </OmnichainListWrap>
      </MainWrap>
      <RightWrap>
        <Lottie
          options={defaultOptions(animationData)}
          isStopped={false}
          isPaused={false}
        />
      </RightWrap>
    </Wrapper>
  );
};

export default Identifier;
