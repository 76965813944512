import img_0 from './images/img_0.png'
import img_1 from './images/img_1.jpg'
export default{
  'v': '5.10.0',
  "fr": 60,
  "ip": 0,
  "op": 359,
  "w": 1206,
  "h": 1100,
  'nm': 'demens 6',
  "ddd": 0,
  "assets": [
    {
      'id': 'image_0',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_0,
      "e": 0
    },
    {
      'id': 'image_1',
      "w": 620,
      "h": 624,
      'u': '',
      'p': img_1,
      "e": 0
    },
    {
      'id': 'comp_0',
      'nm': 'minihearts',
      "fr": 29.9700012207031,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': 'main_heart 9',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    1
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    361
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    42.333,
                    -58.667,
                    0
                  ],
                  "ti": [
                    -42.333,
                    58.667,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    794,
                    148,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': 'main_heart 8',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    64
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    361
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    -38,
                    -66.333,
                    0
                  ],
                  "ti": [
                    38,
                    66.333,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    312,
                    102,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 24.024,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': 'main_heart 7',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 181,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    12.333,
                    -34,
                    0
                  ],
                  "ti": [
                    -12.333,
                    34,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    614,
                    296,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': 'main_heart 6',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    181
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    541
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    -57.333,
                    -39.333,
                    0
                  ],
                  "ti": [
                    57.333,
                    39.333,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    196,
                    264,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': 'main_heart 5',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    1
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    139
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    66.333,
                    -8.667,
                    0
                  ],
                  "ti": [
                    -66.333,
                    8.667,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    938,
                    448,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          'nm': 'main_heart 4',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    1
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    361
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    -88.333,
                    -12.333,
                    0
                  ],
                  "ti": [
                    88.333,
                    12.333,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    10,
                    426,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          'nm': 'main_heart 3',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    1
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    361
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    -75.667,
                    14.667,
                    0
                  ],
                  "ti": [
                    75.667,
                    -14.667,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    86,
                    588,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 12.012,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 4,
          'nm': 'main_heart 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    292
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    385
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    0,
                    18.333,
                    0
                  ],
                  "ti": [
                    0,
                    -18.333,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    540,
                    610,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          'nm': 'main_heart 11',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    1
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    433
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    0,
                    53,
                    0
                  ],
                  "ti": [
                    0,
                    -53,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    540,
                    818,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 20.02,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          'nm': 'main_heart 10',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    1
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    361
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    41.333,
                    21.667,
                    0
                  ],
                  "ti": [
                    -41.333,
                    -21.667,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    788,
                    630,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 4,
          'nm': 'main_heart',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    1
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    199
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    540,
                    500,
                    0
                  ],
                  "to": [
                    38.667,
                    20.333,
                    0
                  ],
                  "ti": [
                    -38.667,
                    -20.333,
                    0
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    772,
                    622,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                191.763,
                170.375,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    25,
                    25,
                    100
                  ]
                },
                {
                  "t": 48.0480480480481,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          29.042,
                          -0.225
                        ],
                        [
                          17.08,
                          -13.504
                        ],
                        [
                          6.834,
                          -9.562
                        ],
                        [
                          9.259,
                          7.24
                        ],
                        [
                          21.774,
                          -0.097
                        ],
                        [
                          19.64,
                          -21.396
                        ],
                        [
                          -0.528,
                          -30.016
                        ],
                        [
                          -23.537,
                          -25.374
                        ],
                        [
                          -33.951,
                          -27.076
                        ],
                        [
                          -15.463,
                          -13.491
                        ],
                        [
                          -8.455,
                          7.395
                        ],
                        [
                          -13.151,
                          11.164
                        ],
                        [
                          -29.165,
                          32.169
                        ],
                        [
                          -0.871,
                          34.604
                        ],
                        [
                          19.998,
                          22.408
                        ]
                      ],
                      "o": [
                        [
                          -19.64,
                          -21.396
                        ],
                        [
                          -21.773,
                          -0.096
                        ],
                        [
                          -9.258,
                          7.24
                        ],
                        [
                          -6.833,
                          -9.563
                        ],
                        [
                          -17.079,
                          -13.505
                        ],
                        [
                          -29.043,
                          -0.225
                        ],
                        [
                          -19.975,
                          22.412
                        ],
                        [
                          0.881,
                          34.6
                        ],
                        [
                          29.166,
                          32.174
                        ],
                        [
                          13.134,
                          11.198
                        ],
                        [
                          8.454,
                          7.395
                        ],
                        [
                          15.463,
                          -13.592
                        ],
                        [
                          33.952,
                          -27.069
                        ],
                        [
                          23.536,
                          -25.381
                        ],
                        [
                          0.521,
                          -30.029
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.743,
                          -137.462
                        ],
                        [
                          84.281,
                          -170.713
                        ],
                        [
                          24.267,
                          -150.001
                        ],
                        [
                          0.003,
                          -124.667
                        ],
                        [
                          -24.26,
                          -150.001
                        ],
                        [
                          -84.275,
                          -170.713
                        ],
                        [
                          -160.737,
                          -137.462
                        ],
                        [
                          -190.982,
                          -55.919
                        ],
                        [
                          -153.091,
                          37.143
                        ],
                        [
                          -58.26,
                          126.162
                        ],
                        [
                          -14.762,
                          163.544
                        ],
                        [
                          14.768,
                          163.544
                        ],
                        [
                          58.284,
                          126.162
                        ],
                        [
                          153.114,
                          37.161
                        ],
                        [
                          190.988,
                          -55.919
                        ],
                        [
                          160.692,
                          -137.479
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.16862745098,
                      0.2,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': 'Fill 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      191.759,
                      171.189
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Group 1',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 72.0720720720721,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_1',
      'nm': '▽ point 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ CursorClick 4',
          'refId': 'comp_2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                83,
                65.752,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                42,
                42,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 84,
          "h": 84,
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': '333',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                164.999,
                80.19,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                200,
                200,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 22,
                    'f': 'Inter-SemiBold',
                    't': '333',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 26.4000015258789,
                    "ls": 0,
                    "fc": [
                      1,
                      1,
                      1
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形备份 19',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                202,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -32,
                          -32.5
                        ],
                        [
                          16,
                          -32.5
                        ],
                        [
                          32,
                          -16.5
                        ],
                        [
                          32,
                          16.5
                        ],
                        [
                          16,
                          32.5
                        ],
                        [
                          -32,
                          32.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 19',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形备份 19',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                141,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ]
                      ],
                      "v": [
                        [
                          -46.5,
                          -32.5
                        ],
                        [
                          46.5,
                          -32.5
                        ],
                        [
                          62.5,
                          -16.5
                        ],
                        [
                          62.5,
                          16.5
                        ],
                        [
                          46.5,
                          32.5
                        ],
                        [
                          -46.5,
                          32.5
                        ],
                        [
                          -62.5,
                          16.5
                        ],
                        [
                          -62.5,
                          -16.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 19',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': '矩形备份 19',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.345
                    ],
                    "y": [
                      0.569
                    ]
                  },
                  "t": 195,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 210,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                81,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -62.5,
                          -32.5
                        ],
                        [
                          62.5,
                          -32.5
                        ],
                        [
                          62.5,
                          32.5
                        ],
                        [
                          -62.5,
                          32.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 16,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 19',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_2',
      'nm': '▽ CursorClick 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42,
                42,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      42,
                      42
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                38.062,
                38.062,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.21,
                          0.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.65,
                          -0.33
                        ],
                        [
                          0.32,
                          -0.65
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.47,
                          -0.03
                        ],
                        [
                          0.26,
                          0.39
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0.33,
                          -0.65
                        ],
                        [
                          0.65,
                          0.32
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.21,
                          0.41
                        ],
                        [
                          -0.47,
                          0.03
                        ],
                        [
                          -0.26,
                          -0.39
                        ]
                      ],
                      "v": [
                        [
                          0.797,
                          -14.369
                        ],
                        [
                          2.107,
                          -16.989
                        ],
                        [
                          3.867,
                          -17.579
                        ],
                        [
                          4.457,
                          -15.819
                        ],
                        [
                          3.147,
                          -13.189
                        ],
                        [
                          2.047,
                          -12.469
                        ],
                        [
                          0.877,
                          -13.059
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.72,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.73
                        ],
                        [
                          0.72,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.72
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0.72,
                          0
                        ],
                        [
                          0,
                          -0.72
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.72,
                          0
                        ],
                        [
                          0,
                          0.73
                        ]
                      ],
                      "v": [
                        [
                          -16.408,
                          -1.964
                        ],
                        [
                          -15.088,
                          -1.964
                        ],
                        [
                          -13.778,
                          -3.284
                        ],
                        [
                          -15.088,
                          -4.594
                        ],
                        [
                          -16.408,
                          -4.594
                        ],
                        [
                          -17.718,
                          -3.284
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.72,
                          0
                        ],
                        [
                          0,
                          0.72
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.73,
                          0
                        ],
                        [
                          0,
                          -0.72
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.73,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.72
                        ],
                        [
                          -0.72,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.72
                        ]
                      ],
                      "v": [
                        [
                          -3.283,
                          -13.779
                        ],
                        [
                          -1.963,
                          -15.089
                        ],
                        [
                          -1.963,
                          -16.409
                        ],
                        [
                          -3.283,
                          -17.719
                        ],
                        [
                          -4.593,
                          -16.409
                        ],
                        [
                          -4.593,
                          -15.089
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.33,
                          -0.65
                        ],
                        [
                          -0.65,
                          0.32
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.03,
                          0.47
                        ],
                        [
                          0.39,
                          0.26
                        ],
                        [
                          0.39,
                          -0.14
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.65,
                          0.33
                        ],
                        [
                          0.32,
                          0.65
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.41,
                          -0.21
                        ],
                        [
                          0.03,
                          -0.47
                        ],
                        [
                          -0.35,
                          -0.23
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -14.369,
                          0.797
                        ],
                        [
                          -16.989,
                          2.107
                        ],
                        [
                          -17.579,
                          3.867
                        ],
                        [
                          -15.819,
                          4.457
                        ],
                        [
                          -13.189,
                          3.147
                        ],
                        [
                          -12.469,
                          2.047
                        ],
                        [
                          -13.059,
                          0.877
                        ],
                        [
                          -14.229,
                          0.737
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 4,
                  'ty': 'sh',
                  "ix": 5,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          1.08
                        ],
                        [
                          1.03,
                          0.34
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.69,
                          -0.7
                        ],
                        [
                          -0.31,
                          -0.94
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.09,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.41,
                          0.95
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.69,
                          0
                        ],
                        [
                          -0.49,
                          0.49
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.7
                        ],
                        [
                          0.49,
                          0.49
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0.99,
                          -0.43
                        ],
                        [
                          -0.05,
                          -1.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.94,
                          -0.31
                        ],
                        [
                          -0.7,
                          0.69
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.33,
                          1.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.04,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.49,
                          0.49
                        ],
                        [
                          0.7,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.49,
                          -0.49
                        ],
                        [
                          0,
                          -0.69
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          7.891,
                          3.4
                        ],
                        [
                          16.151,
                          -0.19
                        ],
                        [
                          17.701,
                          -2.71
                        ],
                        [
                          15.911,
                          -5.07
                        ],
                        [
                          -10.349,
                          -13.65
                        ],
                        [
                          -13.009,
                          -13.01
                        ],
                        [
                          -13.649,
                          -10.35
                        ],
                        [
                          -5.069,
                          15.91
                        ],
                        [
                          -2.709,
                          17.72
                        ],
                        [
                          -2.579,
                          17.72
                        ],
                        [
                          -0.189,
                          16.15
                        ],
                        [
                          3.401,
                          7.89
                        ],
                        [
                          12.471,
                          16.95
                        ],
                        [
                          14.321,
                          17.72
                        ],
                        [
                          16.181,
                          16.95
                        ],
                        [
                          16.951,
                          16.18
                        ],
                        [
                          17.721,
                          14.32
                        ],
                        [
                          16.951,
                          12.47
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 5',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 7,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_3',
      'nm': '▽ like 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': '666',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                143.143,
                81.386,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                200,
                200,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 24,
                    'f': 'Inter-SemiBold',
                    't': '666',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 28.8000011444092,
                    "ls": 0,
                    "fc": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                81,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -5.71
                        ],
                        [
                          0.74,
                          -0.39
                        ],
                        [
                          0.39,
                          0.21
                        ],
                        [
                          0,
                          11.67
                        ],
                        [
                          -5.71,
                          0.01
                        ],
                        [
                          -1.88,
                          -2.5
                        ],
                        [
                          -3.45,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          11.67
                        ],
                        [
                          -0.39,
                          0.21
                        ],
                        [
                          -0.74,
                          -0.39
                        ],
                        [
                          0.01,
                          -5.71
                        ],
                        [
                          3.44,
                          0
                        ],
                        [
                          1.88,
                          -2.5
                        ],
                        [
                          5.7,
                          0.01
                        ]
                      ],
                      "v": [
                        [
                          18.669,
                          -5.66
                        ],
                        [
                          0.629,
                          15.84
                        ],
                        [
                          -0.631,
                          15.84
                        ],
                        [
                          -18.671,
                          -5.66
                        ],
                        [
                          -8.331,
                          -16
                        ],
                        [
                          -0.001,
                          -12.02
                        ],
                        [
                          8.339,
                          -16
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.92,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形备份 13',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                138.665,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -61.333,
                          -32.5
                        ],
                        [
                          61.337,
                          -32.5
                        ],
                        [
                          61.337,
                          32.5
                        ],
                        [
                          -61.333,
                          32.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 16,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 13',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形备份 13',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.345
                    ],
                    "y": [
                      0.569
                    ]
                  },
                  "t": 195,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 210,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                122.665,
                81,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -61.333,
                          -32.5
                        ],
                        [
                          61.337,
                          -32.5
                        ],
                        [
                          61.337,
                          32.5
                        ],
                        [
                          -61.333,
                          32.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 16,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 13',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_4',
      'nm': '▽ share 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ ShareFat 4',
          'refId': 'comp_5',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                81,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                42,
                42,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 84,
          "h": 84,
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形备份 12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                81,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      65,
                      65
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 16,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 12',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形备份 12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.345
                    ],
                    "y": [
                      0.569
                    ]
                  },
                  "t": 195,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 210,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                65,
                81,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      65,
                      65
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 16,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 12',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_5',
      'nm': '▽ ShareFat 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42,
                42,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      42,
                      42
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.001,
                38.066,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.24,
                          -0.25
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.49,
                          0.2
                        ],
                        [
                          0,
                          0.53
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.8,
                          -1.92
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.77,
                          0.36
                        ],
                        [
                          -0.09,
                          0.84
                        ],
                        [
                          -4.66,
                          3.95
                        ],
                        [
                          -4.26,
                          0.36
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.49,
                          0.2
                        ],
                        [
                          -0.37,
                          -0.37
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.35
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.37,
                          0.38
                        ],
                        [
                          -0.49,
                          -0.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -9.37,
                          0.53
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.58,
                          0.62
                        ],
                        [
                          -0.77,
                          -0.36
                        ],
                        [
                          0.61,
                          -5.29
                        ],
                        [
                          3.86,
                          -3.28
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.53
                        ],
                        [
                          0.49,
                          -0.2
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.24,
                          0.25
                        ],
                        [
                          0,
                          0.35
                        ]
                      ],
                      "v": [
                        [
                          17.995,
                          0.273
                        ],
                        [
                          4.865,
                          13.393
                        ],
                        [
                          3.434,
                          13.683
                        ],
                        [
                          2.624,
                          12.463
                        ],
                        [
                          2.624,
                          5.943
                        ],
                        [
                          -14.965,
                          14.473
                        ],
                        [
                          -14.965,
                          14.473
                        ],
                        [
                          -17.236,
                          14.913
                        ],
                        [
                          -18.365,
                          12.903
                        ],
                        [
                          -10.195,
                          -1.437
                        ],
                        [
                          2.624,
                          -7.157
                        ],
                        [
                          2.624,
                          -13.787
                        ],
                        [
                          3.434,
                          -14.997
                        ],
                        [
                          4.865,
                          -14.717
                        ],
                        [
                          17.995,
                          -1.587
                        ],
                        [
                          18.374,
                          -0.657
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_6',
      'nm': '▽ commond 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': '222',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                136.821,
                81.386,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                200,
                200,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 24,
                    'f': 'Inter-SemiBold',
                    't': '222',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 28.8000011444092,
                    "ls": 0,
                    "fc": [
                      1,
                      1,
                      1
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ ChatCenteredDots 4',
          'refId': 'comp_7',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                71.288,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                42,
                42,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 84,
          "h": 84,
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                185.811,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -31.5,
                          -32.5
                        ],
                        [
                          15.5,
                          -32.5
                        ],
                        [
                          31.5,
                          -16.5
                        ],
                        [
                          31.5,
                          16.5
                        ],
                        [
                          15.5,
                          32.5
                        ],
                        [
                          -31.5,
                          32.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                132.406,
                65,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ]
                      ],
                      "v": [
                        [
                          -42.203,
                          -32.5
                        ],
                        [
                          42.207,
                          -32.5
                        ],
                        [
                          58.207,
                          -16.5
                        ],
                        [
                          58.207,
                          16.5
                        ],
                        [
                          42.207,
                          32.5
                        ],
                        [
                          -42.203,
                          32.5
                        ],
                        [
                          -58.203,
                          16.5
                        ],
                        [
                          -58.203,
                          -16.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.345
                    ],
                    "y": [
                      0.569
                    ]
                  },
                  "t": 195,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 210,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                116.406,
                81,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ]
                      ],
                      "v": [
                        [
                          -42.203,
                          -32.5
                        ],
                        [
                          42.207,
                          -32.5
                        ],
                        [
                          58.207,
                          -16.5
                        ],
                        [
                          58.207,
                          16.5
                        ],
                        [
                          42.207,
                          32.5
                        ],
                        [
                          -42.203,
                          32.5
                        ],
                        [
                          -58.203,
                          16.5
                        ],
                        [
                          -58.203,
                          -16.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_7',
      'nm': '▽ ChatCenteredDots 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42,
                42,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      42,
                      42
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42,
                44.626,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          1.45,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -1.45
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.45,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.92,
                          0
                        ],
                        [
                          -0.48,
                          0.78
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.01,
                          1.44
                        ],
                        [
                          -0.01,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -1.45,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.44
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.47,
                          0.78
                        ],
                        [
                          0.92,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.45,
                          0
                        ],
                        [
                          -0.01,
                          0
                        ],
                        [
                          -0.01,
                          -1.45
                        ]
                      ],
                      "v": [
                        [
                          14.438,
                          -15.75
                        ],
                        [
                          -14.432,
                          -15.75
                        ],
                        [
                          -17.062,
                          -13.12
                        ],
                        [
                          -17.062,
                          7.88
                        ],
                        [
                          -14.432,
                          10.5
                        ],
                        [
                          -4.673,
                          10.51
                        ],
                        [
                          -2.253,
                          14.48
                        ],
                        [
                          -0.003,
                          15.75
                        ],
                        [
                          2.247,
                          14.49
                        ],
                        [
                          4.678,
                          10.5
                        ],
                        [
                          14.438,
                          10.5
                        ],
                        [
                          17.068,
                          7.88
                        ],
                        [
                          17.068,
                          -13.12
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          1.09,
                          0
                        ],
                        [
                          0,
                          1.09
                        ],
                        [
                          -1.09,
                          0
                        ],
                        [
                          0,
                          -1.09
                        ]
                      ],
                      "o": [
                        [
                          -1.09,
                          0
                        ],
                        [
                          0,
                          -1.09
                        ],
                        [
                          1.09,
                          0
                        ],
                        [
                          0,
                          1.09
                        ]
                      ],
                      "v": [
                        [
                          -7.217,
                          -0.654
                        ],
                        [
                          -9.188,
                          -2.624
                        ],
                        [
                          -7.217,
                          -4.594
                        ],
                        [
                          -5.247,
                          -2.624
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          1.09,
                          0
                        ],
                        [
                          0,
                          1.09
                        ],
                        [
                          -1.09,
                          0
                        ],
                        [
                          0,
                          -1.09
                        ]
                      ],
                      "o": [
                        [
                          -1.09,
                          0
                        ],
                        [
                          0,
                          -1.09
                        ],
                        [
                          1.09,
                          0
                        ],
                        [
                          0,
                          1.09
                        ]
                      ],
                      "v": [
                        [
                          0.001,
                          -0.654
                        ],
                        [
                          -1.969,
                          -2.624
                        ],
                        [
                          0.001,
                          -4.594
                        ],
                        [
                          1.971,
                          -2.624
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          1.09,
                          0
                        ],
                        [
                          0,
                          1.09
                        ],
                        [
                          -1.09,
                          0
                        ],
                        [
                          0,
                          -1.09
                        ]
                      ],
                      "o": [
                        [
                          -1.09,
                          0
                        ],
                        [
                          0,
                          -1.09
                        ],
                        [
                          1.09,
                          0
                        ],
                        [
                          0,
                          1.09
                        ]
                      ],
                      "v": [
                        [
                          7.22,
                          -0.654
                        ],
                        [
                          5.25,
                          -2.624
                        ],
                        [
                          7.22,
                          -4.594
                        ],
                        [
                          9.19,
                          -2.624
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 6,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_8',
      'nm': '▽ face 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 路径 39',
          'refId': 'comp_9',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                91.961,
                92.494,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                62,
                39.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 124,
          "h": 79,
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                137.003,
                54.742,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.56,
                          0
                        ],
                        [
                          0,
                          -1.75
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.55,
                          0
                        ],
                        [
                          0,
                          1.75
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          1.55,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.75
                        ],
                        [
                          -1.56,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -1.75
                        ]
                      ],
                      "v": [
                        [
                          0.005,
                          -6.607
                        ],
                        [
                          2.815,
                          -3.437
                        ],
                        [
                          2.815,
                          3.433
                        ],
                        [
                          0.005,
                          6.603
                        ],
                        [
                          -2.815,
                          3.433
                        ],
                        [
                          -2.815,
                          -3.437
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                84.454,
                39.64,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.59,
                          0
                        ],
                        [
                          0,
                          -2.49
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.59,
                          0
                        ],
                        [
                          0,
                          2.49
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          2.59,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          2.49
                        ],
                        [
                          -2.59,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -2.49
                        ]
                      ],
                      "v": [
                        [
                          -0.002,
                          -8.494
                        ],
                        [
                          4.688,
                          -3.984
                        ],
                        [
                          4.688,
                          3.986
                        ],
                        [
                          -0.002,
                          8.496
                        ],
                        [
                          -4.692,
                          3.986
                        ],
                        [
                          -4.692,
                          -3.984
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                78.824,
                79.281,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      78.824,
                      79.281
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_9',
      'nm': '▽ 路径 39',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                48.515,
                64.193,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -6.94,
                          -2.36
                        ],
                        [
                          -1.49,
                          -5.91
                        ],
                        [
                          0.16,
                          -0.03
                        ],
                        [
                          7.17,
                          9.71
                        ]
                      ],
                      "o": [
                        [
                          6.88,
                          2.34
                        ],
                        [
                          -0.16,
                          0.03
                        ],
                        [
                          -16.29,
                          2.54
                        ],
                        [
                          10.38,
                          -1.72
                        ]
                      ],
                      "v": [
                        [
                          6.71,
                          -5.494
                        ],
                        [
                          19.27,
                          6.896
                        ],
                        [
                          18.8,
                          6.976
                        ],
                        [
                          -19.27,
                          -6.454
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                67.663,
                24.16,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.44,
                          -0.17
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.1,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.09,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.12,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          -0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.15,
                          -0.48
                        ],
                        [
                          4.79,
                          1.38
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.19,
                          3.25
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.12,
                          0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.12,
                          0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          0.49
                        ],
                        [
                          -1.46,
                          4.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.1,
                          -0.96
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.58,
                          0.13
                        ]
                      ],
                      "v": [
                        [
                          -21.532,
                          -11.277
                        ],
                        [
                          -18.642,
                          -10.077
                        ],
                        [
                          -18.642,
                          -10.077
                        ],
                        [
                          -15.642,
                          -8.857
                        ],
                        [
                          -15.642,
                          -8.857
                        ],
                        [
                          -12.902,
                          -7.757
                        ],
                        [
                          -12.902,
                          -7.757
                        ],
                        [
                          -10.392,
                          -6.767
                        ],
                        [
                          -10.392,
                          -6.767
                        ],
                        [
                          -9.222,
                          -6.307
                        ],
                        [
                          -9.222,
                          -6.307
                        ],
                        [
                          -8.042,
                          -5.847
                        ],
                        [
                          -8.042,
                          -5.847
                        ],
                        [
                          -6.332,
                          -5.197
                        ],
                        [
                          -6.332,
                          -5.197
                        ],
                        [
                          -4.872,
                          -4.647
                        ],
                        [
                          -4.872,
                          -4.647
                        ],
                        [
                          -3.442,
                          -4.117
                        ],
                        [
                          -3.442,
                          -4.117
                        ],
                        [
                          -2.402,
                          -3.737
                        ],
                        [
                          -2.402,
                          -3.737
                        ],
                        [
                          -1.402,
                          -3.377
                        ],
                        [
                          -1.402,
                          -3.377
                        ],
                        [
                          -0.752,
                          -3.157
                        ],
                        [
                          -0.752,
                          -3.157
                        ],
                        [
                          -0.122,
                          -2.937
                        ],
                        [
                          -0.122,
                          -2.937
                        ],
                        [
                          0.478,
                          -2.737
                        ],
                        [
                          0.768,
                          -2.637
                        ],
                        [
                          1.328,
                          -2.457
                        ],
                        [
                          1.598,
                          -2.377
                        ],
                        [
                          2.268,
                          -2.167
                        ],
                        [
                          2.268,
                          -2.167
                        ],
                        [
                          2.948,
                          -1.967
                        ],
                        [
                          2.948,
                          -1.967
                        ],
                        [
                          3.658,
                          -1.757
                        ],
                        [
                          3.658,
                          -1.757
                        ],
                        [
                          4.378,
                          -1.557
                        ],
                        [
                          4.738,
                          -1.447
                        ],
                        [
                          5.488,
                          -1.247
                        ],
                        [
                          5.868,
                          -1.147
                        ],
                        [
                          6.638,
                          -0.937
                        ],
                        [
                          6.638,
                          -0.937
                        ],
                        [
                          7.828,
                          -0.627
                        ],
                        [
                          7.828,
                          -0.627
                        ],
                        [
                          8.648,
                          -0.427
                        ],
                        [
                          8.648,
                          -0.427
                        ],
                        [
                          9.488,
                          -0.227
                        ],
                        [
                          9.488,
                          -0.227
                        ],
                        [
                          10.338,
                          -0.017
                        ],
                        [
                          10.338,
                          -0.017
                        ],
                        [
                          11.208,
                          0.183
                        ],
                        [
                          11.208,
                          0.183
                        ],
                        [
                          12.098,
                          0.393
                        ],
                        [
                          12.098,
                          0.393
                        ],
                        [
                          12.998,
                          0.593
                        ],
                        [
                          12.998,
                          0.593
                        ],
                        [
                          13.928,
                          0.793
                        ],
                        [
                          13.928,
                          0.793
                        ],
                        [
                          14.868,
                          1.003
                        ],
                        [
                          14.868,
                          1.003
                        ],
                        [
                          16.318,
                          1.303
                        ],
                        [
                          16.318,
                          1.303
                        ],
                        [
                          17.298,
                          1.503
                        ],
                        [
                          17.298,
                          1.503
                        ],
                        [
                          18.818,
                          1.813
                        ],
                        [
                          18.818,
                          1.813
                        ],
                        [
                          19.848,
                          2.013
                        ],
                        [
                          19.848,
                          2.013
                        ],
                        [
                          20.888,
                          2.213
                        ],
                        [
                          20.888,
                          2.213
                        ],
                        [
                          21.958,
                          2.423
                        ],
                        [
                          21.958,
                          2.423
                        ],
                        [
                          23.588,
                          2.723
                        ],
                        [
                          23.588,
                          2.723
                        ],
                        [
                          23.458,
                          3.713
                        ],
                        [
                          23.138,
                          5.173
                        ],
                        [
                          11.868,
                          11.383
                        ],
                        [
                          11.698,
                          11.323
                        ],
                        [
                          -18.632,
                          1.923
                        ],
                        [
                          -23.582,
                          -5.267
                        ],
                        [
                          -23.572,
                          -5.447
                        ],
                        [
                          -23.052,
                          -11.737
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '路径 35',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                61.933,
                39.64,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -7.08,
                          -2.22
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          -1.37
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          10.91,
                          -1.71
                        ],
                        [
                          6.44,
                          11
                        ],
                        [
                          -0.51,
                          5.92
                        ],
                        [
                          -4.77,
                          0
                        ],
                        [
                          -1.12,
                          -0.46
                        ]
                      ],
                      "o": [
                        [
                          8.88,
                          2.77
                        ],
                        [
                          1.35,
                          0.16
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.65,
                          11.92
                        ],
                        [
                          -17.55,
                          2.74
                        ],
                        [
                          -1.89,
                          -3.22
                        ],
                        [
                          0.52,
                          -5.91
                        ],
                        [
                          2.21,
                          0
                        ],
                        [
                          1.12,
                          0.46
                        ]
                      ],
                      "v": [
                        [
                          4.464,
                          -10.11
                        ],
                        [
                          28.724,
                          -4.61
                        ],
                        [
                          30.964,
                          -1.87
                        ],
                        [
                          30.944,
                          -1.73
                        ],
                        [
                          30.944,
                          -1.7
                        ],
                        [
                          12.094,
                          19.25
                        ],
                        [
                          -27.506,
                          3.51
                        ],
                        [
                          -30.886,
                          -10.59
                        ],
                        [
                          -23.436,
                          -19.82
                        ],
                        [
                          -18.486,
                          -18.94
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 35',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_10',
      'nm': '▽ 12',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                552,
                189,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ]
                      ],
                      "v": [
                        [
                          -73,
                          -80.5
                        ],
                        [
                          73,
                          -80.5
                        ],
                        [
                          81,
                          -72.5
                        ],
                        [
                          81,
                          72.5
                        ],
                        [
                          73,
                          80.5
                        ],
                        [
                          -73,
                          80.5
                        ],
                        [
                          -81,
                          72.5
                        ],
                        [
                          -81,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ 矩形 12',
          'refId': 'comp_11',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                552,
                189,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                162,
                161,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 324,
          "h": 322,
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                204,
                189,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ]
                      ],
                      "v": [
                        [
                          -73,
                          -80.5
                        ],
                        [
                          73,
                          -80.5
                        ],
                        [
                          81,
                          -72.5
                        ],
                        [
                          81,
                          72.5
                        ],
                        [
                          73,
                          80.5
                        ],
                        [
                          -73,
                          80.5
                        ],
                        [
                          -81,
                          72.5
                        ],
                        [
                          -81,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 0,
          'nm': '▽ 矩形 11',
          'refId': 'comp_12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                204,
                189,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                162,
                161,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 324,
          "h": 322,
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                379,
                189,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.15
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          12.15
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          12.15
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.15
                        ]
                      ],
                      "v": [
                        [
                          -157.5,
                          -94.5
                        ],
                        [
                          157.5,
                          -94.5
                        ],
                        [
                          179.5,
                          -72.5
                        ],
                        [
                          179.5,
                          72.5
                        ],
                        [
                          157.5,
                          94.5
                        ],
                        [
                          -157.5,
                          94.5
                        ],
                        [
                          -179.5,
                          72.5
                        ],
                        [
                          -179.5,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.971
                    ],
                    "y": [
                      0.65
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 34,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 56,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                359,
                209,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.15
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          12.15
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          12.15
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -12.15,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -12.15
                        ]
                      ],
                      "v": [
                        [
                          -157.5,
                          -94.5
                        ],
                        [
                          157.5,
                          -94.5
                        ],
                        [
                          179.5,
                          -72.5
                        ],
                        [
                          179.5,
                          72.5
                        ],
                        [
                          157.5,
                          94.5
                        ],
                        [
                          -157.5,
                          94.5
                        ],
                        [
                          -179.5,
                          72.5
                        ],
                        [
                          -179.5,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.776470601559,
                      0.776470601559,
                      0.776470601559,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_11',
      'nm': '▽ 矩形 12',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ]
                      ],
                      "v": [
                        [
                          -73,
                          -80.5
                        ],
                        [
                          73,
                          -80.5
                        ],
                        [
                          81,
                          -72.5
                        ],
                        [
                          81,
                          72.5
                        ],
                        [
                          73,
                          80.5
                        ],
                        [
                          -73,
                          80.5
                        ],
                        [
                          -81,
                          72.5
                        ],
                        [
                          -81,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_0',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                162,
                161,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                51.347,
                51.347,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 300,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                162,
                161,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ]
                      ],
                      "v": [
                        [
                          -73,
                          -80.5
                        ],
                        [
                          73,
                          -80.5
                        ],
                        [
                          81,
                          -72.5
                        ],
                        [
                          81,
                          72.5
                        ],
                        [
                          73,
                          80.5
                        ],
                        [
                          -73,
                          80.5
                        ],
                        [
                          -81,
                          72.5
                        ],
                        [
                          -81,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_12',
      'nm': '▽ 矩形 11',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ]
                      ],
                      "v": [
                        [
                          -73,
                          -80.5
                        ],
                        [
                          73,
                          -80.5
                        ],
                        [
                          81,
                          -72.5
                        ],
                        [
                          81,
                          72.5
                        ],
                        [
                          73,
                          80.5
                        ],
                        [
                          -73,
                          80.5
                        ],
                        [
                          -81,
                          72.5
                        ],
                        [
                          -81,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_1',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                162,
                161,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                310,
                312,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                52.258,
                52.258,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 300,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                162,
                161,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -4.42,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.42
                        ]
                      ],
                      "v": [
                        [
                          -73,
                          -80.5
                        ],
                        [
                          73,
                          -80.5
                        ],
                        [
                          81,
                          -72.5
                        ],
                        [
                          81,
                          72.5
                        ],
                        [
                          73,
                          80.5
                        ],
                        [
                          -73,
                          80.5
                        ],
                        [
                          -81,
                          72.5
                        ],
                        [
                          -81,
                          -72.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_13',
      'nm': '▽ 11',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形备份 17',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                158.198,
                135.501,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -2.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          2.21
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          2.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -2.21
                        ]
                      ],
                      "v": [
                        [
                          -46.5,
                          -9.5
                        ],
                        [
                          46.5,
                          -9.5
                        ],
                        [
                          50.5,
                          -5.5
                        ],
                        [
                          50.5,
                          5.5
                        ],
                        [
                          46.5,
                          9.5
                        ],
                        [
                          -46.5,
                          9.5
                        ],
                        [
                          -50.5,
                          5.5
                        ],
                        [
                          -50.5,
                          -5.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形备份 17',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                266.198,
                65.501,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -2.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          2.21
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          2.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -2.21
                        ]
                      ],
                      "v": [
                        [
                          -100.5,
                          -9.5
                        ],
                        [
                          100.5,
                          -9.5
                        ],
                        [
                          104.5,
                          -5.5
                        ],
                        [
                          104.5,
                          5.5
                        ],
                        [
                          100.5,
                          9.5
                        ],
                        [
                          -100.5,
                          9.5
                        ],
                        [
                          -104.5,
                          5.5
                        ],
                        [
                          -104.5,
                          -5.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                319.265,
                100.501,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ]
                      ],
                      "v": [
                        [
                          -133.633,
                          -50.251
                        ],
                        [
                          133.637,
                          -50.251
                        ],
                        [
                          149.637,
                          -34.251
                        ],
                        [
                          149.637,
                          34.249
                        ],
                        [
                          133.637,
                          50.249
                        ],
                        [
                          -133.633,
                          50.249
                        ],
                        [
                          -149.633,
                          34.249
                        ],
                        [
                          -149.633,
                          -34.251
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 20,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 30,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.345
                    ],
                    "y": [
                      0.569
                    ]
                  },
                  "t": 255,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 270,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                299.265,
                120.501,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.83,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          8.84
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -8.84,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.84
                        ]
                      ],
                      "v": [
                        [
                          -133.633,
                          -50.251
                        ],
                        [
                          133.637,
                          -50.251
                        ],
                        [
                          149.637,
                          -34.251
                        ],
                        [
                          149.637,
                          34.249
                        ],
                        [
                          133.637,
                          50.249
                        ],
                        [
                          -133.633,
                          50.249
                        ],
                        [
                          -149.633,
                          34.249
                        ],
                        [
                          -149.633,
                          -34.251
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_14',
      'nm': '▽ 10',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ yonghu 4',
          'refId': 'comp_15',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                80.9,
                71.09,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                38.5,
                45,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 77,
          "h": 90,
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                80.254,
                71.09,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      71.022,
                      71.09
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.345
                    ],
                    "y": [
                      0.569
                    ]
                  },
                  "t": 255,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 270,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                71.022,
                80.91,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      71.022,
                      71.09
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_15',
      'nm': '▽ yonghu 4',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                38.739,
                45.238,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -6.26,
                          0
                        ],
                        [
                          0,
                          -6.25
                        ],
                        [
                          6.26,
                          0
                        ],
                        [
                          0,
                          6.25
                        ]
                      ],
                      "o": [
                        [
                          6.26,
                          0
                        ],
                        [
                          0,
                          6.24
                        ],
                        [
                          -6.26,
                          0
                        ],
                        [
                          0,
                          -6.25
                        ]
                      ],
                      "v": [
                        [
                          -0.471,
                          -22.619
                        ],
                        [
                          10.859,
                          -11.309
                        ],
                        [
                          -0.471,
                          0.001
                        ],
                        [
                          -11.811,
                          -11.309
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -6.26,
                          0
                        ],
                        [
                          0,
                          -6.25
                        ],
                        [
                          6.26,
                          0
                        ],
                        [
                          0,
                          6.25
                        ]
                      ],
                      "o": [
                        [
                          6.26,
                          0
                        ],
                        [
                          0,
                          6.24
                        ],
                        [
                          -6.26,
                          0
                        ],
                        [
                          0,
                          -6.25
                        ]
                      ],
                      "v": [
                        [
                          -0.471,
                          -22.619
                        ],
                        [
                          10.859,
                          -11.309
                        ],
                        [
                          -0.471,
                          0.001
                        ],
                        [
                          -11.811,
                          -11.309
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -8.08,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.56,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          2.73
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          8.09,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          3.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.14,
                          -0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -8.07
                        ]
                      ],
                      "v": [
                        [
                          -4.73,
                          3.77
                        ],
                        [
                          4.72,
                          3.77
                        ],
                        [
                          19.37,
                          18.38
                        ],
                        [
                          19.37,
                          19.32
                        ],
                        [
                          5.85,
                          22.62
                        ],
                        [
                          -6.96,
                          22.62
                        ],
                        [
                          -8.73,
                          22.6
                        ],
                        [
                          -19.37,
                          19.32
                        ],
                        [
                          -19.37,
                          18.38
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      200,
                      200
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 5,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 300,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    }
  ],
  "fonts": {
    "list": [
      {
        'fName': 'Inter-SemiBold',
        'fFamily': 'Inter',
        'fStyle': 'Semi Bold',
        "ascent": 72.7264404296875
      }
    ]
  },
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      'nm': 'minihearts',
      'refId': 'comp_0',
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            818.17,
            891.218,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            540,
            500,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            80,
            80,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 1080,
      "h": 1000,
      "ip": 105,
      "op": 20123.018018018,
      "st": 105,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 0,
      'nm': '▽ point 4',
      'refId': 'comp_1',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.03
                ],
                "y": [
                  0.467
                ]
              },
              "t": 80,
              "s": [
                80
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 195,
              "s": [
                100
              ]
            },
            {
              "t": 210,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            893.158,
            512.22,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            146,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.03,
                  0.03,
                  0.333
                ],
                "y": [
                  0.272,
                  0.272,
                  0
                ]
              },
              "t": 80,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 90,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 266,
      "h": 146,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 0,
      'nm': '▽ like 4',
      'refId': 'comp_3',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.308
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.03
                ],
                "y": [
                  0.467
                ]
              },
              "t": 90,
              "s": [
                80
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 100,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 195,
              "s": [
                100
              ]
            },
            {
              "t": 210,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            733.5,
            832.218,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.029,
                  0.029,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.03,
                  0.03,
                  0.333
                ],
                "y": [
                  0.272,
                  0.272,
                  0
                ]
              },
              "t": 90,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 100,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 261,
      "h": 146,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 0,
      'nm': '▽ share 4',
      'refId': 'comp_4',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.03
                ],
                "y": [
                  0.467
                ]
              },
              "t": 80,
              "s": [
                80
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 195,
              "s": [
                100
              ]
            },
            {
              "t": 210,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            476.998,
            855.72,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.03,
                  0.03,
                  0.333
                ],
                "y": [
                  0.272,
                  0.272,
                  0
                ]
              },
              "t": 80,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 90,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 146,
      "h": 146,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 0,
      'nm': '▽ commond 4',
      'refId': 'comp_6',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.151
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.03
                ],
                "y": [
                  0.467
                ]
              },
              "t": 70,
              "s": [
                80
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 80,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 195,
              "s": [
                100
              ]
            },
            {
              "t": 210,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            152.284,
            716.22,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.03,
                  0.03,
                  0.333
                ],
                "y": [
                  0.272,
                  0.272,
                  0
                ]
              },
              "t": 70,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 80,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 249,
      "h": 146,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 0,
      'nm': '▽ face 4',
      'refId': 'comp_8',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.147
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.03
                ],
                "y": [
                  0.467
                ]
              },
              "t": 70,
              "s": [
                80
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 80,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 195,
              "s": [
                100
              ]
            },
            {
              "t": 210,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            814.158,
            294.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            158.562,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.03,
                  0.03,
                  0.333
                ],
                "y": [
                  0.272,
                  0.272,
                  0
                ]
              },
              "t": 70,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 80,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 158,
      "h": 159,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 0,
      'nm': '▽ 12',
      'refId': 'comp_10',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 34,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 56,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 225,
              "s": [
                100
              ]
            },
            {
              "t": 240,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 34,
              "s": [
                664.47,
                107.72,
                0
              ],
              "to": [
                0,
                49.5,
                0
              ],
              "ti": [
                0,
                -49.5,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 0.667
              },
              "o": {
                "x": 0.333,
                "y": 0.333
              },
              "t": 56,
              "s": [
                664.47,
                404.72,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 225,
              "s": [
                664.47,
                404.72,
                0
              ],
              "to": [
                0,
                70.333,
                0
              ],
              "ti": [
                0,
                -70.333,
                0
              ]
            },
            {
              "t": 240,
              "s": [
                664.47,
                826.72,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 24,
              "s": [
                369,
                0,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "t": 44,
              "s": [
                369,
                0,
                0
              ]
            }
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 738,
      "h": 398,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 0,
      'nm': '▽ 11',
      'refId': 'comp_13',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.03
                ],
                "y": [
                  0.467
                ]
              },
              "t": 20,
              "s": [
                80
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 30,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.96
                ],
                "y": [
                  0.79
                ]
              },
              "o": {
                "x": [
                  0.963
                ],
                "y": [
                  0.002
                ]
              },
              "t": 255,
              "s": [
                100
              ]
            },
            {
              "t": 270,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 255,
              "s": [
                295.235,
                372.718,
                0
              ],
              "to": [
                0,
                -45.833,
                0
              ],
              "ti": [
                0,
                45.833,
                0
              ]
            },
            {
              "t": 270,
              "s": [
                295.235,
                97.718,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            221,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.03,
                  0.03,
                  0.333
                ],
                "y": [
                  0.272,
                  0.272,
                  0
                ]
              },
              "t": 20,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 30,
              "s": [
                100,
                100,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 255,
              "s": [
                100,
                100,
                100
              ]
            },
            {
              "t": 270,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 619,
      "h": 221,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 0,
      'nm': '▽ 10',
      'refId': 'comp_14',
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.03
                ],
                "y": [
                  0.371
                ]
              },
              "t": 0,
              "s": [
                80
              ]
            },
            {
              "i": {
                "x": [
                  0.998
                ],
                "y": [
                  47.701
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 10,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.959
                ],
                "y": [
                  0.835
                ]
              },
              "o": {
                "x": [
                  0.695
                ],
                "y": [
                  0.026
                ]
              },
              "t": 255,
              "s": [
                100
              ]
            },
            {
              "t": 270,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 255,
              "s": [
                96.332,
                372.72,
                0
              ],
              "to": [
                0,
                -45.833,
                0
              ],
              "ti": [
                0,
                45.833,
                0
              ]
            },
            {
              "t": 270,
              "s": [
                96.332,
                97.72,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            152,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.03,
                  0.03,
                  0.333
                ],
                "y": [
                  0.272,
                  0.272,
                  0
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 10,
              "s": [
                100,
                100,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 255,
              "s": [
                100,
                100,
                100
              ]
            },
            {
              "t": 270,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 151,
      "h": 152,
      "ip": 0,
      "op": 300,
      "st": 0,
      "ct": 1,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      'nm': 'BG',
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            603,
            550,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          'ty': 'gr',
          "it": [
            {
              "ind": 0,
              'ty': 'sh',
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -301.5,
                      -275
                    ],
                    [
                      301.5,
                      -275
                    ],
                    [
                      301.5,
                      275
                    ],
                    [
                      -301.5,
                      275
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              'nm': '路径 1',
              'mn': 'ADBE Vector Shape - Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            },
            {
              'ty': 'tr',
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  200,
                  200
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              'nm': '变换'
            }
          ],
          'nm': 'BG',
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          'mn': 'ADBE Vector Group',
          "hd": false
        }
      ],
      "ip": 0,
      "op": 360,
      "st": 0,
      "ct": 1,
      "bm": 0
    }
  ],
  "markers": [],
  "chars": [
    {
      'ch': '2',
      "size": 24,
      'style': 'Semi Bold',
      "w": 62.14,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.616,
                        3.208
                      ],
                      [
                        -1.042,
                        2.628
                      ],
                      [
                        0,
                        2.723
                      ],
                      [
                        2.06,
                        3.173
                      ],
                      [
                        3.681,
                        1.823
                      ],
                      [
                        4.877,
                        0
                      ],
                      [
                        3.705,
                        -1.87
                      ],
                      [
                        2.107,
                        -3.409
                      ],
                      [
                        0,
                        -4.616
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.166,
                        2.166
                      ],
                      [
                        -3.622,
                        0
                      ],
                      [
                        -2.297,
                        -1.976
                      ],
                      [
                        0,
                        -3.432
                      ],
                      [
                        1.823,
                        -2.557
                      ],
                      [
                        3.598,
                        -3.645
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.711,
                        -4.522
                      ],
                      [
                        2.616,
                        -3.208
                      ],
                      [
                        1.041,
                        -2.628
                      ],
                      [
                        0,
                        -4.072
                      ],
                      [
                        -2.06,
                        -3.172
                      ],
                      [
                        -3.682,
                        -1.823
                      ],
                      [
                        -4.806,
                        0
                      ],
                      [
                        -3.705,
                        1.87
                      ],
                      [
                        -2.107,
                        3.409
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -3.716
                      ],
                      [
                        2.166,
                        -2.166
                      ],
                      [
                        3.456,
                        0
                      ],
                      [
                        2.296,
                        1.977
                      ],
                      [
                        0,
                        3.031
                      ],
                      [
                        -1.823,
                        2.557
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        56.321,
                        0
                      ],
                      [
                        56.321,
                        -11.009
                      ],
                      [
                        24.645,
                        -11.009
                      ],
                      [
                        24.645,
                        -11.506
                      ],
                      [
                        37.18,
                        -24.254
                      ],
                      [
                        48.171,
                        -35.849
                      ],
                      [
                        53.658,
                        -44.602
                      ],
                      [
                        55.22,
                        -52.628
                      ],
                      [
                        52.131,
                        -63.494
                      ],
                      [
                        43.519,
                        -70.987
                      ],
                      [
                        30.682,
                        -73.722
                      ],
                      [
                        17.915,
                        -70.916
                      ],
                      [
                        9.197,
                        -62.997
                      ],
                      [
                        6.037,
                        -50.959
                      ],
                      [
                        18.572,
                        -50.959
                      ],
                      [
                        21.822,
                        -59.783
                      ],
                      [
                        30.504,
                        -63.033
                      ],
                      [
                        39.134,
                        -60.067
                      ],
                      [
                        42.578,
                        -51.953
                      ],
                      [
                        39.844,
                        -43.572
                      ],
                      [
                        31.712,
                        -34.268
                      ],
                      [
                        6.463,
                        -9.517
                      ],
                      [
                        6.463,
                        0
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '2',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '2',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Inter'
    },
    {
      'ch': '6',
      "size": 24,
      'style': 'Semi Bold',
      "w": 64.81,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -3.942,
                        2.178
                      ],
                      [
                        -2.19,
                        3.8
                      ],
                      [
                        0.023,
                        4.782
                      ],
                      [
                        1.989,
                        3.563
                      ],
                      [
                        3.397,
                        2.036
                      ],
                      [
                        4.261,
                        0
                      ],
                      [
                        3.232,
                        -1.787
                      ],
                      [
                        1.752,
                        -3.125
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.711,
                        4.38
                      ],
                      [
                        -5.043,
                        0
                      ],
                      [
                        -2.048,
                        -1.669
                      ],
                      [
                        -0.663,
                        -2.793
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.178,
                        3.102
                      ],
                      [
                        3.527,
                        1.799
                      ],
                      [
                        4.593,
                        0
                      ],
                      [
                        4.261,
                        -3.113
                      ],
                      [
                        2.332,
                        -5.883
                      ],
                      [
                        0,
                        -8.286
                      ],
                      [
                        -1.551,
                        -4.522
                      ],
                      [
                        -2.652,
                        -2.699
                      ],
                      [
                        -3.315,
                        -1.195
                      ],
                      [
                        -3.48,
                        -0.024
                      ]
                    ],
                    "o": [
                      [
                        3.942,
                        -2.178
                      ],
                      [
                        2.189,
                        -3.8
                      ],
                      [
                        0.023,
                        -4.593
                      ],
                      [
                        -1.989,
                        -3.563
                      ],
                      [
                        -3.397,
                        -2.036
                      ],
                      [
                        -3.906,
                        0
                      ],
                      [
                        -3.232,
                        1.788
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.023,
                        -7.647
                      ],
                      [
                        2.711,
                        -4.38
                      ],
                      [
                        3.125,
                        0
                      ],
                      [
                        2.047,
                        1.669
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.521,
                        -3.93
                      ],
                      [
                        -2.178,
                        -3.101
                      ],
                      [
                        -3.528,
                        -1.799
                      ],
                      [
                        -5.824,
                        0
                      ],
                      [
                        -4.261,
                        3.113
                      ],
                      [
                        -2.332,
                        5.883
                      ],
                      [
                        0,
                        6.676
                      ],
                      [
                        1.55,
                        4.522
                      ],
                      [
                        2.651,
                        2.699
                      ],
                      [
                        3.314,
                        1.195
                      ],
                      [
                        5.232,
                        0.023
                      ]
                    ],
                    "v": [
                      [
                        46.857,
                        -2.237
                      ],
                      [
                        56.055,
                        -11.204
                      ],
                      [
                        59.304,
                        -24.077
                      ],
                      [
                        56.357,
                        -36.31
                      ],
                      [
                        48.278,
                        -44.709
                      ],
                      [
                        36.79,
                        -47.763
                      ],
                      [
                        26.083,
                        -45.082
                      ],
                      [
                        18.608,
                        -37.713
                      ],
                      [
                        18.111,
                        -37.713
                      ],
                      [
                        22.212,
                        -55.753
                      ],
                      [
                        33.842,
                        -62.322
                      ],
                      [
                        41.602,
                        -59.819
                      ],
                      [
                        45.668,
                        -53.125
                      ],
                      [
                        58.629,
                        -53.125
                      ],
                      [
                        54.581,
                        -63.672
                      ],
                      [
                        46.023,
                        -71.023
                      ],
                      [
                        33.842,
                        -73.722
                      ],
                      [
                        18.714,
                        -69.052
                      ],
                      [
                        8.825,
                        -55.558
                      ],
                      [
                        5.327,
                        -34.304
                      ],
                      [
                        7.653,
                        -17.507
                      ],
                      [
                        13.956,
                        -6.676
                      ],
                      [
                        22.905,
                        -0.835
                      ],
                      [
                        33.097,
                        0.994
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.036,
                        1.278
                      ],
                      [
                        1.16,
                        2.131
                      ],
                      [
                        0.023,
                        2.534
                      ],
                      [
                        -1.207,
                        2.119
                      ],
                      [
                        -2.06,
                        1.278
                      ],
                      [
                        -2.557,
                        0
                      ],
                      [
                        -2.001,
                        -1.231
                      ],
                      [
                        -1.16,
                        -2.107
                      ],
                      [
                        0.023,
                        -2.604
                      ],
                      [
                        1.184,
                        -2.131
                      ],
                      [
                        2.036,
                        -1.255
                      ],
                      [
                        2.58,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -2.036,
                        -1.278
                      ],
                      [
                        -1.16,
                        -2.131
                      ],
                      [
                        0.023,
                        -2.557
                      ],
                      [
                        1.207,
                        -2.118
                      ],
                      [
                        2.06,
                        -1.278
                      ],
                      [
                        2.58,
                        0
                      ],
                      [
                        2,
                        1.231
                      ],
                      [
                        1.16,
                        2.107
                      ],
                      [
                        0.023,
                        2.652
                      ],
                      [
                        -1.184,
                        2.131
                      ],
                      [
                        -2.036,
                        1.255
                      ],
                      [
                        -2.581,
                        0
                      ]
                    ],
                    "v": [
                      [
                        26.101,
                        -11.577
                      ],
                      [
                        21.307,
                        -16.69
                      ],
                      [
                        19.531,
                        -23.686
                      ],
                      [
                        21.378,
                        -30.7
                      ],
                      [
                        26.278,
                        -35.795
                      ],
                      [
                        33.203,
                        -37.713
                      ],
                      [
                        40.075,
                        -35.866
                      ],
                      [
                        44.815,
                        -30.859
                      ],
                      [
                        46.52,
                        -23.793
                      ],
                      [
                        44.78,
                        -16.619
                      ],
                      [
                        39.95,
                        -11.541
                      ],
                      [
                        33.026,
                        -9.659
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '6',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Inter'
    },
    {
      'ch': '3',
      "size": 22,
      'style': 'Semi Bold',
      "w": 65.16,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.048,
                        1.823
                      ],
                      [
                        -2.32,
                        3.196
                      ],
                      [
                        0.023,
                        4.096
                      ],
                      [
                        2.746,
                        3.054
                      ],
                      [
                        5.09,
                        0.734
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.415,
                        2.782
                      ],
                      [
                        0.023,
                        4.072
                      ],
                      [
                        1.976,
                        3.054
                      ],
                      [
                        3.587,
                        1.811
                      ],
                      [
                        4.853,
                        0
                      ],
                      [
                        3.8,
                        -1.74
                      ],
                      [
                        2.249,
                        -3.089
                      ],
                      [
                        0.094,
                        -4.072
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.462,
                        1.776
                      ],
                      [
                        -3.362,
                        0
                      ],
                      [
                        -2.072,
                        -1.882
                      ],
                      [
                        0.023,
                        -3.054
                      ],
                      [
                        2.379,
                        -1.941
                      ],
                      [
                        3.764,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.592,
                        -2.083
                      ],
                      [
                        0.023,
                        -3.29
                      ],
                      [
                        2.509,
                        -2
                      ],
                      [
                        3.882,
                        0
                      ],
                      [
                        2.521,
                        1.764
                      ],
                      [
                        0.165,
                        2.96
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.308,
                        -3.125
                      ],
                      [
                        -3.966,
                        -1.752
                      ],
                      [
                        -5.114,
                        0
                      ]
                    ],
                    "o": [
                      [
                        4.048,
                        -1.823
                      ],
                      [
                        2.32,
                        -3.196
                      ],
                      [
                        0.023,
                        -4.545
                      ],
                      [
                        -2.747,
                        -3.054
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.953,
                        -0.852
                      ],
                      [
                        2.415,
                        -2.782
                      ],
                      [
                        0.023,
                        -3.693
                      ],
                      [
                        -1.977,
                        -3.054
                      ],
                      [
                        -3.587,
                        -1.811
                      ],
                      [
                        -4.711,
                        0
                      ],
                      [
                        -3.8,
                        1.74
                      ],
                      [
                        -2.249,
                        3.089
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.118,
                        -3.054
                      ],
                      [
                        2.462,
                        -1.776
                      ],
                      [
                        3.385,
                        0
                      ],
                      [
                        2.071,
                        1.882
                      ],
                      [
                        0.023,
                        3.173
                      ],
                      [
                        -2.379,
                        1.941
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.593,
                        0
                      ],
                      [
                        2.592,
                        2.084
                      ],
                      [
                        0.023,
                        3.22
                      ],
                      [
                        -2.51,
                        2.001
                      ],
                      [
                        -3.646,
                        0
                      ],
                      [
                        -2.521,
                        -1.763
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.118,
                        4.096
                      ],
                      [
                        2.308,
                        3.125
                      ],
                      [
                        3.965,
                        1.752
                      ],
                      [
                        5.185,
                        0
                      ]
                    ],
                    "v": [
                      [
                        46.413,
                        -1.74
                      ],
                      [
                        55.966,
                        -9.268
                      ],
                      [
                        59.411,
                        -20.206
                      ],
                      [
                        55.327,
                        -31.605
                      ],
                      [
                        43.572,
                        -37.287
                      ],
                      [
                        43.572,
                        -37.855
                      ],
                      [
                        53.125,
                        -43.306
                      ],
                      [
                        56.712,
                        -53.587
                      ],
                      [
                        53.782,
                        -63.707
                      ],
                      [
                        45.437,
                        -71.005
                      ],
                      [
                        32.777,
                        -73.722
                      ],
                      [
                        20.011,
                        -71.112
                      ],
                      [
                        10.938,
                        -63.867
                      ],
                      [
                        7.422,
                        -53.125
                      ],
                      [
                        20.099,
                        -53.125
                      ],
                      [
                        23.97,
                        -60.369
                      ],
                      [
                        32.706,
                        -63.033
                      ],
                      [
                        40.891,
                        -60.21
                      ],
                      [
                        43.963,
                        -52.805
                      ],
                      [
                        40.43,
                        -45.135
                      ],
                      [
                        31.214,
                        -42.223
                      ],
                      [
                        24.787,
                        -42.223
                      ],
                      [
                        24.787,
                        -32.067
                      ],
                      [
                        31.214,
                        -32.067
                      ],
                      [
                        41.992,
                        -28.942
                      ],
                      [
                        45.845,
                        -20.881
                      ],
                      [
                        42.116,
                        -13.05
                      ],
                      [
                        32.528,
                        -10.05
                      ],
                      [
                        23.278,
                        -12.695
                      ],
                      [
                        19.247,
                        -19.78
                      ],
                      [
                        5.895,
                        -19.78
                      ],
                      [
                        9.535,
                        -8.949
                      ],
                      [
                        18.945,
                        -1.634
                      ],
                      [
                        32.564,
                        0.994
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '3',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '3',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Inter'
    }
  ]
}