import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import WebDashBoard from './WebDashboard';
import MobileDashBoard from './MobileDashBoard';
import useStore from '../../store';

const DashBoard = (): React.ReactElement => {
  const { getDashboardData } = useStore()

  useEffect(()=>{
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    getDashboardData()
  },[])

  return <>
    {isMobile? <MobileDashBoard/>:<WebDashBoard/>}
  </>
};

export default DashBoard;