import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import "./css/font.css";
import "./css/base.css";
import "./css/animate.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { SettingProvider } from "./components/SettingProvider";
import AddressProvider from "./utils/providers/AddressProvider";
import "rc-texty/assets/index.css";

ReactDOM.render(
  <AddressProvider>
    <BrowserRouter>
      <SettingProvider>
        <App />
      </SettingProvider>
    </BrowserRouter>
  </AddressProvider>,
  document.getElementById("root")
);
