import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import shadow from "./assetshome/shadow.png";
import three from "./assetshome/3.0.svg";
import i18n from 'i18next';
import Logo from '../../assets/home/LOGO-point.svg'
import { ListItem, mobileImgList } from '../download/utils/data';
import DownloadImg from '../download/DownloadImg';
import { PublicMainWrap, PublicWrapper } from './style';
import HomeAnimation from './homeAnimation';
import changeMobileData from '../download/utils/changeMobileData';

const Wrapper = styled(PublicWrapper)`
  background: #D6DCED;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
const MainWrap = styled(PublicMainWrap)`
  .homeAni{
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .backImg{
    position: absolute;
    width: 40vw;
    //top: 10vw;
  }
  * {
    font-weight: 900;
    font-family: TimesNewRomanPS-BoldMT, TimesNewRomanPS !important;
  }
  .zh {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .to {
      position: absolute;
      height: 14.74vw;
      line-height: 14.74vw;
      font-size: 10.521vw;
      left: 0;
      top: 0;
      color: black;
    }
    .web {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 11.667vw;
      line-height: 11.667vw;
      font-size: 10.521vw;
      right: 3vw;
      top: 2.24vw;
      color: #1b4fee;
      font-weight: normal !important;
      img.three {
        display: inline;
        position: relative;
        width: 12vw;
        height: 7vw;
        left: 1vw;
      }
      img.shadow {
        position: absolute;
        width: 18.333vw;
        heighr: 9.271vw;
        left: 17.604vw;
        top: 0.781vw;
      }
    }
    .world {
      text-align: left;
      position: absolute;
      height: 14.74vw;
      line-height: 14.74vw;
      font-size: 10.521vw;
      left: 0;
      bottom: 0;
      color: #1b4fee;
      right: 3vw;
      bottom: 0;
    }
    .door {
      position: absolute;
      height: 14.74vw;
      line-height: 14.74vw;
      font-size: 10.521vw;
      right: 1.5vw;
      bottom: 0;
      color: black;
    }
  }
  .en {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div.firstline {
      animation: 0.6s middleshow cubic-bezier(0.41, 0, 0.31, 1);
    }
    div.secondline {
      animation: 0.6s 0.1s middleshow cubic-bezier(0.41, 0, 0.31, 1);
    }
    div {
      height: 11.334vw;
      flex-direction: column;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      .black {
        color: black;
        margin: 0 2vw;
      }
      .blue {
        color: #1b4fee;
      }
      .black,
      .blue {
        display: inline-flex;
        font-size: 8.857vw;
      }
      img.three {
        display: inline;
        position: relative;
        width: 12vw;
        height: 7vw;
        margin-left: 1vw;
        max-width: none;
        vertical-align: unset;
      }
    }
    .info{
      display: flex;
      flex-direction: column;
      justify-content: start;
      position: relative;
      padding-top: 3.6vw;
      margin: 0 2vw;
      span{
        display: flex;
        max-width: 24vw;
        flex-wrap: wrap;
        font-size: max(1.3vw,20px);
        font-family: Inter-Regular, Inter !important;
        font-weight: 400;
        color: #000000;
        line-height: 1.67vw;
        white-space: normal;
        align-self: flex-start;
        margin-bottom: 2.78vw;
      }
    }
    .downloadIcon {
      display: flex;
      flex-direction: row;
      img {
        //width: 10.2vw;
        height: 3.75vw;
        margin-right: 2.22vw;
        cursor: pointer;
      }
      p {
        margin-top: 9px;
        width: 73%;
        text-align: center;
        height: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .content {
    width: 23.448vw;
    height: 6.875vw;
    font-size: 1.25vw;
    position: absolute;
    bottom: -7vw;
    left: 5.656vw;
    font-weight: 100;
    color: #b3b3b3;
    line-height: 1.719vw;
    text-align: left;
    animation: 0.7s middleshow cubic-bezier(0.41, 0, 0.31, 1);
  }
 
`;

const HomePage: React.FC = () => {
  const [targetList, setTargetList] = useState<ListItem[]>([]);

  useEffect(() => {
    changeMobileData(mobileImgList).then((res) => {
      setTargetList(res);
    });
  }, []);

  return (
      <Wrapper>
        <MainWrap>
          <img className='backImg' src={Logo} alt=""/>
          <div className='homeAni'>
            <div className="en">
              <div className="firstline">
                <div className="blue">Web3 & AI</div>
                <div className="black">One-Stop</div>
              </div>
              <div className="secondline">
                <div className="black">Life Tool</div>
              </div>
              <div className='info'>
            <span>
              All-in-One Portal and Solution for Web3 & AI
            </span>
                <div className="downloadIcon flex">
                  {mobileImgList.map((item) => (
                    <DownloadImg
                      name={item.name}
                      link={item.link}
                      imgSrc={item.imgSrc}
                      key={item.name}
                      version={item.version}
                    />
                  ))}
                </div>
              </div>
            </div>
            <HomeAnimation/>
          </div>
        </MainWrap>
      </Wrapper>
  );
};

export default HomePage;
