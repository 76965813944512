// Copyright 2017-2020 @polkadot/app-accounts authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from "react";

// type ToggleContext = undefined | (([address, onUpdateName]: [StringOrNull, VoidFn | null]) => void)
type ToggleContext = any;

interface Props {
  children: React.ReactNode;
}

const AddressContext: React.Context<ToggleContext> =
  React.createContext<ToggleContext>(undefined);

function AddressProvider({ children }: Props): React.ReactElement<Props> {
  const [androidDownload, setAndroidDownload] = useState(
    "https://coming-application.oss-cn-hongkong.aliyuncs.com/release/latest/ComingChat.apk"
  );
  const [IOSDownload, setIOSDownload] = useState(
    "https://apps.apple.com/us/app/coming-chat/id1564704361"
  );
  return (
    <AddressContext.Provider
      value={{ androidDownload, IOSDownload, setAndroidDownload }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export { AddressContext };

export default React.memo(AddressProvider);
