import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { PublicMainWrap, PublicWrapper } from './style';
import Arrow from '../../assets/home/more.svg'
import Info1 from '../../assets/home/img1.svg'
import TotalStatistics from './totalStatistics';
import { Link } from "react-router-dom";

const ComingChatInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper id='comingChat'>
      <MainWrap>
        <TotalStatistics/>
        <Link className='scroll' to={'/dashboard'}>
          <span>MORE</span>
          <div className='icon'>
            <img src={Arrow} alt=""/>
          </div>
        </Link>
        <div className='info'>
          <span className='title'>What Is ComingChat?</span>
          <div className='content'>
            <span>An all-in-one life tool that utilizes Web3 and AI<br/>technologies to make your mobile life more secure<br/>and convenient.</span>
          </div>
        </div>
      </MainWrap>
    </Wrapper>
  );
};

export default ComingChatInfo;

const Wrapper = styled(PublicWrapper)`
  background: #fff;
  width: 100vw;
  height: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.22vw 0;
`;

const MainWrap = styled(PublicMainWrap)`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  width: 100vw;
  height: fit-content;
  .scroll{
    font-size: 1vw;
    font-weight: 600;
    color: #131415;
    line-height: 1.11vw;
    position: relative;
    &>span{
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .icon{
      width: 3.47vw;
      height: 3.47vw;
      border-radius: 50%;
      background:#2B33FF;
      margin-top: 0.2vw;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &>img{
        width: 32px;
      }
    }
  }
  .info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 7vw 0;
    .title{
      font-size: 4.17vw;
      font-weight: 500;
      color: #000000;
      line-height: 5vw;
      max-width: 33.3vw;
      flex-wrap: wrap;
      margin-right: 3.82vw;
    }
    .content{
      display: flex;
      flex-direction: column;
      max-width: 38.33vw;
      flex-wrap: wrap;
      gap: 1vw;
      &>span{
        font-size: 1.25vw;
        font-weight: 400;
        color: #333333;
        line-height: 1.875vw;
      }
    }
  }
`;
