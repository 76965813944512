import styled from "styled-components";
import { judgeFontWeightBySystem } from "../../utils/help";
import { isMobile } from 'react-device-detect';

export const HeaderWrapper = styled.div<{ bgColor?: string }>`
  position: fixed;
  width: 100%;
  font-weight: ${judgeFontWeightBySystem};
  padding: ${isMobile?'':'1.187vw 3.05vw'};
  top: 0;
  .ComingChat-logo {
    position: relative;
    z-index: 999;
    display: inline-block;
    width: fit-content;
    .logo-pc{
      width: 13.4vw;
      height: 2.5vw;
    }
    .logo-mobile{
      width: 44vw;
    }
  }

  #react-burger-menu-btn {
    display: none;
  }

  .bm-menu-wrap {
    top: 0;
    width: 400px !important;
    .bm-morph-shape {
      left: 46px !important;
      fill: #d6dced;
      width: 280px !important;
    }
    .bm-item-list {
      background: #d6dced;
      display: flex;
      flex-direction: column;
      width: 193% !important;
      left: -168px !important;
    }
  }
  .menu-border {
    top: 0;
    left: 0;
  }

  .menu-title {
    display: flex !important;
    justify-content: space-between;
    padding: 1.8vh 20px 0 20px;
    align-items: center;
    .menu-logo {
      margin-top: 10.5px;
      height: 35px;
      @media screen and (max-width: 769px) {
        display: none;
      }
    }
    .close-btn {
    }
  }

  .menu-content {
    display: flex !important;
    padding: 9.7vh 0;
    flex-grow: 1;
    align-items: center;
    .en-btn {
      margin-right: 44px;
    }
    .en-btn,
    .zh-btn {
      color: #b7b7b7;
      font-size: 2vh;
      font-weight: ${judgeFontWeightBySystem};
      &:focus {
        outline: none;
      }
      &.active {
        color: black;
      }
    }
  }

  .menu-link {
    height: 100vh;
    display: flex !important;
    flex-direction: column;
    font-size: 3vh;
    line-height: 3.4vh;
    transform: translate3d(0px, 0px, 0px) !important;
    .menu-item {
      color: #242ce0;
      text-align: center;
      font-size: 4.32vw;
      border-bottom: 1px solid transparent;
      @media screen and (min-width: 1200px) {
        font-size: 25px;
      }
      //line-height: 5.4vh;
      margin: 0 0 5.5vh 0;
      white-space: nowrap;
      width: 32vw;
      user-select: none;

      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        border-bottom: 1px solid black;
        color: black;
      }
    }
  }

  .menu-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 50px 100px;
    @media screen and (max-width: 769px) {
      padding: 0 3.6vw 100px 3.6vw;
    }
    .sidebar {
      width: 100%;
      @media screen and (max-width: 769px) {
        width: 80%;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      .menu-link-item-img {
        height: 6.7vw;
        margin-bottom: 0;
        // margin: 20px;
      }
    }

    p {
      padding-top: 3.37vh;
      text-align: center;
      font-size: 2.97vw;
      font-family: Inter-Regular, Inter;
    }
  }
  .toggle-menu {
    position: absolute;
    z-index: 1101;
    right: 15px;
    top: 15px;

    .inner {
      left: 16.5px;
    }
  }
  .toggle-menu-mobile {
    display: none;
  }
  .logo-mobile {
    display: none;
  }
  @media screen and (max-width: 769px) {
    padding: 15px;
    justify-content: space-between;
    .logo-pc {
      display: none;
    }
    .logo-mobile {
      display: block;
    }
    .toggle-menu {
      display: none;
    }
    .toggle-menu-mobile {
      position: absolute;
      right: 15px;
      top: 8px;
      z-index: 1101;
      display: block;
      .round {
        transform: rotate(0);
      }
      &.cross {
        .bar {
          background: black !important;
        }
      }
      &::before {
        display: none !important;
      }
    }
    .bm-burger-bars {
      &:nth-child(2) {
        display: none;
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  cursor: pointer;

  img:hover {
    transition: 0.4s in;
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;