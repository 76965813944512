import cid1 from "../assets/cid-1.svg";
import cid2 from "../assets/cid-2.svg";
import cid3 from "../assets/cid-3.svg";
import cid4 from "../assets/cid-4.svg";
import cid5 from "../assets/cid-5.svg";

import icon1 from "../pages/home/assetsidentity/icon1.png";
import icon2 from "../pages/home/assetsidentity/icon2.png";
import icon3 from "../pages/home/assetsidentity/icon3.png";

export const cidDataList: ICidData[] = [
  {
    title: "4-DIGIT CID",
    text: "The decentralized identity (CID) for Aptos network. Only 9,000 CIDs on sale. When you have 4-digit CIDs, you can log in at ComingChat app, where unlimited benefits are waiting for you!",
    link: "https://aptoscid.coming.chat/",
    imgUrl: cid1,
    imgSize: "221px",
    mImgSize: "150px",
    textWidth: "210px",
    mTextWidth: "203px",
  },
  {
    title: "5-DIGIT CID",
    text: "The decentralized identity (CID) for ChainX network. Only 90,000 CIDs on sale. When you have 5-digit CIDs, you can log in at ComingChat app, where unlimited benefits are waiting for you!",
    link: "https://appcid.coming.chat/",
    imgUrl: cid3,
    imgSize: "213px",
    mImgSize: "145px",
    textWidth: "206px",
    mTextWidth: "170px",
  },
  {
    title: "COMINGCHAT",
    text: "Register new CID on the ComingChat app and send it to whomever you want through the app.",
    link: "https://coming.chat/download",
    imgUrl: cid5,
    imgSize: "227px",
    mImgSize: "153px",
    textWidth: "160px",
    mTextWidth: "144px",
  },
  {
    title: "COMFUTURE",
    text: "Buy any CID numbers you like or that you think are lucky for you. Register unique CID numbers and sell them to make massive profits on Comfuture and also earn royalties on them.",
    link: "https://comfuture.coming.chat/",
    imgUrl: cid2,
    imgSize: "213px",
    mImgSize: "145px",
    textWidth: "196px",
    mTextWidth: "170px",
  },
];

export const omnichainData = [
  {
    imgUrl: icon1,
    text: "Cross-chain",
  },
  {
    imgUrl: icon2,
    text: "Private key",
  },
  { imgUrl: icon3, text: "DApp ecological" },
];

export interface ICidData {
  title: string;
  text: string;
  textWidth: string;
  link: string;
  imgUrl: string;
  imgSize?: string;
  right?: string;
  mImgSize?: string;
  mTextWidth?: string;
}
