import React, { useState } from "react";
import WhiteLogo from "../../assets/blue-logo.svg";
import styled from "styled-components";
import MenuButton from "../MenuButton/MenuButton";
import MenuContent, { TranslationItem } from "../Header/MenuContent";
import { HeaderWrapper } from "../Header/styles";

const Wrapper = styled.div`
  position: relative;
  .showNav {
    background: white;
    max-height: 88px;
    transition: max-height 0.3s ease-in;
    transform-origin: 50% 0;
    animation: slide-down 0.3s ease-in;
    -webkit-animation: slide-down 0.3s ease-in;
  }

  .hiddenNav {
    visibility: hidden;
    transition: max-height 0.3s ease-out;
  }
`;


const MobileHeader = ({ bgColor }: { bgColor?: string }): React.ReactElement => {
  const [isMenuIconWhite, setIsMenuIconWhite] = useState<boolean>(true);
  const [show, SetShow] = useState<boolean>(false);
  const translationList: TranslationItem[] = [
    {
      text: "English",
      language: "en",
    },
    {
      text: "中文",
      language: "zh",
    },
  ];

  return (
    <Wrapper>
      <HeaderWrapper
        className={`z-10 fixed top-0  flex items-center`}
      >
        <a className="ComingChat-logo" href="/">
          <img
            className="logo-mobile"
            src={WhiteLogo}
            alt="ComingChat"
          />
        </a >

            <MenuButton
              className="toggle-menu"
              onClick={() => SetShow(!show)}
              type="list"
              color={isMenuIconWhite ? "white" : "black"}
              isMenuOpen={show}
            />
            <MenuButton
              className="toggle-menu-mobile"
              onClick={() => SetShow(!show)}
              type="list"
              color={isMenuIconWhite ? "white" : "black"}
              isMenuOpen={show}
            />
            <MenuContent
              isMenuOpen={show}
              setIsMenuOpen={SetShow}
              translationList={translationList}
            />
      </HeaderWrapper>
    </Wrapper>
  );
};

export default MobileHeader;