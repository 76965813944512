import img_0 from './images/img_0.png'
import img_1 from './images/img_1.png'
import img_2 from './images/img_2.png'
import img_3 from './images/img_3.jpg'
import img_4 from './images/img_4.jpg'
import img_5 from './images/img_5.jpg'
import img_6 from './images/img_6.png'
import img_7 from './images/img_7.png'
import img_8 from './images/img_8.png'
import img_9 from './images/img_9.png'
import img_10 from './images/img_10.png'
import img_11 from './images/img_11.png'
import img_12 from './images/img_12.png'
import img_13 from './images/img_13.png'
import img_14 from './images/img_14.jpg'
import img_15 from './images/img_15.jpg'
import img_16 from './images/img_16.jpg'
import img_17 from './images/img_17.jpg'
import img_18 from './images/img_18.jpg'
import img_19 from './images/img_19.jpg'
import img_20 from './images/img_20.jpg'
export default {
  'v': '5.10.0',
  "fr": 25,
  "ip": 0,
  "op": 250,
  "w": 520,
  "h": 507,
  'nm': 'area2',
  "ddd": 0,
  "assets": [
    {
      'id': 'image_0',
      "w": 712,
      "h": 1440,
      'u': '',
      'p': img_0,
      "e": 0
    },
    {
      'id': 'image_1',
      "w": 200,
      "h": 200,
      'u': '',
      'p': img_1,
      "e": 0
    },
    {
      'id': 'image_2',
      "w": 837,
      "h": 837,
      'u': '',
      'p': img_2,
      "e": 0
    },
    {
      'id': 'image_3',
      "w": 1080,
      "h": 1080,
      'u': '',
      'p': img_3,
      "e": 0
    },
    {
      'id': 'image_4',
      "w": 1080,
      "h": 1080,
      'u': '',
      'p': img_4,
      "e": 0
    },
    {
      'id': 'image_5',
      "w": 1000,
      "h": 1000,
      'u': '',
      'p': img_5,
      "e": 0
    },
    {
      'id': 'image_6',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_6,
      "e": 0
    },
    {
      'id': 'image_7',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_7,
      "e": 0
    },
    {
      'id': 'image_8',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_8,
      "e": 0
    },
    {
      'id': 'image_9',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_9,
      "e": 0
    },
    {
      'id': 'image_10',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_10,
      "e": 0
    },
    {
      'id': 'image_11',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_11,
      "e": 0
    },
    {
      'id': 'image_12',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_12,
      "e": 0
    },
    {
      'id': 'image_13',
      "w": 631,
      "h": 631,
      'u': '',
      'p': img_13,
      "e": 0
    },
    {
      'id': 'image_14',
      "w": 1008,
      "h": 1008,
      'u': '',
      'p': img_14,
      "e": 0
    },
    {
      'id': 'image_15',
      "w": 2048,
      "h": 2048,
      'u': '',
      'p': img_15,
      "e": 0
    },
    {
      'id': 'image_16',
      "w": 1600,
      "h": 1600,
      'u': '',
      'p': img_16,
      "e": 0
    },
    {
      'id': 'image_17',
      "w": 1181,
      "h": 1241,
      'u': '',
      'p': img_17,
      "e": 0
    },
    {
      'id': 'image_18',
      "w": 556,
      "h": 558,
      'u': '',
      'p': img_18,
      "e": 0
    },
    {
      'id': 'image_19',
      "w": 558,
      "h": 556,
      'u': '',
      'p': img_19,
      "e": 0
    },
    {
      'id': 'image_20',
      "w": 558,
      "h": 554,
      'u': '',
      'p': img_20,
      "e": 0
    },
    {
      'id': 'comp_0',
      'nm': 'phone_2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': 'finger 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 25,
                  "s": [
                    23
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 50,
                  "s": [
                    23
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 95,
                  "s": [
                    23
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 105,
                  "s": [
                    23
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 135,
                  "s": [
                    23
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 220,
                  "s": [
                    23
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 230,
                  "s": [
                    23
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 240,
                  "s": [
                    23
                  ]
                },
                {
                  "t": 245,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 5,
                  "s": [
                    35.5,
                    44.5,
                    0
                  ],
                  "to": [
                    9.333,
                    80.667,
                    0
                  ],
                  "ti": [
                    -9.333,
                    -80.667,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 25,
                  "s": [
                    91.5,
                    528.5,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0
                  },
                  "t": 35,
                  "s": [
                    91.5,
                    528.5,
                    0
                  ],
                  "to": [
                    12.167,
                    -9.667,
                    0
                  ],
                  "ti": [
                    -15.333,
                    54.167,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0
                  },
                  "t": 50,
                  "s": [
                    164.5,
                    470.5,
                    0
                  ],
                  "to": [
                    15.333,
                    -54.167,
                    0
                  ],
                  "ti": [
                    -3.167,
                    44.5,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 70,
                  "s": [
                    183.5,
                    203.5,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0
                  },
                  "t": 95,
                  "s": [
                    183.5,
                    203.5,
                    0
                  ],
                  "to": [
                    -15.667,
                    -19.5,
                    0
                  ],
                  "ti": [
                    15.667,
                    19.5,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 105,
                  "s": [
                    89.5,
                    86.5,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0
                  },
                  "t": 115,
                  "s": [
                    89.5,
                    86.5,
                    0
                  ],
                  "to": [
                    25.5,
                    37.667,
                    0
                  ],
                  "ti": [
                    -25.5,
                    -37.667,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 135,
                  "s": [
                    242.5,
                    312.5,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0
                  },
                  "t": 165,
                  "s": [
                    242.5,
                    312.5,
                    0
                  ],
                  "to": [
                    -13.333,
                    28.5,
                    0
                  ],
                  "ti": [
                    13.333,
                    -28.5,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 180,
                  "s": [
                    162.5,
                    483.5,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0
                  },
                  "t": 185,
                  "s": [
                    162.5,
                    483.5,
                    0
                  ],
                  "to": [
                    0,
                    -47.667,
                    0
                  ],
                  "ti": [
                    0,
                    47.667,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 205,
                  "s": [
                    162.5,
                    197.5,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0
                  },
                  "t": 220,
                  "s": [
                    162.5,
                    197.5,
                    0
                  ],
                  "to": [
                    -20.833,
                    -24.667,
                    0
                  ],
                  "ti": [
                    20.833,
                    24.667,
                    0
                  ]
                },
                {
                  "t": 230,
                  "s": [
                    37.5,
                    49.5,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 25,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 30,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 35,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 50,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 55,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 70,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 75,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 95,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 105,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 110,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 115,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 135,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 140,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 145,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 180,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 185,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 205,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 210,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 220,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 230,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 235,
                  "s": [
                    60,
                    60,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 240,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "t": 245,
                  "s": [
                    0,
                    0,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      29,
                      29
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.20000000298,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'finger',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 250,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': 'phone case 2',
          'refId': 'image_0',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                140,
                283,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                356,
                720,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                39.326,
                39.326,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 250,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': 'buttom2',
          'refId': 'comp_1',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                140,
                534,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 40,
          "ip": 0,
          "op": 275,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': 'case 3',
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                140,
                284,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      250,
                      542
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 14,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'case',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 240,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 0,
          'nm': '▽ wallet_home',
          "tt": 1,
          "tp": 4,
          'refId': 'comp_14',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 30,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 35,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                140,
                349.606,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                335.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 671,
          "ip": 30,
          "op": 255,
          "st": 30,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 0,
          'nm': '▽ message list',
          'refId': 'comp_97',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                139.977,
                283.667,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                270.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 541,
          "ip": 0,
          "op": 275,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          'nm': 'case 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                140,
                284,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      250,
                      542
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 14,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'case',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 250,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_1',
      'nm': 'buttom2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': 'Me',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.898
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      1
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 350,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.745
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.16
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 355,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.746
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.406
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 467,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 472,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                225.482,
                30.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      10,
                      9
                    ],
                    "ps": [
                      -5,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'Me',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.188,
                      0.114,
                      0.961
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 243,
          "op": 533,
          "st": 243,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.898
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      1
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 350,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.745
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.16
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 355,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.746
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.406
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 467,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 472,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                225.48,
                15.744,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -3.22,
                          0
                        ],
                        [
                          -0.52,
                          -3.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.01,
                          -0.04
                        ],
                        [
                          0.4,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.05,
                          0.3
                        ]
                      ],
                      "o": [
                        [
                          3.16,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.03
                        ],
                        [
                          0.04,
                          0.4
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.31
                        ],
                        [
                          0.44,
                          -3.09
                        ]
                      ],
                      "v": [
                        [
                          0.024,
                          0.799
                        ],
                        [
                          6.324,
                          6.099
                        ],
                        [
                          6.354,
                          6.279
                        ],
                        [
                          6.364,
                          6.389
                        ],
                        [
                          5.714,
                          7.189
                        ],
                        [
                          5.684,
                          7.189
                        ],
                        [
                          -6.366,
                          7.189
                        ],
                        [
                          -6.296,
                          6.269
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.76,
                          0
                        ],
                        [
                          0,
                          -1.76
                        ],
                        [
                          1.77,
                          0
                        ],
                        [
                          0,
                          1.77
                        ]
                      ],
                      "o": [
                        [
                          1.77,
                          0
                        ],
                        [
                          0,
                          1.77
                        ],
                        [
                          -1.76,
                          0
                        ],
                        [
                          0,
                          -1.76
                        ]
                      ],
                      "v": [
                        [
                          0.064,
                          -7.188
                        ],
                        [
                          3.264,
                          -3.998
                        ],
                        [
                          0.064,
                          -0.798
                        ],
                        [
                          -3.126,
                          -3.998
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.1882353127,
                      0.113725498319,
                      0.960784375668,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 243,
          "op": 511,
          "st": 243,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': '▽ wallet_P',
          'refId': 'comp_2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.898
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      1
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 30,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.745
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.16
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 35,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.746
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.406
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 239,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 244,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                74.986,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 0,
          "op": 275,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 0,
          'nm': '▽ chat_P',
          'refId': 'comp_4',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.072
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 30,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.745
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.16
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 35,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.746
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.406
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 239,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 244,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.072
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 351,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.745
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.16
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 356,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.746
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.406
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 467,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 472,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 0,
          "op": 500,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 0,
          'nm': '▽ 2',
          'refId': 'comp_6',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                74.986,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 369,
          "op": 594,
          "st": 369,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 0,
          'nm': '▽ 2',
          'refId': 'comp_6',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                74.986,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 150,
          "op": 375,
          "st": 150,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 0,
          'nm': '▽ 3',
          'refId': 'comp_8',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                172.718,
                20.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                60,
                12.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 120,
          "h": 25,
          "ip": 150,
          "op": 375,
          "st": 150,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 0,
          'nm': '▽ 1',
          'refId': 'comp_12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 369,
          "op": 594,
          "st": 369,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 0,
          'nm': '▽ 1',
          'refId': 'comp_12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 150,
          "op": 375,
          "st": 150,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          'nm': 'BG 3',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.050000000745
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 12.75,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      39.993
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'BG',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 150,
          "op": 375,
          "st": 150,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 0,
          'nm': '▽ 2',
          'refId': 'comp_6',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                74.986,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 0,
          'nm': '▽ 3',
          'refId': 'comp_8',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                172.718,
                20.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                60,
                12.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 120,
          "h": 25,
          "ip": 435,
          "op": 660,
          "st": 435,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 0,
          'nm': '▽ 3',
          'refId': 'comp_8',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                172.718,
                20.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                60,
                12.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 120,
          "h": 25,
          "ip": 212,
          "op": 437,
          "st": 212,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 0,
          'nm': '▽ 3',
          'refId': 'comp_8',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                172.718,
                20.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                60,
                12.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 120,
          "h": 25,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 0,
          'nm': '▽ 1',
          'refId': 'comp_12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 292,
          "op": 517,
          "st": 292,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 0,
          'nm': '▽ 1',
          'refId': 'comp_12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                25,
                20,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 50,
          "h": 40,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 4,
          'nm': 'BG 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.050000000745
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 12.75,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      39.993
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'BG',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 550,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_2',
      'nm': '▽ wallet_P',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ buttom_wallet_p 2',
          'refId': 'comp_3',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                15.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 275,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'Wallet',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                30.496,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      20,
                      9
                    ],
                    "ps": [
                      -10,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'Wallet',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.188,
                      0.114,
                      0.961
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 282,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      49.991,
                      39.993
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 282,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_3',
      'nm': '▽ buttom_wallet_p 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.9
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.06,
                          -1.89
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.89,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.9
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.9,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.91
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.9
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.9
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          5.698,
                          -5.703
                        ],
                        [
                          7.328,
                          -4.073
                        ],
                        [
                          7.328,
                          -3.533
                        ],
                        [
                          3.798,
                          -3.533
                        ],
                        [
                          0.278,
                          -0.123
                        ],
                        [
                          0.268,
                          -0.003
                        ],
                        [
                          3.678,
                          3.527
                        ],
                        [
                          3.798,
                          3.527
                        ],
                        [
                          7.328,
                          3.527
                        ],
                        [
                          7.328,
                          4.077
                        ],
                        [
                          5.698,
                          5.707
                        ],
                        [
                          -5.702,
                          5.707
                        ],
                        [
                          -7.332,
                          4.077
                        ],
                        [
                          -7.332,
                          -4.073
                        ],
                        [
                          -5.702,
                          -5.703
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.2
                        ],
                        [
                          -1.2,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.2,
                          0
                        ],
                        [
                          0,
                          -1.2
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          7.328,
                          -2.172
                        ],
                        [
                          7.328,
                          2.168
                        ],
                        [
                          3.528,
                          2.168
                        ],
                        [
                          1.358,
                          -0.002
                        ],
                        [
                          3.528,
                          -2.172
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.188235297799,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 281,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      15.997,
                      15.997
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 281,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_4',
      'nm': '▽ chat_P',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ buttom_chat_n 2',
          'refId': 'comp_5',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                15.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 318,
          "op": 543,
          "st": 318,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ buttom_chat_n 2',
          'refId': 'comp_5',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                15.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 95,
          "op": 320,
          "st": 95,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': '▽ buttom_chat_n 2',
          'refId': 'comp_5',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                15.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 5,
          'nm': 'Chat',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                30.496,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      15,
                      9
                    ],
                    "ps": [
                      -7.5,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'Chat',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.62,
                      0.663,
                      0.745
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 542,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      49.991,
                      39.993
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 542,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_5',
      'nm': '▽ buttom_chat_n 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.85
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.85,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.31,
                          0.29
                        ],
                        [
                          0,
                          0.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.85
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.85,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.85,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.85
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.29,
                          0.31
                        ],
                        [
                          -0.15,
                          -0.14
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.85,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.85
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          5.788,
                          -6.501
                        ],
                        [
                          7.328,
                          -4.961
                        ],
                        [
                          7.328,
                          1.989
                        ],
                        [
                          5.788,
                          3.529
                        ],
                        [
                          -0.642,
                          3.529
                        ],
                        [
                          -3.192,
                          6.259
                        ],
                        [
                          -4.282,
                          6.289
                        ],
                        [
                          -4.522,
                          5.729
                        ],
                        [
                          -4.522,
                          3.529
                        ],
                        [
                          -5.792,
                          3.529
                        ],
                        [
                          -7.332,
                          1.989
                        ],
                        [
                          -7.332,
                          -4.961
                        ],
                        [
                          -5.792,
                          -6.501
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          -0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.39,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.39,
                          0.05
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.4,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.4,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.4
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.929,
                          -3.027
                        ],
                        [
                          -1.931,
                          -3.027
                        ],
                        [
                          -2.701,
                          -2.337
                        ],
                        [
                          -2.701,
                          -2.257
                        ],
                        [
                          -2.701,
                          -0.707
                        ],
                        [
                          -2.011,
                          0.053
                        ],
                        [
                          -1.931,
                          0.062
                        ],
                        [
                          1.929,
                          0.062
                        ],
                        [
                          2.699,
                          -0.627
                        ],
                        [
                          2.699,
                          -0.707
                        ],
                        [
                          2.699,
                          -2.257
                        ],
                        [
                          2.009,
                          -3.027
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.61960786581,
                      0.662745118141,
                      0.745098054409,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      15.997,
                      15.997
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_6',
      'nm': '▽ 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ buttom_wallet_p',
          'refId': 'comp_7',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                15.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'Wallet',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                30.496,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      20,
                      9
                    ],
                    "ps": [
                      -10,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'Wallet',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.62,
                      0.663,
                      0.745
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      49.991,
                      39.993
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_7',
      'nm': '▽ buttom_wallet_p',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.9
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.06,
                          -1.89
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.89,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.9
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.9,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.91
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.9
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.9,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.9
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          5.698,
                          -5.703
                        ],
                        [
                          7.328,
                          -4.073
                        ],
                        [
                          7.328,
                          -3.533
                        ],
                        [
                          3.798,
                          -3.533
                        ],
                        [
                          0.278,
                          -0.123
                        ],
                        [
                          0.268,
                          -0.003
                        ],
                        [
                          3.678,
                          3.527
                        ],
                        [
                          3.798,
                          3.527
                        ],
                        [
                          7.328,
                          3.527
                        ],
                        [
                          7.328,
                          4.077
                        ],
                        [
                          5.698,
                          5.707
                        ],
                        [
                          -5.702,
                          5.707
                        ],
                        [
                          -7.332,
                          4.077
                        ],
                        [
                          -7.332,
                          -4.073
                        ],
                        [
                          -5.702,
                          -5.703
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.2
                        ],
                        [
                          -1.2,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.2,
                          0
                        ],
                        [
                          0,
                          -1.2
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          7.328,
                          -2.172
                        ],
                        [
                          7.328,
                          2.168
                        ],
                        [
                          3.528,
                          2.168
                        ],
                        [
                          1.358,
                          -0.002
                        ],
                        [
                          3.528,
                          -2.172
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.61960786581,
                      0.662745118141,
                      0.745098054409,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      15.997,
                      15.997
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_8',
      'nm': '▽ 3',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          'nm': 'omnibtc.png',
          'cl': 'png',
          'refId': 'image_1',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                12.553,
                8.247,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                100,
                100,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                10,
                10,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ 编组 89',
          'refId': 'comp_9',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                12.555,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 5,
          'nm': 'Finance',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                12.5,
                22.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      25,
                      9
                    ],
                    "ps": [
                      -12.5,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'DMens',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.62,
                      0.663,
                      0.745
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 0,
          'nm': '▽ buttom_me_p',
          'refId': 'comp_10',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                112.482,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 5,
          'nm': 'Me',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                112.482,
                22.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      10,
                      9
                    ],
                    "ps": [
                      -5,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'Me',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.62,
                      0.663,
                      0.745
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 0,
          'nm': '▽ buttom_meta_n',
          'refId': 'comp_11',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                62.491,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 5,
          'nm': 'Discover',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                62.491,
                22.497,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      28,
                      9
                    ],
                    "ps": [
                      -14,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'Discover',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.62,
                      0.663,
                      0.745
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_9',
      'nm': '▽ 编组 89',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      15.997,
                      15.997
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_10',
      'nm': '▽ buttom_me_p',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -3.22,
                          0
                        ],
                        [
                          -0.52,
                          -3.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.01,
                          -0.04
                        ],
                        [
                          0.4,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.05,
                          0.3
                        ]
                      ],
                      "o": [
                        [
                          3.16,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.03
                        ],
                        [
                          0.04,
                          0.4
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.31
                        ],
                        [
                          0.44,
                          -3.09
                        ]
                      ],
                      "v": [
                        [
                          0.024,
                          0.799
                        ],
                        [
                          6.324,
                          6.099
                        ],
                        [
                          6.354,
                          6.279
                        ],
                        [
                          6.364,
                          6.389
                        ],
                        [
                          5.714,
                          7.189
                        ],
                        [
                          5.684,
                          7.189
                        ],
                        [
                          -6.366,
                          7.189
                        ],
                        [
                          -6.296,
                          6.269
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.76,
                          0
                        ],
                        [
                          0,
                          -1.76
                        ],
                        [
                          1.77,
                          0
                        ],
                        [
                          0,
                          1.77
                        ]
                      ],
                      "o": [
                        [
                          1.77,
                          0
                        ],
                        [
                          0,
                          1.77
                        ],
                        [
                          -1.76,
                          0
                        ],
                        [
                          0,
                          -1.76
                        ]
                      ],
                      "v": [
                        [
                          0.064,
                          -7.188
                        ],
                        [
                          3.264,
                          -3.998
                        ],
                        [
                          0.064,
                          -0.798
                        ],
                        [
                          -3.126,
                          -3.998
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.61960786581,
                      0.662745118141,
                      0.745098054409,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      15.997,
                      15.997
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_11',
      'nm': '▽ buttom_meta_n',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.05,
                          0
                        ],
                        [
                          0,
                          -4.05
                        ],
                        [
                          4.05,
                          0
                        ],
                        [
                          0,
                          4.05
                        ]
                      ],
                      "o": [
                        [
                          4.05,
                          0
                        ],
                        [
                          0,
                          4.05
                        ],
                        [
                          -4.05,
                          0
                        ],
                        [
                          0,
                          -4.05
                        ]
                      ],
                      "v": [
                        [
                          -0.002,
                          -7.332
                        ],
                        [
                          7.328,
                          -0.002
                        ],
                        [
                          -0.002,
                          7.328
                        ],
                        [
                          -7.332,
                          -0.002
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.16,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.16,
                          -0.46
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.38,
                          -0.14
                        ],
                        [
                          -0.16,
                          0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.16,
                          0.46
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.38,
                          0.14
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.46,
                          0.16
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          0.38
                        ],
                        [
                          0.17,
                          0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.46,
                          -0.16
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.13,
                          -0.38
                        ],
                        [
                          -0.17,
                          -0.05
                        ]
                      ],
                      "v": [
                        [
                          2.565,
                          -3.505
                        ],
                        [
                          -1.165,
                          -2.155
                        ],
                        [
                          -2.155,
                          -1.165
                        ],
                        [
                          -3.505,
                          2.565
                        ],
                        [
                          -3.065,
                          3.505
                        ],
                        [
                          -2.565,
                          3.505
                        ],
                        [
                          1.165,
                          2.155
                        ],
                        [
                          2.155,
                          1.165
                        ],
                        [
                          3.505,
                          -2.565
                        ],
                        [
                          3.065,
                          -3.505
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.51,
                          0
                        ],
                        [
                          0,
                          0.5
                        ],
                        [
                          0.47,
                          0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.51
                        ]
                      ],
                      "o": [
                        [
                          0.5,
                          0
                        ],
                        [
                          0,
                          -0.48
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.51,
                          0
                        ],
                        [
                          0,
                          0.5
                        ]
                      ],
                      "v": [
                        [
                          0.003,
                          0.913
                        ],
                        [
                          0.913,
                          0.003
                        ],
                        [
                          0.083,
                          -0.917
                        ],
                        [
                          0.003,
                          -0.917
                        ],
                        [
                          -0.917,
                          0.003
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.61960786581,
                      0.662745118141,
                      0.745098054409,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 5,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      15.997,
                      15.997
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.188235297799,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_12',
      'nm': '▽ 1',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ buttom_chat_n',
          'refId': 'comp_13',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                15.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'Chat',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                30.496,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      15,
                      9
                    ],
                    "ps": [
                      -7.5,
                      -4.5
                    ],
                    "s": 6.66547012329102,
                    'f': 'PingFangSC-Semibold',
                    't': 'Chat',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 7.99856424331665,
                    "ls": 0,
                    "fc": [
                      0.188,
                      0.114,
                      0.961
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                24.995,
                19.996,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      49.991,
                      39.993
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_13',
      'nm': '▽ buttom_chat_n',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.85
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.85,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.31,
                          0.29
                        ],
                        [
                          0,
                          0.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.85
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.85,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.85,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.85
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.29,
                          0.31
                        ],
                        [
                          -0.15,
                          -0.14
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.85,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.85
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          5.788,
                          -6.501
                        ],
                        [
                          7.328,
                          -4.961
                        ],
                        [
                          7.328,
                          1.989
                        ],
                        [
                          5.788,
                          3.529
                        ],
                        [
                          -0.642,
                          3.529
                        ],
                        [
                          -3.192,
                          6.259
                        ],
                        [
                          -4.282,
                          6.289
                        ],
                        [
                          -4.522,
                          5.729
                        ],
                        [
                          -4.522,
                          3.529
                        ],
                        [
                          -5.792,
                          3.529
                        ],
                        [
                          -7.332,
                          1.989
                        ],
                        [
                          -7.332,
                          -4.961
                        ],
                        [
                          -5.792,
                          -6.501
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          -0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.39,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.39,
                          0.05
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.4,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.4,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.4
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.929,
                          -3.027
                        ],
                        [
                          -1.931,
                          -3.027
                        ],
                        [
                          -2.701,
                          -2.337
                        ],
                        [
                          -2.701,
                          -2.257
                        ],
                        [
                          -2.701,
                          -0.707
                        ],
                        [
                          -2.011,
                          0.053
                        ],
                        [
                          -1.931,
                          0.062
                        ],
                        [
                          1.929,
                          0.062
                        ],
                        [
                          2.699,
                          -0.627
                        ],
                        [
                          2.699,
                          -0.707
                        ],
                        [
                          2.699,
                          -2.257
                        ],
                        [
                          2.009,
                          -3.027
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.188235297799,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      15.997,
                      15.997
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_14',
      'nm': '▽ wallet_home',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 81',
          'refId': 'comp_15',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 80,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 85,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                68.321,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                19,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 38,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ 编组 49',
          'refId': 'comp_19',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.044,
                    "y": 0.955
                  },
                  "o": {
                    "x": 0.941,
                    "y": 0
                  },
                  "t": 25,
                  "s": [
                    124.978,
                    215.461,
                    0
                  ],
                  "to": [
                    0,
                    -24.667,
                    0
                  ],
                  "ti": [
                    0,
                    25.124,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.652,
                    "y": 1
                  },
                  "o": {
                    "x": 0.318,
                    "y": 0.157
                  },
                  "t": 39,
                  "s": [
                    124.978,
                    68,
                    0
                  ],
                  "to": [
                    0,
                    -0.078,
                    0
                  ],
                  "ti": [
                    0,
                    0.077,
                    0
                  ]
                },
                {
                  "t": 40,
                  "s": [
                    124.978,
                    67,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                19,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 38,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': '▽ 编组 70',
          'refId': 'comp_23',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.899
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 25,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 40,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0,
                    "y": 1
                  },
                  "o": {
                    "x": 0.899,
                    "y": 0
                  },
                  "t": 25,
                  "s": [
                    124.978,
                    -25.338,
                    0
                  ],
                  "to": [
                    0,
                    8.5,
                    0
                  ],
                  "ti": [
                    0,
                    -8.5,
                    0
                  ]
                },
                {
                  "t": 40,
                  "s": [
                    124.978,
                    25.662,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                25.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 51,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 0,
          'nm': 'token2',
          "parent": 2,
          'refId': 'comp_27',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.095,
                    "y": 1
                  },
                  "o": {
                    "x": 1,
                    "y": 0
                  },
                  "t": 80,
                  "s": [
                    125,
                    389.433,
                    0
                  ],
                  "to": [
                    -44,
                    0,
                    0
                  ],
                  "ti": [
                    44,
                    0,
                    0
                  ]
                },
                {
                  "t": 85,
                  "s": [
                    -139,
                    389.433,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                85.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 171,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 0,
          'nm': 'nft1',
          'refId': 'comp_37',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.095,
                    "y": 1
                  },
                  "o": {
                    "x": 1,
                    "y": 0
                  },
                  "t": 80,
                  "s": [
                    375.978,
                    213.628,
                    0
                  ],
                  "to": [
                    -41.833,
                    0,
                    0
                  ],
                  "ti": [
                    41.833,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0.333
                  },
                  "t": 85,
                  "s": [
                    124.978,
                    213.628,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 155,
                  "s": [
                    124.978,
                    213.628,
                    0
                  ],
                  "to": [
                    0,
                    -50,
                    0
                  ],
                  "ti": [
                    0,
                    50,
                    0
                  ]
                },
                {
                  "t": 175,
                  "s": [
                    124.978,
                    -86.372,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                109.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 219,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 0,
          'nm': 'nft2',
          'refId': 'comp_51',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.715
                    ],
                    "y": [
                      -7.455
                    ]
                  },
                  "o": {
                    "x": [
                      0.384
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 110,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.908
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.618
                    ],
                    "y": [
                      0.275
                    ]
                  },
                  "t": 112,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 115,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.095,
                    "y": 1
                  },
                  "o": {
                    "x": 1,
                    "y": 0
                  },
                  "t": 80,
                  "s": [
                    375.704,
                    328.046,
                    0
                  ],
                  "to": [
                    -41.833,
                    0,
                    0
                  ],
                  "ti": [
                    41.833,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.686,
                    "y": 1
                  },
                  "o": {
                    "x": 0.274,
                    "y": 0
                  },
                  "t": 85,
                  "s": [
                    124.704,
                    328.046,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.75,
                    "y": 1
                  },
                  "o": {
                    "x": 1,
                    "y": 0
                  },
                  "t": 110,
                  "s": [
                    124.704,
                    305.046,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0.333
                  },
                  "t": 115,
                  "s": [
                    124.704,
                    328.046,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 155,
                  "s": [
                    124.704,
                    328.046,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "t": 175,
                  "s": [
                    124.704,
                    28.046,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                114.226,
                -0.177,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      1,
                      1,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 110,
                  "s": [
                    100,
                    0,
                    100
                  ]
                },
                {
                  "t": 115,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 229,
          "h": 576,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 0,
          'nm': 'token1',
          "parent": 2,
          'refId': 'comp_60',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.095,
                    "y": 1
                  },
                  "o": {
                    "x": 1,
                    "y": 0
                  },
                  "t": 80,
                  "s": [
                    125,
                    176.138,
                    0
                  ],
                  "to": [
                    -44,
                    0,
                    0
                  ],
                  "ti": [
                    44,
                    0,
                    0
                  ]
                },
                {
                  "t": 85,
                  "s": [
                    -139,
                    176.138,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                128,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 256,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 0,
          'nm': '▽ 编组 32',
          "parent": 2,
          'refId': 'comp_75',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125.022,
                -98.146,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                98.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 197,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                270.951,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -270.951
                        ],
                        [
                          124.973,
                          -270.951
                        ],
                        [
                          124.973,
                          270.949
                        ],
                        [
                          -124.977,
                          270.949
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_15',
      'nm': '▽ 编组 81',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 84',
          'refId': 'comp_16',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                50.493,
                25.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                40,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 80,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径 3',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                37.327,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -0.003
                        ],
                        [
                          124.973,
                          -0.003
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.949019610882,
                      0.949019610882,
                      0.949019610882,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 3',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': '▽ 编组 82',
          'refId': 'comp_17',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                231.292,
                21.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                18.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      37.327
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_16',
      'nm': '▽ 编组 84',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': 'NFTs',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                66.157,
                10.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      15
                    ],
                    "ps": [
                      -13.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'NFTs',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'Tokens',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18.5,
                10.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      37,
                      15
                    ],
                    "ps": [
                      -18.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'Tokens',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                65.655,
                23.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -12.998,
                          -0.667
                        ],
                        [
                          13.002,
                          -0.667
                        ],
                        [
                          13.002,
                          0.663
                        ],
                        [
                          -12.998,
                          0.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_17',
      'nm': '▽ 编组 82',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 83',
          'refId': 'comp_18',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                8.318,
                8.581,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                6.5,
                5.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 13,
          "h": 11,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_18',
      'nm': '▽ 编组 83',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.932,
                7.678,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.39,
                          -0.39
                        ],
                        [
                          0.39,
                          -0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.08,
                          0.21
                        ],
                        [
                          -0.02,
                          0.07
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0.39,
                          -0.39
                        ],
                        [
                          0.39,
                          0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.21,
                          0.08
                        ],
                        [
                          -0.02,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.812,
                          1.412
                        ],
                        [
                          1.668,
                          -3.068
                        ],
                        [
                          3.078,
                          -3.068
                        ],
                        [
                          3.078,
                          -1.648
                        ],
                        [
                          -1.402,
                          2.822
                        ],
                        [
                          -2.832,
                          3.332
                        ],
                        [
                          -3.352,
                          3.092
                        ],
                        [
                          -3.362,
                          2.872
                        ],
                        [
                          -3.342,
                          2.802
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                5.666,
                0.27,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -5.666,
                          0
                        ],
                        [
                          5.664,
                          0
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 2',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '路径 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                2.5,
                9.601,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.5,
                          -0.003
                        ],
                        [
                          2.5,
                          -0.003
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 2',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '路径 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                3.713,
                4.936,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -3.713,
                          -0.003
                        ],
                        [
                          3.717,
                          -0.003
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 2',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_19',
      'nm': '▽ 编组 49',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 52',
          'refId': 'comp_20',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                50.493,
                25.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                40,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 80,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径 3',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                37.327,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -0.003
                        ],
                        [
                          124.973,
                          -0.003
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.949019610882,
                      0.949019610882,
                      0.949019610882,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 3',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': '▽ 编组 50',
          'refId': 'comp_21',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                231.292,
                21.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                18.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      37.327
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_20',
      'nm': '▽ 编组 52',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': 'NFTs',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                66.157,
                10.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      15
                    ],
                    "ps": [
                      -13.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'NFTs',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'Tokens',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18.5,
                10.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      37,
                      15
                    ],
                    "ps": [
                      -18.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'Tokens',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18.33,
                23.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -18.33,
                          -0.667
                        ],
                        [
                          18.33,
                          -0.667
                        ],
                        [
                          18.33,
                          0.663
                        ],
                        [
                          -18.33,
                          0.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_21',
      'nm': '▽ 编组 50',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 51',
          'refId': 'comp_22',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                8.318,
                8.581,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                6.5,
                5.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 13,
          "h": 11,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_22',
      'nm': '▽ 编组 51',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.932,
                7.678,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.39,
                          -0.39
                        ],
                        [
                          0.39,
                          -0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.08,
                          0.21
                        ],
                        [
                          -0.02,
                          0.07
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0.39,
                          -0.39
                        ],
                        [
                          0.39,
                          0.39
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.21,
                          0.08
                        ],
                        [
                          -0.02,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.812,
                          1.412
                        ],
                        [
                          1.668,
                          -3.068
                        ],
                        [
                          3.078,
                          -3.068
                        ],
                        [
                          3.078,
                          -1.648
                        ],
                        [
                          -1.402,
                          2.822
                        ],
                        [
                          -2.832,
                          3.332
                        ],
                        [
                          -3.352,
                          3.092
                        ],
                        [
                          -3.362,
                          2.872
                        ],
                        [
                          -3.342,
                          2.802
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                5.666,
                0.27,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -5.666,
                          0
                        ],
                        [
                          5.664,
                          0
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 2',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '路径 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                2.5,
                9.601,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.5,
                          -0.003
                        ],
                        [
                          2.5,
                          -0.003
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 2',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '路径 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                3.713,
                4.936,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -3.713,
                          -0.003
                        ],
                        [
                          3.717,
                          -0.003
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径 2',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_23',
      'nm': '▽ 编组 70',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 72',
          'refId': 'comp_24',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                20.663,
                31.994,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                6.5,
                6,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 13,
          "h": 12,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ icon_search 5',
          'refId': 'comp_25',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                229.069,
                31.994,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                10,
                10,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 20,
          "h": 20,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': '▽ nav 3',
          'refId': 'comp_26',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                31.994,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                18.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 37,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                25.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      50.658
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_24',
      'nm': '▽ 编组 72',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.332,
                5.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                90,
                90,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      1.333,
                      1.333
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.998,
                5.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                90,
                90,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      6.665,
                      3.999
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 2,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                6.665,
                5.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                90,
                90,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      13.331,
                      11.998
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_25',
      'nm': '▽ icon_search 5',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': 'Path 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                8.549,
                8.549,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          2.65,
                          0
                        ],
                        [
                          0,
                          2.65
                        ],
                        [
                          -2.65,
                          0
                        ],
                        [
                          0,
                          -2.65
                        ]
                      ],
                      "o": [
                        [
                          -2.65,
                          0
                        ],
                        [
                          0,
                          -2.65
                        ],
                        [
                          2.65,
                          0
                        ],
                        [
                          0,
                          2.65
                        ]
                      ],
                      "v": [
                        [
                          0.001,
                          4.801
                        ],
                        [
                          -4.799,
                          0.001
                        ],
                        [
                          0.001,
                          -4.799
                        ],
                        [
                          4.801,
                          0.001
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                13.948,
                13.948,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -1.8,
                          -1.8
                        ],
                        [
                          1.8,
                          1.8
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.775,
                9.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      19.55,
                      19.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.833,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_26',
      'nm': '▽ nav 3',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                18.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      37.327
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_27',
      'nm': '▽ 编组 53',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon_btc 4',
          'refId': 'comp_28',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                149.306,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'DOT',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                146.794,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'DOT',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 5,
          'nm': '＄1,558.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                158.823,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄1,558.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 5,
          'nm': '＄5958.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.964,
                158.823,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄5958.46',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 5,
          'nm': '0.345678',
          'cl': '345678',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.965,
                146.794,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.345678',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                149.306,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 0,
          'nm': '▽ icon_solana_network 2',
          'refId': 'comp_31',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                106.647,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 5,
          'nm': 'USDT',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                104.135,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'SOL',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 5,
          'nm': '＄6.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                116.164,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄6.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 5,
          'nm': '＄0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.628,
                116.164,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 5,
          'nm': '0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.628,
                104.135,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                106.647,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 0,
          'nm': '▽ icon_BUSD 2',
          'refId': 'comp_33',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                63.988,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 5,
          'nm': 'BUSD',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                61.476,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'BUSD',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 5,
          'nm': '＄6.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                73.505,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄6.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 5,
          'nm': '＄558.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.63,
                73.505,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄558.46',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 5,
          'nm': '0.1257',
          'cl': '1257',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.629,
                61.476,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.1257',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 18,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                63.988,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 19,
          "ty": 0,
          'nm': '▽ icon_BNB',
          'refId': 'comp_35',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                21.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 20,
          "ty": 5,
          'nm': 'BNB',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                18.667,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'BNB',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 21,
          "ty": 5,
          'nm': '＄558.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42.659,
                30.846,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄558.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 22,
          "ty": 5,
          'nm': '＄0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.628,
                30.846,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 23,
          "ty": 5,
          'nm': '0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                238.628,
                18.817,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 24,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                21.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_28',
      'nm': '▽ icon_btc 4',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon_btc_31',
          'refId': 'comp_29',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.917,
                11.917,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_29',
      'nm': '▽ icon_btc_31',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ BTC 2',
          'refId': 'comp_30',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                12.079,
                12.079,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.917,
                11.917,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.833,
                      23.833
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_30',
      'nm': '▽ BTC 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.387,
                11.387,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.73,
                          0
                        ],
                        [
                          0,
                          -1.01
                        ],
                        [
                          1.72,
                          0
                        ],
                        [
                          0,
                          1
                        ]
                      ],
                      "o": [
                        [
                          1.72,
                          0
                        ],
                        [
                          0,
                          1
                        ],
                        [
                          -1.73,
                          0
                        ],
                        [
                          0,
                          -1.01
                        ]
                      ],
                      "v": [
                        [
                          0.004,
                          5.031
                        ],
                        [
                          3.124,
                          6.851
                        ],
                        [
                          0.004,
                          8.671
                        ],
                        [
                          -3.126,
                          6.851
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.86,
                          1.49
                        ],
                        [
                          -0.87,
                          -0.5
                        ],
                        [
                          0.87,
                          -1.5
                        ],
                        [
                          0.86,
                          0.5
                        ]
                      ],
                      "o": [
                        [
                          0.87,
                          -1.5
                        ],
                        [
                          0.87,
                          0.5
                        ],
                        [
                          -0.86,
                          1.49
                        ],
                        [
                          -0.87,
                          -0.5
                        ]
                      ],
                      "v": [
                        [
                          4.353,
                          2.518
                        ],
                        [
                          7.493,
                          0.718
                        ],
                        [
                          7.503,
                          4.338
                        ],
                        [
                          4.373,
                          6.128
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.87,
                          0.5
                        ],
                        [
                          -0.86,
                          -1.5
                        ],
                        [
                          0.87,
                          -0.5
                        ],
                        [
                          0.87,
                          1.49
                        ]
                      ],
                      "o": [
                        [
                          0.87,
                          -0.5
                        ],
                        [
                          0.87,
                          1.49
                        ],
                        [
                          -0.87,
                          0.51
                        ],
                        [
                          -0.86,
                          -1.5
                        ]
                      ],
                      "v": [
                        [
                          -7.49,
                          0.718
                        ],
                        [
                          -4.36,
                          2.518
                        ],
                        [
                          -4.37,
                          6.128
                        ],
                        [
                          -7.51,
                          4.338
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.86,
                          1.49
                        ],
                        [
                          -0.87,
                          -0.5
                        ],
                        [
                          0.87,
                          -1.5
                        ],
                        [
                          0.87,
                          0.51
                        ]
                      ],
                      "o": [
                        [
                          0.87,
                          -1.5
                        ],
                        [
                          0.87,
                          0.5
                        ],
                        [
                          -0.86,
                          1.49
                        ],
                        [
                          -0.87,
                          -0.5
                        ]
                      ],
                      "v": [
                        [
                          -7.51,
                          -4.33
                        ],
                        [
                          -4.37,
                          -6.13
                        ],
                        [
                          -4.36,
                          -2.51
                        ],
                        [
                          -7.49,
                          -0.72
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 4,
                  'ty': 'sh',
                  "ix": 5,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.87,
                          0.5
                        ],
                        [
                          -0.86,
                          -1.5
                        ],
                        [
                          0.87,
                          -0.5
                        ],
                        [
                          0.87,
                          1.49
                        ]
                      ],
                      "o": [
                        [
                          0.86,
                          -0.5
                        ],
                        [
                          0.87,
                          1.49
                        ],
                        [
                          -0.87,
                          0.5
                        ],
                        [
                          -0.86,
                          -1.5
                        ]
                      ],
                      "v": [
                        [
                          4.374,
                          -6.13
                        ],
                        [
                          7.504,
                          -4.33
                        ],
                        [
                          7.493,
                          -0.72
                        ],
                        [
                          4.354,
                          -2.51
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 5',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 5,
                  'ty': 'sh',
                  "ix": 6,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.73,
                          0
                        ],
                        [
                          0,
                          -1.01
                        ],
                        [
                          1.72,
                          0
                        ],
                        [
                          0,
                          1
                        ]
                      ],
                      "o": [
                        [
                          1.72,
                          0
                        ],
                        [
                          0,
                          1
                        ],
                        [
                          -1.73,
                          0
                        ],
                        [
                          0,
                          -1.01
                        ]
                      ],
                      "v": [
                        [
                          0.004,
                          -8.667
                        ],
                        [
                          3.124,
                          -6.847
                        ],
                        [
                          0.004,
                          -5.027
                        ],
                        [
                          -3.126,
                          -6.847
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 6',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 8,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.387,
                11.387,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -6.29,
                          0
                        ],
                        [
                          0,
                          -6.29
                        ],
                        [
                          6.29,
                          0
                        ],
                        [
                          0,
                          6.29
                        ]
                      ],
                      "o": [
                        [
                          6.29,
                          0
                        ],
                        [
                          0,
                          6.29
                        ],
                        [
                          -6.29,
                          0
                        ],
                        [
                          0,
                          -6.29
                        ]
                      ],
                      "v": [
                        [
                          0.003,
                          -11.387
                        ],
                        [
                          11.383,
                          0.003
                        ],
                        [
                          0.003,
                          11.383
                        ],
                        [
                          -11.387,
                          0.003
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.901960790157,
                      0,
                      0.478431373835,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.387,
                11.387,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      22.268,
                      22.268
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_31',
      'nm': '▽ icon_solana_network 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 56',
          'refId': 'comp_32',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.45,
                      23.45
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 11.725,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.831372559071,
                      0.847058832645,
                      0.878431379795,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_32',
      'nm': '▽ 编组 56',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.06,
                          0.03
                        ],
                        [
                          -0.07,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          -0.02
                        ],
                        [
                          -0.02,
                          -0.04
                        ],
                        [
                          0,
                          -0.05
                        ],
                        [
                          0.04,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.06,
                          -0.03
                        ],
                        [
                          0.07,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.04,
                          0.03
                        ],
                        [
                          0.02,
                          0.05
                        ],
                        [
                          0,
                          0.05
                        ],
                        [
                          -0.04,
                          0.04
                        ]
                      ],
                      "o": [
                        [
                          0.05,
                          -0.05
                        ],
                        [
                          0.06,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          0
                        ],
                        [
                          0.04,
                          0.03
                        ],
                        [
                          0.02,
                          0.05
                        ],
                        [
                          -0.01,
                          0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          0.05
                        ],
                        [
                          -0.07,
                          0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.05,
                          0
                        ],
                        [
                          -0.04,
                          -0.03
                        ],
                        [
                          -0.02,
                          -0.04
                        ],
                        [
                          0.01,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.778,
                          3.432
                        ],
                        [
                          -4.608,
                          3.312
                        ],
                        [
                          -4.408,
                          3.272
                        ],
                        [
                          7.012,
                          3.272
                        ],
                        [
                          7.152,
                          3.312
                        ],
                        [
                          7.242,
                          3.422
                        ],
                        [
                          7.262,
                          3.562
                        ],
                        [
                          7.192,
                          3.692
                        ],
                        [
                          4.772,
                          6.382
                        ],
                        [
                          4.612,
                          6.502
                        ],
                        [
                          4.412,
                          6.542
                        ],
                        [
                          -7.018,
                          6.542
                        ],
                        [
                          -7.158,
                          6.502
                        ],
                        [
                          -7.248,
                          6.392
                        ],
                        [
                          -7.268,
                          6.252
                        ],
                        [
                          -7.198,
                          6.122
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.01,
                          -0.05
                        ],
                        [
                          0.02,
                          -0.04
                        ],
                        [
                          0.04,
                          -0.03
                        ],
                        [
                          0.04,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.06,
                          0.03
                        ],
                        [
                          0.04,
                          0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.01,
                          0.05
                        ],
                        [
                          -0.02,
                          0.04
                        ],
                        [
                          -0.04,
                          0.03
                        ],
                        [
                          -0.05,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.06,
                          -0.02
                        ],
                        [
                          -0.05,
                          -0.05
                        ]
                      ],
                      "o": [
                        [
                          0.03,
                          0.04
                        ],
                        [
                          0.01,
                          0.05
                        ],
                        [
                          -0.02,
                          0.05
                        ],
                        [
                          -0.04,
                          0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          0
                        ],
                        [
                          -0.06,
                          -0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          -0.04
                        ],
                        [
                          0,
                          -0.05
                        ],
                        [
                          0.02,
                          -0.04
                        ],
                        [
                          0.04,
                          -0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.07,
                          0
                        ],
                        [
                          0.07,
                          0.03
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          7.208,
                          1.204
                        ],
                        [
                          7.268,
                          1.334
                        ],
                        [
                          7.248,
                          1.474
                        ],
                        [
                          7.158,
                          1.584
                        ],
                        [
                          7.028,
                          1.624
                        ],
                        [
                          -4.402,
                          1.634
                        ],
                        [
                          -4.602,
                          1.594
                        ],
                        [
                          -4.762,
                          1.474
                        ],
                        [
                          -7.202,
                          -1.206
                        ],
                        [
                          -7.272,
                          -1.336
                        ],
                        [
                          -7.252,
                          -1.476
                        ],
                        [
                          -7.162,
                          -1.586
                        ],
                        [
                          -7.022,
                          -1.626
                        ],
                        [
                          4.398,
                          -1.636
                        ],
                        [
                          4.598,
                          -1.596
                        ],
                        [
                          4.768,
                          -1.476
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -0.06,
                          0.03
                        ],
                        [
                          -0.07,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          -0.03
                        ],
                        [
                          -0.02,
                          -0.04
                        ],
                        [
                          0.01,
                          -0.05
                        ],
                        [
                          0.03,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.06,
                          -0.03
                        ],
                        [
                          0.07,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.04,
                          0.03
                        ],
                        [
                          0.02,
                          0.05
                        ],
                        [
                          0,
                          0.05
                        ],
                        [
                          -0.04,
                          0.04
                        ]
                      ],
                      "o": [
                        [
                          0.05,
                          -0.05
                        ],
                        [
                          0.06,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          0
                        ],
                        [
                          0.04,
                          0.03
                        ],
                        [
                          0.01,
                          0.04
                        ],
                        [
                          -0.01,
                          0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          0.05
                        ],
                        [
                          -0.07,
                          0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.05,
                          0
                        ],
                        [
                          -0.04,
                          -0.03
                        ],
                        [
                          -0.02,
                          -0.04
                        ],
                        [
                          0.01,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.778,
                          -6.384
                        ],
                        [
                          -4.608,
                          -6.504
                        ],
                        [
                          -4.408,
                          -6.544
                        ],
                        [
                          7.022,
                          -6.534
                        ],
                        [
                          7.152,
                          -6.494
                        ],
                        [
                          7.252,
                          -6.384
                        ],
                        [
                          7.262,
                          -6.244
                        ],
                        [
                          7.202,
                          -6.114
                        ],
                        [
                          4.772,
                          -3.434
                        ],
                        [
                          4.612,
                          -3.314
                        ],
                        [
                          4.412,
                          -3.274
                        ],
                        [
                          -7.018,
                          -3.274
                        ],
                        [
                          -7.158,
                          -3.314
                        ],
                        [
                          -7.248,
                          -3.424
                        ],
                        [
                          -7.268,
                          -3.564
                        ],
                        [
                          -7.198,
                          -3.694
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.461727935193,
                      0.248985499962,
                      0.967371323529,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 5,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_33',
      'nm': '▽ icon_BUSD 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 55',
          'refId': 'comp_34',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_34',
      'nm': '▽ 编组 55',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.99,
                          -2.983
                        ],
                        [
                          0,
                          -7.973
                        ],
                        [
                          2,
                          -5.983
                        ],
                        [
                          -3,
                          -0.983
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.986,
                          2.988
                        ],
                        [
                          2.994,
                          -4.992
                        ],
                        [
                          4.984,
                          -3.002
                        ],
                        [
                          -2.996,
                          4.978
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          5.979,
                          -2.001
                        ],
                        [
                          -2.001,
                          5.979
                        ],
                        [
                          -0.011,
                          7.969
                        ],
                        [
                          7.969,
                          -0.011
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -7.973,
                          0.003
                        ],
                        [
                          -5.983,
                          -1.987
                        ],
                        [
                          -3.993,
                          0.003
                        ],
                        [
                          -5.983,
                          1.993
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.184313729405,
                      0.211764708161,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 6,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.45,
                      23.45
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.826672004251,
                      0.065042914596,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_35',
      'nm': '▽ icon_BNB',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 54',
          'refId': 'comp_36',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_36',
      'nm': '▽ 编组 54',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.232,
                          -5.533
                        ],
                        [
                          -0.002,
                          -7.973
                        ],
                        [
                          4.228,
                          -5.533
                        ],
                        [
                          2.678,
                          -4.633
                        ],
                        [
                          -0.002,
                          -6.173
                        ],
                        [
                          -2.672,
                          -4.633
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          4.228,
                          -2.453
                        ],
                        [
                          2.678,
                          -3.353
                        ],
                        [
                          -0.002,
                          -1.813
                        ],
                        [
                          -2.672,
                          -3.353
                        ],
                        [
                          -4.232,
                          -2.453
                        ],
                        [
                          -4.232,
                          -0.643
                        ],
                        [
                          -1.552,
                          0.897
                        ],
                        [
                          -1.552,
                          3.977
                        ],
                        [
                          -0.002,
                          4.877
                        ],
                        [
                          1.558,
                          3.977
                        ],
                        [
                          1.558,
                          0.897
                        ],
                        [
                          4.228,
                          -0.643
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          4.236,
                          2.43
                        ],
                        [
                          4.236,
                          0.63
                        ],
                        [
                          2.676,
                          1.53
                        ],
                        [
                          2.676,
                          3.34
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          5.34,
                          3.07
                        ],
                        [
                          2.66,
                          4.61
                        ],
                        [
                          2.66,
                          6.42
                        ],
                        [
                          6.89,
                          3.97
                        ],
                        [
                          6.89,
                          -0.91
                        ],
                        [
                          5.34,
                          -0.01
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 4,
                  'ty': 'sh',
                  "ix": 5,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.78,
                          -3.993
                        ],
                        [
                          5.34,
                          -3.093
                        ],
                        [
                          5.34,
                          -1.283
                        ],
                        [
                          6.89,
                          -2.183
                        ],
                        [
                          6.89,
                          -3.993
                        ],
                        [
                          5.34,
                          -4.893
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 5',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 5,
                  'ty': 'sh',
                  "ix": 6,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -1.556,
                          5.266
                        ],
                        [
                          -1.556,
                          7.066
                        ],
                        [
                          0.004,
                          7.976
                        ],
                        [
                          1.554,
                          7.066
                        ],
                        [
                          1.554,
                          5.266
                        ],
                        [
                          0.004,
                          6.166
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 6',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 6,
                  'ty': 'sh',
                  "ix": 7,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.232,
                          2.43
                        ],
                        [
                          -2.672,
                          3.34
                        ],
                        [
                          -2.672,
                          1.53
                        ],
                        [
                          -4.232,
                          0.63
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 7',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 7,
                  'ty': 'sh',
                  "ix": 8,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -1.556,
                          -3.993
                        ],
                        [
                          0.004,
                          -3.093
                        ],
                        [
                          1.554,
                          -3.993
                        ],
                        [
                          0.004,
                          -4.893
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 8',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 8,
                  'ty': 'sh',
                  "ix": 9,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -5.332,
                          -3.093
                        ],
                        [
                          -3.782,
                          -3.993
                        ],
                        [
                          -5.332,
                          -4.893
                        ],
                        [
                          -6.892,
                          -3.993
                        ],
                        [
                          -6.892,
                          -2.183
                        ],
                        [
                          -5.332,
                          -1.283
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 9',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 9,
                  'ty': 'sh',
                  "ix": 10,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -5.332,
                          -0.01
                        ],
                        [
                          -6.892,
                          -0.91
                        ],
                        [
                          -6.892,
                          3.97
                        ],
                        [
                          -2.662,
                          6.42
                        ],
                        [
                          -2.662,
                          4.61
                        ],
                        [
                          -5.332,
                          3.07
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 10',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 12,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.45,
                      23.45
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.759471998028,
                      0.065042914596,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_37',
      'nm': '▽ 编组 74',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon_drawer备份 5',
          'refId': 'comp_38',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.667
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 110,
                  "s": [
                    360
                  ]
                },
                {
                  "t": 115,
                  "s": [
                    450
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                231.292,
                199.964,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ 编组 75',
          'refId': 'comp_39',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                109.314,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                109.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 219,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_38',
      'nm': '▽ icon_drawer备份 5',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.664,
                          3.001
                        ],
                        [
                          -1.666,
                          0.001
                        ],
                        [
                          1.664,
                          -2.999
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.600000023842,
                      0.600000023842,
                      0.600000023842,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_39',
      'nm': '▽ 编组 75',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 79',
          'refId': 'comp_40',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125.644,
                10.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                115,
                10.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 230,
          "h": 21,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ 编组 76',
          'refId': 'comp_41',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                125.311,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                93.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 187,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_40',
      'nm': '▽ 编组 79',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': 'ChainX',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                207.382,
                12.499,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      31.75,
                      11
                    ],
                    "ps": [
                      -14,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': 'Polygon',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                208.296,
                10.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -21.663,
                          -10.665
                        ],
                        [
                          21.667,
                          -10.665
                        ],
                        [
                          21.667,
                          10.665
                        ],
                        [
                          -21.663,
                          10.665
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 5.332,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 5,
          'nm': 'SherpaX',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                111.982,
                12.499,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      37.875,
                      11
                    ],
                    "ps": [
                      -16,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': 'Ethereum',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                113.979,
                10.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -23.996,
                          -10.665
                        ],
                        [
                          23.994,
                          -10.665
                        ],
                        [
                          23.994,
                          10.665
                        ],
                        [
                          -23.996,
                          10.665
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 5.332,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 5,
          'nm': 'MiniX',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                164.306,
                12.499,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      22,
                      11
                    ],
                    "ps": [
                      -11,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': 'BSC',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                162.304,
                10.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -18.997,
                          -10.665
                        ],
                        [
                          18.993,
                          -10.665
                        ],
                        [
                          18.993,
                          10.665
                        ],
                        [
                          -18.997,
                          10.665
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 5.332,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 5,
          'nm': 'ChainX',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                65.324,
                12.499,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      28,
                      11
                    ],
                    "ps": [
                      -14,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': 'Aptos',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                62.989,
                10.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -21.663,
                          -10.665
                        ],
                        [
                          21.667,
                          -10.665
                        ],
                        [
                          21.667,
                          10.665
                        ],
                        [
                          -21.663,
                          10.665
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 5.332,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 5,
          'nm': 'All',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                17.998,
                12.499,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      10,
                      11
                    ],
                    "ps": [
                      -5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': 'All',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.035,
                      0,
                      0.4
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                17.997,
                10.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -17.997,
                          -10.665
                        ],
                        [
                          17.993,
                          -10.665
                        ],
                        [
                          17.993,
                          10.665
                        ],
                        [
                          -17.997,
                          10.665
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 5.332,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.86274510622,
                      0.854901969433,
                      0.988235294819,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_41',
      'nm': '▽ 编组 76',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          'nm': 'Bored Ape Yacht Club',
          'refId': 'image_2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                167.97,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                418.5,
                418.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                2.87,
                2.87,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'Bored Ape Yacht Club',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                92.159,
                170.804,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      99,
                      13
                    ],
                    "ps": [
                      -49.5,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Medium',
                    't': 'Bored Ape Yacht Club',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 5,
          'nm': '12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                211.963,
                170.804,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      10,
                      13
                    ],
                    "ps": [
                      -5,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Semibold',
                    't': '12',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                167.97,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -18.663
                        ],
                        [
                          124.973,
                          -18.663
                        ],
                        [
                          124.973,
                          18.667
                        ],
                        [
                          -124.977,
                          18.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 0,
          'nm': '▽ 椭圆形 8',
          'refId': 'comp_42',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                130.643,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 5,
          'nm': 'CryptoPunks',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                71.159,
                133.478,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      57,
                      13
                    ],
                    "ps": [
                      -28.5,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Medium',
                    't': 'CryptoPunks',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 0,
          'nm': '▽ icon_drawer备份 4',
          'refId': 'comp_43',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                231.292,
                130.643,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 5,
          'nm': '1',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                214.961,
                133.478,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      4,
                      13
                    ],
                    "ps": [
                      -2,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Semibold',
                    't': '1',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                130.643,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -18.663
                        ],
                        [
                          124.973,
                          -18.663
                        ],
                        [
                          124.973,
                          18.667
                        ],
                        [
                          -124.977,
                          18.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 0,
          'nm': '▽ 编组 77',
          'refId': 'comp_44',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                93.317,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 5,
          'nm': 'Future is coming',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                80.159,
                96.151,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      75,
                      13
                    ],
                    "ps": [
                      -37.5,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Medium',
                    't': 'Future is coming',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 0,
          'nm': '▽ icon_drawer备份 3',
          'refId': 'comp_46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                231.292,
                93.317,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 5,
          'nm': '3',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                213.962,
                96.151,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      6,
                      13
                    ],
                    "ps": [
                      -3,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Semibold',
                    't': '3',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                93.317,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -18.663
                        ],
                        [
                          124.973,
                          -18.663
                        ],
                        [
                          124.973,
                          18.667
                        ],
                        [
                          -124.977,
                          18.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 0,
          'nm': '▽ 椭圆形 7',
          'refId': 'comp_47',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                55.99,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 5,
          'nm': 'Cool Cats NFT',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                75.159,
                58.824,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      65,
                      13
                    ],
                    "ps": [
                      -32.5,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Medium',
                    't': 'Cool Cats NFT',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 0,
          'nm': '▽ icon_drawer备份 2',
          'refId': 'comp_48',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                231.292,
                55.99,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 18,
          "ty": 5,
          'nm': '10',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                211.963,
                58.824,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      10,
                      13
                    ],
                    "ps": [
                      -5,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Semibold',
                    't': '10',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 19,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                55.99,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -18.663
                        ],
                        [
                          124.973,
                          -18.663
                        ],
                        [
                          124.973,
                          18.667
                        ],
                        [
                          -124.977,
                          18.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 20,
          "ty": 0,
          'nm': '▽ 椭圆形 6',
          'refId': 'comp_49',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                18.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 21,
          "ty": 5,
          'nm': 'Pudgy Penguins',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                78.659,
                21.498,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      72,
                      13
                    ],
                    "ps": [
                      -36,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Medium',
                    't': 'Pudgy Penguins',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 22,
          "ty": 0,
          'nm': '▽ icon_drawer备份',
          'refId': 'comp_50',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                231.292,
                18.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                8,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 16,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 23,
          "ty": 5,
          'nm': '2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                213.962,
                21.498,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      6,
                      13
                    ],
                    "ps": [
                      -3,
                      -6.5
                    ],
                    "s": 9.33164978027344,
                    'f': 'PingFangSC-Semibold',
                    't': '2',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 11.197979927063,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 24,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                18.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -18.663
                        ],
                        [
                          124.973,
                          -18.663
                        ],
                        [
                          124.973,
                          18.667
                        ],
                        [
                          -124.977,
                          18.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_42',
      'nm': '▽ 椭圆形 8',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 椭圆形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '椭圆形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_3',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                540,
                540,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                2.222,
                2.222,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_43',
      'nm': '▽ icon_drawer备份 4',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.664,
                          3.001
                        ],
                        [
                          -1.666,
                          0.001
                        ],
                        [
                          1.664,
                          -2.999
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.600000023842,
                      0.600000023842,
                      0.600000023842,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_44',
      'nm': '▽ 编组 77',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 78',
          'refId': 'comp_45',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                12.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                7.5,
                7.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 15,
          "h": 15,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.176470592618,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_45',
      'nm': '▽ 编组 78',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.252,
                6.22,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.38,
                          0
                        ],
                        [
                          -0.46,
                          -0.58
                        ],
                        [
                          -0.01,
                          -0.01
                        ],
                        [
                          0.12,
                          -0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.16,
                          -0.09
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.13,
                          0.13
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.29,
                          0
                        ],
                        [
                          0,
                          -0.62
                        ],
                        [
                          -0.62,
                          0
                        ],
                        [
                          -0.2,
                          0.24
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          -0.07
                        ],
                        [
                          -0.01,
                          -0.01
                        ],
                        [
                          -0.3,
                          -0.17
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.07,
                          -0.12
                        ],
                        [
                          0,
                          -0.01
                        ],
                        [
                          0.01,
                          0
                        ],
                        [
                          0.84,
                          0
                        ],
                        [
                          0,
                          1.39
                        ]
                      ],
                      "o": [
                        [
                          0.8,
                          0
                        ],
                        [
                          0,
                          0.01
                        ],
                        [
                          0.08,
                          0.11
                        ],
                        [
                          -0.01,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.28,
                          0.16
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.16,
                          0.09
                        ],
                        [
                          -0.01,
                          -0.01
                        ],
                        [
                          -0.2,
                          -0.18
                        ],
                        [
                          -0.62,
                          0
                        ],
                        [
                          0,
                          0.63
                        ],
                        [
                          0.34,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          -0.11
                        ],
                        [
                          0.01,
                          0.01
                        ],
                        [
                          0.16,
                          0.09
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.12,
                          0.07
                        ],
                        [
                          0,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.45,
                          0.63
                        ],
                        [
                          -1.38,
                          0
                        ],
                        [
                          0,
                          -1.38
                        ]
                      ],
                      "v": [
                        [
                          0.207,
                          -2.504
                        ],
                        [
                          2.187,
                          -1.544
                        ],
                        [
                          2.207,
                          -1.514
                        ],
                        [
                          2.147,
                          -1.154
                        ],
                        [
                          2.127,
                          -1.134
                        ],
                        [
                          2.127,
                          -1.134
                        ],
                        [
                          1.477,
                          -0.764
                        ],
                        [
                          1.477,
                          -0.764
                        ],
                        [
                          0.977,
                          -0.824
                        ],
                        [
                          0.957,
                          -0.844
                        ],
                        [
                          0.207,
                          -1.124
                        ],
                        [
                          -0.913,
                          -0.004
                        ],
                        [
                          0.207,
                          1.126
                        ],
                        [
                          1.057,
                          0.736
                        ],
                        [
                          1.057,
                          0.736
                        ],
                        [
                          1.447,
                          0.676
                        ],
                        [
                          1.477,
                          0.696
                        ],
                        [
                          2.167,
                          1.086
                        ],
                        [
                          2.167,
                          1.086
                        ],
                        [
                          2.257,
                          1.436
                        ],
                        [
                          2.247,
                          1.466
                        ],
                        [
                          2.237,
                          1.466
                        ],
                        [
                          0.207,
                          2.506
                        ],
                        [
                          -2.293,
                          -0.004
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '形状结合备份',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.305,
                6.388,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.32,
                          0
                        ],
                        [
                          0,
                          -2.32
                        ],
                        [
                          1.92,
                          -0.4
                        ],
                        [
                          0.52,
                          -0.09
                        ],
                        [
                          0.92,
                          -0.15
                        ],
                        [
                          0.04,
                          0.04
                        ],
                        [
                          -0.07,
                          0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.11,
                          0.13
                        ],
                        [
                          0.15,
                          0.13
                        ],
                        [
                          0,
                          1.4
                        ]
                      ],
                      "o": [
                        [
                          2.33,
                          0
                        ],
                        [
                          0,
                          2.04
                        ],
                        [
                          -0.46,
                          0.12
                        ],
                        [
                          -0.48,
                          0.09
                        ],
                        [
                          -0.06,
                          0.01
                        ],
                        [
                          -0.08,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.12,
                          -0.12
                        ],
                        [
                          0.21,
                          -0.23
                        ],
                        [
                          -1.05,
                          -0.77
                        ],
                        [
                          0,
                          -2.32
                        ]
                      ],
                      "v": [
                        [
                          -0.004,
                          -4.499
                        ],
                        [
                          4.216,
                          -0.289
                        ],
                        [
                          0.856,
                          3.841
                        ],
                        [
                          -0.624,
                          4.151
                        ],
                        [
                          -2.724,
                          4.501
                        ],
                        [
                          -2.884,
                          4.451
                        ],
                        [
                          -2.894,
                          4.181
                        ],
                        [
                          -2.894,
                          4.181
                        ],
                        [
                          -2.784,
                          4.051
                        ],
                        [
                          -2.444,
                          3.671
                        ],
                        [
                          -2.484,
                          3.121
                        ],
                        [
                          -4.214,
                          -0.289
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.176470592618,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状结合备份',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.305,
                7.332,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.38,
                          0
                        ],
                        [
                          -1.99,
                          -1.02
                        ],
                        [
                          -0.14,
                          -0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.24
                        ],
                        [
                          0,
                          -0.09
                        ],
                        [
                          1.09,
                          -1.93
                        ],
                        [
                          2.03,
                          -1.3
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.33,
                          0.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.19,
                          2.12
                        ],
                        [
                          0.11,
                          2.33
                        ],
                        [
                          0,
                          0.25
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.2,
                          0.11
                        ],
                        [
                          -0.08,
                          0.04
                        ]
                      ],
                      "o": [
                        [
                          2.39,
                          0
                        ],
                        [
                          0.09,
                          0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.2,
                          0.11
                        ],
                        [
                          0,
                          0.22
                        ],
                        [
                          -0.1,
                          2.35
                        ],
                        [
                          -1.18,
                          2.11
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.33,
                          0.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -2.04,
                          -1.3
                        ],
                        [
                          -1.07,
                          -1.91
                        ],
                        [
                          0,
                          -0.1
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.24
                        ],
                        [
                          0.14,
                          -0.08
                        ],
                        [
                          1.99,
                          -1.02
                        ]
                      ],
                      "v": [
                        [
                          -0.005,
                          -7.332
                        ],
                        [
                          6.625,
                          -5.742
                        ],
                        [
                          6.975,
                          -5.562
                        ],
                        [
                          6.975,
                          -5.562
                        ],
                        [
                          7.305,
                          -4.992
                        ],
                        [
                          7.295,
                          -4.512
                        ],
                        [
                          5.445,
                          1.978
                        ],
                        [
                          0.545,
                          7.168
                        ],
                        [
                          0.535,
                          7.178
                        ],
                        [
                          0.535,
                          7.178
                        ],
                        [
                          -0.535,
                          7.178
                        ],
                        [
                          -0.535,
                          7.178
                        ],
                        [
                          -5.465,
                          1.958
                        ],
                        [
                          -7.295,
                          -4.462
                        ],
                        [
                          -7.305,
                          -4.992
                        ],
                        [
                          -7.305,
                          -4.992
                        ],
                        [
                          -6.975,
                          -5.562
                        ],
                        [
                          -6.635,
                          -5.742
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_46',
      'nm': '▽ icon_drawer备份 3',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.664,
                          3.001
                        ],
                        [
                          -1.666,
                          0.001
                        ],
                        [
                          1.664,
                          -2.999
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.600000023842,
                      0.600000023842,
                      0.600000023842,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_47',
      'nm': '▽ 椭圆形 7',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 椭圆形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '椭圆形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_4',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                540,
                540,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                2.222,
                2.222,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_48',
      'nm': '▽ icon_drawer备份 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.664,
                          3.001
                        ],
                        [
                          -1.666,
                          0.001
                        ],
                        [
                          1.664,
                          -2.999
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.600000023842,
                      0.600000023842,
                      0.600000023842,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_49',
      'nm': '▽ 椭圆形 6',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 椭圆形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '椭圆形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_5',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                500,
                500,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                2.4,
                2.4,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_50',
      'nm': '▽ icon_drawer备份',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.999,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.664,
                          3.001
                        ],
                        [
                          -1.666,
                          0.001
                        ],
                        [
                          1.664,
                          -2.999
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.600000023842,
                      0.600000023842,
                      0.600000023842,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_51',
      'nm': '▽ 编组 80',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 矩形 12',
          'refId': 'comp_52',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                493.245,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'BoredCryptoAp #1',
          'ln': '1',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                160.477,
                564.066,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      69,
                      11
                    ],
                    "ps": [
                      -34.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': 'BoredCryptoAp #1',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                506.909,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 0,
          'nm': '▽ 矩形 11',
          'refId': 'comp_53',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                493.245,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 5,
          'nm': '# 9059',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                21.499,
                564.066,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      11
                    ],
                    "ps": [
                      -13.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': '# 9059',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                506.909,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 0,
          'nm': '▽ 矩形 10',
          'refId': 'comp_54',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                347.271,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 5,
          'nm': '# 5823',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                139.477,
                418.093,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      11
                    ],
                    "ps": [
                      -13.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': '# 5823',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                360.935,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 0,
          'nm': '▽ 矩形 9',
          'refId': 'comp_55',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                347.271,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 5,
          'nm': '# 3298',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                21.499,
                418.093,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      11
                    ],
                    "ps": [
                      -13.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': '# 3298',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                360.935,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 0,
          'nm': '▽ 矩形 8',
          'refId': 'comp_56',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                201.297,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 5,
          'nm': '# 956',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                136.977,
                272.119,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      22,
                      11
                    ],
                    "ps": [
                      -11,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': '# 956',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                214.961,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 0,
          'nm': '▽ 矩形 7',
          'refId': 'comp_57',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                201.297,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 5,
          'nm': '# 3832',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                21.499,
                272.119,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      11
                    ],
                    "ps": [
                      -13.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': '# 3832',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 18,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                214.961,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 19,
          "ty": 0,
          'nm': '▽ 矩形 6',
          'refId': 'comp_58',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 20,
          "ty": 5,
          'nm': '# 4589',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                139.477,
                126.145,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      11
                    ],
                    "ps": [
                      -13.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': '# 4589',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 21,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                173.302,
                68.988,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 22,
          "ty": 0,
          'nm': '▽ 矩形 5',
          'refId': 'comp_59',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                55.5,
                55.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 111,
          "h": 111,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 23,
          "ty": 5,
          'nm': '# 1223',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                20.999,
                126.145,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      26,
                      11
                    ],
                    "ps": [
                      -13,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Medium',
                    't': '# 1223',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 24,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                68.988,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -68.988
                        ],
                        [
                          55.327,
                          -68.988
                        ],
                        [
                          55.327,
                          68.992
                        ],
                        [
                          -55.323,
                          68.992
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.92549020052,
                      0.92549020052,
                      0.92549020052,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_52',
      'nm': '▽ 矩形 12',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_6',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_53',
      'nm': '▽ 矩形 11',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_7',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_54',
      'nm': '▽ 矩形 10',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_8',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_55',
      'nm': '▽ 矩形 9',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_9',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_56',
      'nm': '▽ 矩形 8',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_10',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_57',
      'nm': '▽ 矩形 7',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_11',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_58',
      'nm': '▽ 矩形 6',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_12',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_59',
      'nm': '▽ 矩形 5',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_13',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                315.5,
                315.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                17.535,
                17.535,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 225,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.323,
                55.323,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -55.323,
                          -55.323
                        ],
                        [
                          55.327,
                          -55.323
                        ],
                        [
                          55.327,
                          55.327
                        ],
                        [
                          -55.323,
                          55.327
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 7.999,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_60',
      'nm': '▽ 编组 44',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon_btc 3',
          'refId': 'comp_61',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                234.624,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ icon_USDC',
          'refId': 'comp_64',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                191.965,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 5,
          'nm': 'Multichain',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                101.485,
                187.634,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      35,
                      10
                    ],
                    "ps": [
                      -17.5,
                      -5
                    ],
                    "s": 7.33201026916504,
                    'f': 'PingFangSC-Regular',
                    't': 'Multichain',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 8.79841232299805,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                101.648,
                185.967,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -20.33,
                          -5.332
                        ],
                        [
                          20.33,
                          -5.332
                        ],
                        [
                          20.33,
                          5.328
                        ],
                        [
                          -20.33,
                          5.328
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 2.666,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 5,
          'nm': 'USDC',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                58.159,
                189.135,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      31,
                      15
                    ],
                    "ps": [
                      -15.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'USDC',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 5,
          'nm': '＄8.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.159,
                200.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      25,
                      11
                    ],
                    "ps": [
                      -12.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄8.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 5,
          'nm': '＄5958.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                219.464,
                200.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      39,
                      11
                    ],
                    "ps": [
                      -19.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄5958.46',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 0,
          'nm': '▽ 编组 48',
          'refId': 'comp_66',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                140.812,
                234.459,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                98,
                13,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 196,
          "h": 26,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 5,
          'nm': '0.345678',
          'cl': '345678',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                214.965,
                189.135,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      48,
                      15
                    ],
                    "ps": [
                      -24,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.345678',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          'nm': 'bg_nav备份',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                234.624,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav备份',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                191.965,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 0,
          'nm': '▽ icon_btc 2',
          'refId': 'comp_67',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.556,
                149.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 5,
          'nm': 'Multichain',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94.819,
                144.975,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      35,
                      10
                    ],
                    "ps": [
                      -17.5,
                      -5
                    ],
                    "s": 7.33201026916504,
                    'f': 'PingFangSC-Regular',
                    't': 'Multichain',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 8.79841232299805,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94.983,
                143.308,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -20.33,
                          -5.332
                        ],
                        [
                          20.33,
                          -5.332
                        ],
                        [
                          20.33,
                          5.328
                        ],
                        [
                          -20.33,
                          5.328
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 2.666,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 5,
          'nm': 'ETH',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.659,
                146.476,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      22,
                      15
                    ],
                    "ps": [
                      -11,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'ETH',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 5,
          'nm': '＄1,558.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                62.659,
                157.806,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      40,
                      11
                    ],
                    "ps": [
                      -20,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄1,558.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 5,
          'nm': '＄5958.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                219.464,
                157.806,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      39,
                      11
                    ],
                    "ps": [
                      -19.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄5958.46',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 18,
          "ty": 5,
          'nm': '0.345678',
          'cl': '345678',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                214.965,
                146.476,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      48,
                      15
                    ],
                    "ps": [
                      -24,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.345678',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 19,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                149.306,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 20,
          "ty": 0,
          'nm': '▽ icon_btc',
          'refId': 'comp_69',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                106.647,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 21,
          "ty": 5,
          'nm': 'Multichain',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94.819,
                102.316,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      35,
                      10
                    ],
                    "ps": [
                      -17.5,
                      -5
                    ],
                    "s": 7.33201026916504,
                    'f': 'PingFangSC-Regular',
                    't': 'Multichain',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 8.79841232299805,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 22,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94.983,
                100.649,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -20.33,
                          -5.332
                        ],
                        [
                          20.33,
                          -5.332
                        ],
                        [
                          20.33,
                          5.328
                        ],
                        [
                          -20.33,
                          5.328
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 2.666,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 23,
          "ty": 5,
          'nm': 'USDT',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                57.159,
                103.817,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      29,
                      15
                    ],
                    "ps": [
                      -14.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'USDT',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 24,
          "ty": 5,
          'nm': '＄6.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.159,
                115.147,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      25,
                      11
                    ],
                    "ps": [
                      -12.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄6.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 25,
          "ty": 5,
          'nm': '＄0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                226.128,
                115.147,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      25,
                      11
                    ],
                    "ps": [
                      -12.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 26,
          "ty": 5,
          'nm': '0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                227.128,
                103.817,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      23,
                      15
                    ],
                    "ps": [
                      -11.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 27,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                106.647,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 28,
          "ty": 0,
          'nm': '▽ icon_BUSD',
          'refId': 'comp_71',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                63.988,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 29,
          "ty": 5,
          'nm': 'BUSD',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                57.659,
                61.158,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      30,
                      15
                    ],
                    "ps": [
                      -15,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'BUSD',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 30,
          "ty": 5,
          'nm': '＄6.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                55.159,
                72.488,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      25,
                      11
                    ],
                    "ps": [
                      -12.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄6.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 31,
          "ty": 5,
          'nm': '＄558.46',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                221.13,
                72.488,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      35,
                      11
                    ],
                    "ps": [
                      -17.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄558.46',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 32,
          "ty": 5,
          'nm': '0.1257',
          'cl': '1257',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                222.129,
                61.158,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      33,
                      15
                    ],
                    "ps": [
                      -16.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.1257',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 33,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                63.988,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 34,
          "ty": 0,
          'nm': '▽ icon_solana_network',
          'refId': 'comp_73',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                22.663,
                21.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 35,
          "ty": 5,
          'nm': 'APT',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.159,
                18.499,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      21,
                      15
                    ],
                    "ps": [
                      -10.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'APT',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 36,
          "ty": 5,
          'nm': '＄- -',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.159,
                29.829,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      21,
                      11
                    ],
                    "ps": [
                      -10.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄- -',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 37,
          "ty": 5,
          'nm': '＄0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                226.128,
                29.829,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      25,
                      11
                    ],
                    "ps": [
                      -12.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 38,
          "ty": 5,
          'nm': '0.00',
          'cl': '00',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                227.128,
                18.499,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      23,
                      15
                    ],
                    "ps": [
                      -11.5,
                      -7.5
                    ],
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.00',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 39,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                21.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -21.329
                        ],
                        [
                          124.973,
                          -21.329
                        ],
                        [
                          124.973,
                          21.331
                        ],
                        [
                          -124.977,
                          21.331
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_61',
      'nm': '▽ icon_btc 3',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon_btc_30',
          'refId': 'comp_62',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.917,
                11.917,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                12,
                12,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 24,
          "h": 24,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_62',
      'nm': '▽ icon_btc_30',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ BTC',
          'refId': 'comp_63',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                12.079,
                12.079,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.917,
                11.917,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.833,
                      23.833
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_63',
      'nm': '▽ BTC',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                10.868,
                11.306,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.26,
                          0.18
                        ],
                        [
                          0.44,
                          0.07
                        ],
                        [
                          0.37,
                          -0.26
                        ],
                        [
                          0.3,
                          -0.26
                        ],
                        [
                          1.21,
                          -0.74
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.41,
                          0.22
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.26,
                          -0.67
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.44,
                          -0.26
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.22
                        ],
                        [
                          -0.67,
                          -0.22
                        ],
                        [
                          0.52,
                          0.14
                        ],
                        [
                          0.34,
                          0.48
                        ],
                        [
                          -0.18,
                          0.47
                        ],
                        [
                          -1.29,
                          0.66
                        ],
                        [
                          -0.18,
                          0.47
                        ],
                        [
                          -0.14,
                          0.44
                        ],
                        [
                          -0.33,
                          0.3
                        ],
                        [
                          -0.4,
                          0.15
                        ]
                      ],
                      "o": [
                        [
                          -0.33,
                          -0.3
                        ],
                        [
                          -0.56,
                          -0.07
                        ],
                        [
                          -0.36,
                          0.26
                        ],
                        [
                          -0.29,
                          0.26
                        ],
                        [
                          -1.22,
                          0.74
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.44,
                          0.11
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.08,
                          0
                        ],
                        [
                          0.08,
                          0.52
                        ],
                        [
                          0.74,
                          0.48
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.7,
                          -0.22
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.51,
                          -0.23
                        ],
                        [
                          -0.29,
                          -0.4
                        ],
                        [
                          0.19,
                          -0.48
                        ],
                        [
                          1.51,
                          -0.78
                        ],
                        [
                          0.19,
                          -0.48
                        ],
                        [
                          0.15,
                          -0.4
                        ],
                        [
                          0.4,
                          -0.22
                        ],
                        [
                          0.07,
                          0.04
                        ]
                      ],
                      "v": [
                        [
                          7.842,
                          -4.817
                        ],
                        [
                          6.702,
                          -5.407
                        ],
                        [
                          5.222,
                          -4.857
                        ],
                        [
                          3.822,
                          -3.677
                        ],
                        [
                          1.582,
                          -2.537
                        ],
                        [
                          -4.348,
                          1.223
                        ],
                        [
                          -3.128,
                          1.223
                        ],
                        [
                          -8.618,
                          4.053
                        ],
                        [
                          -8.068,
                          4.053
                        ],
                        [
                          -8.838,
                          4.643
                        ],
                        [
                          -7.548,
                          4.463
                        ],
                        [
                          -7.548,
                          4.643
                        ],
                        [
                          0.222,
                          2.733
                        ],
                        [
                          -0.558,
                          2.953
                        ],
                        [
                          0.732,
                          3.023
                        ],
                        [
                          1.542,
                          4.273
                        ],
                        [
                          2.322,
                          5.053
                        ],
                        [
                          1.952,
                          5.413
                        ],
                        [
                          4.052,
                          5.413
                        ],
                        [
                          3.492,
                          5.053
                        ],
                        [
                          2.202,
                          4.013
                        ],
                        [
                          1.982,
                          2.623
                        ],
                        [
                          4.082,
                          1.223
                        ],
                        [
                          6.142,
                          -0.617
                        ],
                        [
                          6.842,
                          -2.427
                        ],
                        [
                          7.582,
                          -3.457
                        ],
                        [
                          8.832,
                          -4.007
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.387,
                11.387,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -6.29,
                          0
                        ],
                        [
                          0,
                          -6.29
                        ],
                        [
                          6.29,
                          0
                        ],
                        [
                          0,
                          6.29
                        ]
                      ],
                      "o": [
                        [
                          6.29,
                          0
                        ],
                        [
                          0,
                          6.29
                        ],
                        [
                          -6.29,
                          0
                        ],
                        [
                          0,
                          -6.29
                        ]
                      ],
                      "v": [
                        [
                          0.003,
                          -11.387
                        ],
                        [
                          11.383,
                          0.003
                        ],
                        [
                          0.003,
                          11.383
                        ],
                        [
                          -11.387,
                          0.003
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.133333340287,
                      0.133333340287,
                      0.133333340287,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.387,
                11.387,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      22.268,
                      22.268
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_64',
      'nm': '▽ icon_USDC',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon-USDCoin',
          'refId': 'comp_65',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.933,
                11.933,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_65',
      'nm': '▽ icon-USDCoin',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                17.125,
                11.787,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          3.54,
                          -1.13
                        ],
                        [
                          0,
                          0.27
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.19,
                          0.06
                        ],
                        [
                          0,
                          3.13
                        ],
                        [
                          2.8,
                          1.02
                        ],
                        [
                          0,
                          0.18
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.25,
                          -0.08
                        ],
                        [
                          0,
                          -3.9
                        ]
                      ],
                      "o": [
                        [
                          -0.26,
                          0.09
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.2
                        ],
                        [
                          2.79,
                          -1.02
                        ],
                        [
                          0,
                          -3.14
                        ],
                        [
                          -0.17,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.28
                        ],
                        [
                          3.54,
                          1.11
                        ],
                        [
                          0,
                          3.91
                        ]
                      ],
                      "v": [
                        [
                          -2.809,
                          8.322
                        ],
                        [
                          -3.279,
                          7.982
                        ],
                        [
                          -3.279,
                          7.292
                        ],
                        [
                          -2.969,
                          6.842
                        ],
                        [
                          1.821,
                          0.002
                        ],
                        [
                          -2.969,
                          -6.838
                        ],
                        [
                          -3.279,
                          -7.298
                        ],
                        [
                          -3.279,
                          -7.978
                        ],
                        [
                          -2.809,
                          -8.328
                        ],
                        [
                          3.281,
                          -0.008
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.607,
                11.778,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0.2,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.2
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.2,
                          1.23
                        ],
                        [
                          -0.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          -0.16
                        ],
                        [
                          -1.28,
                          0
                        ],
                        [
                          0,
                          0.78
                        ],
                        [
                          1.39,
                          0.22
                        ],
                        [
                          0,
                          1.59
                        ],
                        [
                          -1.44,
                          0.2
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.2,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.2
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.25,
                          -1.11
                        ],
                        [
                          0.22,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          0.16
                        ],
                        [
                          0.87,
                          0
                        ],
                        [
                          0,
                          -0.65
                        ],
                        [
                          -1.49,
                          -0.21
                        ],
                        [
                          0,
                          -1.7
                        ],
                        [
                          1.5,
                          -0.24
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.2
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.2,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.59,
                          -0.22
                        ],
                        [
                          -0.04,
                          -0.2
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.17,
                          0
                        ],
                        [
                          0.15,
                          0.72
                        ],
                        [
                          0.93,
                          0
                        ],
                        [
                          0,
                          -0.79
                        ],
                        [
                          -2.04,
                          -0.28
                        ],
                        [
                          0,
                          -1.23
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.2
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.2,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.18,
                          0.21
                        ],
                        [
                          0.04,
                          0.21
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.17,
                          0
                        ],
                        [
                          -0.21,
                          -0.7
                        ],
                        [
                          -0.97,
                          0
                        ],
                        [
                          0,
                          0.7
                        ],
                        [
                          2,
                          0.27
                        ],
                        [
                          0,
                          1.3
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.781,
                          5.47
                        ],
                        [
                          0.421,
                          5.83
                        ],
                        [
                          -0.309,
                          5.83
                        ],
                        [
                          -0.679,
                          5.47
                        ],
                        [
                          -0.679,
                          4.32
                        ],
                        [
                          -3.249,
                          1.99
                        ],
                        [
                          -2.919,
                          1.6
                        ],
                        [
                          -2.089,
                          1.6
                        ],
                        [
                          -1.729,
                          1.89
                        ],
                        [
                          0.121,
                          3.17
                        ],
                        [
                          1.731,
                          1.86
                        ],
                        [
                          -0.049,
                          0.55
                        ],
                        [
                          -3.049,
                          -1.94
                        ],
                        [
                          -0.679,
                          -4.34
                        ],
                        [
                          -0.679,
                          -5.47
                        ],
                        [
                          -0.309,
                          -5.83
                        ],
                        [
                          0.421,
                          -5.83
                        ],
                        [
                          0.781,
                          -5.47
                        ],
                        [
                          0.781,
                          -4.31
                        ],
                        [
                          2.951,
                          -2.32
                        ],
                        [
                          2.611,
                          -1.92
                        ],
                        [
                          1.851,
                          -1.92
                        ],
                        [
                          1.501,
                          -2.19
                        ],
                        [
                          -0.079,
                          -3.2
                        ],
                        [
                          -1.549,
                          -2.08
                        ],
                        [
                          0.221,
                          -0.83
                        ],
                        [
                          3.261,
                          1.72
                        ],
                        [
                          0.791,
                          4.3
                        ],
                        [
                          0.791,
                          5.47
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                6.194,
                11.778,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0.25,
                          0.08
                        ],
                        [
                          0,
                          3.9
                        ],
                        [
                          -3.54,
                          1.13
                        ],
                        [
                          0,
                          -0.28
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.17,
                          -0.07
                        ],
                        [
                          0,
                          -3.14
                        ],
                        [
                          -2.8,
                          -1.02
                        ],
                        [
                          0,
                          -0.18
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.27
                        ],
                        [
                          -3.54,
                          -1.13
                        ],
                        [
                          0,
                          -3.9
                        ],
                        [
                          0.26,
                          -0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.18
                        ],
                        [
                          -2.8,
                          1.03
                        ],
                        [
                          0,
                          3.14
                        ],
                        [
                          0.17,
                          0.06
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.281,
                          7.993
                        ],
                        [
                          2.811,
                          8.333
                        ],
                        [
                          -3.279,
                          0.003
                        ],
                        [
                          2.811,
                          -8.337
                        ],
                        [
                          3.281,
                          -7.987
                        ],
                        [
                          3.281,
                          -7.307
                        ],
                        [
                          2.971,
                          -6.847
                        ],
                        [
                          -1.819,
                          0.003
                        ],
                        [
                          2.971,
                          6.843
                        ],
                        [
                          3.281,
                          7.293
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.66,
                11.66,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.32,
                      23.32
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.152941182256,
                      0.458823531866,
                      0.792156875134,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_66',
      'nm': '▽ 编组 48',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': 'KSM',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                10.819,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'KSM',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': '＄1,558.46备份',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                22.847,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄1,558.46',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 5,
          'nm': '＄5958.46备份',
          'cl': '46',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                196.305,
                22.847,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': '＄5958.46',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 5,
          'nm': '0.345678备份',
          'cl': '345678',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                196.306,
                10.819,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Semibold',
                    't': '0.345678',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_67',
      'nm': '▽ icon_btc 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 47',
          'refId': 'comp_68',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.6,
                11.685,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                5,
                8,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 10,
          "h": 16,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.731,
                11.731,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          6.34,
                          0
                        ],
                        [
                          0,
                          6.34
                        ],
                        [
                          -6.33,
                          0
                        ],
                        [
                          0,
                          -6.33
                        ]
                      ],
                      "o": [
                        [
                          -6.33,
                          0
                        ],
                        [
                          0,
                          -6.33
                        ],
                        [
                          6.34,
                          0
                        ],
                        [
                          0,
                          6.34
                        ]
                      ],
                      "v": [
                        [
                          -0.005,
                          11.465
                        ],
                        [
                          -11.465,
                          -0.005
                        ],
                        [
                          -0.005,
                          -11.465
                        ],
                        [
                          11.465,
                          -0.005
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          6.16,
                          0
                        ],
                        [
                          0,
                          -6.16
                        ],
                        [
                          -6.16,
                          0
                        ],
                        [
                          0,
                          6.16
                        ]
                      ],
                      "o": [
                        [
                          -6.16,
                          0
                        ],
                        [
                          0,
                          6.16
                        ],
                        [
                          6.16,
                          0
                        ],
                        [
                          0,
                          -6.16
                        ]
                      ],
                      "v": [
                        [
                          0.002,
                          -11.178
                        ],
                        [
                          -11.178,
                          0.002
                        ],
                        [
                          0.002,
                          11.182
                        ],
                        [
                          11.182,
                          0.002
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.831372559071,
                      0.847058832645,
                      0.878431379795,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.731,
                11.731,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      22.929,
                      22.929
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.937254905701,
                      0.945098042488,
                      0.964705884457,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.731,
                11.731,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.462,
                      23.462
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_68',
      'nm': '▽ 编组 47',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.33,
                8.361,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          2.447,
                          -0.052
                        ],
                        [
                          -2.443,
                          -2.332
                        ],
                        [
                          -2.443,
                          2.328
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.250980407,
                      0.282352954149,
                      0.443137258291,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                2.443,
                8.361,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.443,
                          -0.052
                        ],
                        [
                          2.447,
                          -2.332
                        ],
                        [
                          2.447,
                          2.328
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.380392163992,
                      0.40000000596,
                      0.552941203117,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.33,
                12.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.443,
                          3.717
                        ],
                        [
                          -2.443,
                          -1.013
                        ],
                        [
                          2.447,
                          -3.713
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.368627458811,
                      0.392156869173,
                      0.552941203117,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                2.443,
                12.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          2.447,
                          3.717
                        ],
                        [
                          2.447,
                          -1.013
                        ],
                        [
                          -2.443,
                          -3.713
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.525490224361,
                      0.54509806633,
                      0.694117665291,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                7.33,
                4.157,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.443,
                          -4.157
                        ],
                        [
                          -2.443,
                          2.633
                        ],
                        [
                          2.447,
                          4.153
                        ],
                        [
                          -2.443,
                          -4.157
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.372549027205,
                      0.396078437567,
                      0.549019634724,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                2.443,
                4.157,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          2.447,
                          -4.157
                        ],
                        [
                          2.447,
                          2.633
                        ],
                        [
                          -2.443,
                          4.153
                        ],
                        [
                          2.447,
                          -4.157
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.533333361149,
                      0.568627476692,
                      0.68235296011,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_69',
      'nm': '▽ icon_btc',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ usdt',
          'refId': 'comp_70',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.868,
                11.868,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_70',
      'nm': '▽ usdt',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.598,
                12.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          1.41,
                          0.24
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.03,
                          -0.56
                        ],
                        [
                          -1.51,
                          -0.26
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.09,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.06,
                          0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.05,
                          0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.04,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.03,
                          0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.02,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.01,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          -0.26,
                          -0.67
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.23,
                          0.29
                        ],
                        [
                          0.04,
                          0.72
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.06,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.04,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.03,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.02,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.01,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          7.974,
                          -0.827
                        ],
                        [
                          4.934,
                          -2.137
                        ],
                        [
                          4.434,
                          -2.217
                        ],
                        [
                          4.434,
                          -2.217
                        ],
                        [
                          3.944,
                          -2.287
                        ],
                        [
                          3.944,
                          -2.287
                        ],
                        [
                          3.254,
                          -2.367
                        ],
                        [
                          3.254,
                          -2.367
                        ],
                        [
                          2.824,
                          -2.407
                        ],
                        [
                          2.824,
                          -2.407
                        ],
                        [
                          2.274,
                          -2.447
                        ],
                        [
                          2.274,
                          -2.447
                        ],
                        [
                          1.564,
                          -2.487
                        ],
                        [
                          1.564,
                          -2.487
                        ],
                        [
                          1.564,
                          -4.347
                        ],
                        [
                          5.964,
                          -4.347
                        ],
                        [
                          5.964,
                          -7.247
                        ],
                        [
                          -5.966,
                          -7.247
                        ],
                        [
                          -5.966,
                          -4.347
                        ],
                        [
                          -1.576,
                          -4.347
                        ],
                        [
                          -1.576,
                          -2.507
                        ],
                        [
                          -1.836,
                          -2.507
                        ],
                        [
                          -1.836,
                          -2.507
                        ],
                        [
                          -2.256,
                          -2.477
                        ],
                        [
                          -2.256,
                          -2.477
                        ],
                        [
                          -2.796,
                          -2.427
                        ],
                        [
                          -2.796,
                          -2.427
                        ],
                        [
                          -3.216,
                          -2.387
                        ],
                        [
                          -3.216,
                          -2.387
                        ],
                        [
                          -3.886,
                          -2.307
                        ],
                        [
                          -3.886,
                          -2.307
                        ],
                        [
                          -4.366,
                          -2.237
                        ],
                        [
                          -4.366,
                          -2.237
                        ],
                        [
                          -4.846,
                          -2.157
                        ],
                        [
                          -4.846,
                          -2.157
                        ],
                        [
                          -5.326,
                          -2.067
                        ],
                        [
                          -5.326,
                          -2.067
                        ],
                        [
                          -5.796,
                          -1.977
                        ],
                        [
                          -7.996,
                          -0.727
                        ],
                        [
                          -5.006,
                          0.693
                        ],
                        [
                          -4.506,
                          0.773
                        ],
                        [
                          -4.506,
                          0.773
                        ],
                        [
                          -4.006,
                          0.843
                        ],
                        [
                          -4.006,
                          0.843
                        ],
                        [
                          -3.526,
                          0.903
                        ],
                        [
                          -3.526,
                          0.903
                        ],
                        [
                          -3.076,
                          0.943
                        ],
                        [
                          -3.076,
                          0.943
                        ],
                        [
                          -2.476,
                          1.003
                        ],
                        [
                          -2.476,
                          1.003
                        ],
                        [
                          -1.996,
                          1.033
                        ],
                        [
                          -1.996,
                          1.033
                        ],
                        [
                          -1.576,
                          1.053
                        ],
                        [
                          -1.576,
                          1.053
                        ],
                        [
                          -1.576,
                          7.243
                        ],
                        [
                          1.564,
                          7.243
                        ],
                        [
                          1.564,
                          1.073
                        ],
                        [
                          2.234,
                          1.023
                        ],
                        [
                          2.234,
                          1.023
                        ],
                        [
                          2.864,
                          0.973
                        ],
                        [
                          3.154,
                          0.943
                        ],
                        [
                          3.714,
                          0.883
                        ],
                        [
                          3.714,
                          0.883
                        ],
                        [
                          4.224,
                          0.823
                        ],
                        [
                          4.224,
                          0.823
                        ],
                        [
                          4.694,
                          0.753
                        ],
                        [
                          4.694,
                          0.753
                        ],
                        [
                          5.124,
                          0.683
                        ],
                        [
                          5.124,
                          0.683
                        ],
                        [
                          5.514,
                          0.603
                        ],
                        [
                          5.694,
                          0.563
                        ],
                        [
                          6.034,
                          0.483
                        ],
                        [
                          6.184,
                          0.443
                        ],
                        [
                          6.474,
                          0.363
                        ],
                        [
                          6.474,
                          0.363
                        ],
                        [
                          6.734,
                          0.283
                        ],
                        [
                          6.844,
                          0.243
                        ],
                        [
                          7.054,
                          0.163
                        ],
                        [
                          7.054,
                          0.163
                        ],
                        [
                          7.244,
                          0.073
                        ],
                        [
                          7.324,
                          0.033
                        ],
                        [
                          7.474,
                          -0.047
                        ],
                        [
                          7.534,
                          -0.087
                        ],
                        [
                          7.654,
                          -0.167
                        ],
                        [
                          7.654,
                          -0.167
                        ],
                        [
                          7.744,
                          -0.247
                        ],
                        [
                          7.744,
                          -0.247
                        ],
                        [
                          7.824,
                          -0.317
                        ],
                        [
                          7.824,
                          -0.317
                        ],
                        [
                          7.884,
                          -0.387
                        ],
                        [
                          7.884,
                          -0.387
                        ],
                        [
                          7.924,
                          -0.457
                        ],
                        [
                          7.944,
                          -0.487
                        ],
                        [
                          7.974,
                          -0.547
                        ],
                        [
                          7.974,
                          -0.547
                        ],
                        [
                          7.984,
                          -0.607
                        ],
                        [
                          7.984,
                          -0.607
                        ],
                        [
                          7.994,
                          -0.657
                        ],
                        [
                          7.994,
                          -0.657
                        ],
                        [
                          7.994,
                          -0.727
                        ],
                        [
                          7.994,
                          -0.727
                        ],
                        [
                          7.984,
                          -0.787
                        ],
                        [
                          7.984,
                          -0.787
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.41,
                          0.26
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.6,
                          0.27
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.65,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.19,
                          -0.53
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.03,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.03,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.12,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.75,
                          -0.14
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.01,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.06,
                          -0.16
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.58,
                          -0.49
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.33,
                          0.19
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.02,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.03,
                          0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.11,
                          0.01
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.494,
                          0.571
                        ],
                        [
                          0.034,
                          0.581
                        ],
                        [
                          0.034,
                          0.581
                        ],
                        [
                          -0.616,
                          0.571
                        ],
                        [
                          -0.616,
                          0.571
                        ],
                        [
                          -1.326,
                          0.551
                        ],
                        [
                          -1.326,
                          0.551
                        ],
                        [
                          -1.966,
                          0.511
                        ],
                        [
                          -1.966,
                          0.511
                        ],
                        [
                          -2.656,
                          0.471
                        ],
                        [
                          -2.656,
                          0.471
                        ],
                        [
                          -3.136,
                          0.431
                        ],
                        [
                          -3.136,
                          0.431
                        ],
                        [
                          -3.626,
                          0.381
                        ],
                        [
                          -3.626,
                          0.381
                        ],
                        [
                          -4.106,
                          0.321
                        ],
                        [
                          -4.106,
                          0.321
                        ],
                        [
                          -4.586,
                          0.251
                        ],
                        [
                          -4.586,
                          0.251
                        ],
                        [
                          -5.056,
                          0.171
                        ],
                        [
                          -6.886,
                          -0.429
                        ],
                        [
                          -7.036,
                          -0.529
                        ],
                        [
                          -7.036,
                          -0.529
                        ],
                        [
                          -7.076,
                          -0.559
                        ],
                        [
                          -7.076,
                          -0.559
                        ],
                        [
                          -7.116,
                          -0.609
                        ],
                        [
                          -7.136,
                          -0.639
                        ],
                        [
                          -7.176,
                          -0.719
                        ],
                        [
                          -6.586,
                          -1.399
                        ],
                        [
                          -6.436,
                          -1.459
                        ],
                        [
                          -1.556,
                          -2.189
                        ],
                        [
                          -1.556,
                          0.021
                        ],
                        [
                          1.584,
                          0.021
                        ],
                        [
                          1.584,
                          -2.199
                        ],
                        [
                          2.214,
                          -2.159
                        ],
                        [
                          2.214,
                          -2.159
                        ],
                        [
                          2.784,
                          -2.119
                        ],
                        [
                          2.784,
                          -2.119
                        ],
                        [
                          3.234,
                          -2.079
                        ],
                        [
                          3.234,
                          -2.079
                        ],
                        [
                          3.704,
                          -2.029
                        ],
                        [
                          3.704,
                          -2.029
                        ],
                        [
                          4.204,
                          -1.969
                        ],
                        [
                          7.114,
                          -0.929
                        ],
                        [
                          7.144,
                          -0.809
                        ],
                        [
                          7.144,
                          -0.809
                        ],
                        [
                          7.134,
                          -0.769
                        ],
                        [
                          7.134,
                          -0.769
                        ],
                        [
                          7.114,
                          -0.719
                        ],
                        [
                          7.114,
                          -0.719
                        ],
                        [
                          7.094,
                          -0.679
                        ],
                        [
                          7.094,
                          -0.679
                        ],
                        [
                          7.074,
                          -0.639
                        ],
                        [
                          7.074,
                          -0.639
                        ],
                        [
                          7.034,
                          -0.589
                        ],
                        [
                          7.034,
                          -0.589
                        ],
                        [
                          6.974,
                          -0.539
                        ],
                        [
                          6.974,
                          -0.539
                        ],
                        [
                          6.914,
                          -0.479
                        ],
                        [
                          6.914,
                          -0.479
                        ],
                        [
                          6.834,
                          -0.419
                        ],
                        [
                          6.834,
                          -0.419
                        ],
                        [
                          6.734,
                          -0.359
                        ],
                        [
                          6.734,
                          -0.359
                        ],
                        [
                          6.614,
                          -0.299
                        ],
                        [
                          6.614,
                          -0.299
                        ],
                        [
                          6.474,
                          -0.229
                        ],
                        [
                          6.394,
                          -0.199
                        ],
                        [
                          6.224,
                          -0.139
                        ],
                        [
                          6.134,
                          -0.099
                        ],
                        [
                          5.924,
                          -0.039
                        ],
                        [
                          5.924,
                          -0.039
                        ],
                        [
                          5.684,
                          0.031
                        ],
                        [
                          5.684,
                          0.031
                        ],
                        [
                          5.424,
                          0.091
                        ],
                        [
                          5.424,
                          0.091
                        ],
                        [
                          5.124,
                          0.151
                        ],
                        [
                          5.124,
                          0.151
                        ],
                        [
                          4.794,
                          0.211
                        ],
                        [
                          4.794,
                          0.211
                        ],
                        [
                          4.434,
                          0.271
                        ],
                        [
                          4.434,
                          0.271
                        ],
                        [
                          4.044,
                          0.321
                        ],
                        [
                          4.044,
                          0.321
                        ],
                        [
                          3.604,
                          0.381
                        ],
                        [
                          3.604,
                          0.381
                        ],
                        [
                          3.134,
                          0.421
                        ],
                        [
                          3.134,
                          0.421
                        ],
                        [
                          2.624,
                          0.471
                        ],
                        [
                          2.624,
                          0.471
                        ],
                        [
                          2.074,
                          0.501
                        ],
                        [
                          2.074,
                          0.501
                        ],
                        [
                          1.474,
                          0.541
                        ],
                        [
                          1.474,
                          0.541
                        ],
                        [
                          0.834,
                          0.561
                        ],
                        [
                          0.494,
                          0.571
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.595,
                11.595,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.4,
                          0
                        ],
                        [
                          0,
                          6.41
                        ],
                        [
                          6.41,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -6.4
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          6.41
                        ],
                        [
                          6.41,
                          0
                        ],
                        [
                          0,
                          -6.4
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.4,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -11.595,
                          -0.005
                        ],
                        [
                          -11.595,
                          -0.005
                        ],
                        [
                          -0.005,
                          11.595
                        ],
                        [
                          11.595,
                          -0.005
                        ],
                        [
                          -0.005,
                          -11.595
                        ],
                        [
                          -0.005,
                          -11.595
                        ],
                        [
                          -11.595,
                          -0.005
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.180392161012,
                      0.600000023842,
                      0.600000023842,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_71',
      'nm': '▽ icon_BUSD',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 46',
          'refId': 'comp_72',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.996,
                      23.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_72',
      'nm': '▽ 编组 46',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.99,
                          -2.983
                        ],
                        [
                          0,
                          -7.973
                        ],
                        [
                          2,
                          -5.983
                        ],
                        [
                          -3,
                          -0.983
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.986,
                          2.988
                        ],
                        [
                          2.994,
                          -4.992
                        ],
                        [
                          4.984,
                          -3.002
                        ],
                        [
                          -2.996,
                          4.978
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          5.979,
                          -2.001
                        ],
                        [
                          -2.001,
                          5.979
                        ],
                        [
                          -0.011,
                          7.969
                        ],
                        [
                          7.969,
                          -0.011
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -7.973,
                          0.003
                        ],
                        [
                          -5.983,
                          -1.987
                        ],
                        [
                          -3.993,
                          0.003
                        ],
                        [
                          -5.983,
                          1.993
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.168627455831,
                      0.184313729405,
                      0.211764708161,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 6,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      23.45,
                      23.45
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.759471998028,
                      0.065042914596,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_73',
      'nm': '▽ icon_solana_network',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 45',
          'refId': 'comp_74',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                11.5,
                11.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 23,
          "h": 23,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.998,
                11.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      23.45,
                      23.45
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 11.725,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.831372559071,
                      0.847058832645,
                      0.878431379795,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.5,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.176470592618,
                      0.215686276555,
                      0.29411765933,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_74',
      'nm': '▽ 编组 45',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                11.725,
                11.725,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.13,
                          0
                        ],
                        [
                          -0.09,
                          -0.06
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          2.26,
                          0
                        ],
                        [
                          1.52,
                          1.52
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.15,
                          0.13
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.1,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          0.1
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.54,
                          1.66
                        ],
                        [
                          -2.17,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          -0.09
                        ]
                      ],
                      "v": [
                        [
                          -4.444,
                          4.558
                        ],
                        [
                          -4.154,
                          4.658
                        ],
                        [
                          -4.104,
                          4.708
                        ],
                        [
                          -3.524,
                          5.368
                        ],
                        [
                          -3.184,
                          5.558
                        ],
                        [
                          -3.084,
                          5.568
                        ],
                        [
                          -0.944,
                          5.568
                        ],
                        [
                          5.976,
                          5.578
                        ],
                        [
                          0.006,
                          8.178
                        ],
                        [
                          -5.764,
                          5.798
                        ],
                        [
                          -5.974,
                          5.578
                        ],
                        [
                          -5.944,
                          5.578
                        ],
                        [
                          -5.384,
                          5.378
                        ],
                        [
                          -5.294,
                          5.288
                        ],
                        [
                          -4.794,
                          4.708
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.13,
                          0
                        ],
                        [
                          -0.08,
                          -0.06
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.24,
                          -0.55
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.08,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.14,
                          0.59
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.15,
                          0.13
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.11,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          0.1
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.11,
                          0.59
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.14,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          -0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.09,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.26,
                          -0.54
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.21,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          -0.1
                        ]
                      ],
                      "v": [
                        [
                          -1.242,
                          0.504
                        ],
                        [
                          -0.952,
                          0.604
                        ],
                        [
                          -0.892,
                          0.664
                        ],
                        [
                          -0.312,
                          1.324
                        ],
                        [
                          0.028,
                          1.504
                        ],
                        [
                          0.128,
                          1.514
                        ],
                        [
                          8.028,
                          1.514
                        ],
                        [
                          7.508,
                          3.224
                        ],
                        [
                          7.358,
                          3.554
                        ],
                        [
                          -0.872,
                          3.554
                        ],
                        [
                          -1.242,
                          3.424
                        ],
                        [
                          -1.312,
                          3.354
                        ],
                        [
                          -1.892,
                          2.694
                        ],
                        [
                          -2.162,
                          2.544
                        ],
                        [
                          -2.242,
                          2.534
                        ],
                        [
                          -2.272,
                          2.534
                        ],
                        [
                          -2.532,
                          2.614
                        ],
                        [
                          -2.602,
                          2.674
                        ],
                        [
                          -3.292,
                          3.404
                        ],
                        [
                          -3.562,
                          3.544
                        ],
                        [
                          -3.642,
                          3.554
                        ],
                        [
                          -7.362,
                          3.554
                        ],
                        [
                          -7.962,
                          1.864
                        ],
                        [
                          -8.032,
                          1.514
                        ],
                        [
                          -2.732,
                          1.514
                        ],
                        [
                          -2.172,
                          1.314
                        ],
                        [
                          -2.082,
                          1.224
                        ],
                        [
                          -1.582,
                          0.664
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.13,
                          0
                        ],
                        [
                          -0.08,
                          -0.06
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          -0.57
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.11,
                          0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.07,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.15,
                          0.53
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.15,
                          0.13
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0.1,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          0.1
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.19,
                          0.55
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          -0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.1,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.05,
                          -0.55
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.2,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.09,
                          -0.09
                        ]
                      ],
                      "v": [
                        [
                          1.667,
                          -3.723
                        ],
                        [
                          1.947,
                          -3.622
                        ],
                        [
                          2.007,
                          -3.573
                        ],
                        [
                          2.587,
                          -2.913
                        ],
                        [
                          2.927,
                          -2.723
                        ],
                        [
                          3.027,
                          -2.713
                        ],
                        [
                          7.717,
                          -2.713
                        ],
                        [
                          8.107,
                          -1.033
                        ],
                        [
                          8.147,
                          -0.693
                        ],
                        [
                          2.067,
                          -0.693
                        ],
                        [
                          1.697,
                          -0.823
                        ],
                        [
                          1.627,
                          -0.893
                        ],
                        [
                          1.047,
                          -1.553
                        ],
                        [
                          0.777,
                          -1.702
                        ],
                        [
                          0.697,
                          -1.702
                        ],
                        [
                          0.677,
                          -1.702
                        ],
                        [
                          0.417,
                          -1.622
                        ],
                        [
                          0.337,
                          -1.562
                        ],
                        [
                          -0.353,
                          -0.842
                        ],
                        [
                          -0.623,
                          -0.702
                        ],
                        [
                          -0.693,
                          -0.693
                        ],
                        [
                          -8.143,
                          -0.693
                        ],
                        [
                          -7.843,
                          -2.312
                        ],
                        [
                          -7.713,
                          -2.713
                        ],
                        [
                          0.177,
                          -2.713
                        ],
                        [
                          0.727,
                          -2.913
                        ],
                        [
                          0.817,
                          -3.003
                        ],
                        [
                          1.317,
                          -3.573
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.57,
                          0
                        ],
                        [
                          -1.55,
                          -1.91
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.07,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          -0.02
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          2.48,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.07,
                          -0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.09,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.08,
                          0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.54,
                          -2.05
                        ]
                      ],
                      "v": [
                        [
                          -0.005,
                          -8.18
                        ],
                        [
                          6.355,
                          -5.15
                        ],
                        [
                          6.535,
                          -4.92
                        ],
                        [
                          4.965,
                          -4.92
                        ],
                        [
                          4.605,
                          -5.04
                        ],
                        [
                          4.535,
                          -5.11
                        ],
                        [
                          3.945,
                          -5.77
                        ],
                        [
                          3.685,
                          -5.92
                        ],
                        [
                          3.605,
                          -5.93
                        ],
                        [
                          3.575,
                          -5.93
                        ],
                        [
                          3.315,
                          -5.85
                        ],
                        [
                          3.245,
                          -5.79
                        ],
                        [
                          2.555,
                          -5.06
                        ],
                        [
                          2.285,
                          -4.92
                        ],
                        [
                          2.205,
                          -4.92
                        ],
                        [
                          -6.535,
                          -4.92
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 6,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_75',
      'nm': '▽ 编组 32',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 33',
          'refId': 'comp_76',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                98.316,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                98.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 197,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_76',
      'nm': '▽ 编组 33',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 43',
          'refId': 'comp_77',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                158.971,
                83.652,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                5.5,
                5.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 11,
          "h": 11,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ 编组 42',
          'refId': 'comp_79',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                60.322,
                62.823,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                49.5,
                7.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 99,
          "h": 15,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 5,
          'nm': '101.84462',
          'cl': '84462',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                91.16,
                96.154,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      113,
                      33
                    ],
                    "ps": [
                      -56.5,
                      -16.5
                    ],
                    "s": 23.9956798553467,
                    'f': 'PingFangSC-Semibold',
                    't': '101.84462',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 28.7948169708252,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 5,
          'nm': '$',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                23.497,
                94.154,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      15,
                      33
                    ],
                    "ps": [
                      -7.5,
                      -16.5
                    ],
                    "s": 23.9956798553467,
                    'f': 'PingFangSC-Semibold',
                    't': '$',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 28.7948169708252,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 0,
          'nm': '▽ 编组 41',
          'refId': 'comp_80',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                208.629,
                141.308,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 5,
          'nm': 'History',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                208.629,
                166.138,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      27,
                      11
                    ],
                    "ps": [
                      -13.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': 'History',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                208.341,
                148.307,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -41.659,
                          -21.663
                        ],
                        [
                          41.661,
                          -21.663
                        ],
                        [
                          41.661,
                          21.667
                        ],
                        [
                          -41.659,
                          21.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 0,
          'nm': '▽ 编组 40',
          'refId': 'comp_84',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125.311,
                141.308,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 5,
          'nm': 'Receive',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125.311,
                166.138,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      30,
                      11
                    ],
                    "ps": [
                      -15,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': 'Receive',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 5,
          'nm': 'Send',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                41.326,
                166.138,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      19,
                      11
                    ],
                    "ps": [
                      -9.5,
                      -5.5
                    ],
                    "s": 7.99855995178223,
                    'f': 'PingFangSC-Regular',
                    't': 'Send',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 9.5982723236084,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 0,
          'nm': '▽ 编组 38',
          'refId': 'comp_87',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                41.326,
                141.308,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125.266,
                148.307,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -41.659,
                          -21.663
                        ],
                        [
                          41.661,
                          -21.663
                        ],
                        [
                          41.661,
                          21.667
                        ],
                        [
                          -41.659,
                          21.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                41.659,
                148.307,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -41.659,
                          -21.663
                        ],
                        [
                          41.661,
                          -21.663
                        ],
                        [
                          41.661,
                          21.667
                        ],
                        [
                          -41.659,
                          21.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 0,
          'nm': '▽ 编组 34',
          'refId': 'comp_91',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                25.995,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                25.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 51,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                98.316,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -98.316
                        ],
                        [
                          124.973,
                          -98.316
                        ],
                        [
                          124.973,
                          98.314
                        ],
                        [
                          -124.977,
                          98.314
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_77',
      'nm': '▽ 编组 43',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 眼睛_显示_o',
          'refId': 'comp_78',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                5.666,
                5.532,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                5.5,
                3,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 11,
          "h": 6,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_78',
      'nm': '▽ 眼睛_显示_o',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                5.332,
                3.199,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          2.03,
                          0
                        ],
                        [
                          1.12,
                          -1.82
                        ],
                        [
                          -2.02,
                          0
                        ],
                        [
                          -1.11,
                          1.81
                        ]
                      ],
                      "o": [
                        [
                          -2.02,
                          0
                        ],
                        [
                          1.12,
                          1.81
                        ],
                        [
                          2.03,
                          0
                        ],
                        [
                          -1.11,
                          -1.82
                        ]
                      ],
                      "v": [
                        [
                          -0.002,
                          -2.666
                        ],
                        [
                          -4.692,
                          0.004
                        ],
                        [
                          -0.002,
                          2.664
                        ],
                        [
                          4.688,
                          0.004
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          2.3,
                          0
                        ],
                        [
                          1.23,
                          2.13
                        ],
                        [
                          -2.29,
                          0
                        ],
                        [
                          -1.22,
                          -2.13
                        ]
                      ],
                      "o": [
                        [
                          -2.29,
                          0
                        ],
                        [
                          1.23,
                          -2.13
                        ],
                        [
                          2.3,
                          0
                        ],
                        [
                          -1.22,
                          2.13
                        ]
                      ],
                      "v": [
                        [
                          -0.002,
                          3.201
                        ],
                        [
                          -5.332,
                          0.001
                        ],
                        [
                          -0.002,
                          -3.199
                        ],
                        [
                          5.328,
                          0.001
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.59,
                          0
                        ],
                        [
                          0,
                          0.58
                        ],
                        [
                          0.58,
                          0
                        ],
                        [
                          0,
                          -0.59
                        ]
                      ],
                      "o": [
                        [
                          0.58,
                          0
                        ],
                        [
                          0,
                          -0.59
                        ],
                        [
                          -0.59,
                          0
                        ],
                        [
                          0,
                          0.58
                        ]
                      ],
                      "v": [
                        [
                          0.004,
                          1.064
                        ],
                        [
                          1.064,
                          0.004
                        ],
                        [
                          0.004,
                          -1.066
                        ],
                        [
                          -1.066,
                          0.004
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.91,
                          0
                        ],
                        [
                          0,
                          0.91
                        ],
                        [
                          -0.91,
                          0
                        ],
                        [
                          0,
                          -0.91
                        ]
                      ],
                      "o": [
                        [
                          -0.91,
                          0
                        ],
                        [
                          0,
                          -0.91
                        ],
                        [
                          0.91,
                          0
                        ],
                        [
                          0,
                          0.91
                        ]
                      ],
                      "v": [
                        [
                          0,
                          1.6
                        ],
                        [
                          -1.6,
                          0
                        ],
                        [
                          0,
                          -1.6
                        ],
                        [
                          1.6,
                          0
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.800000011921,
                      0.803921580315,
                      0.815686285496,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 6,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_79',
      'nm': '▽ 编组 42',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 5,
          'nm': 'Omni Wallet',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                10.819,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 10.6647500991821,
                    'f': 'PingFangSC-Medium',
                    't': 'Omni Wallet',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 12.797700881958,
                    "ls": 0,
                    "fc": [
                      0.157,
                      0.157,
                      0.157
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 5,
          'nm': 'Binding',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                69.987,
                10.042,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 7.33201026916504,
                    'f': 'PingFangSC-Medium',
                    't': 'Binding',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 8.79841232299805,
                    "ls": 0,
                    "fc": [
                      1,
                      1,
                      1
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                83.318,
                7.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -15.997,
                          -5.332
                        ],
                        [
                          15.993,
                          -5.332
                        ],
                        [
                          15.993,
                          5.328
                        ],
                        [
                          -15.997,
                          5.328
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'rd',
                  'nm': '圆角 1',
                  "r": {
                    "a": 0,
                    "k": 2.666,
                    "ix": 1
                  },
                  "ix": 2,
                  'mn': 'ADBE Vector Filter - RC',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_80',
      'nm': '▽ 编组 41',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ Wallet_btn_history 2',
          'refId': 'comp_81',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_81',
      'nm': '▽ Wallet_btn_history 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ Wallet_btn_history',
          'refId': 'comp_82',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_82',
      'nm': '▽ Wallet_btn_history',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ contract_invite',
          'refId': 'comp_83',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.331,
                14.331,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                6.5,
                6.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 13,
          "h": 13,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.331,
                14.331,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.662,
                      28.662
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_83',
      'nm': '▽ contract_invite',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                6.665,
                6.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          4.719,
                          -5.555
                        ],
                        [
                          4.719,
                          3.355
                        ],
                        [
                          2.489,
                          5.555
                        ],
                        [
                          -4.721,
                          5.555
                        ],
                        [
                          -4.721,
                          -5.555
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.835,
                          0.555
                        ],
                        [
                          -3.055,
                          0.555
                        ],
                        [
                          -3.055,
                          1.665
                        ],
                        [
                          0.835,
                          1.665
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.055,
                          -2.222
                        ],
                        [
                          -3.055,
                          -2.222
                        ],
                        [
                          -3.055,
                          -1.112
                        ],
                        [
                          3.055,
                          -1.112
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 5,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                6.665,
                6.665,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      13.331,
                      13.331
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_84',
      'nm': '▽ 编组 40',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon_search 4',
          'refId': 'comp_85',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_85',
      'nm': '▽ icon_search 4',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 二维码',
          'refId': 'comp_86',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                5.5,
                5.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 11,
          "h": 11,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      13.331,
                      13.331
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.578,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.662,
                      28.662
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_86',
      'nm': '▽ 二维码',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                5.505,
                5.488,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -5.505,
                          -0.328
                        ],
                        [
                          -0.315,
                          -0.328
                        ],
                        [
                          -0.315,
                          -5.488
                        ],
                        [
                          -5.505,
                          -5.488
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.207,
                          -4.191
                        ],
                        [
                          -1.617,
                          -4.191
                        ],
                        [
                          -1.617,
                          -1.601
                        ],
                        [
                          -4.207,
                          -1.601
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 2,
                  'ty': 'sh',
                  "ix": 3,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.316,
                          -5.488
                        ],
                        [
                          0.316,
                          -0.298
                        ],
                        [
                          5.506,
                          -0.298
                        ],
                        [
                          5.506,
                          -5.488
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 3',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 3,
                  'ty': 'sh',
                  "ix": 4,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          4.203,
                          -1.631
                        ],
                        [
                          1.613,
                          -1.631
                        ],
                        [
                          1.613,
                          -4.191
                        ],
                        [
                          4.203,
                          -4.191
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 4',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 4,
                  'ty': 'sh',
                  "ix": 5,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -3.542,
                          -2.259
                        ],
                        [
                          -2.242,
                          -2.259
                        ],
                        [
                          -2.242,
                          -3.559
                        ],
                        [
                          -3.542,
                          -3.559
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 5',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 5,
                  'ty': 'sh',
                  "ix": 6,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -5.505,
                          5.493
                        ],
                        [
                          -0.315,
                          5.493
                        ],
                        [
                          -0.315,
                          0.333
                        ],
                        [
                          -5.505,
                          0.333
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 6',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 6,
                  'ty': 'sh',
                  "ix": 7,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.207,
                          1.63
                        ],
                        [
                          -1.617,
                          1.63
                        ],
                        [
                          -1.617,
                          4.22
                        ],
                        [
                          -4.207,
                          4.22
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 7',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 7,
                  'ty': 'sh',
                  "ix": 8,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.578,
                          -3.559
                        ],
                        [
                          2.278,
                          -3.559
                        ],
                        [
                          2.278,
                          -2.259
                        ],
                        [
                          3.578,
                          -2.259
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 8',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 8,
                  'ty': 'sh',
                  "ix": 9,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.316,
                          5.491
                        ],
                        [
                          1.616,
                          5.491
                        ],
                        [
                          1.616,
                          4.191
                        ],
                        [
                          0.316,
                          4.191
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 9',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 9,
                  'ty': 'sh',
                  "ix": 10,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.613,
                          1.63
                        ],
                        [
                          1.613,
                          4.22
                        ],
                        [
                          2.913,
                          4.22
                        ],
                        [
                          2.913,
                          1.63
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 10',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 10,
                  'ty': 'sh',
                  "ix": 11,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          4.21,
                          4.194
                        ],
                        [
                          2.91,
                          4.194
                        ],
                        [
                          2.91,
                          5.484
                        ],
                        [
                          5.5,
                          5.484
                        ],
                        [
                          5.5,
                          2.894
                        ],
                        [
                          4.21,
                          2.894
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 11',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 11,
                  'ty': 'sh',
                  "ix": 12,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          4.207,
                          1.633
                        ],
                        [
                          5.507,
                          1.633
                        ],
                        [
                          5.507,
                          0.333
                        ],
                        [
                          4.207,
                          0.333
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 12',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 12,
                  'ty': 'sh',
                  "ix": 13,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.316,
                          0.333
                        ],
                        [
                          0.316,
                          1.633
                        ],
                        [
                          1.616,
                          1.633
                        ],
                        [
                          1.616,
                          0.333
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 13',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 13,
                  'ty': 'sh',
                  "ix": 14,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -3.542,
                          3.562
                        ],
                        [
                          -2.242,
                          3.562
                        ],
                        [
                          -2.242,
                          2.262
                        ],
                        [
                          -3.542,
                          2.262
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 14',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 16,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_87',
      'nm': '▽ 编组 38',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ icon_search 3',
          'refId': 'comp_88',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_88',
      'nm': '▽ icon_search 3',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 39',
          'refId': 'comp_89',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_89',
      'nm': '▽ 编组 39',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 发送',
          'refId': 'comp_90',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                5,
                5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 10,
          "h": 10,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      13.331,
                      13.331
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.555,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.664,
                14.664,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.662,
                      28.662
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.192156866193,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_90',
      'nm': '▽ 发送',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                4.999,
                4.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.09,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.03,
                          -0.1
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.16,
                          0.04
                        ],
                        [
                          0.04,
                          0.03
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.11,
                          -0.11
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.11,
                          0.12
                        ],
                        [
                          0,
                          0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.06,
                          0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          0.09
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.11,
                          0.12
                        ],
                        [
                          -0.04,
                          0.02
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.1,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.08,
                          0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.03,
                          0.15
                        ],
                        [
                          -0.04,
                          -0.01
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.12,
                          -0.1
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.11,
                          0.12
                        ],
                        [
                          -0.06,
                          -0.05
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.07
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.11,
                          0.08
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.13,
                          -0.1
                        ],
                        [
                          0.03,
                          -0.04
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.819,
                          -1.039
                        ],
                        [
                          4.591,
                          -4.979
                        ],
                        [
                          4.591,
                          -4.979
                        ],
                        [
                          4.901,
                          -4.929
                        ],
                        [
                          4.901,
                          -4.929
                        ],
                        [
                          4.991,
                          -4.639
                        ],
                        [
                          2.641,
                          4.781
                        ],
                        [
                          2.641,
                          4.781
                        ],
                        [
                          2.291,
                          4.991
                        ],
                        [
                          2.171,
                          4.931
                        ],
                        [
                          0.141,
                          3.231
                        ],
                        [
                          0.141,
                          3.231
                        ],
                        [
                          -0.259,
                          3.251
                        ],
                        [
                          -1.299,
                          4.291
                        ],
                        [
                          -1.299,
                          4.291
                        ],
                        [
                          -1.709,
                          4.291
                        ],
                        [
                          -1.799,
                          4.091
                        ],
                        [
                          -1.799,
                          1.871
                        ],
                        [
                          -1.709,
                          1.671
                        ],
                        [
                          2.531,
                          -2.599
                        ],
                        [
                          -2.479,
                          1.181
                        ],
                        [
                          -2.479,
                          1.181
                        ],
                        [
                          -2.839,
                          1.171
                        ],
                        [
                          -4.889,
                          -0.549
                        ],
                        [
                          -4.889,
                          -0.549
                        ],
                        [
                          -4.929,
                          -0.959
                        ],
                        [
                          -4.819,
                          -1.039
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_91',
      'nm': '▽ 编组 34',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 36',
          'refId': 'comp_92',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                20.663,
                31.994,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                6.5,
                6,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 13,
          "h": 12,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          'nm': '▽ icon_search 2',
          'refId': 'comp_94',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                229.069,
                31.994,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                10,
                10,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 20,
          "h": 20,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          'nm': '▽ nav 2',
          'refId': 'comp_96',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                31.994,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                18.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 37,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                25.329,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      50.658
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_92',
      'nm': '▽ 编组 36',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 37',
          'refId': 'comp_93',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                6.665,
                5.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                6.5,
                6,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 13,
          "h": 12,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_93',
      'nm': '▽ 编组 37',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.332,
                5.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      1.333,
                      1.333
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.998,
                5.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      6.665,
                      3.999
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 2,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                6.665,
                5.999,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      13.331,
                      11.998
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_94',
      'nm': '▽ icon_search 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': '▽ 编组 35',
          'refId': 'comp_95',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.998,
                9.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                6,
                6,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 12,
          "h": 12,
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                9.775,
                9.998,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      19.55,
                      19.996
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.833,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_95',
      'nm': '▽ 编组 35',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                4.799,
                4.799,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          2.65,
                          0
                        ],
                        [
                          0,
                          2.65
                        ],
                        [
                          -2.65,
                          0
                        ],
                        [
                          0,
                          -2.65
                        ]
                      ],
                      "o": [
                        [
                          -2.65,
                          0
                        ],
                        [
                          0,
                          -2.65
                        ],
                        [
                          2.65,
                          0
                        ],
                        [
                          0,
                          2.65
                        ]
                      ],
                      "v": [
                        [
                          0.001,
                          4.801
                        ],
                        [
                          -4.799,
                          0.001
                        ],
                        [
                          0.001,
                          -4.799
                        ],
                        [
                          4.801,
                          0.001
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                10.198,
                10.198,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -1.8,
                          -1.8
                        ],
                        [
                          1.8,
                          1.8
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_96',
      'nm': '▽ nav 2',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                18.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      37.327
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 225,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_97',
      'nm': '▽ message list',
      "fr": 25,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          'nm': 'massage list 2',
          'refId': 'comp_98',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                270.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                125,
                270.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 250,
          "h": 541,
          "ip": 0,
          "op": 280,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.225
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.65
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 50,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.566
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.211
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 60,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.662
                    ],
                    "y": [
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.329
                    ],
                    "y": [
                      0
                    ]
                  },
                  "t": 80,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 82,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                182,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.257,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.618,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 50,
                  "s": [
                    0,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.566,
                      0.566,
                      0.566
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.211,
                      0.211,
                      0.211
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 60,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "t": 80,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -124.977,
                          -26.667
                        ],
                        [
                          124.973,
                          -26.667
                        ],
                        [
                          124.973,
                          26.663
                        ],
                        [
                          -124.977,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 277,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          'nm': 'BG 2',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                349.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -192
                        ],
                        [
                          125,
                          -192
                        ],
                        [
                          125,
                          192
                        ],
                        [
                          -125,
                          192
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'BG',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': 'BG',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                124.978,
                270.667,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      249.955,
                      541.333
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'BG',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 277,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_98',
      'nm': 'massage list 2',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                228.333,
                33.667,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -1.667,
                          -1.667
                        ],
                        [
                          1.663,
                          -1.667
                        ],
                        [
                          -1.667,
                          -1.667
                        ],
                        [
                          -1.667,
                          1.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.067,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                225,
                30.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.663,
                          1.663
                        ],
                        [
                          -1.667,
                          1.663
                        ],
                        [
                          1.663,
                          1.663
                        ],
                        [
                          1.663,
                          -1.667
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.067,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                226.667,
                32,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          3.68,
                          0
                        ],
                        [
                          0,
                          3.68
                        ],
                        [
                          -3.69,
                          0
                        ],
                        [
                          0,
                          -3.69
                        ]
                      ],
                      "o": [
                        [
                          -3.69,
                          0
                        ],
                        [
                          0,
                          -3.69
                        ],
                        [
                          3.68,
                          0
                        ],
                        [
                          0,
                          3.68
                        ]
                      ],
                      "v": [
                        [
                          0.003,
                          6.663
                        ],
                        [
                          -6.667,
                          0.003
                        ],
                        [
                          0.003,
                          -6.667
                        ],
                        [
                          6.663,
                          0.003
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.156862750649,
                      0.156862750649,
                      0.156862750649,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.104,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 5,
          'nm': 'LS',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                458.529,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 16,
                    'f': 'PingFangSC-Semibold',
                    't': 'LS',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 19.2000007629395,
                    "ls": 0,
                    "fc": [
                      1,
                      1,
                      1
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                452.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.945098042488,
                      0.768627464771,
                      0.129411771894,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 5,
          'nm': 'I’ll come with you.',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.333,
                464.525,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'I’ll come with you. ',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.588,
                      0.588,
                      0.588
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 5,
          'nm': '手机联系人',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.333,
                450.263,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 12,
                    'f': 'PingFangSC-Medium',
                    't': 'Lelia Schmidt',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 14.4000005722046,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 5,
          'nm': 'Wed',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                236,
                447.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Wed',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                452.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -26.667
                        ],
                        [
                          125,
                          -26.667
                        ],
                        [
                          125,
                          26.663
                        ],
                        [
                          -125,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 0,
          'nm': '▽ 矩形 24',
          'refId': 'comp_99',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                398.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 297.5,
          "op": 422.5,
          "st": 297.5,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 0,
          'nm': '▽ 矩形 24',
          'refId': 'comp_99',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                398.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 244.166666666667,
          "op": 369.166666666667,
          "st": 244.166666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 0,
          'nm': '▽ 矩形 24',
          'refId': 'comp_99',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                398.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 121.666666666667,
          "op": 246.666666666667,
          "st": 121.666666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 0,
          'nm': '▽ 矩形 24',
          'refId': 'comp_99',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                398.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 5,
          'nm': 'Wed',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                237.465,
                393.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Wed',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 5,
          'nm': 'never never give upn',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                133,
                410.131,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "sz": [
                      156.666687011719,
                      22
                    ],
                    "ps": [
                      -78.3333435058594,
                      -11
                    ],
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'never never give upnever never give upnever never give up',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.588,
                      0.588,
                      0.588
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 5,
          'nm': '手机联系人',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                54.667,
                390.596,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 12,
                    'f': 'PingFangSC-Medium',
                    't': 'Helena Hansen',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 14.4000005722046,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                398.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -26.667
                        ],
                        [
                          125,
                          -26.667
                        ],
                        [
                          125,
                          26.663
                        ],
                        [
                          -125,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 18,
          "ty": 0,
          'nm': '▽ 矩形 23',
          'refId': 'comp_100',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                345.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 287.5,
          "op": 412.5,
          "st": 287.5,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 19,
          "ty": 0,
          'nm': '▽ 矩形 23',
          'refId': 'comp_100',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                345.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 242.083333333333,
          "op": 367.083333333333,
          "st": 242.083333333333,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 20,
          "ty": 0,
          'nm': '▽ 矩形 23',
          'refId': 'comp_100',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                345.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 121.666666666667,
          "op": 246.666666666667,
          "st": 121.666666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 21,
          "ty": 0,
          'nm': '▽ 矩形 23',
          'refId': 'comp_100',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                345.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 22,
          "ty": 5,
          'nm': '立转准因克目层飞争压查近两机月常压查近两',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                54.667,
                357.201,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'never never give up',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.588,
                      0.588,
                      0.588
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 23,
          "ty": 5,
          'nm': 'Wed',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                237.465,
                340.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Wed',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 24,
          "ty": 5,
          'nm': '手机联系人',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                54.667,
                342.929,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 12,
                    'f': 'PingFangSC-Medium',
                    't': 'Myra Atkins',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 14.4000005722046,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 25,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                345.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -26.667
                        ],
                        [
                          125,
                          -26.667
                        ],
                        [
                          125,
                          26.663
                        ],
                        [
                          -125,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 26,
          "ty": 0,
          'nm': '▽ 矩形 22',
          'refId': 'comp_101',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                291.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 280.833333333333,
          "op": 405.833333333333,
          "st": 280.833333333333,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 27,
          "ty": 0,
          'nm': '▽ 矩形 22',
          'refId': 'comp_101',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                291.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 234.166666666667,
          "op": 359.166666666667,
          "st": 234.166666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 28,
          "ty": 0,
          'nm': '▽ 矩形 22',
          'refId': 'comp_101',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                291.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 120,
          "op": 245,
          "st": 120,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 29,
          "ty": 0,
          'nm': '▽ 矩形 22',
          'refId': 'comp_101',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28.667,
                291.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 30,
          "ty": 5,
          'nm': '立转准因克目层飞争压查近两机月常压查近两',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                54.667,
                303.201,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'never never give up',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.588,
                      0.588,
                      0.588
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 31,
          "ty": 5,
          'nm': 'Wed',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                237.465,
                286.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Wed',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 32,
          "ty": 5,
          'nm': '手机联系人',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                54.667,
                288.929,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 12,
                    'f': 'PingFangSC-Medium',
                    't': 'Mabel Morales',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 14.4000005722046,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 33,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                291.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -26.667
                        ],
                        [
                          125,
                          -26.667
                        ],
                        [
                          125,
                          26.663
                        ],
                        [
                          -125,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 34,
          "ty": 5,
          'nm': 'LS',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                512.195,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 16,
                    'f': 'PingFangSC-Semibold',
                    't': 'LS',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 19.2000007629395,
                    "ls": 0,
                    "fc": [
                      1,
                      1,
                      1
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 35,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                506.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.1254902035,
                      0.705882370472,
                      0.117647059262,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 36,
          "ty": 5,
          'nm': 'I’ll come with you.',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.333,
                518.192,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'I’ll come with you. ',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.588,
                      0.588,
                      0.588
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 37,
          "ty": 5,
          'nm': 'Jesse Greene',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.333,
                503.929,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 12,
                    'f': 'PingFangSC-Medium',
                    't': 'Jesse Greene',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 14.4000005722046,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 38,
          "ty": 5,
          'nm': 'Wed',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                236,
                501.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Wed',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 39,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                506.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -26.667
                        ],
                        [
                          125,
                          -26.667
                        ],
                        [
                          125,
                          26.663
                        ],
                        [
                          -125,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 40,
          "ty": 5,
          'nm': 'Thu',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                236.333,
                286.997,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Thu',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 41,
          "ty": 5,
          'nm': 'Thu',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                236.667,
                240.33,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Thu',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 42,
          "ty": 5,
          'nm': '11 h',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                235.667,
                186.33,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': '11 h',
                    "ca": 0,
                    "j": 1,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 43,
          "ty": 5,
          'nm': '1',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                42,
                173.551,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Medium',
                    't': '1',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      1,
                      1,
                      1
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 44,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                43.667,
                171.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      14,
                      11.333
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 6,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.188235297799,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 45,
          "ty": 0,
          'nm': '▽ 矩形 21',
          'refId': 'comp_102',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                183.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 290.416666666667,
          "op": 415.416666666667,
          "st": 290.416666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 46,
          "ty": 0,
          'nm': '▽ 矩形 21',
          'refId': 'comp_102',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                183.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 240,
          "op": 365,
          "st": 240,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 47,
          "ty": 0,
          'nm': '▽ 矩形 21',
          'refId': 'comp_102',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                183.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 120.416666666667,
          "op": 245.416666666667,
          "st": 120.416666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 48,
          "ty": 0,
          'nm': '▽ 矩形 21',
          'refId': 'comp_102',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                183.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 36,
          "h": 36,
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 49,
          "ty": 5,
          'nm': 'You can chat with GP',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.333,
                195.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'You can chat with GPTBot now!',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.518,
                      0.518,
                      0.518
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 50,
          "ty": 5,
          'nm': 'ChatBot',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.333,
                181.263,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 12,
                    'f': 'PingFangSC-Medium',
                    't': 'ChatBot',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 14.4000005722046,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 51,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                183.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -26.667
                        ],
                        [
                          125,
                          -26.667
                        ],
                        [
                          125,
                          26.663
                        ],
                        [
                          -125,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 52,
          "ty": 5,
          'nm': 'BH',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                16.333,
                243.303,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 16,
                    'f': 'PingFangSC-Semibold',
                    't': 'BH',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 19.2000007629395,
                    "ls": 0,
                    "fc": [
                      1,
                      1,
                      1
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 53,
          "ty": 4,
          'nm': 'avatar_contact',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                28,
                237.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.188235297799,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'avatar_contact',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 54,
          "ty": 4,
          'nm': '形状',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                58.713,
                230.852,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -1.42
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.44
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.44,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.44
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.44,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.43,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0.45,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0.44
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.44,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.44
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -1.42
                        ],
                        [
                          1.43,
                          0
                        ]
                      ],
                      "v": [
                        [
                          2.576,
                          -1.614
                        ],
                        [
                          2.576,
                          -1.354
                        ],
                        [
                          3.386,
                          -0.554
                        ],
                        [
                          3.386,
                          3.396
                        ],
                        [
                          2.586,
                          4.196
                        ],
                        [
                          -2.584,
                          4.196
                        ],
                        [
                          -3.384,
                          3.396
                        ],
                        [
                          -3.384,
                          -0.554
                        ],
                        [
                          -2.584,
                          -1.354
                        ],
                        [
                          -2.584,
                          -1.614
                        ],
                        [
                          -0.004,
                          -4.194
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  "ind": 1,
                  'ty': 'sh',
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.88,
                          0
                        ],
                        [
                          0.03,
                          -0.86
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          -0.86,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -0.88
                        ]
                      ],
                      "v": [
                        [
                          -0.004,
                          -3.2
                        ],
                        [
                          -1.594,
                          -1.66
                        ],
                        [
                          -1.594,
                          -1.35
                        ],
                        [
                          1.586,
                          -1.35
                        ],
                        [
                          1.586,
                          -1.61
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 2',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'mm',
                  "mm": 1,
                  'nm': '合并路径 1',
                  'mn': 'ADBE Vector Filter - Merge',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.188235297799,
                      0.113725490868,
                      0.960784316063,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '形状',
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 55,
          "ty": 5,
          'nm': 'You created the grou',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                53.333,
                249.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'You created the group. Invite friends to … ',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.518,
                      0.518,
                      0.518
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 56,
          "ty": 5,
          'nm': '手机联系人',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                66,
                235.263,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 12,
                    'f': 'PingFangSC-Medium',
                    't': 'Bernard Hart',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 14.4000005722046,
                    "ls": 0,
                    "fc": [
                      0.188,
                      0.114,
                      0.961
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 57,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                237.798,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -26.667
                        ],
                        [
                          125,
                          -26.667
                        ],
                        [
                          125,
                          26.663
                        ],
                        [
                          -125,
                          26.663
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 58,
          "ty": 5,
          'nm': 'ComingChat',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                38.154,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 16,
                    'f': 'Anton',
                    't': 'ComingChat',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 19.2000007629395,
                    "ls": 0,
                    "fc": [
                      0.157,
                      0.157,
                      0.157
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 59,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                32,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      250,
                      37.333
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 60,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                25.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -25.333
                        ],
                        [
                          125,
                          -25.333
                        ],
                        [
                          125,
                          25.337
                        ],
                        [
                          -125,
                          25.337
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.890196084976,
                      0.89411765337,
                      0.909803926945,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 61,
          "ty": 0,
          'nm': '▽ 椭圆形 13',
          'refId': 'comp_103',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 323.333333333333,
          "op": 448.333333333333,
          "st": 323.333333333333,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 62,
          "ty": 0,
          'nm': '▽ 椭圆形 14',
          'refId': 'comp_104',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                156.667,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 323.333333333333,
          "op": 448.333333333333,
          "st": 323.333333333333,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 63,
          "ty": 0,
          'nm': '▽ 椭圆形 12',
          'refId': 'comp_105',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                219.333,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 323.333333333333,
          "op": 448.333333333333,
          "st": 323.333333333333,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 64,
          "ty": 0,
          'nm': '▽ 椭圆形 13',
          'refId': 'comp_103',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 237.916666666667,
          "op": 362.916666666667,
          "st": 237.916666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 65,
          "ty": 0,
          'nm': '▽ 椭圆形 14',
          'refId': 'comp_104',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                156.667,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 237.916666666667,
          "op": 362.916666666667,
          "st": 237.916666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 66,
          "ty": 0,
          'nm': '▽ 椭圆形 12',
          'refId': 'comp_105',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                219.333,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 237.916666666667,
          "op": 362.916666666667,
          "st": 237.916666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 67,
          "ty": 0,
          'nm': '▽ 椭圆形 13',
          'refId': 'comp_103',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 120.416666666667,
          "op": 245.416666666667,
          "st": 120.416666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 68,
          "ty": 0,
          'nm': '▽ 椭圆形 14',
          'refId': 'comp_104',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                156.667,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 120.416666666667,
          "op": 245.416666666667,
          "st": 120.416666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 69,
          "ty": 0,
          'nm': '▽ 椭圆形 12',
          'refId': 'comp_105',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                219.333,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 120.416666666667,
          "op": 245.416666666667,
          "st": 120.416666666667,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 70,
          "ty": 0,
          'nm': '▽ 椭圆形 14',
          'refId': 'comp_104',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                156.667,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 71,
          "ty": 5,
          'nm': 'Instacart',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                156.667,
                139.551,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Instacart',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 72,
          "ty": 0,
          'nm': '▽ 椭圆形 13',
          'refId': 'comp_103',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 73,
          "ty": 5,
          'nm': 'FiscalNote',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                94,
                139.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'FiscalNote',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 74,
          "ty": 5,
          'nm': 'Expedia',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                31.333,
                139.663,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'Expedia',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 75,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                31.279,
                113.989,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -0.14,
                          -0.23
                        ],
                        [
                          -1.13,
                          0.67
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.27,
                          0.88
                        ],
                        [
                          -0.49,
                          0.33
                        ],
                        [
                          0.19,
                          0.28
                        ],
                        [
                          0.52,
                          -0.24
                        ],
                        [
                          1.44,
                          -0.76
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0.94,
                          -0.53
                        ],
                        [
                          1.54,
                          -1.05
                        ]
                      ],
                      "o": [
                        [
                          1.69,
                          -0.8
                        ],
                        [
                          0.93,
                          -0.54
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.38,
                          -0.86
                        ],
                        [
                          0.46,
                          -0.32
                        ],
                        [
                          -0.15,
                          -0.31
                        ],
                        [
                          -0.53,
                          0.25
                        ],
                        [
                          -1.39,
                          0.67
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.13,
                          0.65
                        ],
                        [
                          0.13,
                          0.24
                        ]
                      ],
                      "v": [
                        [
                          -9.186,
                          5.519
                        ],
                        [
                          -0.646,
                          1.239
                        ],
                        [
                          1.664,
                          -0.091
                        ],
                        [
                          2.254,
                          1.099
                        ],
                        [
                          3.144,
                          6.979
                        ],
                        [
                          3.824,
                          6.589
                        ],
                        [
                          4.284,
                          -1.631
                        ],
                        [
                          8.154,
                          -4.211
                        ],
                        [
                          9.584,
                          -5.191
                        ],
                        [
                          9.074,
                          -6.071
                        ],
                        [
                          7.504,
                          -5.331
                        ],
                        [
                          3.334,
                          -3.271
                        ],
                        [
                          -4.006,
                          -6.981
                        ],
                        [
                          -4.686,
                          -6.591
                        ],
                        [
                          -0.046,
                          -2.871
                        ],
                        [
                          0.694,
                          -1.771
                        ],
                        [
                          0.704,
                          -1.771
                        ],
                        [
                          -1.616,
                          -0.441
                        ],
                        [
                          -9.586,
                          4.819
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.78823530674,
                      0.298039227724,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 76,
          "ty": 4,
          'nm': '路径',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                31.254,
                113.753,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.93,
                          5.65
                        ],
                        [
                          5.65,
                          1.93
                        ],
                        [
                          1.93,
                          -5.65
                        ],
                        [
                          -5.65,
                          -1.93
                        ]
                      ],
                      "o": [
                        [
                          1.93,
                          -5.65
                        ],
                        [
                          -5.65,
                          -1.93
                        ],
                        [
                          -1.93,
                          5.66
                        ],
                        [
                          5.66,
                          1.93
                        ]
                      ],
                      "v": [
                        [
                          10.233,
                          3.493
                        ],
                        [
                          3.493,
                          -10.237
                        ],
                        [
                          -10.237,
                          -3.497
                        ],
                        [
                          -3.497,
                          10.233
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.1254902035,
                      0.156862750649,
                      0.262745112181,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '路径',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 77,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                31.25,
                113.756,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      28.846,
                      28.846
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 14.423,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.78823530674,
                      0.298039227724,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 78,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                31.333,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.667,
                      28.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 79,
          "ty": 0,
          'nm': '▽ 椭圆形 12',
          'refId': 'comp_105',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                219.333,
                114.465,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                14.5,
                14.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "w": 29,
          "h": 29,
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 80,
          "ty": 5,
          'nm': 'KAYAK',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                219.333,
                139.551,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 8,
                    'f': 'PingFangSC-Regular',
                    't': 'KAYAK',
                    "ca": 0,
                    "j": 2,
                    "tr": 0,
                    "lh": 9.60000038146973,
                    "ls": 0,
                    "fc": [
                      0.2,
                      0.2,
                      0.2
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 81,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                27.133,
                70.8,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          2.65,
                          0
                        ],
                        [
                          0,
                          2.65
                        ],
                        [
                          -2.65,
                          0
                        ],
                        [
                          0,
                          -2.65
                        ]
                      ],
                      "o": [
                        [
                          -2.65,
                          0
                        ],
                        [
                          0,
                          -2.65
                        ],
                        [
                          2.65,
                          0
                        ],
                        [
                          0,
                          2.65
                        ]
                      ],
                      "v": [
                        [
                          0,
                          4.8
                        ],
                        [
                          -4.8,
                          0
                        ],
                        [
                          0,
                          -4.8
                        ],
                        [
                          4.8,
                          0
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.20000000298,
                      0.20000000298,
                      0.20000000298,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 82,
          "ty": 4,
          'nm': 'Path',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                32.533,
                76.2,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -1.8,
                          -1.8
                        ],
                        [
                          1.8,
                          1.8
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.20000000298,
                      0.20000000298,
                      0.20000000298,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 1.333,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'Path',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 83,
          "ty": 5,
          'nm': 'Search',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                41,
                75.176,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "t": {
            "d": {
              "k": [
                {
                  "s": {
                    "s": 9.33333015441895,
                    'f': 'PingFangSC-Regular',
                    't': 'Search',
                    "ca": 0,
                    "j": 0,
                    "tr": 0,
                    "lh": 11.1999969482422,
                    "ls": 0,
                    "fc": [
                      0.6,
                      0.6,
                      0.6
                    ]
                  },
                  "t": 0
                }
              ]
            },
            "p": {},
            "m": {
              "g": 1,
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              }
            },
            "a": []
          },
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 84,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                72,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      228.667,
                      26.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 5.333,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 86,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                78.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -125,
                          -78.5
                        ],
                        [
                          125,
                          -78.5
                        ],
                        [
                          125,
                          78.5
                        ],
                        [
                          -125,
                          78.5
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.952941179276,
                      0.956862747669,
                      0.972549021244,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 87,
          "ty": 4,
          'nm': 'bg_nav',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                32,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      250,
                      37.333
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'st',
                  "c": {
                    "a": 0,
                    "k": [
                      0.592156887054,
                      0.592156887054,
                      0.592156887054,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 0.667,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  'nm': '描边 1',
                  'mn': 'ADBE Vector Graphic - Stroke',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.847058832645,
                      0.847058832645,
                      0.847058832645,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': 'bg_nav',
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 88,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 0,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                125,
                25.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  'ty': 'rc',
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      250,
                      50.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  'nm': '矩形路径 1',
                  'mn': 'ADBE Vector Shape - Rect',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 382.5,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_99',
      'nm': '▽ 矩形 24',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_14',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                504,
                504,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                3.571,
                3.571,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 125,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_100',
      'nm': '▽ 矩形 23',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_15',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                1024,
                1024,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                1.758,
                1.758,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 125,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_101',
      'nm': '▽ 矩形 22',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_16',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                800,
                800,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                2.25,
                2.25,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 125,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_102',
      'nm': '▽ 矩形 21',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 矩形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '矩形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_17',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                590.5,
                620.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                3.048,
                3.048,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 125,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '矩形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                18,
                18,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "ind": 0,
                  'ty': 'sh',
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -15.19,
                          0
                        ],
                        [
                          0,
                          -8.99
                        ],
                        [
                          14.93,
                          0
                        ],
                        [
                          0,
                          9.02
                        ]
                      ],
                      "o": [
                        [
                          15.19,
                          0
                        ],
                        [
                          0,
                          9
                        ],
                        [
                          -14.94,
                          0
                        ],
                        [
                          0,
                          -9.01
                        ]
                      ],
                      "v": [
                        [
                          -0.01,
                          -18
                        ],
                        [
                          18,
                          0.01
                        ],
                        [
                          0.25,
                          18
                        ],
                        [
                          -18,
                          0.03
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  'nm': '路径 1',
                  'mn': 'ADBE Vector Shape - Group',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '矩形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_103',
      'nm': '▽ 椭圆形 13',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 椭圆形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.667,
                      28.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '椭圆形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_18',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.333,
                14.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                278,
                279,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                5.156,
                5.156,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 125,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.333,
                14.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.667,
                      28.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_104',
      'nm': '▽ 椭圆形 14',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 椭圆形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.667,
                      28.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '椭圆形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_19',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.333,
                14.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                279,
                278,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                5.156,
                5.156,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 125,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.333,
                14.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.667,
                      28.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    },
    {
      'id': 'comp_105',
      'nm': '▽ 椭圆形 12',
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          'nm': '▨ 椭圆形 2',
          "parent": 3,
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.667,
                      28.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            },
            {
              'ty': 'fl',
              "c": {
                "a": 0,
                "k": [
                  1,
                  0,
                  0,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              'nm': '填充 1',
              'mn': 'ADBE Vector Graphic - Fill',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          'nm': '椭圆形',
          "tt": 1,
          "tp": 1,
          'refId': 'image_20',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.333,
                14.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                279,
                277,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                5.174,
                5.174,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 125,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          'nm': '椭圆形',
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                14.333,
                14.333,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ef": [
            {
              "ty": 25,
              'nm': '投影',
              "np": 8,
              'mn': 'ADBE Drop Shadow',
              "ix": 1,
              "en": 1,
              "ef": [
                {
                  "ty": 2,
                  'nm': '阴影颜色',
                  'mn': 'ADBE Drop Shadow-0001',
                  "ix": 1,
                  "v": {
                    "a": 0,
                    "k": [
                      0,
                      0,
                      0,
                      0.087237000465
                    ],
                    "ix": 1
                  }
                },
                {
                  "ty": 0,
                  'nm': '不透明度',
                  'mn': 'ADBE Drop Shadow-0002',
                  "ix": 2,
                  "v": {
                    "a": 0,
                    "k": 22.245,
                    "ix": 2
                  }
                },
                {
                  "ty": 0,
                  'nm': '方向',
                  'mn': 'ADBE Drop Shadow-0003',
                  "ix": 3,
                  "v": {
                    "a": 0,
                    "k": 180,
                    "ix": 3
                  }
                },
                {
                  "ty": 0,
                  'nm': '距离',
                  'mn': 'ADBE Drop Shadow-0004',
                  "ix": 4,
                  "v": {
                    "a": 0,
                    "k": 1,
                    "ix": 4
                  }
                },
                {
                  "ty": 0,
                  'nm': '柔和度',
                  'mn': 'ADBE Drop Shadow-0005',
                  "ix": 5,
                  "v": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  }
                },
                {
                  "ty": 7,
                  'nm': '仅阴影',
                  'mn': 'ADBE Drop Shadow-0006',
                  "ix": 6,
                  "v": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  }
                }
              ]
            }
          ],
          "shapes": [
            {
              'ty': 'gr',
              "it": [
                {
                  "d": 1,
                  'ty': 'el',
                  "s": {
                    "a": 0,
                    "k": [
                      28.667,
                      28.667
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  'nm': '椭圆路径 1',
                  'mn': 'ADBE Vector Shape - Ellipse',
                  "hd": false
                },
                {
                  'ty': 'fl',
                  "c": {
                    "a": 0,
                    "k": [
                      0.5,
                      0.5,
                      0.5,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  'nm': '填充 1',
                  'mn': 'ADBE Vector Graphic - Fill',
                  "hd": false
                },
                {
                  'ty': 'tr',
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  'nm': '变换'
                }
              ],
              'nm': '椭圆形',
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              'mn': 'ADBE Vector Group',
              "hd": false
            }
          ],
          "ip": 0,
          "op": 125,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    }
  ],
  "fonts": {
    "list": [
      {
        'fName': 'PingFangSC-Regular',
        'fFamily': 'PingFang SC',
        'fStyle': 'Regular',
        "ascent": 72.7996826171875
      },
      {
        'fName': 'Anton',
        'fFamily': 'Anton',
        'fStyle': 'Regular',
        "ascent": 86.2991333007812
      },
      {
        'fName': 'PingFangSC-Medium',
        'fFamily': 'PingFang SC',
        'fStyle': 'Medium',
        "ascent": 72.7996826171875
      },
      {
        'fName': 'PingFangSC-Semibold',
        'fFamily': 'PingFang SC',
        'fStyle': 'Semibold',
        "ascent": 72.7996826171875
      }
    ]
  },
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      'nm': 'phone_2',
      'refId': 'comp_0',
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            257,
            488.9,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            146.5,
            459,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 293,
      "h": 918,
      "ip": 0,
      "op": 250,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [],
  "chars": [
    {
      'ch': 'S',
      "size": 9.33333015441895,
      'style': 'Regular',
      "w": 63.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.057,
                        0
                      ],
                      [
                        4.733,
                        -3.223
                      ],
                      [
                        0,
                        -6.244
                      ],
                      [
                        -5.539,
                        -3.223
                      ],
                      [
                        -8.661,
                        -2.618
                      ],
                      [
                        -2.014,
                        -1.208
                      ],
                      [
                        0,
                        -4.532
                      ],
                      [
                        3.625,
                        -2.216
                      ],
                      [
                        5.841,
                        0
                      ],
                      [
                        3.323,
                        2.618
                      ],
                      [
                        0.705,
                        6.244
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.64,
                        -4.33
                      ],
                      [
                        -8.459,
                        0
                      ],
                      [
                        -5.035,
                        3.525
                      ],
                      [
                        0,
                        6.244
                      ],
                      [
                        5.942,
                        3.625
                      ],
                      [
                        9.467,
                        2.82
                      ],
                      [
                        1.813,
                        1.007
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        -3.424,
                        2.014
                      ],
                      [
                        -5.338,
                        0
                      ],
                      [
                        -3.223,
                        -2.618
                      ],
                      [
                        -0.906,
                        -5.237
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.834,
                        3.827
                      ]
                    ],
                    "o": [
                      [
                        -7.452,
                        0
                      ],
                      [
                        -5.338,
                        3.424
                      ],
                      [
                        0,
                        6.042
                      ],
                      [
                        2.316,
                        1.208
                      ],
                      [
                        8.057,
                        2.316
                      ],
                      [
                        4.733,
                        2.518
                      ],
                      [
                        0,
                        3.827
                      ],
                      [
                        -3.625,
                        2.216
                      ],
                      [
                        -6.445,
                        0
                      ],
                      [
                        -3.625,
                        -2.921
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        8.862
                      ],
                      [
                        4.834,
                        3.625
                      ],
                      [
                        8.56,
                        0
                      ],
                      [
                        5.035,
                        -3.625
                      ],
                      [
                        0,
                        -6.546
                      ],
                      [
                        -3.021,
                        -1.813
                      ],
                      [
                        -6.949,
                        -2.014
                      ],
                      [
                        -4.129,
                        -2.216
                      ],
                      [
                        0,
                        -4.129
                      ],
                      [
                        3.021,
                        -1.813
                      ],
                      [
                        5.841,
                        0
                      ],
                      [
                        3.122,
                        2.518
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.705,
                        -7.553
                      ],
                      [
                        -4.633,
                        -3.726
                      ]
                    ],
                    "v": [
                      [
                        31.421,
                        -73.315
                      ],
                      [
                        13.092,
                        -68.481
                      ],
                      [
                        5.136,
                        -53.879
                      ],
                      [
                        13.394,
                        -39.981
                      ],
                      [
                        29.91,
                        -34.14
                      ],
                      [
                        45.117,
                        -28.903
                      ],
                      [
                        52.267,
                        -18.329
                      ],
                      [
                        46.829,
                        -9.265
                      ],
                      [
                        32.529,
                        -5.942
                      ],
                      [
                        17.926,
                        -9.97
                      ],
                      [
                        11.38,
                        -23.767
                      ],
                      [
                        3.223,
                        -23.767
                      ],
                      [
                        12.589,
                        -4.028
                      ],
                      [
                        32.529,
                        1.41
                      ],
                      [
                        52.872,
                        -3.928
                      ],
                      [
                        60.425,
                        -18.832
                      ],
                      [
                        51.462,
                        -34.039
                      ],
                      [
                        32.73,
                        -40.988
                      ],
                      [
                        19.537,
                        -45.52
                      ],
                      [
                        13.394,
                        -54.282
                      ],
                      [
                        18.53,
                        -63.446
                      ],
                      [
                        31.018,
                        -66.064
                      ],
                      [
                        44.614,
                        -62.238
                      ],
                      [
                        50.656,
                        -50.656
                      ],
                      [
                        58.813,
                        -50.656
                      ],
                      [
                        50.555,
                        -67.776
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'S',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'S',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'e',
      "size": 9.33333015441895,
      'style': 'Regular',
      "w": 55.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.359,
                        0
                      ],
                      [
                        4.431,
                        -5.438
                      ],
                      [
                        0,
                        -7.654
                      ],
                      [
                        -4.633,
                        -5.035
                      ],
                      [
                        -7.452,
                        0
                      ],
                      [
                        -4.33,
                        3.827
                      ],
                      [
                        -1.208,
                        5.035
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.216,
                        -1.813
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        2.82,
                        3.223
                      ],
                      [
                        0.403,
                        5.841
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.827,
                        5.035
                      ]
                    ],
                    "o": [
                      [
                        -7.553,
                        0
                      ],
                      [
                        -4.431,
                        5.136
                      ],
                      [
                        0,
                        8.459
                      ],
                      [
                        4.431,
                        4.834
                      ],
                      [
                        6.747,
                        0
                      ],
                      [
                        3.525,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.309,
                        3.625
                      ],
                      [
                        -2.618,
                        2.115
                      ],
                      [
                        -4.935,
                        0
                      ],
                      [
                        -2.82,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.201,
                        -9.064
                      ],
                      [
                        -4.23,
                        -5.64
                      ]
                    ],
                    "v": [
                      [
                        27.695,
                        -53.476
                      ],
                      [
                        9.769,
                        -45.319
                      ],
                      [
                        3.223,
                        -26.083
                      ],
                      [
                        10.071,
                        -5.942
                      ],
                      [
                        27.997,
                        1.41
                      ],
                      [
                        44.614,
                        -4.431
                      ],
                      [
                        51.663,
                        -16.718
                      ],
                      [
                        43.607,
                        -16.718
                      ],
                      [
                        38.37,
                        -8.56
                      ],
                      [
                        27.997,
                        -5.338
                      ],
                      [
                        16.315,
                        -10.172
                      ],
                      [
                        11.581,
                        -23.767
                      ],
                      [
                        52.67,
                        -23.767
                      ],
                      [
                        46.628,
                        -45.016
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'e',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.633,
                        0
                      ],
                      [
                        -1.007,
                        -11.179
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        3.021
                      ]
                    ],
                    "o": [
                      [
                        9.869,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.504,
                        -5.136
                      ],
                      [
                        2.82,
                        -3.021
                      ]
                    ],
                    "v": [
                      [
                        27.896,
                        -46.729
                      ],
                      [
                        44.312,
                        -30.011
                      ],
                      [
                        11.783,
                        -30.011
                      ],
                      [
                        16.718,
                        -42.197
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'e',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'e',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'a',
      "size": 9.33333015441895,
      'style': 'Regular',
      "w": 55.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.452,
                        0
                      ],
                      [
                        3.827,
                        -2.417
                      ],
                      [
                        1.108,
                        -5.438
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.719,
                        1.611
                      ],
                      [
                        -3.827,
                        0
                      ],
                      [
                        0,
                        -8.762
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.23,
                        -2.82
                      ],
                      [
                        0,
                        -5.942
                      ],
                      [
                        -3.223,
                        -2.719
                      ],
                      [
                        -5.237,
                        0
                      ],
                      [
                        -3.827,
                        2.115
                      ],
                      [
                        -2.014,
                        2.82
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.021,
                        3.424
                      ]
                    ],
                    "o": [
                      [
                        -6.244,
                        0
                      ],
                      [
                        -4.23,
                        2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.806,
                        -3.323
                      ],
                      [
                        2.417,
                        -1.611
                      ],
                      [
                        9.064,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.855,
                        0.101
                      ],
                      [
                        -4.834,
                        3.021
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        3.122,
                        2.618
                      ],
                      [
                        4.834,
                        0
                      ],
                      [
                        3.223,
                        -1.813
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.042
                      ],
                      [
                        -3.625,
                        -3.928
                      ]
                    ],
                    "v": [
                      [
                        28.903,
                        -53.476
                      ],
                      [
                        13.898,
                        -49.85
                      ],
                      [
                        5.841,
                        -37.665
                      ],
                      [
                        13.797,
                        -37.061
                      ],
                      [
                        19.034,
                        -44.513
                      ],
                      [
                        28.4,
                        -46.829
                      ],
                      [
                        41.995,
                        -33.737
                      ],
                      [
                        41.995,
                        -31.018
                      ],
                      [
                        29.507,
                        -30.817
                      ],
                      [
                        11.38,
                        -26.385
                      ],
                      [
                        4.23,
                        -12.991
                      ],
                      [
                        9.064,
                        -2.518
                      ],
                      [
                        21.552,
                        1.41
                      ],
                      [
                        34.543,
                        -1.813
                      ],
                      [
                        42.499,
                        -8.762
                      ],
                      [
                        42.499,
                        0
                      ],
                      [
                        50.052,
                        0
                      ],
                      [
                        50.052,
                        -33.435
                      ],
                      [
                        45.419,
                        -47.635
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.726,
                        -3.122
                      ],
                      [
                        4.935,
                        0
                      ],
                      [
                        2.014,
                        1.511
                      ],
                      [
                        0,
                        2.417
                      ],
                      [
                        -11.682,
                        0.201
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        -3.827,
                        3.223
                      ],
                      [
                        -3.223,
                        0
                      ],
                      [
                        -2.014,
                        -1.611
                      ],
                      [
                        0,
                        -7.553
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        41.995,
                        -24.976
                      ],
                      [
                        41.995,
                        -20.544
                      ],
                      [
                        36.356,
                        -9.769
                      ],
                      [
                        23.264,
                        -4.935
                      ],
                      [
                        15.408,
                        -7.251
                      ],
                      [
                        12.488,
                        -13.193
                      ],
                      [
                        30.011,
                        -24.774
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'a',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'r',
      "size": 9.33333015441895,
      'style': 'Regular',
      "w": 36.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.82,
                        0
                      ],
                      [
                        2.719,
                        -2.216
                      ],
                      [
                        1.41,
                        -3.021
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        3.424
                      ],
                      [
                        -4.028,
                        0
                      ],
                      [
                        -2.618,
                        -0.806
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -3.625,
                        0
                      ],
                      [
                        -2.216,
                        1.712
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        2.719,
                        -3.625
                      ],
                      [
                        2.518,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.913,
                        -0.806
                      ]
                    ],
                    "v": [
                      [
                        29.105,
                        -53.476
                      ],
                      [
                        19.638,
                        -50.153
                      ],
                      [
                        14.2,
                        -43.002
                      ],
                      [
                        14.2,
                        -52.066
                      ],
                      [
                        6.244,
                        -52.066
                      ],
                      [
                        6.244,
                        0
                      ],
                      [
                        14.2,
                        0
                      ],
                      [
                        14.2,
                        -27.594
                      ],
                      [
                        18.329,
                        -39.981
                      ],
                      [
                        28.5,
                        -45.319
                      ],
                      [
                        36.255,
                        -44.211
                      ],
                      [
                        36.255,
                        -52.267
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'r',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'r',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'c',
      "size": 9.33333015441895,
      'style': 'Regular',
      "w": 54.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        6.445,
                        0
                      ],
                      [
                        4.431,
                        -5.539
                      ],
                      [
                        0,
                        -7.956
                      ],
                      [
                        -4.129,
                        -4.935
                      ],
                      [
                        -7.855,
                        0
                      ],
                      [
                        -3.928,
                        3.323
                      ],
                      [
                        -1.208,
                        6.647
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        8.862,
                        0
                      ],
                      [
                        2.719,
                        3.625
                      ],
                      [
                        0,
                        6.345
                      ],
                      [
                        -2.82,
                        3.726
                      ],
                      [
                        -5.035,
                        0
                      ],
                      [
                        -2.618,
                        -2.014
                      ],
                      [
                        -0.705,
                        -4.028
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.028,
                        3.122
                      ]
                    ],
                    "o": [
                      [
                        -7.755,
                        0
                      ],
                      [
                        -4.129,
                        5.035
                      ],
                      [
                        0,
                        7.956
                      ],
                      [
                        4.33,
                        5.237
                      ],
                      [
                        6.445,
                        0
                      ],
                      [
                        4.028,
                        -3.424
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.208,
                        8.963
                      ],
                      [
                        -5.237,
                        0
                      ],
                      [
                        -2.82,
                        -3.625
                      ],
                      [
                        0,
                        -6.345
                      ],
                      [
                        2.921,
                        -3.827
                      ],
                      [
                        4.129,
                        0
                      ],
                      [
                        2.518,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.906,
                        -6.345
                      ],
                      [
                        -3.928,
                        -3.021
                      ]
                    ],
                    "v": [
                      [
                        28.702,
                        -53.476
                      ],
                      [
                        10.373,
                        -45.218
                      ],
                      [
                        4.23,
                        -25.781
                      ],
                      [
                        10.373,
                        -6.445
                      ],
                      [
                        28.601,
                        1.41
                      ],
                      [
                        44.11,
                        -3.625
                      ],
                      [
                        51.865,
                        -18.832
                      ],
                      [
                        43.808,
                        -18.832
                      ],
                      [
                        28.601,
                        -5.338
                      ],
                      [
                        16.617,
                        -10.876
                      ],
                      [
                        12.488,
                        -25.882
                      ],
                      [
                        16.718,
                        -40.988
                      ],
                      [
                        28.702,
                        -46.729
                      ],
                      [
                        38.773,
                        -43.808
                      ],
                      [
                        43.607,
                        -34.744
                      ],
                      [
                        51.562,
                        -34.744
                      ],
                      [
                        44.211,
                        -48.944
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'c',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'c',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'h',
      "size": 9.33333015441895,
      'style': 'Regular',
      "w": 55.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.719,
                        3.223
                      ],
                      [
                        -4.028,
                        0
                      ],
                      [
                        -2.417,
                        -2.618
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.223,
                        3.525
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        2.82,
                        -1.611
                      ],
                      [
                        1.813,
                        -3.323
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        2.618,
                        -3.223
                      ],
                      [
                        4.33,
                        0
                      ],
                      [
                        2.216,
                        2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.647
                      ],
                      [
                        -3.223,
                        -3.625
                      ],
                      [
                        -3.525,
                        0
                      ],
                      [
                        -3.021,
                        1.712
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        6.244,
                        -73.315
                      ],
                      [
                        6.244,
                        0
                      ],
                      [
                        14.301,
                        0
                      ],
                      [
                        14.301,
                        -29.709
                      ],
                      [
                        18.329,
                        -41.895
                      ],
                      [
                        28.299,
                        -46.729
                      ],
                      [
                        38.37,
                        -42.801
                      ],
                      [
                        41.794,
                        -31.622
                      ],
                      [
                        41.794,
                        0
                      ],
                      [
                        49.85,
                        0
                      ],
                      [
                        49.85,
                        -32.831
                      ],
                      [
                        45.016,
                        -48.138
                      ],
                      [
                        31.018,
                        -53.476
                      ],
                      [
                        21.552,
                        -51.059
                      ],
                      [
                        14.301,
                        -43.506
                      ],
                      [
                        14.301,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'h',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'h',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'K',
      "size": 8,
      'style': 'Regular',
      "w": 69,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        54.181,
                        -71.906
                      ],
                      [
                        15.509,
                        -34.543
                      ],
                      [
                        15.509,
                        -71.906
                      ],
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        15.509,
                        0
                      ],
                      [
                        15.509,
                        -25.177
                      ],
                      [
                        26.688,
                        -35.751
                      ],
                      [
                        58.411,
                        0
                      ],
                      [
                        69.489,
                        0
                      ],
                      [
                        32.428,
                        -41.089
                      ],
                      [
                        64.957,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'K',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'K',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'A',
      "size": 8,
      'style': 'Regular',
      "w": 65.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        28.299,
                        -71.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        8.862,
                        0
                      ],
                      [
                        16.516,
                        -20.142
                      ],
                      [
                        49.548,
                        -20.142
                      ],
                      [
                        57.202,
                        0
                      ],
                      [
                        66.165,
                        0
                      ],
                      [
                        37.866,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'A',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        19.135,
                        -27.09
                      ],
                      [
                        32.932,
                        -63.043
                      ],
                      [
                        33.334,
                        -63.043
                      ],
                      [
                        46.93,
                        -27.09
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'A',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'A',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'Y',
      "size": 8,
      'style': 'Regular',
      "w": 66.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -71.906
                      ],
                      [
                        29.205,
                        -29.205
                      ],
                      [
                        29.205,
                        0
                      ],
                      [
                        37.463,
                        0
                      ],
                      [
                        37.463,
                        -29.205
                      ],
                      [
                        66.165,
                        -71.906
                      ],
                      [
                        56.195,
                        -71.906
                      ],
                      [
                        33.334,
                        -36.859
                      ],
                      [
                        10.474,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'Y',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'Y',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'E',
      "size": 8,
      'style': 'Regular',
      "w": 63.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        59.921,
                        0
                      ],
                      [
                        59.921,
                        -7.15
                      ],
                      [
                        15.509,
                        -7.15
                      ],
                      [
                        15.509,
                        -33.536
                      ],
                      [
                        55.792,
                        -33.536
                      ],
                      [
                        55.792,
                        -40.686
                      ],
                      [
                        15.509,
                        -40.686
                      ],
                      [
                        15.509,
                        -64.755
                      ],
                      [
                        58.209,
                        -64.755
                      ],
                      [
                        58.209,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'E',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'E',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'x',
      "size": 8,
      'style': 'Regular',
      "w": 50.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        2.719,
                        -52.066
                      ],
                      [
                        21.149,
                        -27.594
                      ],
                      [
                        0.504,
                        0
                      ],
                      [
                        10.071,
                        0
                      ],
                      [
                        25.681,
                        -21.652
                      ],
                      [
                        41.19,
                        0
                      ],
                      [
                        50.757,
                        0
                      ],
                      [
                        30.112,
                        -27.594
                      ],
                      [
                        48.541,
                        -52.066
                      ],
                      [
                        39.075,
                        -52.066
                      ],
                      [
                        25.681,
                        -33.536
                      ],
                      [
                        12.186,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'x',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'x',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'p',
      "size": 8,
      'style': 'Regular',
      "w": 58.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.452,
                        0
                      ],
                      [
                        3.726,
                        -6.647
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.452,
                        0
                      ],
                      [
                        -4.23,
                        5.438
                      ],
                      [
                        0,
                        7.654
                      ],
                      [
                        3.928,
                        5.035
                      ]
                    ],
                    "o": [
                      [
                        -7.855,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.028,
                        7.251
                      ],
                      [
                        7.452,
                        0
                      ],
                      [
                        3.827,
                        -5.035
                      ],
                      [
                        0,
                        -7.855
                      ],
                      [
                        -4.33,
                        -5.64
                      ]
                    ],
                    "v": [
                      [
                        31.119,
                        -53.476
                      ],
                      [
                        13.797,
                        -43.506
                      ],
                      [
                        13.797,
                        -52.066
                      ],
                      [
                        6.244,
                        -52.066
                      ],
                      [
                        6.244,
                        19.94
                      ],
                      [
                        14.301,
                        19.94
                      ],
                      [
                        14.301,
                        -9.467
                      ],
                      [
                        31.522,
                        1.41
                      ],
                      [
                        48.944,
                        -6.848
                      ],
                      [
                        54.785,
                        -25.882
                      ],
                      [
                        48.843,
                        -45.117
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'p',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.237,
                        0
                      ],
                      [
                        -2.921,
                        -4.23
                      ],
                      [
                        0,
                        -6.143
                      ],
                      [
                        2.618,
                        -3.625
                      ],
                      [
                        5.64,
                        0
                      ],
                      [
                        2.82,
                        3.625
                      ],
                      [
                        0,
                        6.042
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.618,
                        3.625
                      ]
                    ],
                    "o": [
                      [
                        5.539,
                        0
                      ],
                      [
                        2.417,
                        3.625
                      ],
                      [
                        0,
                        6.244
                      ],
                      [
                        -2.921,
                        3.827
                      ],
                      [
                        -4.834,
                        0
                      ],
                      [
                        -3.021,
                        -3.726
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        2.82,
                        -4.129
                      ]
                    ],
                    "v": [
                      [
                        30.112,
                        -46.829
                      ],
                      [
                        42.801,
                        -40.585
                      ],
                      [
                        46.527,
                        -25.882
                      ],
                      [
                        42.599,
                        -11.078
                      ],
                      [
                        29.81,
                        -5.237
                      ],
                      [
                        18.43,
                        -10.776
                      ],
                      [
                        13.998,
                        -25.378
                      ],
                      [
                        13.998,
                        -26.385
                      ],
                      [
                        17.926,
                        -40.686
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'p',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'p',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'd',
      "size": 8,
      'style': 'Regular',
      "w": 58.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        7.452,
                        0
                      ],
                      [
                        4.23,
                        -5.64
                      ],
                      [
                        0,
                        -7.654
                      ],
                      [
                        -3.928,
                        -5.035
                      ],
                      [
                        -7.654,
                        0
                      ],
                      [
                        -3.625,
                        6.647
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -4.028,
                        -7.352
                      ],
                      [
                        -7.452,
                        0
                      ],
                      [
                        -3.928,
                        5.035
                      ],
                      [
                        0,
                        7.855
                      ],
                      [
                        4.23,
                        5.438
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        44.714,
                        -73.315
                      ],
                      [
                        44.714,
                        -42.499
                      ],
                      [
                        27.493,
                        -53.476
                      ],
                      [
                        10.071,
                        -45.117
                      ],
                      [
                        4.23,
                        -26.184
                      ],
                      [
                        10.071,
                        -6.848
                      ],
                      [
                        27.896,
                        1.41
                      ],
                      [
                        45.218,
                        -8.56
                      ],
                      [
                        45.218,
                        0
                      ],
                      [
                        52.771,
                        0
                      ],
                      [
                        52.771,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'd',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.74,
                        0
                      ],
                      [
                        -2.921,
                        -3.827
                      ],
                      [
                        0,
                        -6.042
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.518,
                        -3.625
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.82,
                        4.028
                      ],
                      [
                        0,
                        6.244
                      ],
                      [
                        -2.618,
                        3.525
                      ]
                    ],
                    "o": [
                      [
                        4.633,
                        0
                      ],
                      [
                        2.82,
                        3.726
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        5.841
                      ],
                      [
                        -2.921,
                        4.028
                      ],
                      [
                        -5.64,
                        0
                      ],
                      [
                        -2.518,
                        -3.625
                      ],
                      [
                        0,
                        -6.244
                      ],
                      [
                        2.82,
                        -4.028
                      ]
                    ],
                    "v": [
                      [
                        29.205,
                        -46.829
                      ],
                      [
                        40.585,
                        -41.19
                      ],
                      [
                        44.916,
                        -26.486
                      ],
                      [
                        44.916,
                        -25.479
                      ],
                      [
                        41.089,
                        -11.279
                      ],
                      [
                        28.903,
                        -5.237
                      ],
                      [
                        16.214,
                        -11.38
                      ],
                      [
                        12.488,
                        -26.184
                      ],
                      [
                        16.315,
                        -40.787
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'd',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'd',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'i',
      "size": 8,
      'style': 'Regular',
      "w": 25.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.712,
                        0
                      ],
                      [
                        1.208,
                        -1.208
                      ],
                      [
                        0,
                        -1.813
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.208,
                        1.007
                      ]
                    ],
                    "o": [
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.007
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.208,
                        1.108
                      ],
                      [
                        1.712,
                        0
                      ],
                      [
                        1.208,
                        -1.208
                      ],
                      [
                        0,
                        -1.813
                      ],
                      [
                        -1.208,
                        -1.208
                      ]
                    ],
                    "v": [
                      [
                        12.891,
                        -71.704
                      ],
                      [
                        8.56,
                        -69.992
                      ],
                      [
                        6.848,
                        -65.762
                      ],
                      [
                        8.56,
                        -61.432
                      ],
                      [
                        12.891,
                        -59.72
                      ],
                      [
                        17.221,
                        -61.432
                      ],
                      [
                        19.034,
                        -65.762
                      ],
                      [
                        17.221,
                        -69.992
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        8.862,
                        -52.066
                      ],
                      [
                        8.862,
                        0
                      ],
                      [
                        16.919,
                        0
                      ],
                      [
                        16.919,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'i',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'F',
      "size": 8,
      'style': 'Regular',
      "w": 57.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        15.509,
                        0
                      ],
                      [
                        15.509,
                        -33.435
                      ],
                      [
                        53.275,
                        -33.435
                      ],
                      [
                        53.275,
                        -40.585
                      ],
                      [
                        15.509,
                        -40.585
                      ],
                      [
                        15.509,
                        -64.755
                      ],
                      [
                        55.389,
                        -64.755
                      ],
                      [
                        55.389,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'F',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'F',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 's',
      "size": 8,
      'style': 'Regular',
      "w": 50.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        12.891,
                        0
                      ],
                      [
                        3.625,
                        -2.618
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        -4.028,
                        -2.417
                      ],
                      [
                        -6.143,
                        -1.511
                      ],
                      [
                        -1.712,
                        -0.806
                      ],
                      [
                        0,
                        -2.921
                      ],
                      [
                        9.265,
                        0
                      ],
                      [
                        2.216,
                        1.611
                      ],
                      [
                        0.604,
                        4.129
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -13.696,
                        0
                      ],
                      [
                        0,
                        10.675
                      ],
                      [
                        4.129,
                        2.618
                      ],
                      [
                        6.244,
                        1.511
                      ],
                      [
                        1.913,
                        0.906
                      ],
                      [
                        0,
                        2.216
                      ],
                      [
                        -2.216,
                        1.309
                      ],
                      [
                        -3.625,
                        0
                      ],
                      [
                        -2.216,
                        -1.611
                      ],
                      [
                        -0.705,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -5.841,
                        0
                      ],
                      [
                        -3.928,
                        2.618
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        2.417,
                        1.611
                      ],
                      [
                        5.64,
                        1.41
                      ],
                      [
                        3.223,
                        1.611
                      ],
                      [
                        0,
                        5.841
                      ],
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.216,
                        -1.813
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.208,
                        11.984
                      ],
                      [
                        14.703,
                        0
                      ],
                      [
                        0,
                        -4.532
                      ],
                      [
                        -2.719,
                        -1.712
                      ],
                      [
                        -5.237,
                        -1.41
                      ],
                      [
                        -3.021,
                        -1.511
                      ],
                      [
                        0,
                        -2.316
                      ],
                      [
                        2.014,
                        -1.41
                      ],
                      [
                        4.129,
                        0
                      ],
                      [
                        2.014,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.41,
                        -10.876
                      ]
                    ],
                    "v": [
                      [
                        24.673,
                        -53.476
                      ],
                      [
                        10.373,
                        -49.548
                      ],
                      [
                        4.532,
                        -39.075
                      ],
                      [
                        10.474,
                        -28.903
                      ],
                      [
                        23.364,
                        -24.271
                      ],
                      [
                        34.442,
                        -20.947
                      ],
                      [
                        39.377,
                        -14.2
                      ],
                      [
                        25.378,
                        -5.338
                      ],
                      [
                        15.408,
                        -7.755
                      ],
                      [
                        11.078,
                        -16.617
                      ],
                      [
                        3.223,
                        -16.617
                      ],
                      [
                        25.58,
                        1.41
                      ],
                      [
                        47.635,
                        -14.703
                      ],
                      [
                        41.391,
                        -25.378
                      ],
                      [
                        27.997,
                        -30.212
                      ],
                      [
                        17.221,
                        -33.636
                      ],
                      [
                        12.79,
                        -39.276
                      ],
                      [
                        16.113,
                        -44.714
                      ],
                      [
                        24.573,
                        -46.729
                      ],
                      [
                        34.14,
                        -44.312
                      ],
                      [
                        38.168,
                        -37.262
                      ],
                      [
                        46.024,
                        -37.262
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 's',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 's',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'l',
      "size": 8,
      'style': 'Regular',
      "w": 23.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.855,
                        -73.315
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        15.811,
                        0
                      ],
                      [
                        15.811,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'l',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'l',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'N',
      "size": 8,
      'style': 'Regular',
      "w": 71.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        15.509,
                        0
                      ],
                      [
                        15.509,
                        -59.116
                      ],
                      [
                        15.811,
                        -59.116
                      ],
                      [
                        56.699,
                        0
                      ],
                      [
                        65.158,
                        0
                      ],
                      [
                        65.158,
                        -71.906
                      ],
                      [
                        56.9,
                        -71.906
                      ],
                      [
                        56.9,
                        -13.394
                      ],
                      [
                        56.598,
                        -13.394
                      ],
                      [
                        15.912,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'N',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'N',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'o',
      "size": 8,
      'style': 'Regular',
      "w": 58.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.755,
                        0
                      ],
                      [
                        4.633,
                        -5.438
                      ],
                      [
                        0,
                        -7.855
                      ],
                      [
                        -4.431,
                        -5.035
                      ],
                      [
                        -7.855,
                        0
                      ],
                      [
                        -4.733,
                        5.438
                      ],
                      [
                        0,
                        7.755
                      ],
                      [
                        4.431,
                        5.136
                      ]
                    ],
                    "o": [
                      [
                        -7.855,
                        0
                      ],
                      [
                        -4.431,
                        5.136
                      ],
                      [
                        0,
                        7.755
                      ],
                      [
                        4.633,
                        5.438
                      ],
                      [
                        7.755,
                        0
                      ],
                      [
                        4.33,
                        -5.035
                      ],
                      [
                        0,
                        -7.855
                      ],
                      [
                        -4.633,
                        -5.438
                      ]
                    ],
                    "v": [
                      [
                        29.507,
                        -53.476
                      ],
                      [
                        10.876,
                        -45.419
                      ],
                      [
                        4.23,
                        -25.983
                      ],
                      [
                        10.876,
                        -6.747
                      ],
                      [
                        29.507,
                        1.41
                      ],
                      [
                        48.239,
                        -6.747
                      ],
                      [
                        54.785,
                        -25.983
                      ],
                      [
                        48.138,
                        -45.419
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.539,
                        0
                      ],
                      [
                        -3.223,
                        -4.23
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        2.719,
                        -3.827
                      ],
                      [
                        5.438,
                        0
                      ],
                      [
                        3.021,
                        4.23
                      ],
                      [
                        0,
                        5.74
                      ],
                      [
                        -2.82,
                        3.827
                      ]
                    ],
                    "o": [
                      [
                        5.438,
                        0
                      ],
                      [
                        2.719,
                        3.827
                      ],
                      [
                        0,
                        5.64
                      ],
                      [
                        -3.223,
                        4.23
                      ],
                      [
                        -5.539,
                        0
                      ],
                      [
                        -2.82,
                        -3.827
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        3.021,
                        -4.23
                      ]
                    ],
                    "v": [
                      [
                        29.507,
                        -46.729
                      ],
                      [
                        42.499,
                        -40.384
                      ],
                      [
                        46.628,
                        -25.983
                      ],
                      [
                        42.499,
                        -11.682
                      ],
                      [
                        29.507,
                        -5.338
                      ],
                      [
                        16.617,
                        -11.682
                      ],
                      [
                        12.488,
                        -25.983
                      ],
                      [
                        16.617,
                        -40.384
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'o',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 't',
      "size": 8,
      'style': 'Regular',
      "w": 35.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.712,
                        -2.014
                      ],
                      [
                        -4.23,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.705,
                        0.806
                      ],
                      [
                        0,
                        1.913
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        3.928
                      ],
                      [
                        1.813,
                        2.216
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.813,
                        0
                      ],
                      [
                        -0.806,
                        -0.806
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        20.142,
                        -68.884
                      ],
                      [
                        12.186,
                        -65.561
                      ],
                      [
                        12.186,
                        -52.066
                      ],
                      [
                        1.712,
                        -52.066
                      ],
                      [
                        1.712,
                        -45.319
                      ],
                      [
                        12.186,
                        -45.319
                      ],
                      [
                        12.186,
                        -12.186
                      ],
                      [
                        14.703,
                        -3.323
                      ],
                      [
                        23.868,
                        0
                      ],
                      [
                        31.723,
                        0
                      ],
                      [
                        31.723,
                        -6.747
                      ],
                      [
                        25.076,
                        -6.747
                      ],
                      [
                        21.249,
                        -8.057
                      ],
                      [
                        20.142,
                        -12.186
                      ],
                      [
                        20.142,
                        -45.319
                      ],
                      [
                        33.032,
                        -45.319
                      ],
                      [
                        33.032,
                        -52.066
                      ],
                      [
                        20.142,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 't',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 't',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'I',
      "size": 8,
      'style': 'Regular',
      "w": 23.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.855,
                        -71.906
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        16.013,
                        0
                      ],
                      [
                        16.013,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'I',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'I',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'n',
      "size": 8,
      'style': 'Regular',
      "w": 55.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        12.891,
                        0
                      ],
                      [
                        2.82,
                        -1.611
                      ],
                      [
                        1.813,
                        -2.921
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        2.82
                      ],
                      [
                        -3.625,
                        0
                      ],
                      [
                        0,
                        -10.172
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -3.424,
                        0
                      ],
                      [
                        -2.82,
                        1.511
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.302,
                        -4.733
                      ],
                      [
                        2.618,
                        -2.618
                      ],
                      [
                        9.064,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -14.301
                      ]
                    ],
                    "v": [
                      [
                        30.615,
                        -53.476
                      ],
                      [
                        21.249,
                        -51.059
                      ],
                      [
                        14.301,
                        -44.412
                      ],
                      [
                        14.301,
                        -52.066
                      ],
                      [
                        6.244,
                        -52.066
                      ],
                      [
                        6.244,
                        0
                      ],
                      [
                        14.301,
                        0
                      ],
                      [
                        14.301,
                        -31.421
                      ],
                      [
                        18.933,
                        -42.7
                      ],
                      [
                        28.299,
                        -46.628
                      ],
                      [
                        41.995,
                        -31.421
                      ],
                      [
                        41.995,
                        0
                      ],
                      [
                        50.052,
                        0
                      ],
                      [
                        50.052,
                        -32.025
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'n',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'n',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'C',
      "size": 16,
      'style': 'Regular',
      "w": 48.49,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.813,
                        -0.862
                      ],
                      [
                        1.106,
                        0
                      ],
                      [
                        0.732,
                        1.172
                      ],
                      [
                        0,
                        1.595
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.521,
                        1.025
                      ],
                      [
                        -1.628,
                        0
                      ],
                      [
                        0,
                        -4.655
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.106,
                        2.328
                      ],
                      [
                        1.936,
                        1.433
                      ],
                      [
                        2.506,
                        0.732
                      ],
                      [
                        2.962,
                        0
                      ],
                      [
                        3.401,
                        -3.825
                      ],
                      [
                        0,
                        -7.715
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.841,
                        -3.499
                      ],
                      [
                        -6.12,
                        0
                      ],
                      [
                        -3.841,
                        3.174
                      ],
                      [
                        0,
                        6.771
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        2.214
                      ],
                      [
                        -0.814,
                        0.863
                      ],
                      [
                        -1.205,
                        0
                      ],
                      [
                        -0.732,
                        -1.172
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.823
                      ],
                      [
                        0.52,
                        -1.025
                      ],
                      [
                        2.734,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -3.027
                      ],
                      [
                        -1.107,
                        -2.327
                      ],
                      [
                        -1.937,
                        -1.432
                      ],
                      [
                        -2.507,
                        -0.732
                      ],
                      [
                        -7.259,
                        0
                      ],
                      [
                        -3.402,
                        3.825
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        6.445
                      ],
                      [
                        3.841,
                        3.5
                      ],
                      [
                        6.901,
                        0
                      ],
                      [
                        3.841,
                        -3.174
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        28.369,
                        -33.496
                      ],
                      [
                        28.369,
                        -20.117
                      ],
                      [
                        27.148,
                        -15.503
                      ],
                      [
                        24.268,
                        -14.209
                      ],
                      [
                        21.362,
                        -15.967
                      ],
                      [
                        20.264,
                        -20.117
                      ],
                      [
                        20.264,
                        -56.885
                      ],
                      [
                        21.045,
                        -61.157
                      ],
                      [
                        24.268,
                        -62.695
                      ],
                      [
                        28.369,
                        -55.713
                      ],
                      [
                        28.369,
                        -46.68
                      ],
                      [
                        45.361,
                        -46.68
                      ],
                      [
                        45.361,
                        -60.4
                      ],
                      [
                        43.701,
                        -68.433
                      ],
                      [
                        39.136,
                        -74.072
                      ],
                      [
                        32.471,
                        -77.319
                      ],
                      [
                        24.268,
                        -78.418
                      ],
                      [
                        8.276,
                        -72.681
                      ],
                      [
                        3.174,
                        -55.371
                      ],
                      [
                        3.174,
                        -19.287
                      ],
                      [
                        8.936,
                        -4.37
                      ],
                      [
                        23.877,
                        0.879
                      ],
                      [
                        39.99,
                        -3.882
                      ],
                      [
                        45.752,
                        -18.799
                      ],
                      [
                        45.752,
                        -33.496
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'C',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'C',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'o',
      "size": 16,
      'style': 'Regular',
      "w": 49.71,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -3.728,
                        3.777
                      ],
                      [
                        0,
                        7.65
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.906,
                        4.102
                      ],
                      [
                        6.803,
                        0
                      ],
                      [
                        3.906,
                        -4.102
                      ],
                      [
                        0,
                        -6.998
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -14.584,
                        0
                      ]
                    ],
                    "o": [
                      [
                        3.727,
                        -3.776
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.998
                      ],
                      [
                        -3.906,
                        -4.102
                      ],
                      [
                        -6.771,
                        0
                      ],
                      [
                        -3.906,
                        4.102
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        15.202
                      ],
                      [
                        7.161,
                        0
                      ]
                    ],
                    "v": [
                      [
                        41.187,
                        -4.785
                      ],
                      [
                        46.777,
                        -21.924
                      ],
                      [
                        46.777,
                        -51.318
                      ],
                      [
                        40.918,
                        -67.969
                      ],
                      [
                        24.854,
                        -74.121
                      ],
                      [
                        8.838,
                        -67.969
                      ],
                      [
                        2.979,
                        -51.318
                      ],
                      [
                        2.979,
                        -21.924
                      ],
                      [
                        24.854,
                        0.879
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -2.734,
                        0
                      ],
                      [
                        0,
                        -4.264
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.764,
                        -0.895
                      ],
                      [
                        1.204,
                        0
                      ],
                      [
                        0.764,
                        0.896
                      ],
                      [
                        0,
                        1.953
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        2.734,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.953
                      ],
                      [
                        -0.765,
                        0.896
                      ],
                      [
                        -1.205,
                        0
                      ],
                      [
                        -0.765,
                        -0.895
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.264
                      ]
                    ],
                    "v": [
                      [
                        24.854,
                        -60.498
                      ],
                      [
                        28.955,
                        -54.102
                      ],
                      [
                        28.955,
                        -18.311
                      ],
                      [
                        27.808,
                        -14.038
                      ],
                      [
                        24.854,
                        -12.695
                      ],
                      [
                        21.899,
                        -14.038
                      ],
                      [
                        20.752,
                        -18.311
                      ],
                      [
                        20.752,
                        -54.102
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'o',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'm',
      "size": 16,
      'style': 'Regular',
      "w": 76.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.146,
                        0.945
                      ],
                      [
                        -0.342,
                        0.977
                      ],
                      [
                        -0.668,
                        0.586
                      ],
                      [
                        -0.945,
                        0
                      ],
                      [
                        -0.342,
                        -0.146
                      ],
                      [
                        -0.228,
                        -0.293
                      ],
                      [
                        -0.179,
                        -0.309
                      ],
                      [
                        -0.098,
                        -0.504
                      ],
                      [
                        -0.066,
                        -0.342
                      ],
                      [
                        -0.017,
                        -0.569
                      ],
                      [
                        0,
                        -0.276
                      ],
                      [
                        0,
                        -0.504
                      ],
                      [
                        0,
                        -0.098
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.13,
                        0.863
                      ],
                      [
                        -0.326,
                        0.912
                      ],
                      [
                        -0.668,
                        0.57
                      ],
                      [
                        -0.912,
                        0
                      ],
                      [
                        0,
                        -4.72
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.13,
                        1.465
                      ],
                      [
                        0.342,
                        1.66
                      ],
                      [
                        0.732,
                        1.254
                      ],
                      [
                        1.09,
                        1.058
                      ],
                      [
                        1.709,
                        0.554
                      ],
                      [
                        2.18,
                        0
                      ],
                      [
                        1.936,
                        -1.123
                      ],
                      [
                        1.53,
                        -2.766
                      ],
                      [
                        1.432,
                        1.189
                      ],
                      [
                        1.432,
                        0.423
                      ],
                      [
                        1.497,
                        0
                      ],
                      [
                        0.846,
                        -0.098
                      ],
                      [
                        1.025,
                        -0.391
                      ],
                      [
                        0.797,
                        -0.651
                      ],
                      [
                        0.716,
                        -1.188
                      ],
                      [
                        0.423,
                        -1.627
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -0.977
                      ],
                      [
                        0.146,
                        -0.944
                      ],
                      [
                        0.342,
                        -0.977
                      ],
                      [
                        0.667,
                        -0.586
                      ],
                      [
                        0.423,
                        0
                      ],
                      [
                        0.342,
                        0.146
                      ],
                      [
                        0.227,
                        0.293
                      ],
                      [
                        0.179,
                        0.31
                      ],
                      [
                        0.098,
                        0.505
                      ],
                      [
                        0.065,
                        0.342
                      ],
                      [
                        0.016,
                        0.57
                      ],
                      [
                        0,
                        0.277
                      ],
                      [
                        0,
                        0.505
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -0.846
                      ],
                      [
                        0.13,
                        -0.862
                      ],
                      [
                        0.325,
                        -0.911
                      ],
                      [
                        0.667,
                        -0.569
                      ],
                      [
                        2.376,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.79
                      ],
                      [
                        -0.13,
                        -1.465
                      ],
                      [
                        -0.342,
                        -1.66
                      ],
                      [
                        -0.732,
                        -1.253
                      ],
                      [
                        -1.091,
                        -1.057
                      ],
                      [
                        -1.709,
                        -0.553
                      ],
                      [
                        -2.898,
                        0
                      ],
                      [
                        -1.937,
                        1.123
                      ],
                      [
                        -0.684,
                        -1.79
                      ],
                      [
                        -1.433,
                        -1.188
                      ],
                      [
                        -1.433,
                        -0.423
                      ],
                      [
                        -1.009,
                        0
                      ],
                      [
                        -0.847,
                        0.098
                      ],
                      [
                        -1.025,
                        0.391
                      ],
                      [
                        -0.798,
                        0.652
                      ],
                      [
                        -0.716,
                        1.189
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        4.102,
                        0
                      ],
                      [
                        21.191,
                        0
                      ],
                      [
                        21.191,
                        -51.709
                      ],
                      [
                        21.411,
                        -54.59
                      ],
                      [
                        22.144,
                        -57.471
                      ],
                      [
                        23.657,
                        -59.814
                      ],
                      [
                        26.074,
                        -60.693
                      ],
                      [
                        27.222,
                        -60.474
                      ],
                      [
                        28.076,
                        -59.814
                      ],
                      [
                        28.687,
                        -58.911
                      ],
                      [
                        29.102,
                        -57.69
                      ],
                      [
                        29.346,
                        -56.421
                      ],
                      [
                        29.468,
                        -55.054
                      ],
                      [
                        29.492,
                        -53.784
                      ],
                      [
                        29.492,
                        -52.612
                      ],
                      [
                        29.492,
                        -51.709
                      ],
                      [
                        29.492,
                        0
                      ],
                      [
                        46.973,
                        0
                      ],
                      [
                        46.973,
                        -52.588
                      ],
                      [
                        47.168,
                        -55.151
                      ],
                      [
                        47.852,
                        -57.812
                      ],
                      [
                        49.341,
                        -60.034
                      ],
                      [
                        51.709,
                        -60.889
                      ],
                      [
                        55.273,
                        -53.809
                      ],
                      [
                        55.273,
                        0
                      ],
                      [
                        72.9,
                        0
                      ],
                      [
                        73.096,
                        -53.516
                      ],
                      [
                        72.9,
                        -58.398
                      ],
                      [
                        72.192,
                        -63.086
                      ],
                      [
                        70.581,
                        -67.456
                      ],
                      [
                        67.847,
                        -70.923
                      ],
                      [
                        63.647,
                        -73.34
                      ],
                      [
                        57.812,
                        -74.17
                      ],
                      [
                        50.562,
                        -72.485
                      ],
                      [
                        45.361,
                        -66.65
                      ],
                      [
                        42.188,
                        -71.118
                      ],
                      [
                        37.891,
                        -73.535
                      ],
                      [
                        33.496,
                        -74.17
                      ],
                      [
                        30.713,
                        -74.023
                      ],
                      [
                        27.905,
                        -73.291
                      ],
                      [
                        25.171,
                        -71.729
                      ],
                      [
                        22.9,
                        -68.97
                      ],
                      [
                        21.191,
                        -64.746
                      ],
                      [
                        21.191,
                        -73.291
                      ],
                      [
                        4.102,
                        -73.291
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'm',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'm',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'i',
      "size": 16,
      'style': 'Regular',
      "w": 25.68,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        21.582,
                        0
                      ],
                      [
                        21.582,
                        -73.291
                      ],
                      [
                        4.102,
                        -73.291
                      ],
                      [
                        4.102,
                        0
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        21.582,
                        -77.783
                      ],
                      [
                        21.582,
                        -86.377
                      ],
                      [
                        4.102,
                        -86.377
                      ],
                      [
                        4.102,
                        -77.783
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'i',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'n',
      "size": 16,
      'style': 'Regular',
      "w": 51.03,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.017,
                        0.521
                      ],
                      [
                        -0.163,
                        0.977
                      ],
                      [
                        -0.277,
                        0.603
                      ],
                      [
                        -0.586,
                        0.472
                      ],
                      [
                        -0.847,
                        0
                      ],
                      [
                        -0.619,
                        -1.172
                      ],
                      [
                        0,
                        -1.53
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.539,
                        2.881
                      ],
                      [
                        4.59,
                        0
                      ],
                      [
                        1.936,
                        -1.497
                      ],
                      [
                        0.716,
                        -3.157
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -0.846
                      ],
                      [
                        0.016,
                        -0.52
                      ],
                      [
                        0.163,
                        -0.977
                      ],
                      [
                        0.276,
                        -0.602
                      ],
                      [
                        0.586,
                        -0.471
                      ],
                      [
                        1.367,
                        0
                      ],
                      [
                        0.618,
                        1.172
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -5.989
                      ],
                      [
                        -2.539,
                        -2.881
                      ],
                      [
                        -2.767,
                        0
                      ],
                      [
                        -1.937,
                        1.498
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        21.582,
                        0
                      ],
                      [
                        21.582,
                        -51.514
                      ],
                      [
                        21.606,
                        -53.564
                      ],
                      [
                        21.875,
                        -55.811
                      ],
                      [
                        22.534,
                        -58.179
                      ],
                      [
                        23.828,
                        -59.79
                      ],
                      [
                        25.977,
                        -60.498
                      ],
                      [
                        28.955,
                        -58.74
                      ],
                      [
                        29.883,
                        -54.688
                      ],
                      [
                        29.883,
                        0
                      ],
                      [
                        47.119,
                        0
                      ],
                      [
                        47.119,
                        -56.494
                      ],
                      [
                        43.311,
                        -69.8
                      ],
                      [
                        32.617,
                        -74.121
                      ],
                      [
                        25.562,
                        -71.875
                      ],
                      [
                        21.582,
                        -64.893
                      ],
                      [
                        21.582,
                        -73.291
                      ],
                      [
                        4.102,
                        -73.291
                      ],
                      [
                        4.102,
                        0
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'n',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'n',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'g',
      "size": 16,
      'style': 'Regular',
      "w": 50.78,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -2.816,
                        0.732
                      ],
                      [
                        -2.1,
                        1.725
                      ],
                      [
                        -1.025,
                        3.027
                      ],
                      [
                        0,
                        4.46
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.83,
                        1.254
                      ],
                      [
                        1.074,
                        0.635
                      ],
                      [
                        1.041,
                        0.261
                      ],
                      [
                        1.172,
                        0
                      ],
                      [
                        1.709,
                        -0.651
                      ],
                      [
                        1.334,
                        -1.497
                      ],
                      [
                        0.684,
                        -2.653
                      ],
                      [
                        0,
                        -3.809
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.537,
                        -2.18
                      ],
                      [
                        -1.107,
                        -1.758
                      ],
                      [
                        -1.937,
                        -1.009
                      ],
                      [
                        -2.572,
                        0
                      ],
                      [
                        -1.433,
                        5.013
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.432,
                        -1.692
                      ],
                      [
                        2.669,
                        0
                      ],
                      [
                        4.264,
                        2.539
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.142,
                        -0.798
                      ],
                      [
                        -4.785,
                        0
                      ]
                    ],
                    "o": [
                      [
                        2.815,
                        -0.732
                      ],
                      [
                        2.1,
                        -1.726
                      ],
                      [
                        1.025,
                        -3.027
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.391,
                        -1.79
                      ],
                      [
                        -0.83,
                        -1.253
                      ],
                      [
                        -1.074,
                        -0.635
                      ],
                      [
                        -1.042,
                        -0.26
                      ],
                      [
                        -2.279,
                        0
                      ],
                      [
                        -1.709,
                        0.652
                      ],
                      [
                        -1.335,
                        1.498
                      ],
                      [
                        -0.684,
                        2.654
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        2.572
                      ],
                      [
                        0.537,
                        2.181
                      ],
                      [
                        1.106,
                        1.758
                      ],
                      [
                        1.936,
                        1.009
                      ],
                      [
                        6.022,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        3.06
                      ],
                      [
                        -1.433,
                        1.693
                      ],
                      [
                        -4.265,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.93,
                        2.441
                      ],
                      [
                        3.141,
                        0.797
                      ],
                      [
                        4.004,
                        0
                      ]
                    ],
                    "v": [
                      [
                        33.179,
                        9.644
                      ],
                      [
                        40.552,
                        5.957
                      ],
                      [
                        45.239,
                        -1.172
                      ],
                      [
                        46.777,
                        -12.402
                      ],
                      [
                        46.777,
                        -73.242
                      ],
                      [
                        28.955,
                        -73.242
                      ],
                      [
                        28.955,
                        -64.99
                      ],
                      [
                        27.124,
                        -69.556
                      ],
                      [
                        24.268,
                        -72.388
                      ],
                      [
                        21.094,
                        -73.73
                      ],
                      [
                        17.773,
                        -74.121
                      ],
                      [
                        11.792,
                        -73.145
                      ],
                      [
                        7.227,
                        -69.922
                      ],
                      [
                        4.199,
                        -63.696
                      ],
                      [
                        3.174,
                        -54.004
                      ],
                      [
                        3.174,
                        -26.514
                      ],
                      [
                        3.979,
                        -19.385
                      ],
                      [
                        6.445,
                        -13.477
                      ],
                      [
                        11.011,
                        -9.326
                      ],
                      [
                        17.773,
                        -7.812
                      ],
                      [
                        28.955,
                        -15.332
                      ],
                      [
                        28.955,
                        -11.279
                      ],
                      [
                        26.807,
                        -4.15
                      ],
                      [
                        20.654,
                        -1.611
                      ],
                      [
                        7.861,
                        -5.42
                      ],
                      [
                        1.953,
                        4.688
                      ],
                      [
                        11.06,
                        9.546
                      ],
                      [
                        22.949,
                        10.742
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'g',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -0.765,
                        -1.367
                      ],
                      [
                        0,
                        -1.399
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.734,
                        0
                      ],
                      [
                        0.586,
                        0.488
                      ],
                      [
                        0.227,
                        0.896
                      ],
                      [
                        0.065,
                        0.635
                      ],
                      [
                        0,
                        0.912
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.049,
                        0.488
                      ],
                      [
                        -0.212,
                        0.765
                      ],
                      [
                        -0.57,
                        0.391
                      ],
                      [
                        -0.879,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0.764,
                        1.367
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        4.948
                      ],
                      [
                        -0.912,
                        0
                      ],
                      [
                        -0.586,
                        -0.488
                      ],
                      [
                        -0.228,
                        -0.895
                      ],
                      [
                        -0.066,
                        -0.635
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -0.846
                      ],
                      [
                        0.049,
                        -0.488
                      ],
                      [
                        0.211,
                        -0.764
                      ],
                      [
                        0.569,
                        -0.391
                      ],
                      [
                        1.334,
                        0
                      ]
                    ],
                    "v": [
                      [
                        27.808,
                        -58.643
                      ],
                      [
                        28.955,
                        -54.492
                      ],
                      [
                        28.955,
                        -28.418
                      ],
                      [
                        24.854,
                        -20.996
                      ],
                      [
                        22.607,
                        -21.729
                      ],
                      [
                        21.387,
                        -23.804
                      ],
                      [
                        20.947,
                        -26.099
                      ],
                      [
                        20.85,
                        -28.418
                      ],
                      [
                        20.85,
                        -54.492
                      ],
                      [
                        20.923,
                        -56.494
                      ],
                      [
                        21.313,
                        -58.374
                      ],
                      [
                        22.485,
                        -60.107
                      ],
                      [
                        24.658,
                        -60.693
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'g',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'g',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'h',
      "size": 16,
      'style': 'Regular',
      "w": 51.71,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.879,
                        0.83
                      ],
                      [
                        -0.847,
                        0
                      ],
                      [
                        0,
                        -5.143
                      ],
                      [
                        0.016,
                        -0.569
                      ],
                      [
                        0,
                        -0.195
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.702,
                        2.979
                      ],
                      [
                        4.622,
                        0
                      ],
                      [
                        1.888,
                        -5.469
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -1.855
                      ],
                      [
                        0.879,
                        -0.83
                      ],
                      [
                        2.897,
                        0
                      ],
                      [
                        0,
                        0.326
                      ],
                      [
                        -0.017,
                        0.57
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -5.891
                      ],
                      [
                        -2.702,
                        -2.979
                      ],
                      [
                        -5.241,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        22.07,
                        0
                      ],
                      [
                        22.07,
                        -55.42
                      ],
                      [
                        23.389,
                        -59.448
                      ],
                      [
                        25.977,
                        -60.693
                      ],
                      [
                        30.322,
                        -52.979
                      ],
                      [
                        30.298,
                        -51.636
                      ],
                      [
                        30.273,
                        -50.488
                      ],
                      [
                        30.273,
                        0
                      ],
                      [
                        47.803,
                        0
                      ],
                      [
                        47.803,
                        -56.396
                      ],
                      [
                        43.75,
                        -69.702
                      ],
                      [
                        32.764,
                        -74.17
                      ],
                      [
                        22.07,
                        -65.967
                      ],
                      [
                        22.07,
                        -86.279
                      ],
                      [
                        4.102,
                        -86.279
                      ],
                      [
                        4.102,
                        0
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'h',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'h',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'a',
      "size": 16,
      'style': 'Regular',
      "w": 49.02,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -0.537,
                        -2.653
                      ],
                      [
                        -0.863,
                        -1.579
                      ],
                      [
                        -1.335,
                        -0.944
                      ],
                      [
                        -1.335,
                        -0.31
                      ],
                      [
                        -1.758,
                        0
                      ],
                      [
                        -2.116,
                        1.758
                      ],
                      [
                        -0.586,
                        2.148
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.221,
                        2.441
                      ],
                      [
                        2.018,
                        1.384
                      ],
                      [
                        2.344,
                        0.7
                      ],
                      [
                        2.506,
                        0
                      ],
                      [
                        3.304,
                        -3.239
                      ],
                      [
                        0,
                        -6.966
                      ],
                      [
                        -0.033,
                        -0.553
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.409,
                        0
                      ],
                      [
                        -0.31,
                        -0.065
                      ],
                      [
                        -0.228,
                        -0.163
                      ],
                      [
                        -0.179,
                        -0.195
                      ],
                      [
                        -0.146,
                        -0.374
                      ],
                      [
                        -0.082,
                        -0.309
                      ],
                      [
                        -0.066,
                        -0.586
                      ],
                      [
                        -0.033,
                        -0.488
                      ],
                      [
                        -0.017,
                        -0.813
                      ],
                      [
                        -0.017,
                        -0.651
                      ],
                      [
                        0,
                        -1.041
                      ],
                      [
                        0,
                        -0.032
                      ],
                      [
                        2.083,
                        -0.977
                      ],
                      [
                        1.66,
                        -2.799
                      ],
                      [
                        0,
                        -6.477
                      ]
                    ],
                    "o": [
                      [
                        0.537,
                        2.654
                      ],
                      [
                        0.862,
                        1.579
                      ],
                      [
                        1.334,
                        0.945
                      ],
                      [
                        1.334,
                        0.309
                      ],
                      [
                        2.441,
                        0
                      ],
                      [
                        2.116,
                        -1.758
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -3.157
                      ],
                      [
                        -1.221,
                        -2.441
                      ],
                      [
                        -2.019,
                        -1.383
                      ],
                      [
                        -2.344,
                        -0.7
                      ],
                      [
                        -7.748,
                        0
                      ],
                      [
                        -3.304,
                        3.239
                      ],
                      [
                        0,
                        1.042
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -2.669
                      ],
                      [
                        0.358,
                        0
                      ],
                      [
                        0.309,
                        0.066
                      ],
                      [
                        0.227,
                        0.163
                      ],
                      [
                        0.179,
                        0.195
                      ],
                      [
                        0.146,
                        0.375
                      ],
                      [
                        0.081,
                        0.31
                      ],
                      [
                        0.065,
                        0.586
                      ],
                      [
                        0.032,
                        0.488
                      ],
                      [
                        0.016,
                        0.814
                      ],
                      [
                        0.016,
                        0.652
                      ],
                      [
                        0,
                        0.066
                      ],
                      [
                        -7.227,
                        2.474
                      ],
                      [
                        -4.102,
                        1.921
                      ],
                      [
                        -1.921,
                        3.255
                      ],
                      [
                        0,
                        3.613
                      ]
                    ],
                    "v": [
                      [
                        2.808,
                        -11.597
                      ],
                      [
                        4.907,
                        -5.249
                      ],
                      [
                        8.203,
                        -1.465
                      ],
                      [
                        12.207,
                        0.415
                      ],
                      [
                        16.846,
                        0.879
                      ],
                      [
                        23.682,
                        -1.758
                      ],
                      [
                        27.734,
                        -7.617
                      ],
                      [
                        27.734,
                        0
                      ],
                      [
                        45.117,
                        0
                      ],
                      [
                        45.117,
                        -55.811
                      ],
                      [
                        43.286,
                        -64.209
                      ],
                      [
                        38.428,
                        -69.946
                      ],
                      [
                        31.885,
                        -73.071
                      ],
                      [
                        24.609,
                        -74.121
                      ],
                      [
                        8.032,
                        -69.263
                      ],
                      [
                        3.076,
                        -53.955
                      ],
                      [
                        3.125,
                        -51.562
                      ],
                      [
                        20.117,
                        -51.562
                      ],
                      [
                        20.117,
                        -56.494
                      ],
                      [
                        23.73,
                        -60.498
                      ],
                      [
                        24.731,
                        -60.4
                      ],
                      [
                        25.537,
                        -60.059
                      ],
                      [
                        26.147,
                        -59.521
                      ],
                      [
                        26.636,
                        -58.667
                      ],
                      [
                        26.978,
                        -57.642
                      ],
                      [
                        27.197,
                        -56.299
                      ],
                      [
                        27.344,
                        -54.688
                      ],
                      [
                        27.417,
                        -52.734
                      ],
                      [
                        27.466,
                        -50.537
                      ],
                      [
                        27.49,
                        -47.998
                      ],
                      [
                        27.49,
                        -47.852
                      ],
                      [
                        13.525,
                        -42.676
                      ],
                      [
                        4.883,
                        -35.596
                      ],
                      [
                        2.002,
                        -20.996
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -1.042,
                        1.611
                      ],
                      [
                        -2.995,
                        1.205
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.098,
                        -0.586
                      ],
                      [
                        0.26,
                        -0.618
                      ],
                      [
                        0.52,
                        -0.391
                      ],
                      [
                        0.716,
                        0
                      ],
                      [
                        0,
                        4.33
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        1.041,
                        -1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0.619
                      ],
                      [
                        -0.098,
                        0.586
                      ],
                      [
                        -0.261,
                        0.619
                      ],
                      [
                        -0.521,
                        0.391
                      ],
                      [
                        -2.605,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -2.213
                      ]
                    ],
                    "v": [
                      [
                        21.387,
                        -33.569
                      ],
                      [
                        27.441,
                        -37.793
                      ],
                      [
                        27.441,
                        -18.506
                      ],
                      [
                        27.295,
                        -16.699
                      ],
                      [
                        26.758,
                        -14.893
                      ],
                      [
                        25.586,
                        -13.379
                      ],
                      [
                        23.73,
                        -12.793
                      ],
                      [
                        19.824,
                        -19.287
                      ],
                      [
                        19.824,
                        -27.832
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'a',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 't',
      "size": 16,
      'style': 'Regular',
      "w": 27.78,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.114,
                        -1.009
                      ],
                      [
                        -0.439,
                        -1.155
                      ],
                      [
                        -0.749,
                        -0.732
                      ],
                      [
                        -1.351,
                        -0.504
                      ],
                      [
                        -1.888,
                        0
                      ],
                      [
                        -2.474,
                        0.846
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.423,
                        0
                      ],
                      [
                        0.276,
                        0.179
                      ],
                      [
                        0.098,
                        0.407
                      ],
                      [
                        0.032,
                        0.359
                      ],
                      [
                        0,
                        0.619
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.302
                      ],
                      [
                        0.114,
                        1.009
                      ],
                      [
                        0.439,
                        1.156
                      ],
                      [
                        0.748,
                        0.732
                      ],
                      [
                        1.35,
                        0.504
                      ],
                      [
                        3.743,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.423,
                        0.13
                      ],
                      [
                        -0.423,
                        0
                      ],
                      [
                        -0.277,
                        -0.179
                      ],
                      [
                        -0.098,
                        -0.407
                      ],
                      [
                        -0.033,
                        -0.358
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        4.932,
                        -70.41
                      ],
                      [
                        2.246,
                        -70.41
                      ],
                      [
                        2.246,
                        -57.08
                      ],
                      [
                        4.932,
                        -57.08
                      ],
                      [
                        4.932,
                        -11.279
                      ],
                      [
                        5.103,
                        -7.812
                      ],
                      [
                        5.933,
                        -4.565
                      ],
                      [
                        7.715,
                        -1.733
                      ],
                      [
                        10.864,
                        0.122
                      ],
                      [
                        15.723,
                        0.879
                      ],
                      [
                        25.049,
                        -0.391
                      ],
                      [
                        25.049,
                        -12.305
                      ],
                      [
                        23.779,
                        -12.109
                      ],
                      [
                        22.729,
                        -12.378
                      ],
                      [
                        22.168,
                        -13.257
                      ],
                      [
                        21.973,
                        -14.404
                      ],
                      [
                        21.924,
                        -15.869
                      ],
                      [
                        21.924,
                        -57.08
                      ],
                      [
                        25.049,
                        -57.08
                      ],
                      [
                        25.049,
                        -70.41
                      ],
                      [
                        21.924,
                        -70.41
                      ],
                      [
                        21.924,
                        -85.986
                      ],
                      [
                        4.932,
                        -85.986
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 't',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 't',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'Anton'
    },
    {
      'ch': 'B',
      "size": 12,
      'style': 'Medium',
      "w": 68.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        2.82
                      ],
                      [
                        0,
                        7.251
                      ],
                      [
                        2.417,
                        2.921
                      ],
                      [
                        4.733,
                        0.906
                      ],
                      [
                        -2.014,
                        2.618
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        3.827,
                        3.223
                      ],
                      [
                        7.05,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        7.654,
                        0
                      ],
                      [
                        4.935,
                        -3.424
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        -2.417,
                        -2.921
                      ],
                      [
                        3.625,
                        -1.309
                      ],
                      [
                        2.014,
                        -2.82
                      ],
                      [
                        0,
                        -5.539
                      ],
                      [
                        -4.028,
                        -3.424
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        39.88,
                        0
                      ],
                      [
                        57.706,
                        -4.23
                      ],
                      [
                        65.158,
                        -20.142
                      ],
                      [
                        61.533,
                        -31.824
                      ],
                      [
                        50.757,
                        -37.564
                      ],
                      [
                        59.116,
                        -43.506
                      ],
                      [
                        62.137,
                        -53.778
                      ],
                      [
                        56.396,
                        -66.87
                      ],
                      [
                        39.88,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        -1.712
                      ],
                      [
                        0,
                        -3.625
                      ],
                      [
                        2.216,
                        -1.813
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        4.834,
                        0
                      ],
                      [
                        2.216,
                        1.611
                      ],
                      [
                        0,
                        3.827
                      ],
                      [
                        -2.216,
                        1.712
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        18.027,
                        -62.842
                      ],
                      [
                        37.161,
                        -62.842
                      ],
                      [
                        47.836,
                        -60.324
                      ],
                      [
                        51.16,
                        -52.469
                      ],
                      [
                        47.836,
                        -44.009
                      ],
                      [
                        36.96,
                        -41.391
                      ],
                      [
                        18.027,
                        -41.391
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.518,
                        -1.913
                      ],
                      [
                        0,
                        -4.23
                      ],
                      [
                        3.223,
                        -1.913
                      ],
                      [
                        4.633,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.518,
                        1.913
                      ],
                      [
                        0,
                        4.129
                      ],
                      [
                        -2.618,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        18.027,
                        -32.428
                      ],
                      [
                        38.672,
                        -32.428
                      ],
                      [
                        50.354,
                        -29.608
                      ],
                      [
                        54.181,
                        -20.343
                      ],
                      [
                        49.246,
                        -11.279
                      ],
                      [
                        38.37,
                        -9.064
                      ],
                      [
                        18.027,
                        -9.064
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'B',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'e',
      "size": 12,
      'style': 'Medium',
      "w": 56.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.459,
                        0
                      ],
                      [
                        4.431,
                        -5.338
                      ],
                      [
                        0,
                        -7.755
                      ],
                      [
                        -4.834,
                        -5.035
                      ],
                      [
                        -7.654,
                        0
                      ],
                      [
                        -4.532,
                        3.928
                      ],
                      [
                        -1.208,
                        4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.813,
                        -1.41
                      ],
                      [
                        3.726,
                        0
                      ],
                      [
                        2.417,
                        2.618
                      ],
                      [
                        0.403,
                        5.035
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.028,
                        5.237
                      ]
                    ],
                    "o": [
                      [
                        -7.654,
                        0
                      ],
                      [
                        -4.633,
                        5.237
                      ],
                      [
                        0,
                        8.56
                      ],
                      [
                        4.431,
                        4.733
                      ],
                      [
                        6.949,
                        0
                      ],
                      [
                        3.625,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.41,
                        3.021
                      ],
                      [
                        -2.316,
                        1.712
                      ],
                      [
                        -4.23,
                        0
                      ],
                      [
                        -2.417,
                        -2.719
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.101,
                        -9.265
                      ],
                      [
                        -4.431,
                        -5.841
                      ]
                    ],
                    "v": [
                      [
                        28.098,
                        -53.476
                      ],
                      [
                        9.869,
                        -45.52
                      ],
                      [
                        3.021,
                        -26.083
                      ],
                      [
                        10.272,
                        -5.74
                      ],
                      [
                        28.5,
                        1.41
                      ],
                      [
                        45.721,
                        -4.532
                      ],
                      [
                        52.872,
                        -16.718
                      ],
                      [
                        42.197,
                        -16.718
                      ],
                      [
                        37.463,
                        -9.97
                      ],
                      [
                        28.4,
                        -7.352
                      ],
                      [
                        18.43,
                        -11.38
                      ],
                      [
                        14.301,
                        -23.062
                      ],
                      [
                        53.778,
                        -23.062
                      ],
                      [
                        47.534,
                        -44.815
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'e',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.028,
                        0
                      ],
                      [
                        -1.007,
                        -9.064
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        2.316
                      ]
                    ],
                    "o": [
                      [
                        8.459,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        -4.431
                      ],
                      [
                        2.417,
                        -2.417
                      ]
                    ],
                    "v": [
                      [
                        28.4,
                        -44.714
                      ],
                      [
                        42.599,
                        -31.119
                      ],
                      [
                        14.502,
                        -31.119
                      ],
                      [
                        18.832,
                        -41.19
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'e',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'e',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'r',
      "size": 12,
      'style': 'Medium',
      "w": 37.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.618,
                        0
                      ],
                      [
                        2.417,
                        -2.014
                      ],
                      [
                        1.309,
                        -2.82
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.719,
                        2.82
                      ],
                      [
                        -3.223,
                        0
                      ],
                      [
                        -2.618,
                        -0.806
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -3.223,
                        0
                      ],
                      [
                        -2.014,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.532
                      ],
                      [
                        2.518,
                        -2.618
                      ],
                      [
                        2.417,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.813,
                        -0.906
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -53.476
                      ],
                      [
                        21.753,
                        -50.555
                      ],
                      [
                        16.718,
                        -44.211
                      ],
                      [
                        16.718,
                        -52.066
                      ],
                      [
                        6.042,
                        -52.066
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        16.718,
                        0
                      ],
                      [
                        16.718,
                        -27.594
                      ],
                      [
                        20.746,
                        -38.672
                      ],
                      [
                        29.407,
                        -42.599
                      ],
                      [
                        36.96,
                        -41.492
                      ],
                      [
                        36.96,
                        -52.167
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'r',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'r',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'n',
      "size": 12,
      'style': 'Medium',
      "w": 57.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        12.891,
                        0
                      ],
                      [
                        2.618,
                        -1.41
                      ],
                      [
                        2.014,
                        -2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        2.417
                      ],
                      [
                        -3.021,
                        0
                      ],
                      [
                        0,
                        -8.862
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -3.223,
                        0
                      ],
                      [
                        -2.618,
                        1.309
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.403,
                        -4.23
                      ],
                      [
                        2.216,
                        -2.216
                      ],
                      [
                        8.057,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -14.301
                      ]
                    ],
                    "v": [
                      [
                        32.428,
                        -53.476
                      ],
                      [
                        23.666,
                        -51.462
                      ],
                      [
                        16.718,
                        -45.621
                      ],
                      [
                        16.718,
                        -52.066
                      ],
                      [
                        6.042,
                        -52.066
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        16.718,
                        0
                      ],
                      [
                        16.718,
                        -31.32
                      ],
                      [
                        21.048,
                        -41.29
                      ],
                      [
                        29.004,
                        -44.513
                      ],
                      [
                        41.19,
                        -31.219
                      ],
                      [
                        41.19,
                        0
                      ],
                      [
                        51.865,
                        0
                      ],
                      [
                        51.865,
                        -32.126
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'n',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'n',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'a',
      "size": 12,
      'style': 'Medium',
      "w": 56.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.654,
                        0
                      ],
                      [
                        3.827,
                        -2.417
                      ],
                      [
                        1.108,
                        -5.64
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        1.41
                      ],
                      [
                        -3.323,
                        0
                      ],
                      [
                        0,
                        -7.251
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.23,
                        -3.021
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        -3.323,
                        -2.719
                      ],
                      [
                        -5.438,
                        0
                      ],
                      [
                        -3.424,
                        1.611
                      ],
                      [
                        -2.014,
                        2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.122,
                        3.323
                      ]
                    ],
                    "o": [
                      [
                        -6.445,
                        0
                      ],
                      [
                        -4.431,
                        2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        -2.921
                      ],
                      [
                        2.014,
                        -1.208
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.654,
                        0.201
                      ],
                      [
                        -4.633,
                        3.122
                      ],
                      [
                        0,
                        4.33
                      ],
                      [
                        3.021,
                        2.719
                      ],
                      [
                        4.633,
                        0
                      ],
                      [
                        3.021,
                        -1.511
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.345
                      ],
                      [
                        -3.625,
                        -3.827
                      ]
                    ],
                    "v": [
                      [
                        29.507,
                        -53.476
                      ],
                      [
                        13.998,
                        -49.951
                      ],
                      [
                        5.64,
                        -37.463
                      ],
                      [
                        16.214,
                        -36.557
                      ],
                      [
                        20.746,
                        -43.002
                      ],
                      [
                        28.802,
                        -44.815
                      ],
                      [
                        40.585,
                        -33.939
                      ],
                      [
                        40.585,
                        -31.824
                      ],
                      [
                        28.903,
                        -31.522
                      ],
                      [
                        10.977,
                        -26.788
                      ],
                      [
                        4.028,
                        -13.293
                      ],
                      [
                        8.963,
                        -2.719
                      ],
                      [
                        21.652,
                        1.41
                      ],
                      [
                        33.737,
                        -1.108
                      ],
                      [
                        41.29,
                        -7.352
                      ],
                      [
                        41.29,
                        0
                      ],
                      [
                        51.16,
                        0
                      ],
                      [
                        51.16,
                        -33.234
                      ],
                      [
                        46.426,
                        -47.736
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.223,
                        -2.719
                      ],
                      [
                        4.532,
                        0
                      ],
                      [
                        1.611,
                        1.309
                      ],
                      [
                        0,
                        2.014
                      ],
                      [
                        -9.769,
                        0.201
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        -3.223,
                        2.719
                      ],
                      [
                        -2.719,
                        0
                      ],
                      [
                        -1.712,
                        -1.309
                      ],
                      [
                        0,
                        -6.445
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        40.585,
                        -24.069
                      ],
                      [
                        40.585,
                        -21.048
                      ],
                      [
                        35.651,
                        -10.876
                      ],
                      [
                        24.069,
                        -6.747
                      ],
                      [
                        17.523,
                        -8.762
                      ],
                      [
                        15.005,
                        -13.696
                      ],
                      [
                        29.608,
                        -23.767
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'a',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'd',
      "size": 12,
      'style': 'Medium',
      "w": 59.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.345,
                        0
                      ],
                      [
                        4.23,
                        -5.438
                      ],
                      [
                        0,
                        -7.654
                      ],
                      [
                        -4.028,
                        -5.035
                      ],
                      [
                        -7.654,
                        0
                      ],
                      [
                        -3.625,
                        5.035
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        -6.143
                      ],
                      [
                        -7.452,
                        0
                      ],
                      [
                        -4.028,
                        5.035
                      ],
                      [
                        0,
                        7.956
                      ],
                      [
                        4.33,
                        5.438
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        43.506,
                        -73.315
                      ],
                      [
                        43.506,
                        -44.312
                      ],
                      [
                        27.594,
                        -53.476
                      ],
                      [
                        9.97,
                        -45.319
                      ],
                      [
                        4.028,
                        -26.285
                      ],
                      [
                        10.071,
                        -6.747
                      ],
                      [
                        28.098,
                        1.41
                      ],
                      [
                        44.211,
                        -6.244
                      ],
                      [
                        44.211,
                        0
                      ],
                      [
                        54.181,
                        0
                      ],
                      [
                        54.181,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'd',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.237,
                        0
                      ],
                      [
                        -2.618,
                        -3.223
                      ],
                      [
                        0,
                        -5.64
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.216,
                        -3.323
                      ],
                      [
                        4.633,
                        0
                      ],
                      [
                        2.417,
                        3.726
                      ],
                      [
                        0,
                        5.74
                      ],
                      [
                        -2.216,
                        3.021
                      ]
                    ],
                    "o": [
                      [
                        3.928,
                        0
                      ],
                      [
                        2.618,
                        3.424
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        5.237
                      ],
                      [
                        -2.618,
                        3.625
                      ],
                      [
                        -5.237,
                        0
                      ],
                      [
                        -2.115,
                        -3.223
                      ],
                      [
                        0,
                        -5.74
                      ],
                      [
                        2.518,
                        -3.625
                      ]
                    ],
                    "v": [
                      [
                        30.011,
                        -44.815
                      ],
                      [
                        39.78,
                        -40.082
                      ],
                      [
                        43.808,
                        -26.486
                      ],
                      [
                        43.808,
                        -25.58
                      ],
                      [
                        40.485,
                        -12.79
                      ],
                      [
                        29.709,
                        -7.251
                      ],
                      [
                        18.127,
                        -12.891
                      ],
                      [
                        15.005,
                        -26.285
                      ],
                      [
                        18.329,
                        -39.478
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'd',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'd',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': ' ',
      "size": 12,
      'style': 'Medium',
      "w": 33.3,
      "data": {},
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'H',
      "size": 12,
      'style': 'Medium',
      "w": 73.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.027,
                        0
                      ],
                      [
                        18.027,
                        -32.227
                      ],
                      [
                        55.692,
                        -32.227
                      ],
                      [
                        55.692,
                        0
                      ],
                      [
                        66.669,
                        0
                      ],
                      [
                        66.669,
                        -71.906
                      ],
                      [
                        55.692,
                        -71.906
                      ],
                      [
                        55.692,
                        -41.592
                      ],
                      [
                        18.027,
                        -41.592
                      ],
                      [
                        18.027,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'H',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'H',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 't',
      "size": 12,
      'style': 'Medium',
      "w": 36,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.014,
                        -2.216
                      ],
                      [
                        -4.431,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        0.604
                      ],
                      [
                        0,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        4.129
                      ],
                      [
                        2.014,
                        2.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.309,
                        0
                      ],
                      [
                        -0.604,
                        -0.705
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        21.854,
                        -68.884
                      ],
                      [
                        11.179,
                        -64.453
                      ],
                      [
                        11.179,
                        -52.066
                      ],
                      [
                        1.511,
                        -52.066
                      ],
                      [
                        1.511,
                        -43.204
                      ],
                      [
                        11.179,
                        -43.204
                      ],
                      [
                        11.179,
                        -12.991
                      ],
                      [
                        14.099,
                        -3.525
                      ],
                      [
                        23.868,
                        0
                      ],
                      [
                        32.428,
                        0
                      ],
                      [
                        32.428,
                        -8.862
                      ],
                      [
                        25.58,
                        -8.862
                      ],
                      [
                        22.76,
                        -9.869
                      ],
                      [
                        21.854,
                        -12.991
                      ],
                      [
                        21.854,
                        -43.204
                      ],
                      [
                        33.737,
                        -43.204
                      ],
                      [
                        33.737,
                        -52.066
                      ],
                      [
                        21.854,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 't',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 't',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'u',
      "size": 8,
      'style': 'Regular',
      "w": 56,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -12.689,
                        0
                      ],
                      [
                        -4.23,
                        6.244
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.82,
                        -2.82
                      ],
                      [
                        3.424,
                        0
                      ],
                      [
                        2.115,
                        2.417
                      ],
                      [
                        0,
                        4.935
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        14.099
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.604,
                        4.431
                      ],
                      [
                        -2.719,
                        2.618
                      ],
                      [
                        -4.733,
                        0
                      ],
                      [
                        -2.115,
                        -2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        6.244,
                        -52.066
                      ],
                      [
                        6.244,
                        -19.839
                      ],
                      [
                        25.177,
                        1.41
                      ],
                      [
                        42.096,
                        -8.057
                      ],
                      [
                        42.096,
                        0
                      ],
                      [
                        50.153,
                        0
                      ],
                      [
                        50.153,
                        -52.066
                      ],
                      [
                        42.096,
                        -52.066
                      ],
                      [
                        42.096,
                        -20.343
                      ],
                      [
                        36.96,
                        -9.467
                      ],
                      [
                        27.695,
                        -5.438
                      ],
                      [
                        17.422,
                        -9.164
                      ],
                      [
                        14.301,
                        -20.242
                      ],
                      [
                        14.301,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'u',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'u',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': ' ',
      "size": 8,
      'style': 'Regular',
      "w": 33.3,
      "data": {},
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'g',
      "size": 8,
      'style': 'Regular',
      "w": 59.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.855,
                        0
                      ],
                      [
                        4.23,
                        -5.136
                      ],
                      [
                        0,
                        -7.654
                      ],
                      [
                        -4.23,
                        -4.834
                      ],
                      [
                        -7.251,
                        0
                      ],
                      [
                        -3.827,
                        6.949
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        10.474,
                        0
                      ],
                      [
                        2.316,
                        1.712
                      ],
                      [
                        0.604,
                        3.525
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.726,
                        -2.82
                      ],
                      [
                        -7.05,
                        0
                      ],
                      [
                        0,
                        16.617
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -7.251,
                        0
                      ],
                      [
                        -4.23,
                        4.834
                      ],
                      [
                        0,
                        7.553
                      ],
                      [
                        4.23,
                        4.935
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        11.481
                      ],
                      [
                        -4.733,
                        0
                      ],
                      [
                        -2.316,
                        -1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.906,
                        5.64
                      ],
                      [
                        3.625,
                        2.82
                      ],
                      [
                        15.811,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.827,
                        -6.949
                      ]
                    ],
                    "v": [
                      [
                        27.695,
                        -53.476
                      ],
                      [
                        10.474,
                        -45.822
                      ],
                      [
                        4.23,
                        -27.191
                      ],
                      [
                        10.474,
                        -8.661
                      ],
                      [
                        27.695,
                        -1.208
                      ],
                      [
                        45.218,
                        -11.682
                      ],
                      [
                        45.218,
                        -2.518
                      ],
                      [
                        29.507,
                        14.804
                      ],
                      [
                        18.933,
                        12.186
                      ],
                      [
                        14.502,
                        4.431
                      ],
                      [
                        6.445,
                        4.431
                      ],
                      [
                        13.394,
                        17.12
                      ],
                      [
                        29.507,
                        21.35
                      ],
                      [
                        53.275,
                        -3.625
                      ],
                      [
                        53.275,
                        -52.066
                      ],
                      [
                        45.218,
                        -52.066
                      ],
                      [
                        45.218,
                        -43.103
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'g',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.237,
                        0
                      ],
                      [
                        -3.021,
                        -3.827
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        2.82,
                        -3.525
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.82,
                        3.827
                      ],
                      [
                        0,
                        5.438
                      ],
                      [
                        -2.82,
                        3.424
                      ]
                    ],
                    "o": [
                      [
                        5.136,
                        0
                      ],
                      [
                        2.82,
                        3.424
                      ],
                      [
                        0,
                        5.64
                      ],
                      [
                        -3.021,
                        3.625
                      ],
                      [
                        -5.64,
                        0
                      ],
                      [
                        -2.518,
                        -3.424
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        2.921,
                        -3.827
                      ]
                    ],
                    "v": [
                      [
                        28.903,
                        -46.829
                      ],
                      [
                        41.089,
                        -41.19
                      ],
                      [
                        45.319,
                        -27.191
                      ],
                      [
                        41.089,
                        -13.394
                      ],
                      [
                        28.802,
                        -7.956
                      ],
                      [
                        16.214,
                        -13.797
                      ],
                      [
                        12.488,
                        -27.191
                      ],
                      [
                        16.718,
                        -41.19
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'g',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'g',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '.',
      "size": 8,
      'style': 'Regular',
      "w": 26.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.611,
                        0
                      ],
                      [
                        1.108,
                        -1.208
                      ],
                      [
                        0,
                        -1.813
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.813,
                        0
                      ],
                      [
                        -1.208,
                        1.208
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.208,
                        1.208
                      ]
                    ],
                    "o": [
                      [
                        -1.813,
                        0
                      ],
                      [
                        -1.208,
                        1.208
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.108,
                        1.208
                      ],
                      [
                        1.611,
                        0
                      ],
                      [
                        1.208,
                        -1.208
                      ],
                      [
                        0,
                        -1.813
                      ],
                      [
                        -1.208,
                        -1.208
                      ]
                    ],
                    "v": [
                      [
                        13.293,
                        -11.984
                      ],
                      [
                        8.963,
                        -10.172
                      ],
                      [
                        7.15,
                        -5.74
                      ],
                      [
                        8.963,
                        -1.41
                      ],
                      [
                        13.293,
                        0.403
                      ],
                      [
                        17.624,
                        -1.41
                      ],
                      [
                        19.437,
                        -5.74
                      ],
                      [
                        17.624,
                        -10.172
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '.',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '.',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'v',
      "size": 8,
      'style': 'Regular',
      "w": 48.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -52.066
                      ],
                      [
                        20.142,
                        0
                      ],
                      [
                        28.299,
                        0
                      ],
                      [
                        48.038,
                        -52.066
                      ],
                      [
                        39.175,
                        -52.066
                      ],
                      [
                        24.271,
                        -10.071
                      ],
                      [
                        9.366,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'v',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'v',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'f',
      "size": 8,
      'style': 'Regular',
      "w": 37.3,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        2.316,
                        -2.518
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.007,
                        1.108
                      ],
                      [
                        -2.216,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.216,
                        2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -2.518
                      ],
                      [
                        1.007,
                        -1.208
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        27.09,
                        -71.906
                      ],
                      [
                        16.919,
                        -68.179
                      ],
                      [
                        13.596,
                        -58.008
                      ],
                      [
                        13.596,
                        -52.066
                      ],
                      [
                        0.504,
                        -52.066
                      ],
                      [
                        0.504,
                        -45.319
                      ],
                      [
                        13.596,
                        -45.319
                      ],
                      [
                        13.596,
                        0
                      ],
                      [
                        21.652,
                        0
                      ],
                      [
                        21.652,
                        -45.319
                      ],
                      [
                        36.356,
                        -45.319
                      ],
                      [
                        36.356,
                        -52.066
                      ],
                      [
                        21.652,
                        -52.066
                      ],
                      [
                        21.652,
                        -57.806
                      ],
                      [
                        23.163,
                        -63.245
                      ],
                      [
                        28.098,
                        -64.957
                      ],
                      [
                        37.061,
                        -64.957
                      ],
                      [
                        37.061,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'f',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'f',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '…',
      "size": 8,
      'style': 'Regular',
      "w": 100,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.813,
                        0
                      ],
                      [
                        1.108,
                        -1.208
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.813
                      ],
                      [
                        1.108,
                        1.108
                      ]
                    ],
                    "o": [
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.108,
                        1.108
                      ],
                      [
                        1.712,
                        0
                      ],
                      [
                        1.108,
                        -1.108
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ]
                    ],
                    "v": [
                      [
                        16.718,
                        -11.984
                      ],
                      [
                        12.488,
                        -10.272
                      ],
                      [
                        10.675,
                        -6.042
                      ],
                      [
                        12.488,
                        -1.712
                      ],
                      [
                        16.718,
                        0
                      ],
                      [
                        21.149,
                        -1.712
                      ],
                      [
                        22.861,
                        -6.042
                      ],
                      [
                        21.149,
                        -10.272
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '…',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.712,
                        0
                      ],
                      [
                        1.208,
                        -1.208
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.813
                      ],
                      [
                        1.108,
                        1.108
                      ]
                    ],
                    "o": [
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.208,
                        1.108
                      ],
                      [
                        1.712,
                        0
                      ],
                      [
                        1.108,
                        -1.108
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ]
                    ],
                    "v": [
                      [
                        50.354,
                        -11.984
                      ],
                      [
                        46.024,
                        -10.272
                      ],
                      [
                        44.312,
                        -6.042
                      ],
                      [
                        46.024,
                        -1.712
                      ],
                      [
                        50.354,
                        0
                      ],
                      [
                        54.785,
                        -1.712
                      ],
                      [
                        56.497,
                        -6.042
                      ],
                      [
                        54.785,
                        -10.272
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '…',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.712,
                        0
                      ],
                      [
                        1.208,
                        -1.208
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.813
                      ],
                      [
                        1.208,
                        1.108
                      ]
                    ],
                    "o": [
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.208,
                        1.108
                      ],
                      [
                        1.712,
                        0
                      ],
                      [
                        1.208,
                        -1.108
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ]
                    ],
                    "v": [
                      [
                        83.89,
                        -11.984
                      ],
                      [
                        79.559,
                        -10.272
                      ],
                      [
                        77.847,
                        -6.042
                      ],
                      [
                        79.559,
                        -1.712
                      ],
                      [
                        83.89,
                        0
                      ],
                      [
                        88.22,
                        -1.712
                      ],
                      [
                        90.033,
                        -6.042
                      ],
                      [
                        88.22,
                        -10.272
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '…',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '…',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'B',
      "size": 16,
      'style': 'Semibold',
      "w": 69,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        2.82
                      ],
                      [
                        0,
                        7.251
                      ],
                      [
                        2.316,
                        2.82
                      ],
                      [
                        4.733,
                        0.906
                      ],
                      [
                        -1.913,
                        2.618
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        3.726,
                        3.223
                      ],
                      [
                        7.15,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        7.654,
                        0
                      ],
                      [
                        4.935,
                        -3.424
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        -2.417,
                        -2.921
                      ],
                      [
                        3.625,
                        -1.41
                      ],
                      [
                        1.913,
                        -2.82
                      ],
                      [
                        0,
                        -5.438
                      ],
                      [
                        -4.028,
                        -3.424
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        40.082,
                        0
                      ],
                      [
                        58.008,
                        -4.23
                      ],
                      [
                        65.46,
                        -20.242
                      ],
                      [
                        61.935,
                        -31.824
                      ],
                      [
                        51.26,
                        -37.564
                      ],
                      [
                        59.518,
                        -43.506
                      ],
                      [
                        62.439,
                        -53.778
                      ],
                      [
                        56.799,
                        -66.769
                      ],
                      [
                        39.981,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        -1.611
                      ],
                      [
                        0,
                        -3.424
                      ],
                      [
                        2.014,
                        -1.712
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        4.834,
                        0
                      ],
                      [
                        2.014,
                        1.511
                      ],
                      [
                        0,
                        3.726
                      ],
                      [
                        -2.115,
                        1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        18.832,
                        -62.238
                      ],
                      [
                        37.061,
                        -62.238
                      ],
                      [
                        47.534,
                        -59.821
                      ],
                      [
                        50.656,
                        -52.368
                      ],
                      [
                        47.534,
                        -44.211
                      ],
                      [
                        36.859,
                        -41.693
                      ],
                      [
                        18.832,
                        -41.693
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        -1.813
                      ],
                      [
                        0,
                        -4.129
                      ],
                      [
                        3.223,
                        -1.813
                      ],
                      [
                        4.431,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.316,
                        1.813
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        -2.518,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        18.832,
                        -32.025
                      ],
                      [
                        38.672,
                        -32.025
                      ],
                      [
                        50.153,
                        -29.306
                      ],
                      [
                        53.677,
                        -20.444
                      ],
                      [
                        48.843,
                        -11.783
                      ],
                      [
                        38.37,
                        -9.668
                      ],
                      [
                        18.832,
                        -9.668
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'B',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'H',
      "size": 16,
      'style': 'Semibold',
      "w": 73.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.832,
                        0
                      ],
                      [
                        18.832,
                        -31.824
                      ],
                      [
                        55.289,
                        -31.824
                      ],
                      [
                        55.289,
                        0
                      ],
                      [
                        67.072,
                        0
                      ],
                      [
                        67.072,
                        -71.906
                      ],
                      [
                        55.289,
                        -71.906
                      ],
                      [
                        55.289,
                        -41.895
                      ],
                      [
                        18.832,
                        -41.895
                      ],
                      [
                        18.832,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'H',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'H',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'C',
      "size": 12,
      'style': 'Medium',
      "w": 73.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.359,
                        0
                      ],
                      [
                        6.042,
                        -7.654
                      ],
                      [
                        0,
                        -10.675
                      ],
                      [
                        -5.237,
                        -6.445
                      ],
                      [
                        -11.783,
                        0
                      ],
                      [
                        -5.539,
                        4.431
                      ],
                      [
                        -1.611,
                        8.359
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.625,
                        -2.82
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        3.827,
                        5.035
                      ],
                      [
                        0,
                        8.661
                      ],
                      [
                        -3.726,
                        4.834
                      ],
                      [
                        -7.755,
                        0
                      ],
                      [
                        -3.424,
                        -2.316
                      ],
                      [
                        -1.208,
                        -4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.237,
                        4.23
                      ]
                    ],
                    "o": [
                      [
                        -11.481,
                        0
                      ],
                      [
                        -5.438,
                        6.747
                      ],
                      [
                        0,
                        10.876
                      ],
                      [
                        5.942,
                        7.452
                      ],
                      [
                        7.755,
                        0
                      ],
                      [
                        5.942,
                        -4.733
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.41,
                        5.64
                      ],
                      [
                        -3.424,
                        2.618
                      ],
                      [
                        -8.057,
                        0
                      ],
                      [
                        -3.625,
                        -4.733
                      ],
                      [
                        0,
                        -8.359
                      ],
                      [
                        3.928,
                        -5.338
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        3.424,
                        2.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.108,
                        -7.251
                      ],
                      [
                        -5.438,
                        -4.431
                      ]
                    ],
                    "v": [
                      [
                        38.269,
                        -73.315
                      ],
                      [
                        12.085,
                        -61.935
                      ],
                      [
                        4.028,
                        -35.852
                      ],
                      [
                        11.783,
                        -9.869
                      ],
                      [
                        38.37,
                        1.41
                      ],
                      [
                        58.31,
                        -5.237
                      ],
                      [
                        69.589,
                        -24.875
                      ],
                      [
                        58.914,
                        -24.875
                      ],
                      [
                        51.361,
                        -12.186
                      ],
                      [
                        38.269,
                        -8.258
                      ],
                      [
                        20.444,
                        -15.811
                      ],
                      [
                        15.005,
                        -35.852
                      ],
                      [
                        20.544,
                        -55.692
                      ],
                      [
                        38.068,
                        -63.647
                      ],
                      [
                        50.958,
                        -60.223
                      ],
                      [
                        57.907,
                        -49.448
                      ],
                      [
                        68.582,
                        -49.448
                      ],
                      [
                        59.015,
                        -66.769
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'C',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'C',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'h',
      "size": 12,
      'style': 'Medium',
      "w": 57.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.316,
                        2.921
                      ],
                      [
                        -3.625,
                        0
                      ],
                      [
                        -2.014,
                        -2.518
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.021,
                        3.525
                      ],
                      [
                        6.244,
                        0
                      ],
                      [
                        2.618,
                        -1.41
                      ],
                      [
                        1.913,
                        -3.021
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.532
                      ],
                      [
                        2.216,
                        -2.921
                      ],
                      [
                        4.028,
                        0
                      ],
                      [
                        1.813,
                        2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.848
                      ],
                      [
                        -3.223,
                        -3.726
                      ],
                      [
                        -3.323,
                        0
                      ],
                      [
                        -2.921,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        6.042,
                        -73.315
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        16.718,
                        0
                      ],
                      [
                        16.718,
                        -29.205
                      ],
                      [
                        20.142,
                        -40.384
                      ],
                      [
                        28.903,
                        -44.714
                      ],
                      [
                        38.068,
                        -40.988
                      ],
                      [
                        40.887,
                        -30.515
                      ],
                      [
                        40.887,
                        0
                      ],
                      [
                        51.562,
                        0
                      ],
                      [
                        51.562,
                        -32.327
                      ],
                      [
                        47.031,
                        -47.937
                      ],
                      [
                        32.831,
                        -53.476
                      ],
                      [
                        23.969,
                        -51.462
                      ],
                      [
                        16.718,
                        -44.714
                      ],
                      [
                        16.718,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'h',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'h',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'o',
      "size": 12,
      'style': 'Medium',
      "w": 59.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.855,
                        0
                      ],
                      [
                        4.733,
                        -5.237
                      ],
                      [
                        0,
                        -7.956
                      ],
                      [
                        -4.633,
                        -5.035
                      ],
                      [
                        -7.855,
                        0
                      ],
                      [
                        -4.834,
                        5.237
                      ],
                      [
                        0,
                        7.855
                      ],
                      [
                        4.633,
                        5.136
                      ]
                    ],
                    "o": [
                      [
                        -7.855,
                        0
                      ],
                      [
                        -4.733,
                        5.136
                      ],
                      [
                        0,
                        7.855
                      ],
                      [
                        4.834,
                        5.237
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        4.633,
                        -5.035
                      ],
                      [
                        0,
                        -7.956
                      ],
                      [
                        -4.733,
                        -5.237
                      ]
                    ],
                    "v": [
                      [
                        30.011,
                        -53.476
                      ],
                      [
                        11.078,
                        -45.621
                      ],
                      [
                        4.028,
                        -25.983
                      ],
                      [
                        10.977,
                        -6.546
                      ],
                      [
                        30.011,
                        1.41
                      ],
                      [
                        49.045,
                        -6.546
                      ],
                      [
                        55.994,
                        -25.983
                      ],
                      [
                        48.944,
                        -45.621
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.935,
                        0
                      ],
                      [
                        -2.82,
                        -3.928
                      ],
                      [
                        0,
                        -5.237
                      ],
                      [
                        2.417,
                        -3.424
                      ],
                      [
                        4.935,
                        0
                      ],
                      [
                        2.719,
                        3.827
                      ],
                      [
                        0,
                        5.237
                      ],
                      [
                        -2.417,
                        3.323
                      ]
                    ],
                    "o": [
                      [
                        4.935,
                        0
                      ],
                      [
                        2.417,
                        3.323
                      ],
                      [
                        0,
                        5.136
                      ],
                      [
                        -2.82,
                        3.827
                      ],
                      [
                        -4.935,
                        0
                      ],
                      [
                        -2.417,
                        -3.323
                      ],
                      [
                        0,
                        -5.237
                      ],
                      [
                        2.719,
                        -3.928
                      ]
                    ],
                    "v": [
                      [
                        30.011,
                        -44.714
                      ],
                      [
                        41.592,
                        -38.873
                      ],
                      [
                        45.218,
                        -25.983
                      ],
                      [
                        41.592,
                        -13.193
                      ],
                      [
                        30.011,
                        -7.352
                      ],
                      [
                        18.53,
                        -13.193
                      ],
                      [
                        15.005,
                        -25.983
                      ],
                      [
                        18.53,
                        -38.873
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'o',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'w',
      "size": 8,
      'style': 'Regular',
      "w": 75.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -52.066
                      ],
                      [
                        18.228,
                        0
                      ],
                      [
                        25.781,
                        0
                      ],
                      [
                        38.068,
                        -41.895
                      ],
                      [
                        50.153,
                        0
                      ],
                      [
                        57.806,
                        0
                      ],
                      [
                        75.531,
                        -52.066
                      ],
                      [
                        66.669,
                        -52.066
                      ],
                      [
                        53.879,
                        -9.869
                      ],
                      [
                        41.794,
                        -52.066
                      ],
                      [
                        34.241,
                        -52.066
                      ],
                      [
                        22.156,
                        -9.869
                      ],
                      [
                        9.366,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'w',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'w',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'G',
      "size": 8,
      'style': 'Regular',
      "w": 74.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.963,
                        0
                      ],
                      [
                        6.143,
                        -7.553
                      ],
                      [
                        0,
                        -10.675
                      ],
                      [
                        -5.841,
                        -6.747
                      ],
                      [
                        -11.38,
                        0
                      ],
                      [
                        -5.237,
                        1.611
                      ],
                      [
                        -3.424,
                        2.82
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.525,
                        -1.007
                      ],
                      [
                        3.928,
                        0
                      ],
                      [
                        4.733,
                        5.539
                      ],
                      [
                        0,
                        9.064
                      ],
                      [
                        -4.633,
                        5.438
                      ],
                      [
                        -7.855,
                        0
                      ],
                      [
                        -3.928,
                        -3.122
                      ],
                      [
                        -1.108,
                        -5.438
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.035,
                        4.129
                      ]
                    ],
                    "o": [
                      [
                        -10.574,
                        0
                      ],
                      [
                        -5.841,
                        6.949
                      ],
                      [
                        0,
                        10.675
                      ],
                      [
                        6.244,
                        7.251
                      ],
                      [
                        5.942,
                        0
                      ],
                      [
                        4.935,
                        -1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.518,
                        1.611
                      ],
                      [
                        -3.625,
                        1.007
                      ],
                      [
                        -9.366,
                        0
                      ],
                      [
                        -4.431,
                        -5.237
                      ],
                      [
                        0,
                        -9.265
                      ],
                      [
                        4.633,
                        -5.539
                      ],
                      [
                        6.345,
                        0
                      ],
                      [
                        3.726,
                        2.921
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.309,
                        -7.855
                      ],
                      [
                        -5.338,
                        -4.431
                      ]
                    ],
                    "v": [
                      [
                        37.967,
                        -73.315
                      ],
                      [
                        12.891,
                        -62.036
                      ],
                      [
                        4.23,
                        -35.651
                      ],
                      [
                        12.991,
                        -9.467
                      ],
                      [
                        39.478,
                        1.41
                      ],
                      [
                        56.195,
                        -1.007
                      ],
                      [
                        68.784,
                        -7.553
                      ],
                      [
                        68.784,
                        -36.96
                      ],
                      [
                        38.068,
                        -36.96
                      ],
                      [
                        38.068,
                        -29.81
                      ],
                      [
                        60.626,
                        -29.81
                      ],
                      [
                        60.626,
                        -11.481
                      ],
                      [
                        51.562,
                        -7.452
                      ],
                      [
                        40.283,
                        -5.841
                      ],
                      [
                        19.135,
                        -14.2
                      ],
                      [
                        12.488,
                        -35.651
                      ],
                      [
                        19.336,
                        -57.706
                      ],
                      [
                        37.967,
                        -65.964
                      ],
                      [
                        53.375,
                        -61.331
                      ],
                      [
                        60.626,
                        -48.843
                      ],
                      [
                        68.884,
                        -48.843
                      ],
                      [
                        59.418,
                        -66.769
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'G',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'G',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'P',
      "size": 8,
      'style': 'Regular',
      "w": 64.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        14.2
                      ],
                      [
                        16.516,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        16.516,
                        0
                      ],
                      [
                        0,
                        -14.099
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        15.509,
                        0
                      ],
                      [
                        15.509,
                        -29.407
                      ],
                      [
                        36.557,
                        -29.407
                      ],
                      [
                        61.432,
                        -50.757
                      ],
                      [
                        36.658,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'P',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        -2.417
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        2.719,
                        -2.316
                      ],
                      [
                        5.841,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        5.74,
                        0
                      ],
                      [
                        2.82,
                        2.115
                      ],
                      [
                        0,
                        4.834
                      ],
                      [
                        -2.82,
                        2.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        15.509,
                        -64.755
                      ],
                      [
                        36.154,
                        -64.755
                      ],
                      [
                        48.944,
                        -61.23
                      ],
                      [
                        53.174,
                        -50.757
                      ],
                      [
                        49.045,
                        -40.082
                      ],
                      [
                        36.154,
                        -36.557
                      ],
                      [
                        15.509,
                        -36.557
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'P',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'P',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'T',
      "size": 8,
      'style': 'Regular',
      "w": 61.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        1.712,
                        -71.906
                      ],
                      [
                        1.712,
                        -64.755
                      ],
                      [
                        27.09,
                        -64.755
                      ],
                      [
                        27.09,
                        0
                      ],
                      [
                        35.248,
                        0
                      ],
                      [
                        35.248,
                        -64.755
                      ],
                      [
                        60.626,
                        -64.755
                      ],
                      [
                        60.626,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'T',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'T',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'B',
      "size": 8,
      'style': 'Regular',
      "w": 67.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        2.82
                      ],
                      [
                        0,
                        7.05
                      ],
                      [
                        2.618,
                        3.021
                      ],
                      [
                        4.733,
                        0.806
                      ],
                      [
                        -2.216,
                        2.618
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        3.827,
                        3.223
                      ],
                      [
                        6.647,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        7.352,
                        0
                      ],
                      [
                        4.935,
                        -3.424
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        -2.618,
                        -3.021
                      ],
                      [
                        3.625,
                        -1.208
                      ],
                      [
                        2.115,
                        -2.921
                      ],
                      [
                        0,
                        -5.64
                      ],
                      [
                        -3.928,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        39.175,
                        0
                      ],
                      [
                        56.598,
                        -4.33
                      ],
                      [
                        64.05,
                        -20.041
                      ],
                      [
                        60.022,
                        -31.723
                      ],
                      [
                        49.045,
                        -37.564
                      ],
                      [
                        57.806,
                        -43.304
                      ],
                      [
                        61.029,
                        -53.778
                      ],
                      [
                        55.289,
                        -67.072
                      ],
                      [
                        39.377,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.618,
                        -2.014
                      ],
                      [
                        0,
                        -4.028
                      ],
                      [
                        2.618,
                        -2.115
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        2.618,
                        2.014
                      ],
                      [
                        0,
                        4.129
                      ],
                      [
                        -2.618,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        15.509,
                        -64.957
                      ],
                      [
                        37.463,
                        -64.957
                      ],
                      [
                        48.944,
                        -62.036
                      ],
                      [
                        52.872,
                        -52.972
                      ],
                      [
                        48.843,
                        -43.607
                      ],
                      [
                        37.363,
                        -40.485
                      ],
                      [
                        15.509,
                        -40.485
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.921,
                        -2.216
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        3.525,
                        -2.216
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        5.539,
                        0
                      ],
                      [
                        3.021,
                        2.216
                      ],
                      [
                        0,
                        4.633
                      ],
                      [
                        -3.021,
                        1.813
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        15.509,
                        -33.636
                      ],
                      [
                        38.47,
                        -33.636
                      ],
                      [
                        51.16,
                        -30.414
                      ],
                      [
                        55.792,
                        -20.142
                      ],
                      [
                        50.455,
                        -9.769
                      ],
                      [
                        38.47,
                        -6.949
                      ],
                      [
                        15.509,
                        -6.949
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'B',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'B',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '!',
      "size": 8,
      'style': 'Regular',
      "w": 33.3,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        12.085,
                        -71.906
                      ],
                      [
                        13.696,
                        -19.336
                      ],
                      [
                        19.94,
                        -19.336
                      ],
                      [
                        21.451,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '!',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        1.813,
                        0
                      ],
                      [
                        1.108,
                        -1.208
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.813
                      ],
                      [
                        1.108,
                        1.108
                      ]
                    ],
                    "o": [
                      [
                        -1.712,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.108,
                        1.108
                      ],
                      [
                        1.712,
                        0
                      ],
                      [
                        1.108,
                        -1.108
                      ],
                      [
                        0,
                        -1.712
                      ],
                      [
                        -1.208,
                        -1.208
                      ]
                    ],
                    "v": [
                      [
                        16.718,
                        -11.984
                      ],
                      [
                        12.488,
                        -10.272
                      ],
                      [
                        10.675,
                        -6.042
                      ],
                      [
                        12.488,
                        -1.712
                      ],
                      [
                        16.718,
                        0
                      ],
                      [
                        21.149,
                        -1.712
                      ],
                      [
                        22.861,
                        -6.042
                      ],
                      [
                        21.149,
                        -10.272
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '!',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '!',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '1',
      "size": 8,
      'style': 'Medium',
      "w": 40.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        3.625,
                        -2.216
                      ],
                      [
                        3.223,
                        -0.806
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.33,
                        4.129
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -2.417,
                        2.618
                      ],
                      [
                        -3.625,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.647,
                        -1.913
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        23.364,
                        -71.906
                      ],
                      [
                        14.301,
                        -64.755
                      ],
                      [
                        4.129,
                        -60.526
                      ],
                      [
                        4.129,
                        -49.649
                      ],
                      [
                        20.645,
                        -58.713
                      ],
                      [
                        20.645,
                        0
                      ],
                      [
                        31.622,
                        0
                      ],
                      [
                        31.622,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '1',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '1',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '1',
      "size": 8,
      'style': 'Regular',
      "w": 40.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        3.424,
                        -2.216
                      ],
                      [
                        3.223,
                        -0.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.532,
                        4.633
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -2.417,
                        2.719
                      ],
                      [
                        -3.424,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.747,
                        -2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        23.163,
                        -71.906
                      ],
                      [
                        14.502,
                        -64.554
                      ],
                      [
                        4.431,
                        -60.223
                      ],
                      [
                        4.431,
                        -51.965
                      ],
                      [
                        21.35,
                        -61.835
                      ],
                      [
                        21.35,
                        0
                      ],
                      [
                        29.608,
                        0
                      ],
                      [
                        29.608,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '1',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '1',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'W',
      "size": 8,
      'style': 'Regular',
      "w": 93,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -71.906
                      ],
                      [
                        21.149,
                        0
                      ],
                      [
                        30.313,
                        0
                      ],
                      [
                        46.729,
                        -59.921
                      ],
                      [
                        47.031,
                        -59.921
                      ],
                      [
                        63.345,
                        0
                      ],
                      [
                        72.51,
                        0
                      ],
                      [
                        93.155,
                        -71.906
                      ],
                      [
                        83.89,
                        -71.906
                      ],
                      [
                        68.079,
                        -11.581
                      ],
                      [
                        67.676,
                        -11.581
                      ],
                      [
                        51.361,
                        -71.906
                      ],
                      [
                        42.398,
                        -71.906
                      ],
                      [
                        25.983,
                        -11.581
                      ],
                      [
                        25.58,
                        -11.581
                      ],
                      [
                        9.769,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'W',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'W',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'J',
      "size": 12,
      'style': 'Medium',
      "w": 53.3,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.813,
                        -2.417
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        0,
                        8.56
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.827,
                        -4.028
                      ],
                      [
                        -7.15,
                        0
                      ],
                      [
                        -3.827,
                        4.23
                      ],
                      [
                        0,
                        8.057
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        5.338
                      ],
                      [
                        -1.913,
                        2.417
                      ],
                      [
                        -7.452,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        7.05
                      ],
                      [
                        3.625,
                        3.928
                      ],
                      [
                        8.258,
                        0
                      ],
                      [
                        3.323,
                        -4.028
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        35.651,
                        -71.906
                      ],
                      [
                        35.651,
                        -23.566
                      ],
                      [
                        32.831,
                        -11.984
                      ],
                      [
                        23.666,
                        -8.258
                      ],
                      [
                        12.488,
                        -21.149
                      ],
                      [
                        12.488,
                        -23.465
                      ],
                      [
                        1.511,
                        -23.465
                      ],
                      [
                        1.511,
                        -21.048
                      ],
                      [
                        7.251,
                        -4.532
                      ],
                      [
                        23.465,
                        1.41
                      ],
                      [
                        41.592,
                        -5.035
                      ],
                      [
                        46.628,
                        -23.163
                      ],
                      [
                        46.628,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'J',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'J',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 's',
      "size": 12,
      'style': 'Medium',
      "w": 52,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        13.495,
                        0
                      ],
                      [
                        3.928,
                        -2.719
                      ],
                      [
                        0,
                        -4.733
                      ],
                      [
                        -4.028,
                        -2.518
                      ],
                      [
                        -6.143,
                        -1.41
                      ],
                      [
                        -1.611,
                        -0.806
                      ],
                      [
                        0,
                        -2.518
                      ],
                      [
                        8.258,
                        0
                      ],
                      [
                        1.813,
                        1.309
                      ],
                      [
                        0.604,
                        3.525
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -14.301,
                        0
                      ],
                      [
                        0,
                        10.977
                      ],
                      [
                        4.129,
                        2.618
                      ],
                      [
                        5.942,
                        1.41
                      ],
                      [
                        1.813,
                        0.806
                      ],
                      [
                        0,
                        1.813
                      ],
                      [
                        -1.913,
                        1.007
                      ],
                      [
                        -3.223,
                        0
                      ],
                      [
                        -1.813,
                        -1.208
                      ],
                      [
                        -0.806,
                        -2.82
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -6.244,
                        0
                      ],
                      [
                        -3.928,
                        2.618
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        2.417,
                        1.611
                      ],
                      [
                        5.338,
                        1.208
                      ],
                      [
                        3.021,
                        1.41
                      ],
                      [
                        0,
                        4.633
                      ],
                      [
                        -4.028,
                        0
                      ],
                      [
                        -1.813,
                        -1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.208,
                        12.085
                      ],
                      [
                        15.408,
                        0
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        -2.82,
                        -1.813
                      ],
                      [
                        -5.237,
                        -1.309
                      ],
                      [
                        -2.82,
                        -1.309
                      ],
                      [
                        0,
                        -2.014
                      ],
                      [
                        1.611,
                        -1.007
                      ],
                      [
                        3.625,
                        0
                      ],
                      [
                        1.712,
                        1.108
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.208,
                        -10.977
                      ]
                    ],
                    "v": [
                      [
                        25.479,
                        -53.476
                      ],
                      [
                        10.172,
                        -49.448
                      ],
                      [
                        4.33,
                        -38.37
                      ],
                      [
                        10.373,
                        -27.896
                      ],
                      [
                        23.264,
                        -23.364
                      ],
                      [
                        33.737,
                        -20.343
                      ],
                      [
                        38.37,
                        -14.401
                      ],
                      [
                        25.882,
                        -7.352
                      ],
                      [
                        17.221,
                        -9.366
                      ],
                      [
                        13.495,
                        -16.818
                      ],
                      [
                        3.021,
                        -16.818
                      ],
                      [
                        26.184,
                        1.41
                      ],
                      [
                        49.347,
                        -15.106
                      ],
                      [
                        43.103,
                        -25.983
                      ],
                      [
                        29.91,
                        -30.917
                      ],
                      [
                        19.437,
                        -34.039
                      ],
                      [
                        15.207,
                        -38.672
                      ],
                      [
                        18.027,
                        -43.304
                      ],
                      [
                        25.278,
                        -44.714
                      ],
                      [
                        33.536,
                        -42.902
                      ],
                      [
                        37.262,
                        -37.061
                      ],
                      [
                        47.635,
                        -37.061
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 's',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 's',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'G',
      "size": 12,
      'style': 'Medium',
      "w": 75.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        9.164,
                        0
                      ],
                      [
                        6.244,
                        -7.452
                      ],
                      [
                        0,
                        -10.776
                      ],
                      [
                        -5.942,
                        -6.747
                      ],
                      [
                        -11.481,
                        0
                      ],
                      [
                        -5.237,
                        1.611
                      ],
                      [
                        -3.726,
                        2.921
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.021,
                        -0.806
                      ],
                      [
                        3.525,
                        0
                      ],
                      [
                        4.23,
                        5.035
                      ],
                      [
                        0,
                        8.661
                      ],
                      [
                        -4.431,
                        5.035
                      ],
                      [
                        -6.949,
                        0
                      ],
                      [
                        -3.424,
                        -2.518
                      ],
                      [
                        -1.007,
                        -4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.136,
                        4.028
                      ]
                    ],
                    "o": [
                      [
                        -10.574,
                        0
                      ],
                      [
                        -5.942,
                        6.949
                      ],
                      [
                        0,
                        10.675
                      ],
                      [
                        6.345,
                        7.251
                      ],
                      [
                        6.244,
                        0
                      ],
                      [
                        4.935,
                        -1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        1.511
                      ],
                      [
                        -3.122,
                        0.806
                      ],
                      [
                        -8.963,
                        0
                      ],
                      [
                        -4.028,
                        -4.633
                      ],
                      [
                        0,
                        -8.862
                      ],
                      [
                        4.129,
                        -4.834
                      ],
                      [
                        5.64,
                        0
                      ],
                      [
                        3.424,
                        2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.309,
                        -8.057
                      ],
                      [
                        -5.338,
                        -4.23
                      ]
                    ],
                    "v": [
                      [
                        38.168,
                        -73.315
                      ],
                      [
                        12.891,
                        -62.238
                      ],
                      [
                        4.028,
                        -35.651
                      ],
                      [
                        12.891,
                        -9.467
                      ],
                      [
                        39.578,
                        1.41
                      ],
                      [
                        56.799,
                        -1.007
                      ],
                      [
                        69.791,
                        -7.755
                      ],
                      [
                        69.791,
                        -37.967
                      ],
                      [
                        38.974,
                        -37.967
                      ],
                      [
                        38.974,
                        -28.601
                      ],
                      [
                        58.813,
                        -28.601
                      ],
                      [
                        58.813,
                        -12.991
                      ],
                      [
                        50.757,
                        -9.467
                      ],
                      [
                        40.787,
                        -8.157
                      ],
                      [
                        20.947,
                        -15.71
                      ],
                      [
                        15.005,
                        -35.651
                      ],
                      [
                        21.552,
                        -56.497
                      ],
                      [
                        38.168,
                        -63.647
                      ],
                      [
                        51.865,
                        -59.921
                      ],
                      [
                        58.511,
                        -49.045
                      ],
                      [
                        69.589,
                        -49.045
                      ],
                      [
                        59.921,
                        -67.072
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'G',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'G',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '’',
      "size": 8,
      'style': 'Regular',
      "w": 34,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.216,
                        0
                      ],
                      [
                        1.208,
                        -1.208
                      ],
                      [
                        0,
                        -1.913
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.712,
                        0
                      ],
                      [
                        -0.403,
                        0.201
                      ],
                      [
                        1.611,
                        -2.115
                      ],
                      [
                        2.618,
                        -0.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.618,
                        3.223
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        1.309,
                        1.813
                      ]
                    ],
                    "o": [
                      [
                        -2.014,
                        0
                      ],
                      [
                        -1.208,
                        1.108
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.108,
                        1.208
                      ],
                      [
                        1.007,
                        0
                      ],
                      [
                        0,
                        2.417
                      ],
                      [
                        -1.611,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.23,
                        -1.208
                      ],
                      [
                        2.618,
                        -3.323
                      ],
                      [
                        0,
                        -3.021
                      ],
                      [
                        -1.41,
                        -1.813
                      ]
                    ],
                    "v": [
                      [
                        14.905,
                        -84.091
                      ],
                      [
                        10.071,
                        -82.379
                      ],
                      [
                        8.359,
                        -77.847
                      ],
                      [
                        10.172,
                        -73.517
                      ],
                      [
                        14.401,
                        -71.704
                      ],
                      [
                        16.617,
                        -72.006
                      ],
                      [
                        14.301,
                        -65.259
                      ],
                      [
                        8.057,
                        -60.928
                      ],
                      [
                        8.057,
                        -55.893
                      ],
                      [
                        18.43,
                        -62.54
                      ],
                      [
                        22.357,
                        -74.222
                      ],
                      [
                        20.343,
                        -81.372
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '’',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '’',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'm',
      "size": 8,
      'style': 'Regular',
      "w": 85.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.956,
                        0
                      ],
                      [
                        3.928,
                        -5.64
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        2.82
                      ],
                      [
                        -3.726,
                        0
                      ],
                      [
                        0,
                        -9.265
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        2.618
                      ],
                      [
                        -3.424,
                        0
                      ],
                      [
                        -2.014,
                        -2.417
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.223,
                        3.525
                      ],
                      [
                        5.438,
                        0
                      ],
                      [
                        2.518,
                        -1.611
                      ],
                      [
                        2.014,
                        -3.424
                      ]
                    ],
                    "o": [
                      [
                        -5.841,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.129
                      ],
                      [
                        2.417,
                        -3.021
                      ],
                      [
                        7.956,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.23
                      ],
                      [
                        2.216,
                        -2.618
                      ],
                      [
                        4.431,
                        0
                      ],
                      [
                        1.913,
                        2.216
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -5.942
                      ],
                      [
                        -3.424,
                        -3.424
                      ],
                      [
                        -3.625,
                        0
                      ],
                      [
                        -2.82,
                        1.511
                      ],
                      [
                        -2.921,
                        -6.647
                      ]
                    ],
                    "v": [
                      [
                        29.004,
                        -53.476
                      ],
                      [
                        14.301,
                        -45.117
                      ],
                      [
                        14.301,
                        -52.066
                      ],
                      [
                        6.244,
                        -52.066
                      ],
                      [
                        6.244,
                        0
                      ],
                      [
                        14.301,
                        0
                      ],
                      [
                        14.301,
                        -31.723
                      ],
                      [
                        17.926,
                        -42.197
                      ],
                      [
                        27.09,
                        -46.628
                      ],
                      [
                        39.075,
                        -32.831
                      ],
                      [
                        39.075,
                        0
                      ],
                      [
                        47.131,
                        0
                      ],
                      [
                        47.131,
                        -32.629
                      ],
                      [
                        50.656,
                        -42.801
                      ],
                      [
                        59.216,
                        -46.628
                      ],
                      [
                        68.884,
                        -43.103
                      ],
                      [
                        71.805,
                        -32.831
                      ],
                      [
                        71.805,
                        0
                      ],
                      [
                        79.861,
                        0
                      ],
                      [
                        79.861,
                        -34.14
                      ],
                      [
                        74.927,
                        -48.34
                      ],
                      [
                        61.734,
                        -53.476
                      ],
                      [
                        52.57,
                        -51.059
                      ],
                      [
                        45.319,
                        -43.607
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'm',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'm',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'y',
      "size": 8,
      'style': 'Regular',
      "w": 49.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -52.066
                      ],
                      [
                        21.149,
                        -1.208
                      ],
                      [
                        12.186,
                        19.94
                      ],
                      [
                        20.947,
                        19.94
                      ],
                      [
                        49.448,
                        -52.066
                      ],
                      [
                        40.585,
                        -52.066
                      ],
                      [
                        25.177,
                        -10.977
                      ],
                      [
                        9.265,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'y',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'y',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'L',
      "size": 16,
      'style': 'Semibold',
      "w": 59.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        57.605,
                        0
                      ],
                      [
                        57.605,
                        -10.071
                      ],
                      [
                        18.732,
                        -10.071
                      ],
                      [
                        18.732,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'L',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'L',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'S',
      "size": 16,
      'style': 'Semibold',
      "w": 65.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.459,
                        0
                      ],
                      [
                        5.035,
                        -3.525
                      ],
                      [
                        0,
                        -6.345
                      ],
                      [
                        -5.539,
                        -3.323
                      ],
                      [
                        -8.862,
                        -2.82
                      ],
                      [
                        -1.41,
                        -0.705
                      ],
                      [
                        0,
                        -3.827
                      ],
                      [
                        3.021,
                        -1.813
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.82,
                        2.014
                      ],
                      [
                        0.806,
                        5.438
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -6.042,
                        -4.23
                      ],
                      [
                        -8.862,
                        0
                      ],
                      [
                        -5.035,
                        3.625
                      ],
                      [
                        0,
                        6.445
                      ],
                      [
                        6.244,
                        3.726
                      ],
                      [
                        9.769,
                        3.021
                      ],
                      [
                        1.511,
                        0.806
                      ],
                      [
                        0,
                        3.021
                      ],
                      [
                        -3.021,
                        1.511
                      ],
                      [
                        -4.532,
                        0
                      ],
                      [
                        -2.618,
                        -2.014
                      ],
                      [
                        -1.007,
                        -4.431
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.035,
                        3.726
                      ]
                    ],
                    "o": [
                      [
                        -7.755,
                        0
                      ],
                      [
                        -5.438,
                        3.625
                      ],
                      [
                        0,
                        6.345
                      ],
                      [
                        2.115,
                        1.208
                      ],
                      [
                        7.956,
                        2.417
                      ],
                      [
                        4.431,
                        2.216
                      ],
                      [
                        0,
                        3.021
                      ],
                      [
                        -3.021,
                        1.712
                      ],
                      [
                        -5.942,
                        0
                      ],
                      [
                        -3.122,
                        -2.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.504,
                        9.164
                      ],
                      [
                        4.935,
                        3.424
                      ],
                      [
                        9.164,
                        0
                      ],
                      [
                        5.035,
                        -3.726
                      ],
                      [
                        0,
                        -6.647
                      ],
                      [
                        -2.82,
                        -1.611
                      ],
                      [
                        -6.647,
                        -2.115
                      ],
                      [
                        -3.424,
                        -1.813
                      ],
                      [
                        0,
                        -3.424
                      ],
                      [
                        2.417,
                        -1.309
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.618,
                        1.913
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.705,
                        -7.855
                      ],
                      [
                        -4.733,
                        -3.525
                      ]
                    ],
                    "v": [
                      [
                        32.428,
                        -73.315
                      ],
                      [
                        13.293,
                        -68.079
                      ],
                      [
                        5.237,
                        -53.073
                      ],
                      [
                        13.495,
                        -38.571
                      ],
                      [
                        30.011,
                        -32.529
                      ],
                      [
                        44.009,
                        -27.795
                      ],
                      [
                        50.757,
                        -18.732
                      ],
                      [
                        46.124,
                        -11.581
                      ],
                      [
                        33.636,
                        -8.963
                      ],
                      [
                        20.544,
                        -12.085
                      ],
                      [
                        14.703,
                        -23.767
                      ],
                      [
                        3.021,
                        -23.767
                      ],
                      [
                        12.891,
                        -3.726
                      ],
                      [
                        33.636,
                        1.41
                      ],
                      [
                        54.987,
                        -4.129
                      ],
                      [
                        62.54,
                        -19.437
                      ],
                      [
                        53.174,
                        -34.946
                      ],
                      [
                        34.341,
                        -41.995
                      ],
                      [
                        22.055,
                        -46.426
                      ],
                      [
                        17.02,
                        -53.778
                      ],
                      [
                        21.451,
                        -61.23
                      ],
                      [
                        31.824,
                        -63.144
                      ],
                      [
                        43.707,
                        -60.223
                      ],
                      [
                        49.246,
                        -50.656
                      ],
                      [
                        60.928,
                        -50.656
                      ],
                      [
                        52.267,
                        -68.079
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'S',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'S',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'M',
      "size": 12,
      'style': 'Medium',
      "w": 89.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.027,
                        0
                      ],
                      [
                        18.027,
                        -51.361
                      ],
                      [
                        18.43,
                        -51.361
                      ],
                      [
                        40.485,
                        0
                      ],
                      [
                        49.951,
                        0
                      ],
                      [
                        72.006,
                        -51.361
                      ],
                      [
                        72.409,
                        -51.361
                      ],
                      [
                        72.409,
                        0
                      ],
                      [
                        83.386,
                        0
                      ],
                      [
                        83.386,
                        -71.906
                      ],
                      [
                        70.395,
                        -71.906
                      ],
                      [
                        45.419,
                        -14.301
                      ],
                      [
                        45.117,
                        -14.301
                      ],
                      [
                        20.041,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'M',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'M',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'b',
      "size": 12,
      'style': 'Medium',
      "w": 59.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.15,
                        0
                      ],
                      [
                        -4.431,
                        5.438
                      ],
                      [
                        0,
                        7.956
                      ],
                      [
                        3.928,
                        5.035
                      ],
                      [
                        7.452,
                        0
                      ],
                      [
                        4.23,
                        -6.143
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.625,
                        5.035
                      ],
                      [
                        7.654,
                        0
                      ],
                      [
                        4.028,
                        -5.035
                      ],
                      [
                        0,
                        -7.654
                      ],
                      [
                        -4.33,
                        -5.438
                      ],
                      [
                        -6.445,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        6.042,
                        -73.315
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        16.013,
                        0
                      ],
                      [
                        16.013,
                        -6.244
                      ],
                      [
                        32.126,
                        1.41
                      ],
                      [
                        50.153,
                        -6.747
                      ],
                      [
                        56.195,
                        -26.285
                      ],
                      [
                        50.253,
                        -45.319
                      ],
                      [
                        32.629,
                        -53.476
                      ],
                      [
                        16.718,
                        -44.312
                      ],
                      [
                        16.718,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'b',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.028,
                        0
                      ],
                      [
                        -2.518,
                        -3.625
                      ],
                      [
                        0,
                        -5.74
                      ],
                      [
                        2.014,
                        -3.223
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.518,
                        3.625
                      ],
                      [
                        0,
                        5.237
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        3.424
                      ]
                    ],
                    "o": [
                      [
                        5.237,
                        0
                      ],
                      [
                        2.216,
                        3.021
                      ],
                      [
                        0,
                        5.74
                      ],
                      [
                        -2.518,
                        3.726
                      ],
                      [
                        -4.633,
                        0
                      ],
                      [
                        -2.216,
                        -3.323
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -5.64
                      ],
                      [
                        2.417,
                        -3.223
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -44.815
                      ],
                      [
                        41.895,
                        -39.478
                      ],
                      [
                        45.218,
                        -26.285
                      ],
                      [
                        42.096,
                        -12.891
                      ],
                      [
                        30.515,
                        -7.251
                      ],
                      [
                        19.739,
                        -12.79
                      ],
                      [
                        16.415,
                        -25.58
                      ],
                      [
                        16.415,
                        -26.486
                      ],
                      [
                        20.544,
                        -40.082
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'b',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'b',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'l',
      "size": 12,
      'style': 'Medium',
      "w": 25,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -73.315
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        17.926,
                        0
                      ],
                      [
                        17.926,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'l',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'l',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'y',
      "size": 12,
      'style': 'Medium',
      "w": 51.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -52.066
                      ],
                      [
                        21.149,
                        -1.208
                      ],
                      [
                        12.085,
                        19.94
                      ],
                      [
                        23.465,
                        19.94
                      ],
                      [
                        51.764,
                        -52.066
                      ],
                      [
                        40.082,
                        -52.066
                      ],
                      [
                        26.385,
                        -14.099
                      ],
                      [
                        12.085,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'y',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'y',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'A',
      "size": 12,
      'style': 'Medium',
      "w": 67.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        27.695,
                        -71.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        11.682,
                        0
                      ],
                      [
                        18.43,
                        -18.53
                      ],
                      [
                        49.347,
                        -18.53
                      ],
                      [
                        56.094,
                        0
                      ],
                      [
                        67.877,
                        0
                      ],
                      [
                        40.182,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'A',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        21.753,
                        -27.594
                      ],
                      [
                        33.737,
                        -60.123
                      ],
                      [
                        34.14,
                        -60.123
                      ],
                      [
                        46.024,
                        -27.594
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'A',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'A',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'k',
      "size": 12,
      'style': 'Medium',
      "w": 54.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        6.042,
                        -73.315
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        16.718,
                        0
                      ],
                      [
                        16.718,
                        -18.228
                      ],
                      [
                        22.659,
                        -23.767
                      ],
                      [
                        41.391,
                        0
                      ],
                      [
                        55.087,
                        0
                      ],
                      [
                        30.011,
                        -30.615
                      ],
                      [
                        53.073,
                        -52.066
                      ],
                      [
                        39.276,
                        -52.066
                      ],
                      [
                        16.718,
                        -30.615
                      ],
                      [
                        16.718,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'k',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'k',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'i',
      "size": 12,
      'style': 'Medium',
      "w": 27.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.115,
                        0
                      ],
                      [
                        1.41,
                        -1.41
                      ],
                      [
                        0,
                        -2.115
                      ],
                      [
                        -1.41,
                        -1.41
                      ],
                      [
                        -2.115,
                        0
                      ],
                      [
                        -1.41,
                        1.309
                      ],
                      [
                        0,
                        2.115
                      ],
                      [
                        1.41,
                        1.309
                      ]
                    ],
                    "o": [
                      [
                        -2.115,
                        0
                      ],
                      [
                        -1.41,
                        1.309
                      ],
                      [
                        0,
                        2.014
                      ],
                      [
                        1.41,
                        1.309
                      ],
                      [
                        2.014,
                        0
                      ],
                      [
                        1.41,
                        -1.41
                      ],
                      [
                        0,
                        -2.115
                      ],
                      [
                        -1.41,
                        -1.41
                      ]
                    ],
                    "v": [
                      [
                        13.596,
                        -72.913
                      ],
                      [
                        8.359,
                        -70.898
                      ],
                      [
                        6.244,
                        -65.762
                      ],
                      [
                        8.359,
                        -60.526
                      ],
                      [
                        13.596,
                        -58.511
                      ],
                      [
                        18.832,
                        -60.526
                      ],
                      [
                        21.048,
                        -65.762
                      ],
                      [
                        18.933,
                        -70.898
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        8.258,
                        -52.066
                      ],
                      [
                        8.258,
                        0
                      ],
                      [
                        18.933,
                        0
                      ],
                      [
                        18.933,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'i',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'L',
      "size": 12,
      'style': 'Medium',
      "w": 59.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        57.504,
                        0
                      ],
                      [
                        57.504,
                        -9.366
                      ],
                      [
                        17.926,
                        -9.366
                      ],
                      [
                        17.926,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'L',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'L',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'S',
      "size": 12,
      'style': 'Medium',
      "w": 64.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.359,
                        0
                      ],
                      [
                        5.035,
                        -3.424
                      ],
                      [
                        0,
                        -6.345
                      ],
                      [
                        -5.539,
                        -3.323
                      ],
                      [
                        -8.862,
                        -2.82
                      ],
                      [
                        -1.611,
                        -0.806
                      ],
                      [
                        0,
                        -4.028
                      ],
                      [
                        3.223,
                        -1.813
                      ],
                      [
                        5.438,
                        0
                      ],
                      [
                        2.921,
                        2.216
                      ],
                      [
                        0.806,
                        5.64
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.942,
                        -4.23
                      ],
                      [
                        -8.762,
                        0
                      ],
                      [
                        -5.035,
                        3.625
                      ],
                      [
                        0,
                        6.445
                      ],
                      [
                        6.143,
                        3.726
                      ],
                      [
                        9.668,
                        3.021
                      ],
                      [
                        1.611,
                        0.906
                      ],
                      [
                        0,
                        3.223
                      ],
                      [
                        -3.122,
                        1.611
                      ],
                      [
                        -4.733,
                        0
                      ],
                      [
                        -2.82,
                        -2.115
                      ],
                      [
                        -1.007,
                        -4.633
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.035,
                        3.726
                      ]
                    ],
                    "o": [
                      [
                        -7.654,
                        0
                      ],
                      [
                        -5.338,
                        3.625
                      ],
                      [
                        0,
                        6.244
                      ],
                      [
                        2.216,
                        1.208
                      ],
                      [
                        7.956,
                        2.417
                      ],
                      [
                        4.532,
                        2.316
                      ],
                      [
                        0,
                        3.223
                      ],
                      [
                        -3.223,
                        1.813
                      ],
                      [
                        -6.042,
                        0
                      ],
                      [
                        -3.223,
                        -2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        9.064
                      ],
                      [
                        4.935,
                        3.424
                      ],
                      [
                        9.064,
                        0
                      ],
                      [
                        5.035,
                        -3.726
                      ],
                      [
                        0,
                        -6.647
                      ],
                      [
                        -2.82,
                        -1.712
                      ],
                      [
                        -6.747,
                        -2.014
                      ],
                      [
                        -3.625,
                        -1.913
                      ],
                      [
                        0,
                        -3.625
                      ],
                      [
                        2.518,
                        -1.41
                      ],
                      [
                        5.438,
                        0
                      ],
                      [
                        2.82,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.705,
                        -7.855
                      ],
                      [
                        -4.733,
                        -3.625
                      ]
                    ],
                    "v": [
                      [
                        32.227,
                        -73.315
                      ],
                      [
                        13.193,
                        -68.179
                      ],
                      [
                        5.237,
                        -53.275
                      ],
                      [
                        13.495,
                        -38.873
                      ],
                      [
                        30.011,
                        -32.932
                      ],
                      [
                        44.312,
                        -28.098
                      ],
                      [
                        51.16,
                        -18.631
                      ],
                      [
                        46.326,
                        -11.078
                      ],
                      [
                        33.334,
                        -8.258
                      ],
                      [
                        19.94,
                        -11.581
                      ],
                      [
                        13.898,
                        -23.767
                      ],
                      [
                        3.021,
                        -23.767
                      ],
                      [
                        12.79,
                        -3.827
                      ],
                      [
                        33.334,
                        1.41
                      ],
                      [
                        54.483,
                        -4.028
                      ],
                      [
                        62.036,
                        -19.235
                      ],
                      [
                        52.771,
                        -34.744
                      ],
                      [
                        33.939,
                        -41.794
                      ],
                      [
                        21.451,
                        -46.225
                      ],
                      [
                        16.113,
                        -53.879
                      ],
                      [
                        20.746,
                        -61.734
                      ],
                      [
                        31.622,
                        -63.849
                      ],
                      [
                        43.909,
                        -60.727
                      ],
                      [
                        49.548,
                        -50.656
                      ],
                      [
                        60.425,
                        -50.656
                      ],
                      [
                        51.865,
                        -67.978
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'S',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'S',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'c',
      "size": 12,
      'style': 'Medium',
      "w": 55.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        6.647,
                        0
                      ],
                      [
                        4.532,
                        -5.438
                      ],
                      [
                        0,
                        -7.855
                      ],
                      [
                        -4.33,
                        -5.035
                      ],
                      [
                        -7.956,
                        0
                      ],
                      [
                        -4.028,
                        3.323
                      ],
                      [
                        -1.208,
                        6.949
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        7.956,
                        0
                      ],
                      [
                        2.316,
                        3.223
                      ],
                      [
                        0,
                        5.841
                      ],
                      [
                        -2.618,
                        3.223
                      ],
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.216,
                        -1.712
                      ],
                      [
                        -0.705,
                        -3.525
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.028,
                        3.223
                      ]
                    ],
                    "o": [
                      [
                        -8.057,
                        0
                      ],
                      [
                        -4.23,
                        5.035
                      ],
                      [
                        0,
                        8.057
                      ],
                      [
                        4.431,
                        5.237
                      ],
                      [
                        6.647,
                        0
                      ],
                      [
                        4.028,
                        -3.424
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.007,
                        7.855
                      ],
                      [
                        -4.633,
                        0
                      ],
                      [
                        -2.518,
                        -3.323
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        2.518,
                        -3.323
                      ],
                      [
                        3.625,
                        0
                      ],
                      [
                        2.216,
                        1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.007,
                        -6.445
                      ],
                      [
                        -4.028,
                        -3.021
                      ]
                    ],
                    "v": [
                      [
                        29.205,
                        -53.476
                      ],
                      [
                        10.373,
                        -45.319
                      ],
                      [
                        4.028,
                        -25.983
                      ],
                      [
                        10.474,
                        -6.445
                      ],
                      [
                        29.004,
                        1.41
                      ],
                      [
                        45.117,
                        -3.625
                      ],
                      [
                        53.073,
                        -19.235
                      ],
                      [
                        42.499,
                        -19.235
                      ],
                      [
                        29.105,
                        -7.352
                      ],
                      [
                        18.732,
                        -12.286
                      ],
                      [
                        15.005,
                        -26.083
                      ],
                      [
                        18.832,
                        -39.78
                      ],
                      [
                        29.205,
                        -44.714
                      ],
                      [
                        37.967,
                        -42.197
                      ],
                      [
                        42.297,
                        -34.442
                      ],
                      [
                        52.872,
                        -34.442
                      ],
                      [
                        45.218,
                        -48.944
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'c',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'c',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'm',
      "size": 12,
      'style': 'Medium',
      "w": 87.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.654,
                        0
                      ],
                      [
                        4.23,
                        -4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        2.518
                      ],
                      [
                        -3.424,
                        0
                      ],
                      [
                        0,
                        -8.258
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.014,
                        2.216
                      ],
                      [
                        -3.021,
                        0
                      ],
                      [
                        -1.813,
                        -2.014
                      ],
                      [
                        0,
                        -4.23
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.424,
                        3.525
                      ],
                      [
                        5.338,
                        0
                      ],
                      [
                        2.417,
                        -1.309
                      ],
                      [
                        2.316,
                        -3.223
                      ]
                    ],
                    "o": [
                      [
                        -5.237,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -3.625
                      ],
                      [
                        2.014,
                        -2.618
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -3.827
                      ],
                      [
                        2.014,
                        -2.216
                      ],
                      [
                        4.028,
                        0
                      ],
                      [
                        1.813,
                        1.913
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        -3.525,
                        -3.625
                      ],
                      [
                        -3.525,
                        0
                      ],
                      [
                        -2.618,
                        1.309
                      ],
                      [
                        -3.223,
                        -5.841
                      ]
                    ],
                    "v": [
                      [
                        31.018,
                        -53.476
                      ],
                      [
                        16.718,
                        -46.326
                      ],
                      [
                        16.718,
                        -52.066
                      ],
                      [
                        6.042,
                        -52.066
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        16.718,
                        0
                      ],
                      [
                        16.718,
                        -31.32
                      ],
                      [
                        19.94,
                        -40.585
                      ],
                      [
                        28.198,
                        -44.513
                      ],
                      [
                        38.873,
                        -32.227
                      ],
                      [
                        38.873,
                        0
                      ],
                      [
                        49.548,
                        0
                      ],
                      [
                        49.548,
                        -32.025
                      ],
                      [
                        52.57,
                        -41.19
                      ],
                      [
                        60.022,
                        -44.513
                      ],
                      [
                        68.884,
                        -41.592
                      ],
                      [
                        71.603,
                        -32.327
                      ],
                      [
                        71.603,
                        0
                      ],
                      [
                        82.278,
                        0
                      ],
                      [
                        82.278,
                        -34.14
                      ],
                      [
                        77.042,
                        -48.138
                      ],
                      [
                        63.748,
                        -53.476
                      ],
                      [
                        54.886,
                        -51.562
                      ],
                      [
                        47.433,
                        -44.714
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'm',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'm',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'g',
      "size": 7.33201026916504,
      'style': 'Medium',
      "w": 59.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        6.848,
                        0
                      ],
                      [
                        4.33,
                        -4.935
                      ],
                      [
                        0,
                        -7.855
                      ],
                      [
                        -4.431,
                        -4.733
                      ],
                      [
                        -7.05,
                        0
                      ],
                      [
                        -3.827,
                        5.74
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        9.064,
                        0
                      ],
                      [
                        1.913,
                        1.208
                      ],
                      [
                        0.604,
                        3.021
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.827,
                        -2.82
                      ],
                      [
                        -7.251,
                        0
                      ],
                      [
                        0,
                        16.516
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -7.05,
                        0
                      ],
                      [
                        -4.431,
                        4.834
                      ],
                      [
                        0,
                        7.855
                      ],
                      [
                        4.33,
                        4.733
                      ],
                      [
                        6.848,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        9.769
                      ],
                      [
                        -4.23,
                        0
                      ],
                      [
                        -2.014,
                        -1.309
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.007,
                        5.74
                      ],
                      [
                        3.827,
                        2.719
                      ],
                      [
                        16.113,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.827,
                        -5.64
                      ]
                    ],
                    "v": [
                      [
                        27.695,
                        -53.476
                      ],
                      [
                        10.574,
                        -46.124
                      ],
                      [
                        4.028,
                        -27.191
                      ],
                      [
                        10.574,
                        -8.359
                      ],
                      [
                        27.695,
                        -1.208
                      ],
                      [
                        43.607,
                        -9.869
                      ],
                      [
                        43.607,
                        -1.813
                      ],
                      [
                        30.011,
                        12.891
                      ],
                      [
                        20.746,
                        10.977
                      ],
                      [
                        16.919,
                        4.431
                      ],
                      [
                        6.244,
                        4.431
                      ],
                      [
                        13.394,
                        17.221
                      ],
                      [
                        30.011,
                        21.35
                      ],
                      [
                        54.282,
                        -3.424
                      ],
                      [
                        54.282,
                        -52.066
                      ],
                      [
                        43.607,
                        -52.066
                      ],
                      [
                        43.607,
                        -45.117
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'g',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.532,
                        0
                      ],
                      [
                        -2.618,
                        -3.223
                      ],
                      [
                        0,
                        -5.438
                      ],
                      [
                        2.417,
                        -3.122
                      ],
                      [
                        4.532,
                        0
                      ],
                      [
                        2.316,
                        3.424
                      ],
                      [
                        0,
                        5.035
                      ],
                      [
                        -2.618,
                        3.021
                      ]
                    ],
                    "o": [
                      [
                        4.532,
                        0
                      ],
                      [
                        2.417,
                        3.122
                      ],
                      [
                        0,
                        5.338
                      ],
                      [
                        -2.618,
                        3.021
                      ],
                      [
                        -5.237,
                        0
                      ],
                      [
                        -2.014,
                        -2.921
                      ],
                      [
                        0,
                        -5.539
                      ],
                      [
                        2.417,
                        -3.223
                      ]
                    ],
                    "v": [
                      [
                        29.306,
                        -44.815
                      ],
                      [
                        39.981,
                        -40.082
                      ],
                      [
                        43.707,
                        -27.191
                      ],
                      [
                        39.981,
                        -14.502
                      ],
                      [
                        29.306,
                        -9.97
                      ],
                      [
                        18.027,
                        -15.207
                      ],
                      [
                        15.005,
                        -27.191
                      ],
                      [
                        18.832,
                        -40.082
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'g',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'g',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'O',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 77.3,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        10.876,
                        0
                      ],
                      [
                        6.244,
                        -7.251
                      ],
                      [
                        0,
                        -10.977
                      ],
                      [
                        -6.042,
                        -6.848
                      ],
                      [
                        -10.977,
                        0
                      ],
                      [
                        -6.345,
                        7.05
                      ],
                      [
                        0,
                        10.977
                      ],
                      [
                        6.042,
                        6.747
                      ]
                    ],
                    "o": [
                      [
                        -10.977,
                        0
                      ],
                      [
                        -6.042,
                        6.848
                      ],
                      [
                        0,
                        10.876
                      ],
                      [
                        6.244,
                        7.05
                      ],
                      [
                        10.876,
                        0
                      ],
                      [
                        6.042,
                        -6.848
                      ],
                      [
                        0,
                        -11.078
                      ],
                      [
                        -6.345,
                        -7.15
                      ]
                    ],
                    "v": [
                      [
                        38.873,
                        -73.315
                      ],
                      [
                        12.991,
                        -62.54
                      ],
                      [
                        4.028,
                        -35.852
                      ],
                      [
                        12.991,
                        -9.265
                      ],
                      [
                        38.873,
                        1.41
                      ],
                      [
                        64.755,
                        -9.164
                      ],
                      [
                        73.819,
                        -35.852
                      ],
                      [
                        64.755,
                        -62.64
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'O',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -7.654,
                        0
                      ],
                      [
                        -4.129,
                        -5.035
                      ],
                      [
                        0,
                        -8.661
                      ],
                      [
                        4.028,
                        -4.834
                      ],
                      [
                        7.654,
                        0
                      ],
                      [
                        4.23,
                        5.035
                      ],
                      [
                        0,
                        8.359
                      ],
                      [
                        -4.028,
                        4.935
                      ]
                    ],
                    "o": [
                      [
                        7.654,
                        0
                      ],
                      [
                        4.028,
                        4.935
                      ],
                      [
                        0,
                        8.56
                      ],
                      [
                        -4.129,
                        4.935
                      ],
                      [
                        -7.654,
                        0
                      ],
                      [
                        -4.028,
                        -4.935
                      ],
                      [
                        0,
                        -8.459
                      ],
                      [
                        4.23,
                        -5.237
                      ]
                    ],
                    "v": [
                      [
                        38.873,
                        -63.647
                      ],
                      [
                        56.598,
                        -56.195
                      ],
                      [
                        62.741,
                        -35.852
                      ],
                      [
                        56.598,
                        -15.71
                      ],
                      [
                        38.873,
                        -8.258
                      ],
                      [
                        21.048,
                        -15.912
                      ],
                      [
                        15.005,
                        -35.852
                      ],
                      [
                        21.048,
                        -55.893
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'O',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'O',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'W',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 94.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -71.906
                      ],
                      [
                        21.149,
                        0
                      ],
                      [
                        32.932,
                        0
                      ],
                      [
                        47.534,
                        -55.49
                      ],
                      [
                        47.937,
                        -55.49
                      ],
                      [
                        62.54,
                        0
                      ],
                      [
                        74.222,
                        0
                      ],
                      [
                        94.968,
                        -71.906
                      ],
                      [
                        82.581,
                        -71.906
                      ],
                      [
                        68.582,
                        -15.912
                      ],
                      [
                        68.179,
                        -15.912
                      ],
                      [
                        53.476,
                        -71.906
                      ],
                      [
                        41.995,
                        -71.906
                      ],
                      [
                        27.292,
                        -15.912
                      ],
                      [
                        26.889,
                        -15.912
                      ],
                      [
                        12.891,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'W',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'W',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'R',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 67.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        -1.813
                      ],
                      [
                        -0.403,
                        -4.23
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.611,
                        -2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.403,
                        4.431
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.848,
                        1.511
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        3.223
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        4.23,
                        3.424
                      ],
                      [
                        7.15,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.532,
                        0
                      ],
                      [
                        2.618,
                        1.813
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.302,
                        4.028
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.115,
                        -2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.806,
                        -7.251
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.129,
                        -1.007
                      ],
                      [
                        2.216,
                        -3.122
                      ],
                      [
                        0,
                        -6.647
                      ],
                      [
                        -4.129,
                        -3.323
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        15.509,
                        0
                      ],
                      [
                        15.509,
                        -30.917
                      ],
                      [
                        37.262,
                        -30.917
                      ],
                      [
                        47.635,
                        -28.299
                      ],
                      [
                        52.167,
                        -19.135
                      ],
                      [
                        53.073,
                        -9.567
                      ],
                      [
                        55.994,
                        0
                      ],
                      [
                        64.957,
                        0
                      ],
                      [
                        61.13,
                        -10.574
                      ],
                      [
                        60.022,
                        -21.652
                      ],
                      [
                        48.541,
                        -34.744
                      ],
                      [
                        48.541,
                        -34.946
                      ],
                      [
                        58.31,
                        -41.19
                      ],
                      [
                        61.633,
                        -51.865
                      ],
                      [
                        55.188,
                        -66.971
                      ],
                      [
                        38.269,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'R',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.618,
                        -2.216
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        2.518,
                        -2.316
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        5.539,
                        0
                      ],
                      [
                        2.417,
                        2.115
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        -2.82,
                        2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        15.509,
                        -64.755
                      ],
                      [
                        37.262,
                        -64.755
                      ],
                      [
                        49.548,
                        -61.432
                      ],
                      [
                        53.275,
                        -51.562
                      ],
                      [
                        49.448,
                        -41.794
                      ],
                      [
                        37.262,
                        -38.068
                      ],
                      [
                        15.509,
                        -38.068
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'R',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'R',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'H',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 72,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        15.509,
                        0
                      ],
                      [
                        15.509,
                        -33.536
                      ],
                      [
                        57.101,
                        -33.536
                      ],
                      [
                        57.101,
                        0
                      ],
                      [
                        65.359,
                        0
                      ],
                      [
                        65.359,
                        -71.906
                      ],
                      [
                        57.101,
                        -71.906
                      ],
                      [
                        57.101,
                        -40.686
                      ],
                      [
                        15.509,
                        -40.686
                      ],
                      [
                        15.509,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'H',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'H',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '$',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        3.827,
                        -2.719
                      ],
                      [
                        0,
                        -6.647
                      ],
                      [
                        -7.05,
                        -3.827
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.712,
                        1.611
                      ],
                      [
                        0.403,
                        5.237
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        -4.028
                      ],
                      [
                        -6.848,
                        -0.504
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.028,
                        2.618
                      ],
                      [
                        0,
                        7.15
                      ],
                      [
                        6.949,
                        3.928
                      ],
                      [
                        7.956,
                        2.921
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.511,
                        -1.108
                      ],
                      [
                        -0.604,
                        -4.23
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.129,
                        3.223
                      ],
                      [
                        6.042,
                        0.403
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -5.841,
                        0.403
                      ],
                      [
                        -5.136,
                        3.525
                      ],
                      [
                        0,
                        7.452
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.122,
                        -0.403
                      ],
                      [
                        -2.316,
                        -2.216
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        8.258
                      ],
                      [
                        3.625,
                        3.424
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.143,
                        -0.403
                      ],
                      [
                        5.438,
                        -3.625
                      ],
                      [
                        0,
                        -7.352
                      ],
                      [
                        -0.604,
                        -0.403
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.518,
                        0.302
                      ],
                      [
                        2.417,
                        1.712
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.007,
                        -7.352
                      ],
                      [
                        -3.525,
                        -2.82
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        26.688,
                        -73.215
                      ],
                      [
                        12.286,
                        -68.582
                      ],
                      [
                        4.633,
                        -53.375
                      ],
                      [
                        15.106,
                        -36.456
                      ],
                      [
                        26.688,
                        -32.227
                      ],
                      [
                        26.688,
                        -8.661
                      ],
                      [
                        19.437,
                        -11.682
                      ],
                      [
                        15.408,
                        -22.961
                      ],
                      [
                        3.726,
                        -22.961
                      ],
                      [
                        10.977,
                        -4.532
                      ],
                      [
                        26.688,
                        1.309
                      ],
                      [
                        26.688,
                        10.172
                      ],
                      [
                        33.334,
                        10.172
                      ],
                      [
                        33.334,
                        1.309
                      ],
                      [
                        48.541,
                        -3.223
                      ],
                      [
                        56.699,
                        -19.336
                      ],
                      [
                        46.225,
                        -36.255
                      ],
                      [
                        33.334,
                        -41.19
                      ],
                      [
                        33.334,
                        -63.345
                      ],
                      [
                        39.377,
                        -61.23
                      ],
                      [
                        43.909,
                        -52.368
                      ],
                      [
                        55.389,
                        -52.368
                      ],
                      [
                        47.736,
                        -68.28
                      ],
                      [
                        33.334,
                        -73.215
                      ],
                      [
                        33.334,
                        -81.473
                      ],
                      [
                        26.688,
                        -81.473
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '$',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        -0.403,
                        -0.201
                      ],
                      [
                        0,
                        -4.532
                      ],
                      [
                        2.82,
                        -1.813
                      ],
                      [
                        2.82,
                        -0.403
                      ]
                    ],
                    "o": [
                      [
                        0.403,
                        0.101
                      ],
                      [
                        6.949,
                        2.417
                      ],
                      [
                        0,
                        3.323
                      ],
                      [
                        -2.014,
                        1.309
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        33.334,
                        -29.81
                      ],
                      [
                        34.442,
                        -29.407
                      ],
                      [
                        44.916,
                        -18.933
                      ],
                      [
                        40.686,
                        -11.179
                      ],
                      [
                        33.334,
                        -8.661
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '$',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        3.827
                      ],
                      [
                        -2.719,
                        1.611
                      ],
                      [
                        -2.618,
                        0.302
                      ]
                    ],
                    "o": [
                      [
                        -6.848,
                        -2.618
                      ],
                      [
                        0,
                        -3.726
                      ],
                      [
                        1.511,
                        -1.007
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        26.688,
                        -43.707
                      ],
                      [
                        16.415,
                        -53.375
                      ],
                      [
                        20.444,
                        -61.432
                      ],
                      [
                        26.688,
                        -63.446
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '$',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '$',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '1',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 41,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        3.625,
                        -2.216
                      ],
                      [
                        3.223,
                        -0.806
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.33,
                        3.928
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -2.417,
                        2.518
                      ],
                      [
                        -3.625,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.647,
                        -1.913
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        23.364,
                        -71.906
                      ],
                      [
                        14.301,
                        -64.856
                      ],
                      [
                        4.028,
                        -60.626
                      ],
                      [
                        4.028,
                        -48.944
                      ],
                      [
                        20.444,
                        -57.706
                      ],
                      [
                        20.444,
                        0
                      ],
                      [
                        32.227,
                        0
                      ],
                      [
                        32.227,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '1',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '1',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '0',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.359,
                        0
                      ],
                      [
                        4.633,
                        -7.05
                      ],
                      [
                        0,
                        -11.279
                      ],
                      [
                        -4.431,
                        -6.647
                      ],
                      [
                        -8.459,
                        0
                      ],
                      [
                        -4.733,
                        6.949
                      ],
                      [
                        0,
                        11.279
                      ],
                      [
                        4.431,
                        6.647
                      ]
                    ],
                    "o": [
                      [
                        -8.459,
                        0
                      ],
                      [
                        -4.431,
                        6.647
                      ],
                      [
                        0,
                        11.279
                      ],
                      [
                        4.633,
                        6.949
                      ],
                      [
                        8.359,
                        0
                      ],
                      [
                        4.431,
                        -6.647
                      ],
                      [
                        0,
                        -11.279
                      ],
                      [
                        -4.733,
                        -7.05
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -73.315
                      ],
                      [
                        10.574,
                        -62.842
                      ],
                      [
                        3.928,
                        -35.953
                      ],
                      [
                        10.574,
                        -9.064
                      ],
                      [
                        30.212,
                        1.41
                      ],
                      [
                        49.85,
                        -9.064
                      ],
                      [
                        56.497,
                        -35.953
                      ],
                      [
                        49.85,
                        -62.842
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '0',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.74,
                        0
                      ],
                      [
                        -2.417,
                        -6.445
                      ],
                      [
                        0,
                        -7.553
                      ],
                      [
                        1.611,
                        -4.431
                      ],
                      [
                        5.64,
                        0
                      ],
                      [
                        2.316,
                        6.345
                      ],
                      [
                        0,
                        7.452
                      ],
                      [
                        -1.611,
                        4.33
                      ]
                    ],
                    "o": [
                      [
                        5.64,
                        0
                      ],
                      [
                        1.611,
                        4.33
                      ],
                      [
                        0,
                        7.452
                      ],
                      [
                        -2.417,
                        6.345
                      ],
                      [
                        -5.74,
                        0
                      ],
                      [
                        -1.611,
                        -4.431
                      ],
                      [
                        0,
                        -7.553
                      ],
                      [
                        2.316,
                        -6.445
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -63.345
                      ],
                      [
                        42.297,
                        -53.778
                      ],
                      [
                        44.714,
                        -35.953
                      ],
                      [
                        42.297,
                        -18.127
                      ],
                      [
                        30.212,
                        -8.56
                      ],
                      [
                        18.127,
                        -18.127
                      ],
                      [
                        15.71,
                        -35.953
                      ],
                      [
                        18.127,
                        -53.778
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '0',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '0',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '.',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 27.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.216,
                        0
                      ],
                      [
                        1.41,
                        -1.611
                      ],
                      [
                        0,
                        -2.216
                      ],
                      [
                        -1.611,
                        -1.611
                      ],
                      [
                        -2.216,
                        0
                      ],
                      [
                        -1.611,
                        1.41
                      ],
                      [
                        0,
                        2.216
                      ],
                      [
                        1.511,
                        1.511
                      ]
                    ],
                    "o": [
                      [
                        -2.216,
                        0
                      ],
                      [
                        -1.611,
                        1.511
                      ],
                      [
                        0,
                        2.216
                      ],
                      [
                        1.41,
                        1.41
                      ],
                      [
                        2.216,
                        0
                      ],
                      [
                        1.511,
                        -1.611
                      ],
                      [
                        0,
                        -2.216
                      ],
                      [
                        -1.611,
                        -1.611
                      ]
                    ],
                    "v": [
                      [
                        13.797,
                        -15.207
                      ],
                      [
                        8.258,
                        -12.891
                      ],
                      [
                        5.942,
                        -7.251
                      ],
                      [
                        8.258,
                        -1.611
                      ],
                      [
                        13.797,
                        0.604
                      ],
                      [
                        19.437,
                        -1.611
                      ],
                      [
                        21.753,
                        -7.251
                      ],
                      [
                        19.437,
                        -12.891
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '.',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '.',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '8',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.654,
                        0
                      ],
                      [
                        4.532,
                        -4.129
                      ],
                      [
                        0,
                        -5.438
                      ],
                      [
                        -1.611,
                        -2.518
                      ],
                      [
                        -3.625,
                        -1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.417,
                        -3.122
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        -4.733,
                        -4.028
                      ],
                      [
                        -8.661,
                        0
                      ],
                      [
                        -4.733,
                        3.928
                      ],
                      [
                        0,
                        6.445
                      ],
                      [
                        2.719,
                        3.424
                      ],
                      [
                        3.323,
                        0.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.813,
                        2.618
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        4.028,
                        3.625
                      ]
                    ],
                    "o": [
                      [
                        -7.755,
                        0
                      ],
                      [
                        -4.129,
                        3.625
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        1.712,
                        2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.424,
                        0.906
                      ],
                      [
                        -2.82,
                        3.424
                      ],
                      [
                        0,
                        6.445
                      ],
                      [
                        4.633,
                        3.928
                      ],
                      [
                        8.56,
                        0
                      ],
                      [
                        4.633,
                        -4.028
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        -2.417,
                        -3.122
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.525,
                        -1.41
                      ],
                      [
                        1.611,
                        -2.518
                      ],
                      [
                        0,
                        -5.438
                      ],
                      [
                        -4.633,
                        -4.129
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -73.315
                      ],
                      [
                        11.783,
                        -67.172
                      ],
                      [
                        5.64,
                        -53.577
                      ],
                      [
                        8.057,
                        -44.312
                      ],
                      [
                        16.013,
                        -38.168
                      ],
                      [
                        16.013,
                        -37.967
                      ],
                      [
                        7.352,
                        -31.924
                      ],
                      [
                        3.223,
                        -20.142
                      ],
                      [
                        10.272,
                        -4.532
                      ],
                      [
                        30.212,
                        1.41
                      ],
                      [
                        50.153,
                        -4.532
                      ],
                      [
                        57.202,
                        -20.142
                      ],
                      [
                        53.073,
                        -31.924
                      ],
                      [
                        44.412,
                        -37.967
                      ],
                      [
                        44.412,
                        -38.168
                      ],
                      [
                        52.368,
                        -44.312
                      ],
                      [
                        54.785,
                        -53.577
                      ],
                      [
                        48.642,
                        -67.172
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.23,
                        0
                      ],
                      [
                        -2.618,
                        -2.316
                      ],
                      [
                        0,
                        -2.921
                      ],
                      [
                        1.813,
                        -1.913
                      ],
                      [
                        4.935,
                        0
                      ],
                      [
                        2.216,
                        2.316
                      ],
                      [
                        0,
                        3.323
                      ],
                      [
                        -2.216,
                        2.014
                      ]
                    ],
                    "o": [
                      [
                        4.23,
                        0
                      ],
                      [
                        2.216,
                        2.014
                      ],
                      [
                        0,
                        3.323
                      ],
                      [
                        -2.216,
                        2.316
                      ],
                      [
                        -5.035,
                        0
                      ],
                      [
                        -1.813,
                        -1.913
                      ],
                      [
                        0,
                        -2.921
                      ],
                      [
                        2.518,
                        -2.316
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -63.95
                      ],
                      [
                        40.384,
                        -60.526
                      ],
                      [
                        43.707,
                        -53.174
                      ],
                      [
                        40.988,
                        -45.319
                      ],
                      [
                        30.212,
                        -41.794
                      ],
                      [
                        19.437,
                        -45.319
                      ],
                      [
                        16.718,
                        -53.174
                      ],
                      [
                        20.041,
                        -60.526
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.035,
                        0
                      ],
                      [
                        -2.719,
                        -2.518
                      ],
                      [
                        0,
                        -3.827
                      ],
                      [
                        2.518,
                        -2.216
                      ],
                      [
                        4.834,
                        0
                      ],
                      [
                        2.618,
                        2.216
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        -2.618,
                        2.316
                      ]
                    ],
                    "o": [
                      [
                        5.035,
                        0
                      ],
                      [
                        2.417,
                        2.216
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        -2.82,
                        2.216
                      ],
                      [
                        -4.935,
                        0
                      ],
                      [
                        -2.719,
                        -2.216
                      ],
                      [
                        0,
                        -3.827
                      ],
                      [
                        2.618,
                        -2.417
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -33.032
                      ],
                      [
                        41.794,
                        -29.306
                      ],
                      [
                        45.52,
                        -20.142
                      ],
                      [
                        41.693,
                        -11.481
                      ],
                      [
                        30.212,
                        -8.057
                      ],
                      [
                        18.933,
                        -11.38
                      ],
                      [
                        14.905,
                        -20.142
                      ],
                      [
                        18.732,
                        -29.407
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '8',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '4',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        37.061,
                        -71.906
                      ],
                      [
                        1.913,
                        -26.587
                      ],
                      [
                        1.913,
                        -15.408
                      ],
                      [
                        36.658,
                        -15.408
                      ],
                      [
                        36.658,
                        0
                      ],
                      [
                        47.836,
                        0
                      ],
                      [
                        47.836,
                        -15.408
                      ],
                      [
                        58.511,
                        -15.408
                      ],
                      [
                        58.511,
                        -24.774
                      ],
                      [
                        47.836,
                        -24.774
                      ],
                      [
                        47.836,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '4',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        36.356,
                        -57.001
                      ],
                      [
                        36.658,
                        -57.001
                      ],
                      [
                        36.658,
                        -24.774
                      ],
                      [
                        11.481,
                        -24.774
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '4',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '4',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '6',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        14.099,
                        0
                      ],
                      [
                        4.834,
                        -7.251
                      ],
                      [
                        0,
                        -11.783
                      ],
                      [
                        -4.633,
                        -6.244
                      ],
                      [
                        -8.862,
                        0
                      ],
                      [
                        -4.834,
                        4.633
                      ],
                      [
                        0,
                        7.251
                      ],
                      [
                        4.23,
                        4.532
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        2.82,
                        -1.41
                      ],
                      [
                        2.014,
                        -3.021
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        4.431
                      ],
                      [
                        -4.834,
                        0
                      ],
                      [
                        -1.41,
                        -6.647
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -8.359,
                        0
                      ],
                      [
                        -4.935,
                        7.251
                      ],
                      [
                        0,
                        10.977
                      ],
                      [
                        4.633,
                        6.345
                      ],
                      [
                        7.15,
                        0
                      ],
                      [
                        4.834,
                        -4.633
                      ],
                      [
                        0,
                        -7.05
                      ],
                      [
                        -4.23,
                        -4.532
                      ],
                      [
                        -3.625,
                        0
                      ],
                      [
                        -3.021,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.647
                      ],
                      [
                        2.82,
                        -4.733
                      ],
                      [
                        6.848,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.813,
                        -13.092
                      ]
                    ],
                    "v": [
                      [
                        31.219,
                        -73.315
                      ],
                      [
                        11.38,
                        -62.439
                      ],
                      [
                        4.028,
                        -33.939
                      ],
                      [
                        10.876,
                        -8.157
                      ],
                      [
                        31.119,
                        1.41
                      ],
                      [
                        49.146,
                        -5.64
                      ],
                      [
                        56.497,
                        -23.465
                      ],
                      [
                        50.153,
                        -40.887
                      ],
                      [
                        33.234,
                        -47.635
                      ],
                      [
                        23.566,
                        -45.621
                      ],
                      [
                        16.013,
                        -39.075
                      ],
                      [
                        15.509,
                        -39.075
                      ],
                      [
                        15.509,
                        -39.981
                      ],
                      [
                        19.638,
                        -56.598
                      ],
                      [
                        31.119,
                        -63.647
                      ],
                      [
                        43.607,
                        -53.677
                      ],
                      [
                        55.087,
                        -53.677
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.618,
                        -2.719
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        2.618,
                        -2.82
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        2.618,
                        2.618
                      ],
                      [
                        0,
                        4.532
                      ],
                      [
                        -2.518,
                        2.618
                      ]
                    ],
                    "o": [
                      [
                        4.33,
                        0
                      ],
                      [
                        2.417,
                        2.618
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        -2.618,
                        2.82
                      ],
                      [
                        -4.129,
                        0
                      ],
                      [
                        -2.618,
                        -2.618
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        2.417,
                        -2.719
                      ]
                    ],
                    "v": [
                      [
                        30.716,
                        -38.068
                      ],
                      [
                        41.089,
                        -34.039
                      ],
                      [
                        44.815,
                        -23.465
                      ],
                      [
                        40.887,
                        -12.589
                      ],
                      [
                        30.716,
                        -8.359
                      ],
                      [
                        20.645,
                        -12.286
                      ],
                      [
                        16.718,
                        -23.062
                      ],
                      [
                        20.444,
                        -34.039
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '6',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '2',
      "size": 23.9956798553467,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        6.949,
                        0
                      ],
                      [
                        4.733,
                        -5.237
                      ],
                      [
                        0.101,
                        -8.057
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        2.82
                      ],
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.115,
                        -1.913
                      ],
                      [
                        0,
                        -3.928
                      ],
                      [
                        3.122,
                        -3.525
                      ],
                      [
                        4.633,
                        -3.223
                      ],
                      [
                        2.618,
                        -3.021
                      ],
                      [
                        0,
                        -7.352
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.352,
                        4.834
                      ],
                      [
                        -2.82,
                        3.021
                      ],
                      [
                        0,
                        6.244
                      ],
                      [
                        4.431,
                        4.028
                      ]
                    ],
                    "o": [
                      [
                        -7.755,
                        0
                      ],
                      [
                        -4.633,
                        4.935
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.201,
                        -5.64
                      ],
                      [
                        2.216,
                        -3.021
                      ],
                      [
                        4.129,
                        0
                      ],
                      [
                        2.014,
                        1.913
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        -2.014,
                        2.115
                      ],
                      [
                        -8.359,
                        5.74
                      ],
                      [
                        -4.834,
                        5.438
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.611,
                        -3.625
                      ],
                      [
                        7.05,
                        -4.733
                      ],
                      [
                        4.633,
                        -5.136
                      ],
                      [
                        0,
                        -6.445
                      ],
                      [
                        -4.532,
                        -4.028
                      ]
                    ],
                    "v": [
                      [
                        31.32,
                        -73.315
                      ],
                      [
                        12.589,
                        -65.46
                      ],
                      [
                        5.539,
                        -46.024
                      ],
                      [
                        17.322,
                        -46.024
                      ],
                      [
                        20.947,
                        -58.813
                      ],
                      [
                        30.917,
                        -63.345
                      ],
                      [
                        40.283,
                        -60.526
                      ],
                      [
                        43.405,
                        -51.764
                      ],
                      [
                        38.672,
                        -40.384
                      ],
                      [
                        28.802,
                        -32.327
                      ],
                      [
                        12.286,
                        -19.135
                      ],
                      [
                        5.136,
                        0
                      ],
                      [
                        55.289,
                        0
                      ],
                      [
                        55.289,
                        -10.373
                      ],
                      [
                        19.839,
                        -10.373
                      ],
                      [
                        33.234,
                        -23.062
                      ],
                      [
                        48.138,
                        -34.644
                      ],
                      [
                        55.188,
                        -51.663
                      ],
                      [
                        48.541,
                        -67.273
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '2',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '2',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '3',
      "size": 10.6647500991821,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.251,
                        0
                      ],
                      [
                        4.532,
                        -4.028
                      ],
                      [
                        0.604,
                        -7.251
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        2.115
                      ],
                      [
                        -4.23,
                        0
                      ],
                      [
                        -2.115,
                        -1.813
                      ],
                      [
                        0,
                        -3.525
                      ],
                      [
                        2.115,
                        -1.813
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        -2.014
                      ],
                      [
                        0,
                        -4.129
                      ],
                      [
                        2.316,
                        -2.316
                      ],
                      [
                        4.532,
                        0
                      ],
                      [
                        2.518,
                        2.316
                      ],
                      [
                        0.201,
                        4.633
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.035,
                        -4.23
                      ],
                      [
                        -7.452,
                        0
                      ],
                      [
                        -4.834,
                        4.33
                      ],
                      [
                        0,
                        6.647
                      ],
                      [
                        2.417,
                        2.719
                      ],
                      [
                        4.23,
                        1.309
                      ],
                      [
                        0,
                        7.855
                      ],
                      [
                        4.33,
                        3.525
                      ]
                    ],
                    "o": [
                      [
                        -7.05,
                        0
                      ],
                      [
                        -4.834,
                        4.028
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.403,
                        -4.33
                      ],
                      [
                        2.316,
                        -2.115
                      ],
                      [
                        4.129,
                        0
                      ],
                      [
                        1.913,
                        1.813
                      ],
                      [
                        0,
                        3.525
                      ],
                      [
                        -2.216,
                        1.813
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.633,
                        0
                      ],
                      [
                        2.417,
                        1.913
                      ],
                      [
                        0,
                        3.424
                      ],
                      [
                        -2.618,
                        2.518
                      ],
                      [
                        -4.129,
                        0
                      ],
                      [
                        -2.82,
                        -2.518
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        8.057
                      ],
                      [
                        4.532,
                        3.827
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        4.633,
                        -4.23
                      ],
                      [
                        0,
                        -4.23
                      ],
                      [
                        -2.216,
                        -2.618
                      ],
                      [
                        7.956,
                        -2.618
                      ],
                      [
                        0,
                        -6.244
                      ],
                      [
                        -4.431,
                        -3.525
                      ]
                    ],
                    "v": [
                      [
                        30.917,
                        -73.315
                      ],
                      [
                        13.495,
                        -67.374
                      ],
                      [
                        5.438,
                        -50.455
                      ],
                      [
                        17.02,
                        -50.455
                      ],
                      [
                        21.249,
                        -60.123
                      ],
                      [
                        31.018,
                        -63.245
                      ],
                      [
                        40.384,
                        -60.526
                      ],
                      [
                        43.304,
                        -52.57
                      ],
                      [
                        40.082,
                        -44.513
                      ],
                      [
                        30.414,
                        -41.794
                      ],
                      [
                        25.378,
                        -41.794
                      ],
                      [
                        25.378,
                        -32.932
                      ],
                      [
                        30.414,
                        -32.932
                      ],
                      [
                        40.988,
                        -30.011
                      ],
                      [
                        44.714,
                        -20.947
                      ],
                      [
                        41.19,
                        -12.387
                      ],
                      [
                        30.414,
                        -8.56
                      ],
                      [
                        20.444,
                        -12.085
                      ],
                      [
                        16.013,
                        -22.861
                      ],
                      [
                        4.028,
                        -22.861
                      ],
                      [
                        12.488,
                        -4.33
                      ],
                      [
                        30.515,
                        1.41
                      ],
                      [
                        49.548,
                        -5.136
                      ],
                      [
                        56.497,
                        -21.451
                      ],
                      [
                        52.872,
                        -31.924
                      ],
                      [
                        43.103,
                        -37.766
                      ],
                      [
                        55.087,
                        -53.476
                      ],
                      [
                        48.541,
                        -68.079
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '3',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '3',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '5',
      "size": 10.6647500991821,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.518,
                        1.208
                      ],
                      [
                        -3.122,
                        0
                      ],
                      [
                        -2.417,
                        -2.82
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        2.82,
                        -2.618
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        2.216,
                        1.611
                      ],
                      [
                        0.504,
                        3.928
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.237,
                        -3.928
                      ],
                      [
                        -6.546,
                        0
                      ],
                      [
                        -5.237,
                        4.23
                      ],
                      [
                        0,
                        7.452
                      ],
                      [
                        4.23,
                        4.431
                      ],
                      [
                        6.747,
                        0
                      ],
                      [
                        2.618,
                        -1.007
                      ],
                      [
                        2.216,
                        -2.216
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.41,
                        -2.518
                      ],
                      [
                        2.216,
                        -1.108
                      ],
                      [
                        4.633,
                        0
                      ],
                      [
                        2.417,
                        2.719
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        -2.921,
                        2.618
                      ],
                      [
                        -3.928,
                        0
                      ],
                      [
                        -2.618,
                        -1.913
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.504,
                        6.647
                      ],
                      [
                        4.633,
                        3.525
                      ],
                      [
                        7.15,
                        0
                      ],
                      [
                        5.64,
                        -4.733
                      ],
                      [
                        0,
                        -7.755
                      ],
                      [
                        -4.23,
                        -4.431
                      ],
                      [
                        -3.223,
                        0
                      ],
                      [
                        -2.921,
                        1.007
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        9.265,
                        -71.906
                      ],
                      [
                        5.338,
                        -31.421
                      ],
                      [
                        16.315,
                        -31.421
                      ],
                      [
                        22.156,
                        -36.96
                      ],
                      [
                        30.212,
                        -38.571
                      ],
                      [
                        40.887,
                        -34.442
                      ],
                      [
                        44.614,
                        -23.062
                      ],
                      [
                        40.283,
                        -12.488
                      ],
                      [
                        29.507,
                        -8.56
                      ],
                      [
                        20.242,
                        -11.078
                      ],
                      [
                        15.61,
                        -19.839
                      ],
                      [
                        4.028,
                        -19.839
                      ],
                      [
                        12.589,
                        -3.928
                      ],
                      [
                        29.407,
                        1.41
                      ],
                      [
                        47.937,
                        -4.935
                      ],
                      [
                        56.396,
                        -23.163
                      ],
                      [
                        49.951,
                        -41.492
                      ],
                      [
                        33.435,
                        -48.138
                      ],
                      [
                        24.673,
                        -46.628
                      ],
                      [
                        17.02,
                        -41.794
                      ],
                      [
                        16.718,
                        -41.794
                      ],
                      [
                        18.732,
                        -61.533
                      ],
                      [
                        53.073,
                        -61.533
                      ],
                      [
                        53.073,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '5',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '5',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '7',
      "size": 10.6647500991821,
      'style': 'Semibold',
      "w": 55.3,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        3.021,
                        -71.906
                      ],
                      [
                        3.021,
                        -61.23
                      ],
                      [
                        39.78,
                        -61.23
                      ],
                      [
                        13.998,
                        0
                      ],
                      [
                        26.486,
                        0
                      ],
                      [
                        51.663,
                        -62.439
                      ],
                      [
                        51.663,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '7',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '7',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '＄',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 100,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.726,
                        -2.82
                      ],
                      [
                        0,
                        -6.546
                      ],
                      [
                        -7.15,
                        -3.625
                      ],
                      [
                        -3.625,
                        -1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.216,
                        2.316
                      ],
                      [
                        0.604,
                        6.042
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.928,
                        -4.028
                      ],
                      [
                        -6.747,
                        -0.403
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.827,
                        2.719
                      ],
                      [
                        0,
                        7.05
                      ],
                      [
                        7.15,
                        3.726
                      ],
                      [
                        5.942,
                        2.216
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        -1.813
                      ],
                      [
                        -0.806,
                        -4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.129,
                        3.424
                      ],
                      [
                        5.841,
                        0.403
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        -5.841,
                        0.201
                      ],
                      [
                        -4.834,
                        3.424
                      ],
                      [
                        0,
                        7.05
                      ],
                      [
                        3.625,
                        1.208
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        -0.403
                      ],
                      [
                        -2.618,
                        -2.719
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.806,
                        8.057
                      ],
                      [
                        3.625,
                        3.726
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        6.445,
                        -0.302
                      ],
                      [
                        5.035,
                        -3.525
                      ],
                      [
                        0,
                        -7.05
                      ],
                      [
                        -2.216,
                        -0.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.424,
                        0.403
                      ],
                      [
                        2.82,
                        2.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.007,
                        -7.05
                      ],
                      [
                        -3.625,
                        -3.021
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        47.635,
                        -81.473
                      ],
                      [
                        47.635,
                        -73.215
                      ],
                      [
                        33.334,
                        -68.582
                      ],
                      [
                        26.184,
                        -53.577
                      ],
                      [
                        36.859,
                        -37.564
                      ],
                      [
                        47.635,
                        -33.636
                      ],
                      [
                        47.635,
                        -5.74
                      ],
                      [
                        38.068,
                        -9.869
                      ],
                      [
                        33.234,
                        -22.961
                      ],
                      [
                        24.976,
                        -22.961
                      ],
                      [
                        32.025,
                        -4.935
                      ],
                      [
                        47.635,
                        1.309
                      ],
                      [
                        47.635,
                        10.172
                      ],
                      [
                        52.67,
                        10.172
                      ],
                      [
                        52.67,
                        1.309
                      ],
                      [
                        68.179,
                        -3.223
                      ],
                      [
                        75.732,
                        -19.135
                      ],
                      [
                        64.957,
                        -35.248
                      ],
                      [
                        52.67,
                        -39.88
                      ],
                      [
                        52.67,
                        -66.266
                      ],
                      [
                        61.029,
                        -63.043
                      ],
                      [
                        66.467,
                        -52.368
                      ],
                      [
                        74.524,
                        -52.368
                      ],
                      [
                        66.87,
                        -68.079
                      ],
                      [
                        52.67,
                        -73.215
                      ],
                      [
                        52.67,
                        -81.473
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '＄',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -3.625,
                        0.201
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        4.532
                      ],
                      [
                        -3.021,
                        2.115
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.15,
                        -2.82
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        2.216,
                        -1.611
                      ]
                    ],
                    "v": [
                      [
                        47.635,
                        -66.266
                      ],
                      [
                        47.635,
                        -41.693
                      ],
                      [
                        45.117,
                        -42.599
                      ],
                      [
                        34.442,
                        -53.577
                      ],
                      [
                        38.974,
                        -63.446
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '＄',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        4.23,
                        -0.403
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.935
                      ],
                      [
                        2.921,
                        -2.316
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        7.05,
                        2.719
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        -2.618,
                        1.913
                      ]
                    ],
                    "v": [
                      [
                        52.67,
                        -5.74
                      ],
                      [
                        52.67,
                        -31.924
                      ],
                      [
                        56.799,
                        -30.414
                      ],
                      [
                        67.474,
                        -18.933
                      ],
                      [
                        63.043,
                        -9.164
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '＄',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '＄',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '5',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        1.41
                      ],
                      [
                        -3.424,
                        0
                      ],
                      [
                        -2.921,
                        -3.223
                      ],
                      [
                        0,
                        -5.74
                      ],
                      [
                        3.223,
                        -3.223
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        2.82,
                        2.115
                      ],
                      [
                        0.403,
                        4.532
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.035,
                        -3.827
                      ],
                      [
                        -6.345,
                        0
                      ],
                      [
                        -4.834,
                        4.33
                      ],
                      [
                        0,
                        7.251
                      ],
                      [
                        4.23,
                        4.431
                      ],
                      [
                        6.848,
                        0
                      ],
                      [
                        2.82,
                        -1.208
                      ],
                      [
                        2.014,
                        -2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.41,
                        -2.82
                      ],
                      [
                        2.719,
                        -1.511
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        3.021,
                        3.122
                      ],
                      [
                        0,
                        5.035
                      ],
                      [
                        -3.323,
                        3.223
                      ],
                      [
                        -4.431,
                        0
                      ],
                      [
                        -3.223,
                        -2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.504,
                        6.546
                      ],
                      [
                        4.431,
                        3.424
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        5.136,
                        -4.633
                      ],
                      [
                        0,
                        -7.755
                      ],
                      [
                        -4.23,
                        -4.33
                      ],
                      [
                        -3.323,
                        0
                      ],
                      [
                        -3.122,
                        1.208
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        10.574,
                        -71.906
                      ],
                      [
                        6.747,
                        -32.227
                      ],
                      [
                        14.603,
                        -32.227
                      ],
                      [
                        21.048,
                        -38.672
                      ],
                      [
                        30.212,
                        -40.887
                      ],
                      [
                        42.499,
                        -36.154
                      ],
                      [
                        47.131,
                        -22.861
                      ],
                      [
                        42.197,
                        -10.474
                      ],
                      [
                        29.709,
                        -5.64
                      ],
                      [
                        18.732,
                        -8.862
                      ],
                      [
                        13.293,
                        -19.336
                      ],
                      [
                        5.136,
                        -19.336
                      ],
                      [
                        13.394,
                        -3.726
                      ],
                      [
                        29.608,
                        1.41
                      ],
                      [
                        47.534,
                        -5.136
                      ],
                      [
                        55.289,
                        -22.961
                      ],
                      [
                        48.843,
                        -41.19
                      ],
                      [
                        32.227,
                        -47.635
                      ],
                      [
                        23.062,
                        -45.923
                      ],
                      [
                        15.308,
                        -40.485
                      ],
                      [
                        14.905,
                        -40.485
                      ],
                      [
                        17.322,
                        -64.554
                      ],
                      [
                        52.368,
                        -64.554
                      ],
                      [
                        52.368,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '5',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '5',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '9',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.661,
                        0
                      ],
                      [
                        4.532,
                        -4.733
                      ],
                      [
                        0,
                        -7.15
                      ],
                      [
                        -4.129,
                        -4.33
                      ],
                      [
                        -6.949,
                        0
                      ],
                      [
                        -3.223,
                        2.014
                      ],
                      [
                        -1.813,
                        3.625
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -0.504
                      ],
                      [
                        3.122,
                        -5.338
                      ],
                      [
                        5.539,
                        0
                      ],
                      [
                        1.511,
                        8.157
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -13.394,
                        0
                      ],
                      [
                        -4.834,
                        7.452
                      ],
                      [
                        0,
                        11.481
                      ],
                      [
                        4.23,
                        6.042
                      ]
                    ],
                    "o": [
                      [
                        -7.05,
                        0
                      ],
                      [
                        -4.633,
                        4.633
                      ],
                      [
                        0,
                        6.949
                      ],
                      [
                        4.23,
                        4.532
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        3.323,
                        -2.115
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        1.208
                      ],
                      [
                        0,
                        8.057
                      ],
                      [
                        -3.323,
                        5.539
                      ],
                      [
                        -8.459,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.913,
                        12.79
                      ],
                      [
                        8.057,
                        0
                      ],
                      [
                        4.431,
                        -7.251
                      ],
                      [
                        0,
                        -11.078
                      ],
                      [
                        -4.431,
                        -6.445
                      ]
                    ],
                    "v": [
                      [
                        29.407,
                        -73.315
                      ],
                      [
                        11.984,
                        -66.266
                      ],
                      [
                        5.136,
                        -48.642
                      ],
                      [
                        11.279,
                        -31.723
                      ],
                      [
                        27.997,
                        -24.875
                      ],
                      [
                        39.175,
                        -27.997
                      ],
                      [
                        46.93,
                        -36.658
                      ],
                      [
                        47.333,
                        -36.658
                      ],
                      [
                        47.433,
                        -34.039
                      ],
                      [
                        42.7,
                        -13.898
                      ],
                      [
                        29.407,
                        -5.539
                      ],
                      [
                        14.502,
                        -17.825
                      ],
                      [
                        6.345,
                        -17.825
                      ],
                      [
                        29.306,
                        1.41
                      ],
                      [
                        48.642,
                        -9.869
                      ],
                      [
                        55.389,
                        -37.866
                      ],
                      [
                        49.045,
                        -63.647
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '9',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.935,
                        0
                      ],
                      [
                        -3.021,
                        -3.424
                      ],
                      [
                        0,
                        -5.035
                      ],
                      [
                        3.021,
                        -3.223
                      ],
                      [
                        4.633,
                        0
                      ],
                      [
                        2.921,
                        3.122
                      ],
                      [
                        0,
                        5.035
                      ],
                      [
                        -3.021,
                        3.323
                      ]
                    ],
                    "o": [
                      [
                        4.935,
                        0
                      ],
                      [
                        2.921,
                        3.223
                      ],
                      [
                        0,
                        4.935
                      ],
                      [
                        -3.223,
                        3.223
                      ],
                      [
                        -5.035,
                        0
                      ],
                      [
                        -3.021,
                        -3.021
                      ],
                      [
                        0,
                        -5.237
                      ],
                      [
                        3.021,
                        -3.323
                      ]
                    ],
                    "v": [
                      [
                        29.608,
                        -66.367
                      ],
                      [
                        41.592,
                        -61.23
                      ],
                      [
                        46.024,
                        -48.843
                      ],
                      [
                        41.391,
                        -36.557
                      ],
                      [
                        29.608,
                        -31.723
                      ],
                      [
                        17.725,
                        -36.456
                      ],
                      [
                        13.293,
                        -48.642
                      ],
                      [
                        17.725,
                        -61.432
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '9',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '9',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '8',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.452,
                        0
                      ],
                      [
                        4.23,
                        -3.928
                      ],
                      [
                        0,
                        -5.237
                      ],
                      [
                        -1.813,
                        -2.618
                      ],
                      [
                        -3.827,
                        -1.309
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.618,
                        -3.223
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        -4.431,
                        -3.827
                      ],
                      [
                        -8.359,
                        0
                      ],
                      [
                        -4.633,
                        3.928
                      ],
                      [
                        0,
                        6.345
                      ],
                      [
                        2.82,
                        3.424
                      ],
                      [
                        3.726,
                        0.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.014,
                        2.82
                      ],
                      [
                        0,
                        3.827
                      ],
                      [
                        3.827,
                        3.525
                      ]
                    ],
                    "o": [
                      [
                        -7.553,
                        0
                      ],
                      [
                        -3.928,
                        3.525
                      ],
                      [
                        0,
                        3.827
                      ],
                      [
                        1.913,
                        2.82
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.827,
                        0.906
                      ],
                      [
                        -2.82,
                        3.424
                      ],
                      [
                        0,
                        6.345
                      ],
                      [
                        4.532,
                        3.928
                      ],
                      [
                        8.258,
                        0
                      ],
                      [
                        4.431,
                        -3.827
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        -2.618,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.726,
                        -1.309
                      ],
                      [
                        1.813,
                        -2.618
                      ],
                      [
                        0,
                        -5.237
                      ],
                      [
                        -4.23,
                        -3.928
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -73.315
                      ],
                      [
                        12.589,
                        -67.474
                      ],
                      [
                        6.747,
                        -54.282
                      ],
                      [
                        9.467,
                        -44.614
                      ],
                      [
                        18.027,
                        -38.37
                      ],
                      [
                        18.027,
                        -38.168
                      ],
                      [
                        8.459,
                        -31.924
                      ],
                      [
                        4.23,
                        -19.839
                      ],
                      [
                        10.876,
                        -4.532
                      ],
                      [
                        30.212,
                        1.41
                      ],
                      [
                        49.548,
                        -4.532
                      ],
                      [
                        56.195,
                        -19.839
                      ],
                      [
                        51.965,
                        -31.924
                      ],
                      [
                        42.398,
                        -38.168
                      ],
                      [
                        42.398,
                        -38.37
                      ],
                      [
                        50.958,
                        -44.614
                      ],
                      [
                        53.677,
                        -54.282
                      ],
                      [
                        47.836,
                        -67.474
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.136,
                        0
                      ],
                      [
                        -2.921,
                        -2.618
                      ],
                      [
                        0,
                        -3.525
                      ],
                      [
                        2.216,
                        -2.216
                      ],
                      [
                        5.539,
                        0
                      ],
                      [
                        2.618,
                        2.618
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        -2.518,
                        2.216
                      ]
                    ],
                    "o": [
                      [
                        5.035,
                        0
                      ],
                      [
                        2.417,
                        2.216
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        -2.719,
                        2.618
                      ],
                      [
                        -5.64,
                        0
                      ],
                      [
                        -2.216,
                        -2.216
                      ],
                      [
                        0,
                        -3.525
                      ],
                      [
                        2.82,
                        -2.618
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -66.568
                      ],
                      [
                        42.197,
                        -62.64
                      ],
                      [
                        45.923,
                        -53.979
                      ],
                      [
                        42.599,
                        -45.117
                      ],
                      [
                        30.212,
                        -41.089
                      ],
                      [
                        17.825,
                        -45.117
                      ],
                      [
                        14.502,
                        -53.979
                      ],
                      [
                        18.228,
                        -62.64
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.841,
                        0
                      ],
                      [
                        -3.223,
                        -2.921
                      ],
                      [
                        0,
                        -4.33
                      ],
                      [
                        2.82,
                        -2.618
                      ],
                      [
                        5.841,
                        0
                      ],
                      [
                        3.021,
                        2.719
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        -3.021,
                        2.618
                      ]
                    ],
                    "o": [
                      [
                        5.74,
                        0
                      ],
                      [
                        2.82,
                        2.618
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        -3.223,
                        2.82
                      ],
                      [
                        -5.841,
                        0
                      ],
                      [
                        -3.021,
                        -2.618
                      ],
                      [
                        0,
                        -4.33
                      ],
                      [
                        3.122,
                        -2.921
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -34.644
                      ],
                      [
                        43.707,
                        -30.313
                      ],
                      [
                        48.038,
                        -19.839
                      ],
                      [
                        43.707,
                        -9.567
                      ],
                      [
                        30.212,
                        -5.338
                      ],
                      [
                        16.818,
                        -9.467
                      ],
                      [
                        12.387,
                        -19.839
                      ],
                      [
                        16.818,
                        -30.313
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '8',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '4',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        38.672,
                        -71.906
                      ],
                      [
                        2.417,
                        -24.17
                      ],
                      [
                        2.417,
                        -16.315
                      ],
                      [
                        38.571,
                        -16.315
                      ],
                      [
                        38.571,
                        0
                      ],
                      [
                        46.426,
                        0
                      ],
                      [
                        46.426,
                        -16.315
                      ],
                      [
                        58.109,
                        -16.315
                      ],
                      [
                        58.109,
                        -23.062
                      ],
                      [
                        46.426,
                        -23.062
                      ],
                      [
                        46.426,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '4',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        38.269,
                        -61.13
                      ],
                      [
                        38.571,
                        -61.13
                      ],
                      [
                        38.571,
                        -23.062
                      ],
                      [
                        9.366,
                        -23.062
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '4',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '4',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '6',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        13.293,
                        0
                      ],
                      [
                        4.733,
                        -7.553
                      ],
                      [
                        0,
                        -11.481
                      ],
                      [
                        -4.23,
                        -6.143
                      ],
                      [
                        -8.762,
                        0
                      ],
                      [
                        -4.633,
                        4.633
                      ],
                      [
                        0,
                        7.05
                      ],
                      [
                        4.129,
                        4.431
                      ],
                      [
                        6.848,
                        0
                      ],
                      [
                        3.223,
                        -2.115
                      ],
                      [
                        1.813,
                        -3.726
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0.403
                      ],
                      [
                        -3.223,
                        5.237
                      ],
                      [
                        -5.64,
                        0
                      ],
                      [
                        -1.611,
                        -8.258
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -8.157,
                        0
                      ],
                      [
                        -4.532,
                        7.15
                      ],
                      [
                        0,
                        10.977
                      ],
                      [
                        4.431,
                        6.445
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        4.532,
                        -4.633
                      ],
                      [
                        0,
                        -7.05
                      ],
                      [
                        -4.23,
                        -4.431
                      ],
                      [
                        -4.23,
                        0
                      ],
                      [
                        -3.424,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.101,
                        -1.309
                      ],
                      [
                        0,
                        -8.157
                      ],
                      [
                        3.223,
                        -5.64
                      ],
                      [
                        8.359,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.014,
                        -12.891
                      ]
                    ],
                    "v": [
                      [
                        31.219,
                        -73.315
                      ],
                      [
                        11.884,
                        -62.036
                      ],
                      [
                        5.136,
                        -34.039
                      ],
                      [
                        11.38,
                        -8.359
                      ],
                      [
                        31.119,
                        1.41
                      ],
                      [
                        48.541,
                        -5.64
                      ],
                      [
                        55.389,
                        -23.264
                      ],
                      [
                        49.146,
                        -40.384
                      ],
                      [
                        32.529,
                        -47.031
                      ],
                      [
                        21.35,
                        -43.909
                      ],
                      [
                        13.596,
                        -35.248
                      ],
                      [
                        13.193,
                        -35.248
                      ],
                      [
                        13.092,
                        -37.866
                      ],
                      [
                        17.825,
                        -58.008
                      ],
                      [
                        31.119,
                        -66.367
                      ],
                      [
                        46.024,
                        -54.08
                      ],
                      [
                        54.181,
                        -54.08
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.733,
                        0
                      ],
                      [
                        -3.021,
                        -3.223
                      ],
                      [
                        0,
                        -5.136
                      ],
                      [
                        3.021,
                        -3.424
                      ],
                      [
                        4.834,
                        0
                      ],
                      [
                        3.021,
                        3.424
                      ],
                      [
                        0,
                        5.035
                      ],
                      [
                        -3.122,
                        3.223
                      ]
                    ],
                    "o": [
                      [
                        4.935,
                        0
                      ],
                      [
                        2.921,
                        3.021
                      ],
                      [
                        0,
                        5.136
                      ],
                      [
                        -3.021,
                        3.223
                      ],
                      [
                        -5.035,
                        0
                      ],
                      [
                        -3.021,
                        -3.223
                      ],
                      [
                        0,
                        -5.035
                      ],
                      [
                        3.122,
                        -3.223
                      ]
                    ],
                    "v": [
                      [
                        30.917,
                        -40.182
                      ],
                      [
                        42.801,
                        -35.449
                      ],
                      [
                        47.232,
                        -23.264
                      ],
                      [
                        42.7,
                        -10.474
                      ],
                      [
                        30.917,
                        -5.539
                      ],
                      [
                        18.933,
                        -10.675
                      ],
                      [
                        14.502,
                        -23.062
                      ],
                      [
                        19.135,
                        -35.349
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '6',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': ',',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 26.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.216,
                        0
                      ],
                      [
                        1.108,
                        -1.208
                      ],
                      [
                        0,
                        -1.913
                      ],
                      [
                        -1.208,
                        -1.208
                      ],
                      [
                        -1.712,
                        0
                      ],
                      [
                        -0.504,
                        0.201
                      ],
                      [
                        1.511,
                        -2.115
                      ],
                      [
                        2.618,
                        -0.906
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.618,
                        3.223
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        1.309,
                        1.813
                      ]
                    ],
                    "o": [
                      [
                        -2.014,
                        0
                      ],
                      [
                        -1.309,
                        1.108
                      ],
                      [
                        0,
                        1.712
                      ],
                      [
                        1.208,
                        1.208
                      ],
                      [
                        1.007,
                        0
                      ],
                      [
                        0,
                        2.417
                      ],
                      [
                        -1.611,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.33,
                        -1.108
                      ],
                      [
                        2.618,
                        -3.424
                      ],
                      [
                        0,
                        -2.921
                      ],
                      [
                        -1.41,
                        -1.913
                      ]
                    ],
                    "v": [
                      [
                        13.293,
                        -11.984
                      ],
                      [
                        8.661,
                        -10.272
                      ],
                      [
                        6.747,
                        -5.74
                      ],
                      [
                        8.56,
                        -1.309
                      ],
                      [
                        12.891,
                        0.504
                      ],
                      [
                        15.106,
                        0.101
                      ],
                      [
                        12.891,
                        6.848
                      ],
                      [
                        6.445,
                        11.279
                      ],
                      [
                        6.445,
                        16.214
                      ],
                      [
                        16.919,
                        9.668
                      ],
                      [
                        20.847,
                        -2.115
                      ],
                      [
                        18.832,
                        -9.164
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': ',',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': ',',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'K',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 70.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        52.771,
                        -71.906
                      ],
                      [
                        18.027,
                        -37.161
                      ],
                      [
                        18.027,
                        -71.906
                      ],
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.027,
                        0
                      ],
                      [
                        18.027,
                        -24.875
                      ],
                      [
                        27.393,
                        -33.838
                      ],
                      [
                        56.598,
                        0
                      ],
                      [
                        71.301,
                        0
                      ],
                      [
                        34.845,
                        -40.988
                      ],
                      [
                        66.669,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'K',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'K',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '0',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.56,
                        0
                      ],
                      [
                        4.33,
                        -7.654
                      ],
                      [
                        0,
                        -10.876
                      ],
                      [
                        -3.827,
                        -6.445
                      ],
                      [
                        -8.661,
                        0
                      ],
                      [
                        -4.431,
                        7.553
                      ],
                      [
                        0,
                        10.876
                      ],
                      [
                        3.827,
                        6.445
                      ]
                    ],
                    "o": [
                      [
                        -8.661,
                        0
                      ],
                      [
                        -3.827,
                        6.445
                      ],
                      [
                        0,
                        10.876
                      ],
                      [
                        4.33,
                        7.553
                      ],
                      [
                        8.56,
                        0
                      ],
                      [
                        3.827,
                        -6.445
                      ],
                      [
                        0,
                        -10.876
                      ],
                      [
                        -4.431,
                        -7.654
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -73.315
                      ],
                      [
                        10.776,
                        -61.935
                      ],
                      [
                        5.035,
                        -35.953
                      ],
                      [
                        10.776,
                        -9.97
                      ],
                      [
                        30.212,
                        1.41
                      ],
                      [
                        49.649,
                        -9.97
                      ],
                      [
                        55.389,
                        -35.953
                      ],
                      [
                        49.649,
                        -61.935
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '0',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -6.445,
                        0
                      ],
                      [
                        -2.82,
                        -6.949
                      ],
                      [
                        0,
                        -8.459
                      ],
                      [
                        2.014,
                        -4.935
                      ],
                      [
                        6.445,
                        0
                      ],
                      [
                        2.82,
                        6.848
                      ],
                      [
                        0,
                        8.359
                      ],
                      [
                        -2.014,
                        4.834
                      ]
                    ],
                    "o": [
                      [
                        6.445,
                        0
                      ],
                      [
                        2.014,
                        4.834
                      ],
                      [
                        0,
                        8.359
                      ],
                      [
                        -2.82,
                        6.848
                      ],
                      [
                        -6.445,
                        0
                      ],
                      [
                        -2.014,
                        -4.935
                      ],
                      [
                        0,
                        -8.459
                      ],
                      [
                        2.82,
                        -6.949
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -66.266
                      ],
                      [
                        44.11,
                        -55.893
                      ],
                      [
                        47.232,
                        -35.953
                      ],
                      [
                        44.11,
                        -16.013
                      ],
                      [
                        30.212,
                        -5.64
                      ],
                      [
                        16.315,
                        -16.013
                      ],
                      [
                        13.293,
                        -35.953
                      ],
                      [
                        16.315,
                        -55.893
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '0',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '0',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '-',
      "size": 7.99855995178223,
      'style': 'Regular',
      "w": 60.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        5.035,
                        -29.507
                      ],
                      [
                        5.035,
                        -22.659
                      ],
                      [
                        55.893,
                        -22.659
                      ],
                      [
                        55.893,
                        -29.507
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '-',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '-',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'P',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 65.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        14.703
                      ],
                      [
                        17.12,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        17.221,
                        0
                      ],
                      [
                        0,
                        -14.603
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.027,
                        0
                      ],
                      [
                        18.027,
                        -27.997
                      ],
                      [
                        36.658,
                        -27.997
                      ],
                      [
                        62.54,
                        -50.052
                      ],
                      [
                        36.859,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'P',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.518,
                        -2.014
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        2.417,
                        -2.014
                      ],
                      [
                        5.438,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        5.338,
                        0
                      ],
                      [
                        2.518,
                        1.813
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        -2.518,
                        2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        18.027,
                        -62.54
                      ],
                      [
                        35.953,
                        -62.54
                      ],
                      [
                        47.736,
                        -59.518
                      ],
                      [
                        51.562,
                        -50.052
                      ],
                      [
                        47.836,
                        -40.384
                      ],
                      [
                        35.953,
                        -37.363
                      ],
                      [
                        18.027,
                        -37.363
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'P',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'P',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'T',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 61.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        1.511,
                        -71.906
                      ],
                      [
                        1.511,
                        -62.54
                      ],
                      [
                        25.58,
                        -62.54
                      ],
                      [
                        25.58,
                        0
                      ],
                      [
                        36.456,
                        0
                      ],
                      [
                        36.456,
                        -62.54
                      ],
                      [
                        60.425,
                        -62.54
                      ],
                      [
                        60.425,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'T',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'T',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'U',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 72.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.237,
                        -5.035
                      ],
                      [
                        -9.567,
                        0
                      ],
                      [
                        -5.136,
                        4.834
                      ],
                      [
                        0,
                        9.366
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.921,
                        -3.122
                      ],
                      [
                        6.445,
                        0
                      ],
                      [
                        3.021,
                        3.122
                      ],
                      [
                        0,
                        6.445
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        9.366
                      ],
                      [
                        5.035,
                        4.834
                      ],
                      [
                        9.467,
                        0
                      ],
                      [
                        5.136,
                        -4.935
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        6.445
                      ],
                      [
                        -3.021,
                        3.122
                      ],
                      [
                        -6.546,
                        0
                      ],
                      [
                        -3.021,
                        -3.122
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        -27.393
                      ],
                      [
                        14.905,
                        -5.841
                      ],
                      [
                        36.758,
                        1.41
                      ],
                      [
                        58.612,
                        -5.942
                      ],
                      [
                        66.367,
                        -27.393
                      ],
                      [
                        66.367,
                        -71.906
                      ],
                      [
                        55.389,
                        -71.906
                      ],
                      [
                        55.389,
                        -27.191
                      ],
                      [
                        50.958,
                        -12.891
                      ],
                      [
                        36.758,
                        -8.157
                      ],
                      [
                        22.458,
                        -12.891
                      ],
                      [
                        18.027,
                        -27.191
                      ],
                      [
                        18.027,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'U',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'U',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'D',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 71.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -6.042,
                        6.445
                      ],
                      [
                        0,
                        11.279
                      ],
                      [
                        5.64,
                        6.042
                      ],
                      [
                        11.581,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        11.581,
                        0
                      ],
                      [
                        5.74,
                        -6.244
                      ],
                      [
                        0,
                        -11.38
                      ],
                      [
                        -6.042,
                        -6.546
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        33.032,
                        0
                      ],
                      [
                        59.418,
                        -9.668
                      ],
                      [
                        68.079,
                        -35.953
                      ],
                      [
                        59.619,
                        -62.137
                      ],
                      [
                        33.234,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'D',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.23,
                        -4.33
                      ],
                      [
                        0,
                        -9.265
                      ],
                      [
                        4.129,
                        -4.33
                      ],
                      [
                        9.064,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        9.064,
                        0
                      ],
                      [
                        4.028,
                        4.23
                      ],
                      [
                        0,
                        9.064
                      ],
                      [
                        -4.23,
                        4.33
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        18.027,
                        -62.54
                      ],
                      [
                        31.219,
                        -62.54
                      ],
                      [
                        51.16,
                        -56.094
                      ],
                      [
                        57.303,
                        -35.953
                      ],
                      [
                        51.059,
                        -15.912
                      ],
                      [
                        31.018,
                        -9.366
                      ],
                      [
                        18.027,
                        -9.366
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'D',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'D',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'M',
      "size": 7.33201026916504,
      'style': 'Regular',
      "w": 88.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.251,
                        -71.906
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        15.509,
                        0
                      ],
                      [
                        15.509,
                        -57.202
                      ],
                      [
                        15.811,
                        -57.202
                      ],
                      [
                        40.686,
                        0
                      ],
                      [
                        48.138,
                        0
                      ],
                      [
                        73.013,
                        -57.202
                      ],
                      [
                        73.315,
                        -57.202
                      ],
                      [
                        73.315,
                        0
                      ],
                      [
                        81.573,
                        0
                      ],
                      [
                        81.573,
                        -71.906
                      ],
                      [
                        71.301,
                        -71.906
                      ],
                      [
                        44.614,
                        -10.574
                      ],
                      [
                        44.312,
                        -10.574
                      ],
                      [
                        17.523,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'M',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'M',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'E',
      "size": 10.6647500991821,
      'style': 'Medium',
      "w": 64.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        60.425,
                        0
                      ],
                      [
                        60.425,
                        -9.366
                      ],
                      [
                        18.027,
                        -9.366
                      ],
                      [
                        18.027,
                        -32.227
                      ],
                      [
                        56.296,
                        -32.227
                      ],
                      [
                        56.296,
                        -41.592
                      ],
                      [
                        18.027,
                        -41.592
                      ],
                      [
                        18.027,
                        -62.54
                      ],
                      [
                        58.713,
                        -62.54
                      ],
                      [
                        58.713,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'E',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'E',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '#',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.654,
                        -55.893
                      ],
                      [
                        6.345,
                        -48.138
                      ],
                      [
                        16.718,
                        -48.138
                      ],
                      [
                        12.79,
                        -23.767
                      ],
                      [
                        3.827,
                        -23.767
                      ],
                      [
                        2.518,
                        -16.013
                      ],
                      [
                        11.581,
                        -16.013
                      ],
                      [
                        9.064,
                        0
                      ],
                      [
                        17.825,
                        0
                      ],
                      [
                        20.343,
                        -16.013
                      ],
                      [
                        33.737,
                        -16.013
                      ],
                      [
                        31.219,
                        0
                      ],
                      [
                        39.981,
                        0
                      ],
                      [
                        42.499,
                        -16.013
                      ],
                      [
                        52.771,
                        -16.013
                      ],
                      [
                        54.08,
                        -23.767
                      ],
                      [
                        43.707,
                        -23.767
                      ],
                      [
                        47.635,
                        -48.138
                      ],
                      [
                        56.598,
                        -48.138
                      ],
                      [
                        57.907,
                        -55.893
                      ],
                      [
                        48.843,
                        -55.893
                      ],
                      [
                        51.361,
                        -71.906
                      ],
                      [
                        42.7,
                        -71.906
                      ],
                      [
                        40.082,
                        -55.893
                      ],
                      [
                        26.688,
                        -55.893
                      ],
                      [
                        29.205,
                        -71.906
                      ],
                      [
                        20.544,
                        -71.906
                      ],
                      [
                        17.926,
                        -55.893
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '#',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        21.552,
                        -23.767
                      ],
                      [
                        25.479,
                        -48.138
                      ],
                      [
                        38.873,
                        -48.138
                      ],
                      [
                        34.946,
                        -23.767
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '#',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '#',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '2',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        6.848,
                        0
                      ],
                      [
                        4.633,
                        -5.237
                      ],
                      [
                        0.101,
                        -8.057
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        3.021
                      ],
                      [
                        -4.532,
                        0
                      ],
                      [
                        -2.216,
                        -2.014
                      ],
                      [
                        0,
                        -4.028
                      ],
                      [
                        3.223,
                        -3.625
                      ],
                      [
                        4.834,
                        -3.424
                      ],
                      [
                        2.618,
                        -3.122
                      ],
                      [
                        0,
                        -7.15
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.755,
                        5.136
                      ],
                      [
                        -2.82,
                        2.921
                      ],
                      [
                        0,
                        6.244
                      ],
                      [
                        4.33,
                        4.028
                      ]
                    ],
                    "o": [
                      [
                        -7.654,
                        0
                      ],
                      [
                        -4.532,
                        4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.201,
                        -5.841
                      ],
                      [
                        2.316,
                        -3.122
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        2.216,
                        2.014
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        -2.014,
                        2.115
                      ],
                      [
                        -8.157,
                        5.64
                      ],
                      [
                        -4.834,
                        5.438
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.511,
                        -3.928
                      ],
                      [
                        7.15,
                        -4.834
                      ],
                      [
                        4.633,
                        -5.136
                      ],
                      [
                        0,
                        -6.345
                      ],
                      [
                        -4.431,
                        -4.028
                      ]
                    ],
                    "v": [
                      [
                        31.32,
                        -73.315
                      ],
                      [
                        12.79,
                        -65.561
                      ],
                      [
                        5.841,
                        -46.225
                      ],
                      [
                        16.818,
                        -46.225
                      ],
                      [
                        20.645,
                        -59.418
                      ],
                      [
                        30.917,
                        -64.05
                      ],
                      [
                        40.686,
                        -61.029
                      ],
                      [
                        44.009,
                        -51.965
                      ],
                      [
                        39.075,
                        -40.182
                      ],
                      [
                        28.802,
                        -31.924
                      ],
                      [
                        12.589,
                        -18.832
                      ],
                      [
                        5.438,
                        0
                      ],
                      [
                        54.987,
                        0
                      ],
                      [
                        54.987,
                        -9.668
                      ],
                      [
                        19.034,
                        -9.668
                      ],
                      [
                        32.932,
                        -23.264
                      ],
                      [
                        47.836,
                        -34.845
                      ],
                      [
                        54.886,
                        -51.865
                      ],
                      [
                        48.34,
                        -67.374
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '2',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '2',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '3',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.251,
                        0
                      ],
                      [
                        4.431,
                        -4.028
                      ],
                      [
                        0.604,
                        -7.15
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        2.216
                      ],
                      [
                        -4.33,
                        0
                      ],
                      [
                        -2.216,
                        -2.014
                      ],
                      [
                        0,
                        -3.625
                      ],
                      [
                        2.216,
                        -1.913
                      ],
                      [
                        4.431,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        -2.014
                      ],
                      [
                        0,
                        -4.23
                      ],
                      [
                        2.417,
                        -2.417
                      ],
                      [
                        4.633,
                        0
                      ],
                      [
                        2.618,
                        2.417
                      ],
                      [
                        0.201,
                        4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.035,
                        -4.23
                      ],
                      [
                        -7.352,
                        0
                      ],
                      [
                        -4.834,
                        4.23
                      ],
                      [
                        0,
                        6.647
                      ],
                      [
                        2.417,
                        2.719
                      ],
                      [
                        4.23,
                        1.309
                      ],
                      [
                        0,
                        7.855
                      ],
                      [
                        4.23,
                        3.525
                      ]
                    ],
                    "o": [
                      [
                        -7.05,
                        0
                      ],
                      [
                        -4.733,
                        4.028
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.403,
                        -4.431
                      ],
                      [
                        2.417,
                        -2.216
                      ],
                      [
                        4.23,
                        0
                      ],
                      [
                        2.014,
                        1.913
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        -2.216,
                        1.913
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.733,
                        0
                      ],
                      [
                        2.618,
                        2.014
                      ],
                      [
                        0,
                        3.625
                      ],
                      [
                        -2.82,
                        2.618
                      ],
                      [
                        -4.23,
                        0
                      ],
                      [
                        -3.021,
                        -2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        8.057
                      ],
                      [
                        4.431,
                        3.726
                      ],
                      [
                        7.654,
                        0
                      ],
                      [
                        4.532,
                        -4.23
                      ],
                      [
                        0,
                        -4.23
                      ],
                      [
                        -2.216,
                        -2.618
                      ],
                      [
                        7.855,
                        -2.618
                      ],
                      [
                        0,
                        -6.143
                      ],
                      [
                        -4.431,
                        -3.525
                      ]
                    ],
                    "v": [
                      [
                        30.917,
                        -73.315
                      ],
                      [
                        13.696,
                        -67.374
                      ],
                      [
                        5.74,
                        -50.656
                      ],
                      [
                        16.516,
                        -50.656
                      ],
                      [
                        20.847,
                        -60.626
                      ],
                      [
                        31.018,
                        -63.95
                      ],
                      [
                        40.787,
                        -61.029
                      ],
                      [
                        43.909,
                        -52.771
                      ],
                      [
                        40.485,
                        -44.412
                      ],
                      [
                        30.515,
                        -41.492
                      ],
                      [
                        25.378,
                        -41.492
                      ],
                      [
                        25.378,
                        -33.234
                      ],
                      [
                        30.615,
                        -33.234
                      ],
                      [
                        41.391,
                        -30.212
                      ],
                      [
                        45.319,
                        -20.847
                      ],
                      [
                        41.592,
                        -11.884
                      ],
                      [
                        30.414,
                        -7.855
                      ],
                      [
                        20.142,
                        -11.481
                      ],
                      [
                        15.408,
                        -22.659
                      ],
                      [
                        4.33,
                        -22.659
                      ],
                      [
                        12.79,
                        -4.23
                      ],
                      [
                        30.515,
                        1.41
                      ],
                      [
                        49.347,
                        -5.035
                      ],
                      [
                        56.195,
                        -21.35
                      ],
                      [
                        52.57,
                        -31.824
                      ],
                      [
                        42.902,
                        -37.766
                      ],
                      [
                        54.785,
                        -53.577
                      ],
                      [
                        48.34,
                        -68.079
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '3',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '3',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '4',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        37.463,
                        -71.906
                      ],
                      [
                        2.014,
                        -25.983
                      ],
                      [
                        2.014,
                        -15.61
                      ],
                      [
                        37.061,
                        -15.61
                      ],
                      [
                        37.061,
                        0
                      ],
                      [
                        47.534,
                        0
                      ],
                      [
                        47.534,
                        -15.61
                      ],
                      [
                        58.411,
                        -15.61
                      ],
                      [
                        58.411,
                        -24.371
                      ],
                      [
                        47.534,
                        -24.371
                      ],
                      [
                        47.534,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '4',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        36.758,
                        -58.008
                      ],
                      [
                        37.061,
                        -58.008
                      ],
                      [
                        37.061,
                        -24.371
                      ],
                      [
                        10.977,
                        -24.371
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '4',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '4',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '5',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.618,
                        1.208
                      ],
                      [
                        -3.223,
                        0
                      ],
                      [
                        -2.618,
                        -2.82
                      ],
                      [
                        0,
                        -5.035
                      ],
                      [
                        3.021,
                        -2.82
                      ],
                      [
                        4.431,
                        0
                      ],
                      [
                        2.417,
                        1.813
                      ],
                      [
                        0.403,
                        4.028
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -5.136,
                        -3.827
                      ],
                      [
                        -6.445,
                        0
                      ],
                      [
                        -5.136,
                        4.23
                      ],
                      [
                        0,
                        7.452
                      ],
                      [
                        4.23,
                        4.431
                      ],
                      [
                        6.747,
                        0
                      ],
                      [
                        2.618,
                        -1.108
                      ],
                      [
                        2.216,
                        -2.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.41,
                        -2.618
                      ],
                      [
                        2.417,
                        -1.208
                      ],
                      [
                        4.834,
                        0
                      ],
                      [
                        2.618,
                        2.82
                      ],
                      [
                        0,
                        4.633
                      ],
                      [
                        -3.021,
                        2.719
                      ],
                      [
                        -4.028,
                        0
                      ],
                      [
                        -2.719,
                        -2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.504,
                        6.647
                      ],
                      [
                        4.633,
                        3.525
                      ],
                      [
                        7.15,
                        0
                      ],
                      [
                        5.438,
                        -4.733
                      ],
                      [
                        0,
                        -7.755
                      ],
                      [
                        -4.23,
                        -4.431
                      ],
                      [
                        -3.223,
                        0
                      ],
                      [
                        -2.921,
                        1.007
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        9.567,
                        -71.906
                      ],
                      [
                        5.64,
                        -31.622
                      ],
                      [
                        15.912,
                        -31.622
                      ],
                      [
                        21.854,
                        -37.363
                      ],
                      [
                        30.212,
                        -39.075
                      ],
                      [
                        41.29,
                        -34.845
                      ],
                      [
                        45.218,
                        -23.062
                      ],
                      [
                        40.686,
                        -11.984
                      ],
                      [
                        29.507,
                        -7.855
                      ],
                      [
                        19.839,
                        -10.574
                      ],
                      [
                        15.106,
                        -19.739
                      ],
                      [
                        4.33,
                        -19.739
                      ],
                      [
                        12.79,
                        -3.928
                      ],
                      [
                        29.407,
                        1.41
                      ],
                      [
                        47.836,
                        -4.935
                      ],
                      [
                        56.094,
                        -23.163
                      ],
                      [
                        49.649,
                        -41.391
                      ],
                      [
                        33.133,
                        -48.038
                      ],
                      [
                        24.271,
                        -46.426
                      ],
                      [
                        16.617,
                        -41.492
                      ],
                      [
                        16.315,
                        -41.492
                      ],
                      [
                        18.43,
                        -62.238
                      ],
                      [
                        52.872,
                        -62.238
                      ],
                      [
                        52.872,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '5',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '5',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '8',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.654,
                        0
                      ],
                      [
                        4.431,
                        -4.028
                      ],
                      [
                        0,
                        -5.338
                      ],
                      [
                        -1.611,
                        -2.518
                      ],
                      [
                        -3.625,
                        -1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.417,
                        -3.223
                      ],
                      [
                        0,
                        -4.532
                      ],
                      [
                        -4.633,
                        -4.028
                      ],
                      [
                        -8.56,
                        0
                      ],
                      [
                        -4.733,
                        3.928
                      ],
                      [
                        0,
                        6.345
                      ],
                      [
                        2.82,
                        3.424
                      ],
                      [
                        3.424,
                        0.806
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.813,
                        2.719
                      ],
                      [
                        0,
                        3.726
                      ],
                      [
                        4.028,
                        3.625
                      ]
                    ],
                    "o": [
                      [
                        -7.654,
                        0
                      ],
                      [
                        -4.028,
                        3.625
                      ],
                      [
                        0,
                        3.726
                      ],
                      [
                        1.813,
                        2.719
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -3.525,
                        0.806
                      ],
                      [
                        -2.82,
                        3.424
                      ],
                      [
                        0,
                        6.345
                      ],
                      [
                        4.633,
                        3.928
                      ],
                      [
                        8.459,
                        0
                      ],
                      [
                        4.633,
                        -4.028
                      ],
                      [
                        0,
                        -4.532
                      ],
                      [
                        -2.417,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.625,
                        -1.41
                      ],
                      [
                        1.611,
                        -2.518
                      ],
                      [
                        0,
                        -5.338
                      ],
                      [
                        -4.532,
                        -4.028
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -73.315
                      ],
                      [
                        11.984,
                        -67.273
                      ],
                      [
                        5.942,
                        -53.778
                      ],
                      [
                        8.359,
                        -44.412
                      ],
                      [
                        16.516,
                        -38.269
                      ],
                      [
                        16.516,
                        -37.967
                      ],
                      [
                        7.654,
                        -31.924
                      ],
                      [
                        3.424,
                        -20.041
                      ],
                      [
                        10.373,
                        -4.532
                      ],
                      [
                        30.212,
                        1.41
                      ],
                      [
                        50.052,
                        -4.532
                      ],
                      [
                        57.001,
                        -20.041
                      ],
                      [
                        52.771,
                        -31.924
                      ],
                      [
                        43.909,
                        -37.967
                      ],
                      [
                        43.909,
                        -38.269
                      ],
                      [
                        52.066,
                        -44.412
                      ],
                      [
                        54.483,
                        -53.778
                      ],
                      [
                        48.441,
                        -67.273
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.618,
                        -2.417
                      ],
                      [
                        0,
                        -3.021
                      ],
                      [
                        1.813,
                        -2.014
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        2.316,
                        2.417
                      ],
                      [
                        0,
                        3.424
                      ],
                      [
                        -2.316,
                        2.014
                      ]
                    ],
                    "o": [
                      [
                        4.431,
                        0
                      ],
                      [
                        2.216,
                        2.014
                      ],
                      [
                        0,
                        3.424
                      ],
                      [
                        -2.417,
                        2.417
                      ],
                      [
                        -5.136,
                        0
                      ],
                      [
                        -1.913,
                        -2.014
                      ],
                      [
                        0,
                        -3.021
                      ],
                      [
                        2.618,
                        -2.417
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -64.554
                      ],
                      [
                        40.787,
                        -61.029
                      ],
                      [
                        44.211,
                        -53.375
                      ],
                      [
                        41.391,
                        -45.218
                      ],
                      [
                        30.212,
                        -41.592
                      ],
                      [
                        19.034,
                        -45.218
                      ],
                      [
                        16.214,
                        -53.375
                      ],
                      [
                        19.638,
                        -61.029
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 2,
                'ty': 'sh',
                "ix": 3,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.237,
                        0
                      ],
                      [
                        -2.82,
                        -2.618
                      ],
                      [
                        0,
                        -4.028
                      ],
                      [
                        2.618,
                        -2.316
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        2.719,
                        2.316
                      ],
                      [
                        0,
                        3.726
                      ],
                      [
                        -2.719,
                        2.417
                      ]
                    ],
                    "o": [
                      [
                        5.237,
                        0
                      ],
                      [
                        2.618,
                        2.316
                      ],
                      [
                        0,
                        3.726
                      ],
                      [
                        -2.921,
                        2.316
                      ],
                      [
                        -5.136,
                        0
                      ],
                      [
                        -2.82,
                        -2.316
                      ],
                      [
                        0,
                        -4.028
                      ],
                      [
                        2.618,
                        -2.618
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -33.435
                      ],
                      [
                        42.197,
                        -29.507
                      ],
                      [
                        46.124,
                        -20.041
                      ],
                      [
                        42.197,
                        -10.977
                      ],
                      [
                        30.212,
                        -7.452
                      ],
                      [
                        18.43,
                        -10.977
                      ],
                      [
                        14.301,
                        -20.041
                      ],
                      [
                        18.329,
                        -29.608
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '8',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '8',
            "np": 6,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '9',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.762,
                        0
                      ],
                      [
                        4.834,
                        -4.733
                      ],
                      [
                        0,
                        -7.251
                      ],
                      [
                        -4.129,
                        -4.431
                      ],
                      [
                        -7.05,
                        0
                      ],
                      [
                        -2.921,
                        1.41
                      ],
                      [
                        -2.014,
                        3.122
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -0.403
                      ],
                      [
                        2.82,
                        -4.633
                      ],
                      [
                        4.935,
                        0
                      ],
                      [
                        1.41,
                        6.949
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -13.998,
                        0
                      ],
                      [
                        -4.935,
                        7.251
                      ],
                      [
                        0,
                        11.682
                      ],
                      [
                        4.431,
                        6.143
                      ]
                    ],
                    "o": [
                      [
                        -7.15,
                        0
                      ],
                      [
                        -4.834,
                        4.633
                      ],
                      [
                        0,
                        7.05
                      ],
                      [
                        4.23,
                        4.532
                      ],
                      [
                        3.726,
                        0
                      ],
                      [
                        3.021,
                        -1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0.504
                      ],
                      [
                        0,
                        6.949
                      ],
                      [
                        -3.021,
                        4.834
                      ],
                      [
                        -7.251,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.813,
                        12.991
                      ],
                      [
                        8.258,
                        0
                      ],
                      [
                        4.733,
                        -7.251
                      ],
                      [
                        0,
                        -11.078
                      ],
                      [
                        -4.633,
                        -6.445
                      ]
                    ],
                    "v": [
                      [
                        29.407,
                        -73.315
                      ],
                      [
                        11.481,
                        -66.266
                      ],
                      [
                        4.33,
                        -48.541
                      ],
                      [
                        10.474,
                        -31.32
                      ],
                      [
                        27.493,
                        -24.472
                      ],
                      [
                        37.463,
                        -26.688
                      ],
                      [
                        45.117,
                        -33.737
                      ],
                      [
                        45.52,
                        -33.737
                      ],
                      [
                        45.621,
                        -32.428
                      ],
                      [
                        41.29,
                        -15.005
                      ],
                      [
                        29.407,
                        -7.654
                      ],
                      [
                        16.315,
                        -18.127
                      ],
                      [
                        5.64,
                        -18.127
                      ],
                      [
                        29.306,
                        1.41
                      ],
                      [
                        49.045,
                        -9.567
                      ],
                      [
                        56.195,
                        -37.967
                      ],
                      [
                        49.448,
                        -63.748
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '9',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.719,
                        -2.82
                      ],
                      [
                        0,
                        -4.733
                      ],
                      [
                        2.618,
                        -2.82
                      ],
                      [
                        4.431,
                        0
                      ],
                      [
                        2.518,
                        2.82
                      ],
                      [
                        0,
                        4.633
                      ],
                      [
                        -2.719,
                        2.921
                      ]
                    ],
                    "o": [
                      [
                        4.23,
                        0
                      ],
                      [
                        2.618,
                        2.719
                      ],
                      [
                        0,
                        4.633
                      ],
                      [
                        -2.618,
                        2.82
                      ],
                      [
                        -4.532,
                        0
                      ],
                      [
                        -2.719,
                        -2.719
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        2.618,
                        -3.021
                      ]
                    ],
                    "v": [
                      [
                        29.81,
                        -64.252
                      ],
                      [
                        40.283,
                        -60.022
                      ],
                      [
                        44.312,
                        -48.843
                      ],
                      [
                        40.384,
                        -37.564
                      ],
                      [
                        29.709,
                        -33.334
                      ],
                      [
                        19.135,
                        -37.564
                      ],
                      [
                        15.106,
                        -48.541
                      ],
                      [
                        19.135,
                        -59.821
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '9',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '9',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '6',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        13.898,
                        0
                      ],
                      [
                        4.834,
                        -7.352
                      ],
                      [
                        0,
                        -11.682
                      ],
                      [
                        -4.431,
                        -6.143
                      ],
                      [
                        -8.862,
                        0
                      ],
                      [
                        -4.834,
                        4.633
                      ],
                      [
                        0,
                        7.15
                      ],
                      [
                        4.129,
                        4.532
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        2.82,
                        -1.511
                      ],
                      [
                        2.014,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0.302
                      ],
                      [
                        -2.921,
                        4.633
                      ],
                      [
                        -5.035,
                        0
                      ],
                      [
                        -1.511,
                        -7.05
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -8.258,
                        0
                      ],
                      [
                        -4.834,
                        7.251
                      ],
                      [
                        0,
                        10.977
                      ],
                      [
                        4.633,
                        6.445
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        4.733,
                        -4.633
                      ],
                      [
                        0,
                        -7.05
                      ],
                      [
                        -4.23,
                        -4.431
                      ],
                      [
                        -3.827,
                        0
                      ],
                      [
                        -3.122,
                        1.511
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.101,
                        -0.604
                      ],
                      [
                        0,
                        -7.05
                      ],
                      [
                        2.921,
                        -4.935
                      ],
                      [
                        7.251,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.813,
                        -13.092
                      ]
                    ],
                    "v": [
                      [
                        31.219,
                        -73.315
                      ],
                      [
                        11.481,
                        -62.338
                      ],
                      [
                        4.33,
                        -33.939
                      ],
                      [
                        10.977,
                        -8.258
                      ],
                      [
                        31.119,
                        1.41
                      ],
                      [
                        49.045,
                        -5.64
                      ],
                      [
                        56.195,
                        -23.364
                      ],
                      [
                        49.951,
                        -40.787
                      ],
                      [
                        33.032,
                        -47.433
                      ],
                      [
                        23.062,
                        -45.218
                      ],
                      [
                        15.408,
                        -38.168
                      ],
                      [
                        15.005,
                        -38.168
                      ],
                      [
                        14.905,
                        -39.478
                      ],
                      [
                        19.235,
                        -56.9
                      ],
                      [
                        31.119,
                        -64.252
                      ],
                      [
                        44.211,
                        -53.778
                      ],
                      [
                        54.886,
                        -53.778
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.532,
                        0
                      ],
                      [
                        -2.618,
                        -2.82
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        2.719,
                        -3.021
                      ],
                      [
                        4.431,
                        0
                      ],
                      [
                        2.618,
                        2.82
                      ],
                      [
                        0,
                        4.633
                      ],
                      [
                        -2.618,
                        2.82
                      ]
                    ],
                    "o": [
                      [
                        4.431,
                        0
                      ],
                      [
                        2.618,
                        2.618
                      ],
                      [
                        0,
                        4.532
                      ],
                      [
                        -2.618,
                        2.921
                      ],
                      [
                        -4.33,
                        0
                      ],
                      [
                        -2.719,
                        -2.82
                      ],
                      [
                        0,
                        -4.733
                      ],
                      [
                        2.618,
                        -2.82
                      ]
                    ],
                    "v": [
                      [
                        30.817,
                        -38.571
                      ],
                      [
                        41.492,
                        -34.341
                      ],
                      [
                        45.419,
                        -23.364
                      ],
                      [
                        41.29,
                        -12.085
                      ],
                      [
                        30.716,
                        -7.654
                      ],
                      [
                        20.242,
                        -11.884
                      ],
                      [
                        16.214,
                        -23.062
                      ],
                      [
                        20.142,
                        -34.341
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '6',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '6',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': '0',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.459,
                        0
                      ],
                      [
                        4.633,
                        -7.15
                      ],
                      [
                        0,
                        -11.279
                      ],
                      [
                        -4.23,
                        -6.647
                      ],
                      [
                        -8.459,
                        0
                      ],
                      [
                        -4.633,
                        7.05
                      ],
                      [
                        0,
                        11.078
                      ],
                      [
                        4.23,
                        6.647
                      ]
                    ],
                    "o": [
                      [
                        -8.459,
                        0
                      ],
                      [
                        -4.23,
                        6.546
                      ],
                      [
                        0,
                        11.179
                      ],
                      [
                        4.633,
                        7.05
                      ],
                      [
                        8.459,
                        0
                      ],
                      [
                        4.23,
                        -6.647
                      ],
                      [
                        0,
                        -11.179
                      ],
                      [
                        -4.633,
                        -7.15
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -73.315
                      ],
                      [
                        10.574,
                        -62.64
                      ],
                      [
                        4.23,
                        -35.953
                      ],
                      [
                        10.574,
                        -9.265
                      ],
                      [
                        30.212,
                        1.41
                      ],
                      [
                        49.85,
                        -9.265
                      ],
                      [
                        56.195,
                        -35.953
                      ],
                      [
                        49.85,
                        -62.64
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '0',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -5.942,
                        0
                      ],
                      [
                        -2.417,
                        -6.546
                      ],
                      [
                        0,
                        -7.755
                      ],
                      [
                        1.712,
                        -4.532
                      ],
                      [
                        5.841,
                        0
                      ],
                      [
                        2.417,
                        6.445
                      ],
                      [
                        0,
                        7.654
                      ],
                      [
                        -1.813,
                        4.431
                      ]
                    ],
                    "o": [
                      [
                        5.841,
                        0
                      ],
                      [
                        1.712,
                        4.431
                      ],
                      [
                        0,
                        7.654
                      ],
                      [
                        -2.417,
                        6.445
                      ],
                      [
                        -5.942,
                        0
                      ],
                      [
                        -1.813,
                        -4.532
                      ],
                      [
                        0,
                        -7.755
                      ],
                      [
                        2.417,
                        -6.546
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -64.05
                      ],
                      [
                        42.7,
                        -54.282
                      ],
                      [
                        45.319,
                        -35.953
                      ],
                      [
                        42.7,
                        -17.624
                      ],
                      [
                        30.212,
                        -7.855
                      ],
                      [
                        17.725,
                        -17.624
                      ],
                      [
                        15.106,
                        -35.953
                      ],
                      [
                        17.725,
                        -54.282
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': '0',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': '0',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'p',
      "size": 7.99855995178223,
      'style': 'Medium',
      "w": 59.8,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.654,
                        0
                      ],
                      [
                        3.625,
                        -5.136
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -6.445,
                        0
                      ],
                      [
                        -4.23,
                        5.438
                      ],
                      [
                        0,
                        7.654
                      ],
                      [
                        4.028,
                        5.035
                      ]
                    ],
                    "o": [
                      [
                        -7.05,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.23,
                        6.042
                      ],
                      [
                        7.452,
                        0
                      ],
                      [
                        3.928,
                        -5.035
                      ],
                      [
                        0,
                        -8.057
                      ],
                      [
                        -4.431,
                        -5.438
                      ]
                    ],
                    "v": [
                      [
                        32.126,
                        -53.476
                      ],
                      [
                        16.013,
                        -45.822
                      ],
                      [
                        16.013,
                        -52.066
                      ],
                      [
                        6.042,
                        -52.066
                      ],
                      [
                        6.042,
                        19.94
                      ],
                      [
                        16.718,
                        19.94
                      ],
                      [
                        16.718,
                        -7.654
                      ],
                      [
                        32.629,
                        1.41
                      ],
                      [
                        50.253,
                        -6.747
                      ],
                      [
                        56.195,
                        -25.781
                      ],
                      [
                        50.153,
                        -45.319
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'p',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.633,
                        0
                      ],
                      [
                        -2.518,
                        -3.827
                      ],
                      [
                        0,
                        -5.74
                      ],
                      [
                        2.216,
                        -3.223
                      ],
                      [
                        5.237,
                        0
                      ],
                      [
                        2.417,
                        3.122
                      ],
                      [
                        0,
                        5.539
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        3.223
                      ]
                    ],
                    "o": [
                      [
                        5.237,
                        0
                      ],
                      [
                        2.014,
                        3.223
                      ],
                      [
                        0,
                        5.74
                      ],
                      [
                        -2.518,
                        3.424
                      ],
                      [
                        -4.028,
                        0
                      ],
                      [
                        -2.82,
                        -3.424
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -5.338
                      ],
                      [
                        2.518,
                        -3.726
                      ]
                    ],
                    "v": [
                      [
                        30.515,
                        -44.815
                      ],
                      [
                        42.096,
                        -39.175
                      ],
                      [
                        45.218,
                        -25.781
                      ],
                      [
                        41.794,
                        -12.387
                      ],
                      [
                        30.212,
                        -7.251
                      ],
                      [
                        20.544,
                        -11.984
                      ],
                      [
                        16.415,
                        -25.479
                      ],
                      [
                        16.415,
                        -26.385
                      ],
                      [
                        19.739,
                        -39.276
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'p',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'p',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'u',
      "size": 9.33164978027344,
      'style': 'Medium',
      "w": 57.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -12.589,
                        0
                      ],
                      [
                        -4.431,
                        5.64
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.417,
                        -2.316
                      ],
                      [
                        2.82,
                        0
                      ],
                      [
                        1.813,
                        2.014
                      ],
                      [
                        0,
                        4.431
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        13.998
                      ],
                      [
                        6.546,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.906,
                        3.827
                      ],
                      [
                        -2.417,
                        2.216
                      ],
                      [
                        -4.129,
                        0
                      ],
                      [
                        -1.913,
                        -2.014
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        6.042,
                        -52.066
                      ],
                      [
                        6.042,
                        -19.638
                      ],
                      [
                        24.875,
                        1.41
                      ],
                      [
                        41.29,
                        -7.05
                      ],
                      [
                        41.29,
                        0
                      ],
                      [
                        51.965,
                        0
                      ],
                      [
                        51.965,
                        -52.066
                      ],
                      [
                        41.29,
                        -52.066
                      ],
                      [
                        41.29,
                        -20.242
                      ],
                      [
                        36.255,
                        -10.977
                      ],
                      [
                        28.5,
                        -7.553
                      ],
                      [
                        19.537,
                        -10.675
                      ],
                      [
                        16.718,
                        -20.343
                      ],
                      [
                        16.718,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'u',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'u',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'N',
      "size": 9.33164978027344,
      'style': 'Medium',
      "w": 72.9,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.027,
                        0
                      ],
                      [
                        18.027,
                        -54.08
                      ],
                      [
                        18.43,
                        -54.08
                      ],
                      [
                        55.591,
                        0
                      ],
                      [
                        66.266,
                        0
                      ],
                      [
                        66.266,
                        -71.906
                      ],
                      [
                        55.289,
                        -71.906
                      ],
                      [
                        55.289,
                        -18.43
                      ],
                      [
                        54.886,
                        -18.43
                      ],
                      [
                        18.127,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'N',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'N',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'F',
      "size": 9.33164978027344,
      'style': 'Medium',
      "w": 58.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.027,
                        0
                      ],
                      [
                        18.027,
                        -32.227
                      ],
                      [
                        53.778,
                        -32.227
                      ],
                      [
                        53.778,
                        -41.592
                      ],
                      [
                        18.027,
                        -41.592
                      ],
                      [
                        18.027,
                        -62.54
                      ],
                      [
                        55.893,
                        -62.54
                      ],
                      [
                        55.893,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'F',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'F',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'Y',
      "size": 9.33164978027344,
      'style': 'Medium',
      "w": 68.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -71.906
                      ],
                      [
                        29.004,
                        -28.702
                      ],
                      [
                        29.004,
                        0
                      ],
                      [
                        39.88,
                        0
                      ],
                      [
                        39.88,
                        -28.702
                      ],
                      [
                        68.381,
                        -71.906
                      ],
                      [
                        55.188,
                        -71.906
                      ],
                      [
                        34.442,
                        -38.974
                      ],
                      [
                        13.696,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'Y',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'Y',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'C',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 73.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.459,
                        0
                      ],
                      [
                        6.042,
                        -7.654
                      ],
                      [
                        0,
                        -10.675
                      ],
                      [
                        -5.136,
                        -6.445
                      ],
                      [
                        -11.984,
                        0
                      ],
                      [
                        -5.539,
                        4.431
                      ],
                      [
                        -1.611,
                        8.459
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.424,
                        -2.719
                      ],
                      [
                        5.136,
                        0
                      ],
                      [
                        3.726,
                        4.834
                      ],
                      [
                        0,
                        8.459
                      ],
                      [
                        -3.525,
                        4.633
                      ],
                      [
                        -7.553,
                        0
                      ],
                      [
                        -3.223,
                        -2.216
                      ],
                      [
                        -1.208,
                        -4.733
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        5.237,
                        4.23
                      ]
                    ],
                    "o": [
                      [
                        -11.481,
                        0
                      ],
                      [
                        -5.338,
                        6.647
                      ],
                      [
                        0,
                        10.876
                      ],
                      [
                        5.841,
                        7.452
                      ],
                      [
                        7.755,
                        0
                      ],
                      [
                        5.942,
                        -4.733
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.41,
                        5.438
                      ],
                      [
                        -3.223,
                        2.417
                      ],
                      [
                        -7.855,
                        0
                      ],
                      [
                        -3.424,
                        -4.532
                      ],
                      [
                        0,
                        -8.258
                      ],
                      [
                        3.827,
                        -5.237
                      ],
                      [
                        5.035,
                        0
                      ],
                      [
                        3.323,
                        2.216
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.108,
                        -7.251
                      ],
                      [
                        -5.438,
                        -4.431
                      ]
                    ],
                    "v": [
                      [
                        38.269,
                        -73.315
                      ],
                      [
                        11.984,
                        -61.935
                      ],
                      [
                        4.028,
                        -35.852
                      ],
                      [
                        11.682,
                        -9.869
                      ],
                      [
                        38.47,
                        1.41
                      ],
                      [
                        58.411,
                        -5.237
                      ],
                      [
                        69.69,
                        -24.976
                      ],
                      [
                        58.209,
                        -24.976
                      ],
                      [
                        50.958,
                        -12.689
                      ],
                      [
                        38.37,
                        -8.963
                      ],
                      [
                        20.947,
                        -16.315
                      ],
                      [
                        15.811,
                        -35.852
                      ],
                      [
                        21.048,
                        -55.188
                      ],
                      [
                        38.068,
                        -62.943
                      ],
                      [
                        50.455,
                        -59.72
                      ],
                      [
                        57.202,
                        -49.347
                      ],
                      [
                        68.683,
                        -49.347
                      ],
                      [
                        59.216,
                        -66.669
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'C',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'C',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'h',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 57.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.115,
                        2.82
                      ],
                      [
                        -3.625,
                        0
                      ],
                      [
                        -2.014,
                        -2.518
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        2.921,
                        3.525
                      ],
                      [
                        6.345,
                        0
                      ],
                      [
                        2.518,
                        -1.309
                      ],
                      [
                        1.913,
                        -2.921
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        2.014,
                        -2.82
                      ],
                      [
                        3.928,
                        0
                      ],
                      [
                        1.712,
                        2.316
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.949
                      ],
                      [
                        -3.122,
                        -3.726
                      ],
                      [
                        -3.323,
                        0
                      ],
                      [
                        -2.82,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        6.042,
                        -73.315
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        17.523,
                        0
                      ],
                      [
                        17.523,
                        -29.105
                      ],
                      [
                        20.645,
                        -39.88
                      ],
                      [
                        29.105,
                        -44.11
                      ],
                      [
                        37.967,
                        -40.384
                      ],
                      [
                        40.585,
                        -30.212
                      ],
                      [
                        40.585,
                        0
                      ],
                      [
                        52.066,
                        0
                      ],
                      [
                        52.066,
                        -32.227
                      ],
                      [
                        47.635,
                        -47.937
                      ],
                      [
                        33.435,
                        -53.476
                      ],
                      [
                        24.673,
                        -51.562
                      ],
                      [
                        17.523,
                        -45.117
                      ],
                      [
                        17.523,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'h',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'h',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'a',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 57.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.654,
                        0
                      ],
                      [
                        3.827,
                        -2.417
                      ],
                      [
                        1.108,
                        -5.74
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.216,
                        1.309
                      ],
                      [
                        -3.223,
                        0
                      ],
                      [
                        0,
                        -6.848
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.23,
                        -3.021
                      ],
                      [
                        0,
                        -5.841
                      ],
                      [
                        -3.223,
                        -2.82
                      ],
                      [
                        -5.438,
                        0
                      ],
                      [
                        -3.323,
                        1.511
                      ],
                      [
                        -2.014,
                        2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.223,
                        3.323
                      ]
                    ],
                    "o": [
                      [
                        -6.647,
                        0
                      ],
                      [
                        -4.532,
                        2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        -2.719
                      ],
                      [
                        1.913,
                        -1.208
                      ],
                      [
                        7.452,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -7.654,
                        0.201
                      ],
                      [
                        -4.633,
                        3.122
                      ],
                      [
                        0,
                        4.33
                      ],
                      [
                        3.122,
                        2.719
                      ],
                      [
                        4.532,
                        0
                      ],
                      [
                        2.921,
                        -1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -6.345
                      ],
                      [
                        -3.625,
                        -3.827
                      ]
                    ],
                    "v": [
                      [
                        29.709,
                        -53.476
                      ],
                      [
                        13.998,
                        -49.951
                      ],
                      [
                        5.539,
                        -37.363
                      ],
                      [
                        16.919,
                        -36.456
                      ],
                      [
                        21.249,
                        -42.499
                      ],
                      [
                        28.903,
                        -44.211
                      ],
                      [
                        40.082,
                        -34.039
                      ],
                      [
                        40.082,
                        -32.025
                      ],
                      [
                        28.702,
                        -31.723
                      ],
                      [
                        10.876,
                        -26.889
                      ],
                      [
                        4.028,
                        -13.394
                      ],
                      [
                        8.862,
                        -2.719
                      ],
                      [
                        21.652,
                        1.41
                      ],
                      [
                        33.435,
                        -0.906
                      ],
                      [
                        40.887,
                        -6.848
                      ],
                      [
                        40.887,
                        0
                      ],
                      [
                        51.562,
                        0
                      ],
                      [
                        51.562,
                        -33.234
                      ],
                      [
                        46.729,
                        -47.736
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        3.021,
                        -2.618
                      ],
                      [
                        4.431,
                        0
                      ],
                      [
                        1.611,
                        1.208
                      ],
                      [
                        0,
                        1.813
                      ],
                      [
                        -9.164,
                        0.201
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        4.028
                      ],
                      [
                        -3.021,
                        2.518
                      ],
                      [
                        -2.518,
                        0
                      ],
                      [
                        -1.611,
                        -1.208
                      ],
                      [
                        0,
                        -6.244
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        40.082,
                        -23.767
                      ],
                      [
                        40.082,
                        -21.149
                      ],
                      [
                        35.449,
                        -11.179
                      ],
                      [
                        24.271,
                        -7.352
                      ],
                      [
                        18.127,
                        -9.265
                      ],
                      [
                        15.811,
                        -13.797
                      ],
                      [
                        29.507,
                        -23.465
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'a',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'a',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 't',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 36.2,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.014,
                        -2.216
                      ],
                      [
                        -4.532,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.604,
                        0.604
                      ],
                      [
                        0,
                        1.208
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        2.115,
                        2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.208,
                        0
                      ],
                      [
                        -0.604,
                        -0.705
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        22.357,
                        -68.884
                      ],
                      [
                        10.876,
                        -64.151
                      ],
                      [
                        10.876,
                        -52.066
                      ],
                      [
                        1.511,
                        -52.066
                      ],
                      [
                        1.511,
                        -42.599
                      ],
                      [
                        10.876,
                        -42.599
                      ],
                      [
                        10.876,
                        -13.193
                      ],
                      [
                        13.898,
                        -3.625
                      ],
                      [
                        23.868,
                        0
                      ],
                      [
                        32.629,
                        0
                      ],
                      [
                        32.629,
                        -9.467
                      ],
                      [
                        25.781,
                        -9.467
                      ],
                      [
                        23.163,
                        -10.373
                      ],
                      [
                        22.357,
                        -13.193
                      ],
                      [
                        22.357,
                        -42.599
                      ],
                      [
                        33.939,
                        -42.599
                      ],
                      [
                        33.939,
                        -52.066
                      ],
                      [
                        22.357,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 't',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 't',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'D',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 72,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -6.042,
                        6.445
                      ],
                      [
                        0,
                        11.38
                      ],
                      [
                        5.74,
                        6.042
                      ],
                      [
                        11.682,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        11.682,
                        0
                      ],
                      [
                        5.74,
                        -6.143
                      ],
                      [
                        0,
                        -11.481
                      ],
                      [
                        -6.042,
                        -6.445
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        33.334,
                        0
                      ],
                      [
                        59.821,
                        -9.668
                      ],
                      [
                        68.481,
                        -35.953
                      ],
                      [
                        59.821,
                        -62.238
                      ],
                      [
                        33.334,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'D',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -4.129,
                        -4.129
                      ],
                      [
                        0,
                        -9.064
                      ],
                      [
                        4.028,
                        -4.23
                      ],
                      [
                        8.963,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        8.963,
                        0
                      ],
                      [
                        4.028,
                        4.028
                      ],
                      [
                        0,
                        8.862
                      ],
                      [
                        -4.129,
                        4.129
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        18.832,
                        -61.835
                      ],
                      [
                        31.119,
                        -61.835
                      ],
                      [
                        50.757,
                        -55.692
                      ],
                      [
                        56.799,
                        -35.953
                      ],
                      [
                        50.757,
                        -16.315
                      ],
                      [
                        31.119,
                        -10.071
                      ],
                      [
                        18.832,
                        -10.071
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'D',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'D',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'i',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 27.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.216,
                        0
                      ],
                      [
                        1.511,
                        -1.41
                      ],
                      [
                        0,
                        -2.216
                      ],
                      [
                        -1.511,
                        -1.511
                      ],
                      [
                        -2.216,
                        0
                      ],
                      [
                        -1.511,
                        1.41
                      ],
                      [
                        0,
                        2.216
                      ],
                      [
                        1.41,
                        1.41
                      ]
                    ],
                    "o": [
                      [
                        -2.216,
                        0
                      ],
                      [
                        -1.511,
                        1.41
                      ],
                      [
                        0,
                        2.216
                      ],
                      [
                        1.511,
                        1.41
                      ],
                      [
                        2.216,
                        0
                      ],
                      [
                        1.511,
                        -1.41
                      ],
                      [
                        0,
                        -2.216
                      ],
                      [
                        -1.511,
                        -1.41
                      ]
                    ],
                    "v": [
                      [
                        13.797,
                        -73.315
                      ],
                      [
                        8.258,
                        -71.201
                      ],
                      [
                        6.042,
                        -65.762
                      ],
                      [
                        8.258,
                        -60.223
                      ],
                      [
                        13.797,
                        -58.109
                      ],
                      [
                        19.336,
                        -60.223
                      ],
                      [
                        21.652,
                        -65.762
                      ],
                      [
                        19.437,
                        -71.201
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        8.057,
                        -52.066
                      ],
                      [
                        8.057,
                        0
                      ],
                      [
                        19.537,
                        0
                      ],
                      [
                        19.537,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'i',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'i',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 's',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 52.5,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        13.696,
                        0
                      ],
                      [
                        3.928,
                        -2.82
                      ],
                      [
                        0,
                        -4.834
                      ],
                      [
                        -4.028,
                        -2.518
                      ],
                      [
                        -6.143,
                        -1.41
                      ],
                      [
                        -1.611,
                        -0.806
                      ],
                      [
                        0,
                        -2.417
                      ],
                      [
                        8.057,
                        0
                      ],
                      [
                        1.712,
                        1.208
                      ],
                      [
                        0.604,
                        3.323
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -14.502,
                        0
                      ],
                      [
                        0,
                        11.078
                      ],
                      [
                        4.129,
                        2.618
                      ],
                      [
                        5.841,
                        1.41
                      ],
                      [
                        1.712,
                        0.705
                      ],
                      [
                        0,
                        1.611
                      ],
                      [
                        -1.813,
                        0.906
                      ],
                      [
                        -3.021,
                        0
                      ],
                      [
                        -1.712,
                        -1.108
                      ],
                      [
                        -0.806,
                        -2.618
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -6.445,
                        0
                      ],
                      [
                        -4.028,
                        2.618
                      ],
                      [
                        0,
                        4.532
                      ],
                      [
                        2.518,
                        1.611
                      ],
                      [
                        5.237,
                        1.208
                      ],
                      [
                        3.021,
                        1.41
                      ],
                      [
                        0,
                        4.23
                      ],
                      [
                        -3.827,
                        0
                      ],
                      [
                        -1.712,
                        -1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.108,
                        12.186
                      ],
                      [
                        15.61,
                        0
                      ],
                      [
                        0,
                        -4.633
                      ],
                      [
                        -2.82,
                        -1.913
                      ],
                      [
                        -5.237,
                        -1.309
                      ],
                      [
                        -2.82,
                        -1.208
                      ],
                      [
                        0,
                        -2.014
                      ],
                      [
                        1.511,
                        -0.906
                      ],
                      [
                        3.525,
                        0
                      ],
                      [
                        1.611,
                        1.007
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.208,
                        -11.078
                      ]
                    ],
                    "v": [
                      [
                        25.681,
                        -53.476
                      ],
                      [
                        10.172,
                        -49.347
                      ],
                      [
                        4.23,
                        -38.168
                      ],
                      [
                        10.272,
                        -27.594
                      ],
                      [
                        23.264,
                        -23.062
                      ],
                      [
                        33.536,
                        -20.142
                      ],
                      [
                        38.068,
                        -14.401
                      ],
                      [
                        25.983,
                        -7.956
                      ],
                      [
                        17.725,
                        -9.869
                      ],
                      [
                        14.2,
                        -16.919
                      ],
                      [
                        3.021,
                        -16.919
                      ],
                      [
                        26.385,
                        1.41
                      ],
                      [
                        49.85,
                        -15.207
                      ],
                      [
                        43.607,
                        -26.184
                      ],
                      [
                        30.515,
                        -31.119
                      ],
                      [
                        20.142,
                        -34.14
                      ],
                      [
                        16.013,
                        -38.47
                      ],
                      [
                        18.631,
                        -42.801
                      ],
                      [
                        25.479,
                        -44.11
                      ],
                      [
                        33.334,
                        -42.499
                      ],
                      [
                        36.96,
                        -36.96
                      ],
                      [
                        48.138,
                        -36.96
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 's',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 's',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'c',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 56.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        6.747,
                        0
                      ],
                      [
                        4.532,
                        -5.438
                      ],
                      [
                        0,
                        -7.855
                      ],
                      [
                        -4.33,
                        -5.035
                      ],
                      [
                        -8.057,
                        0
                      ],
                      [
                        -4.028,
                        3.323
                      ],
                      [
                        -1.208,
                        7.05
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        7.654,
                        0
                      ],
                      [
                        2.216,
                        3.122
                      ],
                      [
                        0,
                        5.74
                      ],
                      [
                        -2.417,
                        3.122
                      ],
                      [
                        -4.23,
                        0
                      ],
                      [
                        -2.115,
                        -1.611
                      ],
                      [
                        -0.705,
                        -3.323
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.129,
                        3.223
                      ]
                    ],
                    "o": [
                      [
                        -8.157,
                        0
                      ],
                      [
                        -4.23,
                        4.935
                      ],
                      [
                        0,
                        8.057
                      ],
                      [
                        4.431,
                        5.237
                      ],
                      [
                        6.747,
                        0
                      ],
                      [
                        4.129,
                        -3.424
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -0.906,
                        7.553
                      ],
                      [
                        -4.431,
                        0
                      ],
                      [
                        -2.417,
                        -3.223
                      ],
                      [
                        0,
                        -5.64
                      ],
                      [
                        2.417,
                        -3.223
                      ],
                      [
                        3.424,
                        0
                      ],
                      [
                        2.014,
                        1.611
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.007,
                        -6.546
                      ],
                      [
                        -4.028,
                        -3.021
                      ]
                    ],
                    "v": [
                      [
                        29.407,
                        -53.476
                      ],
                      [
                        10.373,
                        -45.319
                      ],
                      [
                        4.028,
                        -26.083
                      ],
                      [
                        10.474,
                        -6.445
                      ],
                      [
                        29.205,
                        1.41
                      ],
                      [
                        45.419,
                        -3.625
                      ],
                      [
                        53.476,
                        -19.336
                      ],
                      [
                        42.096,
                        -19.336
                      ],
                      [
                        29.306,
                        -7.956
                      ],
                      [
                        19.336,
                        -12.689
                      ],
                      [
                        15.811,
                        -26.184
                      ],
                      [
                        19.437,
                        -39.377
                      ],
                      [
                        29.407,
                        -44.11
                      ],
                      [
                        37.766,
                        -41.693
                      ],
                      [
                        41.895,
                        -34.341
                      ],
                      [
                        53.275,
                        -34.341
                      ],
                      [
                        45.52,
                        -48.944
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'c',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'c',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'o',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 60,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        7.855,
                        0
                      ],
                      [
                        4.733,
                        -5.237
                      ],
                      [
                        0,
                        -8.057
                      ],
                      [
                        -4.733,
                        -5.035
                      ],
                      [
                        -7.956,
                        0
                      ],
                      [
                        -4.834,
                        5.237
                      ],
                      [
                        0,
                        7.956
                      ],
                      [
                        4.733,
                        5.136
                      ]
                    ],
                    "o": [
                      [
                        -7.956,
                        0
                      ],
                      [
                        -4.834,
                        5.136
                      ],
                      [
                        0,
                        7.956
                      ],
                      [
                        4.834,
                        5.237
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        4.633,
                        -5.035
                      ],
                      [
                        0,
                        -8.057
                      ],
                      [
                        -4.834,
                        -5.237
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -53.476
                      ],
                      [
                        11.179,
                        -45.721
                      ],
                      [
                        4.028,
                        -25.983
                      ],
                      [
                        11.078,
                        -6.445
                      ],
                      [
                        30.212,
                        1.41
                      ],
                      [
                        49.347,
                        -6.445
                      ],
                      [
                        56.396,
                        -25.983
                      ],
                      [
                        49.246,
                        -45.721
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -4.834,
                        0
                      ],
                      [
                        -2.618,
                        -3.827
                      ],
                      [
                        0,
                        -5.035
                      ],
                      [
                        2.216,
                        -3.323
                      ],
                      [
                        4.733,
                        0
                      ],
                      [
                        2.618,
                        3.726
                      ],
                      [
                        0,
                        5.035
                      ],
                      [
                        -2.216,
                        3.223
                      ]
                    ],
                    "o": [
                      [
                        4.733,
                        0
                      ],
                      [
                        2.216,
                        3.223
                      ],
                      [
                        0,
                        4.935
                      ],
                      [
                        -2.618,
                        3.726
                      ],
                      [
                        -4.834,
                        0
                      ],
                      [
                        -2.216,
                        -3.223
                      ],
                      [
                        0,
                        -5.035
                      ],
                      [
                        2.618,
                        -3.827
                      ]
                    ],
                    "v": [
                      [
                        30.212,
                        -44.11
                      ],
                      [
                        41.29,
                        -38.47
                      ],
                      [
                        44.714,
                        -25.983
                      ],
                      [
                        41.29,
                        -13.596
                      ],
                      [
                        30.212,
                        -7.956
                      ],
                      [
                        19.135,
                        -13.596
                      ],
                      [
                        15.811,
                        -25.983
                      ],
                      [
                        19.135,
                        -38.47
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'o',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'o',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'v',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 50.6,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -52.066
                      ],
                      [
                        19.739,
                        0
                      ],
                      [
                        31.219,
                        0
                      ],
                      [
                        50.455,
                        -52.066
                      ],
                      [
                        37.967,
                        -52.066
                      ],
                      [
                        25.479,
                        -14.301
                      ],
                      [
                        12.991,
                        -52.066
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'v',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'v',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'e',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 56.7,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        8.56,
                        0
                      ],
                      [
                        4.431,
                        -5.338
                      ],
                      [
                        0,
                        -7.755
                      ],
                      [
                        -4.834,
                        -4.935
                      ],
                      [
                        -7.755,
                        0
                      ],
                      [
                        -4.633,
                        3.928
                      ],
                      [
                        -1.208,
                        4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        1.712,
                        -1.309
                      ],
                      [
                        3.525,
                        0
                      ],
                      [
                        2.216,
                        2.518
                      ],
                      [
                        0.403,
                        4.834
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        4.129,
                        5.237
                      ]
                    ],
                    "o": [
                      [
                        -7.755,
                        0
                      ],
                      [
                        -4.633,
                        5.237
                      ],
                      [
                        0,
                        8.661
                      ],
                      [
                        4.532,
                        4.733
                      ],
                      [
                        6.949,
                        0
                      ],
                      [
                        3.625,
                        -3.223
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.41,
                        2.921
                      ],
                      [
                        -2.216,
                        1.611
                      ],
                      [
                        -4.129,
                        0
                      ],
                      [
                        -2.216,
                        -2.518
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -9.366
                      ],
                      [
                        -4.532,
                        -5.841
                      ]
                    ],
                    "v": [
                      [
                        28.198,
                        -53.476
                      ],
                      [
                        9.869,
                        -45.52
                      ],
                      [
                        3.021,
                        -26.083
                      ],
                      [
                        10.272,
                        -5.74
                      ],
                      [
                        28.702,
                        1.41
                      ],
                      [
                        46.024,
                        -4.532
                      ],
                      [
                        53.275,
                        -16.718
                      ],
                      [
                        41.794,
                        -16.718
                      ],
                      [
                        37.161,
                        -10.373
                      ],
                      [
                        28.601,
                        -7.956
                      ],
                      [
                        19.034,
                        -11.783
                      ],
                      [
                        15.106,
                        -22.861
                      ],
                      [
                        54.08,
                        -22.861
                      ],
                      [
                        47.836,
                        -44.815
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'e',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              },
              {
                "ind": 1,
                'ty': 'sh',
                "ix": 2,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        -3.827,
                        0
                      ],
                      [
                        -1.007,
                        -8.459
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.115,
                        2.115
                      ]
                    ],
                    "o": [
                      [
                        8.057,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.705,
                        -4.129
                      ],
                      [
                        2.216,
                        -2.216
                      ]
                    ],
                    "v": [
                      [
                        28.5,
                        -44.11
                      ],
                      [
                        42.096,
                        -31.522
                      ],
                      [
                        15.308,
                        -31.522
                      ],
                      [
                        19.537,
                        -40.887
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'e',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'e',
            "np": 5,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'r',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 37.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        2.518,
                        0
                      ],
                      [
                        2.316,
                        -1.913
                      ],
                      [
                        1.41,
                        -2.618
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.82,
                        2.719
                      ],
                      [
                        -3.021,
                        0
                      ],
                      [
                        -2.618,
                        -0.806
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -3.223,
                        0
                      ],
                      [
                        -1.913,
                        1.41
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -4.431
                      ],
                      [
                        2.417,
                        -2.417
                      ],
                      [
                        2.417,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -1.813,
                        -0.906
                      ]
                    ],
                    "v": [
                      [
                        30.615,
                        -53.476
                      ],
                      [
                        22.357,
                        -50.656
                      ],
                      [
                        17.422,
                        -44.614
                      ],
                      [
                        17.422,
                        -52.066
                      ],
                      [
                        6.042,
                        -52.066
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        17.422,
                        0
                      ],
                      [
                        17.422,
                        -27.594
                      ],
                      [
                        21.552,
                        -38.269
                      ],
                      [
                        29.709,
                        -41.794
                      ],
                      [
                        37.161,
                        -40.686
                      ],
                      [
                        37.161,
                        -52.167
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'r',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'r',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'M',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 90.3,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -71.906
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.832,
                        0
                      ],
                      [
                        18.832,
                        -49.548
                      ],
                      [
                        19.235,
                        -49.548
                      ],
                      [
                        40.384,
                        0
                      ],
                      [
                        50.555,
                        0
                      ],
                      [
                        71.704,
                        -49.548
                      ],
                      [
                        72.107,
                        -49.548
                      ],
                      [
                        72.107,
                        0
                      ],
                      [
                        83.89,
                        0
                      ],
                      [
                        83.89,
                        -71.906
                      ],
                      [
                        70.093,
                        -71.906
                      ],
                      [
                        45.721,
                        -15.509
                      ],
                      [
                        45.319,
                        -15.509
                      ],
                      [
                        20.847,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'M',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'M',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'n',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 58.1,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        12.991,
                        0
                      ],
                      [
                        2.618,
                        -1.309
                      ],
                      [
                        2.014,
                        -2.417
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        -2.417,
                        2.216
                      ],
                      [
                        -2.82,
                        0
                      ],
                      [
                        0,
                        -8.56
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        -3.122,
                        0
                      ],
                      [
                        -2.518,
                        1.208
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0.403,
                        -4.129
                      ],
                      [
                        2.115,
                        -2.014
                      ],
                      [
                        7.855,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        -14.301
                      ]
                    ],
                    "v": [
                      [
                        32.932,
                        -53.476
                      ],
                      [
                        24.371,
                        -51.562
                      ],
                      [
                        17.523,
                        -46.024
                      ],
                      [
                        17.523,
                        -52.066
                      ],
                      [
                        6.042,
                        -52.066
                      ],
                      [
                        6.042,
                        0
                      ],
                      [
                        17.523,
                        0
                      ],
                      [
                        17.523,
                        -31.32
                      ],
                      [
                        21.753,
                        -40.887
                      ],
                      [
                        29.205,
                        -43.909
                      ],
                      [
                        40.988,
                        -31.119
                      ],
                      [
                        40.988,
                        0
                      ],
                      [
                        52.469,
                        0
                      ],
                      [
                        52.469,
                        -32.126
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'n',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'n',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'W',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 95.3,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        0.504,
                        -71.906
                      ],
                      [
                        21.149,
                        0
                      ],
                      [
                        33.737,
                        0
                      ],
                      [
                        47.836,
                        -54.08
                      ],
                      [
                        48.239,
                        -54.08
                      ],
                      [
                        62.238,
                        0
                      ],
                      [
                        74.725,
                        0
                      ],
                      [
                        95.471,
                        -71.906
                      ],
                      [
                        82.178,
                        -71.906
                      ],
                      [
                        68.683,
                        -17.221
                      ],
                      [
                        68.28,
                        -17.221
                      ],
                      [
                        54.08,
                        -71.906
                      ],
                      [
                        41.895,
                        -71.906
                      ],
                      [
                        27.695,
                        -17.221
                      ],
                      [
                        27.292,
                        -17.221
                      ],
                      [
                        13.797,
                        -71.906
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'W',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'W',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    },
    {
      'ch': 'l',
      "size": 6.66547012329102,
      'style': 'Semibold',
      "w": 25.4,
      "data": {
        "shapes": [
          {
            'ty': 'gr',
            "it": [
              {
                "ind": 0,
                'ty': 'sh',
                "ix": 1,
                "ks": {
                  "a": 0,
                  "k": {
                    "i": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "o": [
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ],
                      [
                        0,
                        0
                      ]
                    ],
                    "v": [
                      [
                        7.05,
                        -73.315
                      ],
                      [
                        7.05,
                        0
                      ],
                      [
                        18.53,
                        0
                      ],
                      [
                        18.53,
                        -73.315
                      ]
                    ],
                    "c": true
                  },
                  "ix": 2
                },
                'nm': 'l',
                'mn': 'ADBE Vector Shape - Group',
                "hd": false
              }
            ],
            'nm': 'l',
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            'mn': 'ADBE Vector Group',
            "hd": false
          }
        ]
      },
      'fFamily': 'PingFang SC'
    }
  ]
}