import * as React from "react";
import styled from "styled-components";
import Row from "./assets/Row.png";

const StyledWarnAndroid = styled.div`
  background: #242ce0;
  height: 16.8vw;
  padding: 3.5vw 6.7vw;
  font-size: 3.5vw;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: inline-flex;
    width: 76.8vw;
    color: #ffffff;
  }
  img {
    // margin-top: 0% !important;
    width: 7.9vw !important;
    height: 9.5vw !important;
  }
`;

type WarnAndroidProps = {};
const WarnAndroid: React.FC<WarnAndroidProps> = ({}) => {
  return (
    <StyledWarnAndroid>
      <div>
        Click the button in the upper right corner, and then in the pop-up menu,
        click open in Browser.
      </div>
      <img src={Row} />
    </StyledWarnAndroid>
  );
};

export default WarnAndroid;
